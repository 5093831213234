import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Input, Switch, Form, Tooltip, Button } from 'antd';
import { FulfillmentProviderLogos, FulfillmentProviders } from '../../../../../../../constants';
import { useState } from 'react';
import { deliveryPartnerFields, deliveryPartnersWithBody } from '../../constants';
import styles from './index.module.scss';

const shouldRenderBody = (deliveryPartnerName) => deliveryPartnersWithBody.includes(deliveryPartnerName?.toLowerCase());

export const DeliveryPartnerCard = ({ provided, deliveryPartnerDetails, handleUpdateField, isDisabled, handleUpdateFieldOnSubmit }) => {
	const { name: deliveryPartnerName, status, priority } = deliveryPartnerDetails;
	const [isBodyOpen, setIsBodyOpen] = useState(false);
	const toggleBodyOpen = () => setIsBodyOpen((isBodyOpen) => !isBodyOpen);
	const [form] = Form.useForm();

	const shouldDisplayShiperfecto3PLEngineLabel =
		deliveryPartnerDetails.name === FulfillmentProviders.SHIPERFECTO && !deliveryPartnerDetails.password;

	const onSubmit = async (values) => {
		try {
			const submitValues = Object.keys(values).map((itemKey) => ({
				key: itemKey,
				value: values[itemKey]
			}));
			await handleUpdateFieldOnSubmit(submitValues, deliveryPartnerName);
		} catch (error) {
			console.log('!!!!!Error!!!!!', error);
		}
	};

	return (
		<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={styles.deliveryPartnerCard}>
			<div className={styles.header}>
				<div className={styles.content}>
					<div className={styles.flex}>
						<img
							alt={`${deliveryPartnerName} logo`}
							className={styles.sellerIcon}
							src={
								FulfillmentProviderLogos[
									shouldDisplayShiperfecto3PLEngineLabel ? FulfillmentProviders.WHEREHOUSE_LIGHTNING : deliveryPartnerName
								]
							}
						/>
						<div>
							<h4 className={styles.name}>
								{shouldDisplayShiperfecto3PLEngineLabel ? 'WH 3pl Engine' : deliveryPartnerName}
							</h4>
							<p className={styles.priority}>Priority: {priority}</p>
						</div>
					</div>
				</div>
				<Switch
					checkedChildren="ON"
					unCheckedChildren="OFF"
					checked={
						status ||
						deliveryPartnerDetails.name === FulfillmentProviders.DTDCAIR ||
						deliveryPartnerDetails.name === FulfillmentProviders.DTDSURFACE
					}
					disabled={
						isDisabled ||
						deliveryPartnerDetails.name === FulfillmentProviders.DTDCAIR ||
						deliveryPartnerDetails.name === FulfillmentProviders.DTDSURFACE
					}
					onChange={(updatedStatus) => handleUpdateField('status', updatedStatus, deliveryPartnerName)}
				/>
			</div>

			{isBodyOpen && (
				<div className={styles.body}>
					<Form form={form} onFinish={onSubmit} layout="vertical" initialValues={deliveryPartnerDetails}>
						{deliveryPartnerFields[deliveryPartnerName]?.map(({ label, name }) => {
							return (
								<Form.Item key={label} label={label} name={name} required>
									<Input name={name} defaultValue={deliveryPartnerDetails[name]} />
								</Form.Item>
							);
						})}

						<Form.Item>
							<Button type="primary" size="large" block htmlType="submit" loading={isDisabled} style={{ top: '20px' }}>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</div>
			)}

			{shouldRenderBody(deliveryPartnerName) && (
				<div className={styles.dropdownIcon}>
					<Tooltip title={isBodyOpen ? null : 'Fill credentials'} placement="right">
						<Button
							type="text"
							shape="circle"
							size="large"
							icon={isBodyOpen ? <UpCircleOutlined /> : <DownCircleOutlined />}
							onClick={toggleBodyOpen}
						/>
					</Tooltip>
				</div>
			)}
		</div>
	);
};
