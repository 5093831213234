import { Checkbox, Col, notification, Row, Switch, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { updatePreferenceDetailAdmin } from '../../api/profile';
import errorHandler from '../../utils/errorHandler';
import styles from './index.module.scss';
import { statusUpdateOptions, defaultWaModule, EWaModule, adminPreferenceRowFields } from './constants';

export const AdminPreference = ({ profile, companyId, isAdmin, revalidate, setLoading }) => {
	const [waModule, setWaModule] = useState(defaultWaModule);
	const [adminPreferenceFields, setAdminPreferenceFields] = useState({});

	useEffect(() => {
		if (profile) {
			const waModules = profile.waModules;
			if (waModules) {
				setWaModule(JSON.parse(profile.waModules));
			} else {
				setWaModule(defaultWaModule);
			}
		}
	}, [profile]);

	const isSwitchChecked = (waModuleName) => {
		switch (waModuleName) {
			case EWaModule.NDR:
				return waModule[EWaModule.NDR]?.isActive ? true : false;

			case EWaModule.WA_NOTIFICATON:
				return waModule[EWaModule.WA_NOTIFICATON]?.isActive ? true : false;

			default:
				return false;
		}
	};

	const handlePreferenceChange = async (fieldName, updatedValue, waModuleFlag) => {
		try {
			setLoading(true);
			if (waModuleFlag) {
				const updatedWaModule = { ...waModule, [fieldName]: { ...waModule[fieldName], ...updatedValue } };
				setWaModule(updatedWaModule);
				await updatePreferenceDetailAdmin({ companyId, profileType: 'waModules', profileValue: updatedWaModule });
			} else {
				setAdminPreferenceFields({ [fieldName]: updatedValue, ...adminPreferenceFields });
				await updatePreferenceDetailAdmin({ companyId, profileType: fieldName, profileValue: updatedValue });
			}

			notification.success({
				message: 'Success',
				description: 'Preferences updated successfully',
				placement: 'topRight'
			});
		} catch (error) {
			errorHandler(error);
		} finally {
			revalidate();
			setLoading(false);
		}
	};

	const createAdminPreferenceFieldObject = (profileData) => {
		const adminPreferenceFieldObject = {};
		adminPreferenceRowFields.forEach((field) => {
			adminPreferenceFieldObject[field.key] = Boolean(profileData[field.key] === '1');
		});
		return adminPreferenceFieldObject;
	};
	useEffect(() => {
		if (profile) {
			const updatedFields = createAdminPreferenceFieldObject(profile);
			setAdminPreferenceFields(updatedFields);
		}
	}, [profile]);

	return (
		<div className={styles.row2}>
			<Typography.Title level={4}>Admin Controlled Preferences</Typography.Title>
			<Typography.Paragraph type="warning">*Please contact admin or sales team to activate these features</Typography.Paragraph>
			<Row className={styles.preferenceRow}>
				<Col span={12}>
					<Typography.Paragraph>Send Whatsapp message to customer when delivery attempt fails</Typography.Paragraph>
					<Typography.Paragraph type="secondary" className={styles.secondaryText}>
						A link of a form will be sent to customer. Using this link cusomter can request our delivery partner to reschedule
						delivery or update contact details(phone number, address).
					</Typography.Paragraph>
				</Col>
				<Col span={12}>
					<Switch
						checkedChildren="ON"
						disabled={!isAdmin}
						unCheckedChildren="OFF"
						checked={isSwitchChecked(EWaModule.NDR)}
						onChange={(updatedStatus) => handlePreferenceChange(EWaModule.NDR, { isActive: updatedStatus }, true)}
					/>
				</Col>

				<Col span={12}>
					<Typography.Paragraph>Send whatsapp messages on order status updates </Typography.Paragraph>
					{waModule[EWaModule.WA_NOTIFICATON].isActive && (
						<>
							<Typography.Paragraph type="secondary" className={styles.secondaryText}>
								Order status update will be sent to customer on following status
							</Typography.Paragraph>

							<Checkbox.Group
								options={statusUpdateOptions}
								value={waModule[EWaModule.WA_NOTIFICATON].triggerStatus}
								onChange={(data) => handlePreferenceChange(EWaModule.WA_NOTIFICATON, { triggerStatus: data }, true)}
								disabled={!isAdmin}
							/>
						</>
					)}
				</Col>
				<Col span={12}>
					<Switch
						checkedChildren="ON"
						disabled={!isAdmin}
						unCheckedChildren="OFF"
						checked={isSwitchChecked(EWaModule.WA_NOTIFICATON)}
						onChange={(updatedStatus) => handlePreferenceChange(EWaModule.WA_NOTIFICATON, { isActive: updatedStatus }, true)}
					/>
				</Col>

				{/* adminPreferenceRowFields contains admin controlled switch based preferences */}
				<Col span={24}>
					<Row className={styles.rowData}>
						{adminPreferenceRowFields.map((item) => {
							return (
								<Col span={24} key={item.key}>
									<Row className={styles.preferenceRow}>
										<Col span={12}>
											<span className="description">{item.description}</span>
										</Col>
										<Col span={12}>
											<Switch
												checkedChildren="ON"
												disabled={!isAdmin}
												unCheckedChildren="OFF"
												checked={adminPreferenceFields[item.key]}
												onChange={(updatedStatus) => handlePreferenceChange(item.key, updatedStatus, false)}
											/>
										</Col>
									</Row>
								</Col>
							);
						})}
					</Row>
				</Col>
			</Row>
		</div>
	);
};
