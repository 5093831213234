import { ReloadOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useEffect, useState } from 'react';
import { apiNms } from '../../../../shared/api';
import { getGupshupTemplates } from '../../../../shared/api/nms';
import { CustomTablePagination } from '../../../../shared/components/CustomTablePagination';
import errorHandler from '../../../../shared/utils/errorHandler';
import { gupshupTemplateColumns } from './columns';
import { defaultPageConfig } from '../../../../constants';
import { GupshupTemplateForm } from './GupshupTemplateForm';

export const GupshupTemplates = ({ companyId }) => {
	const [totalCount, setTotalCount] = useState(0);
	const [selectedItem, setSelectedItem] = useState();
	const [loading, setLoading] = useState(false);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [templates, setTemplates] = useState([]);

	const [isSyncing, setIsSyncing] = useState(false);

	const [isTemplateFormOpen, setIsTemplateFormOpen] = useState(false);

	const fetchTemplates = async () => {
		setLoading(true);
		try {
			const { data } = await getGupshupTemplates(pageConfig, { companyId });
			if (!data.status) {
				throw new Error(data.message);
			}

			setTotalCount(data.totalCount);
			setTemplates(data.templates);
		} catch (error) {
			errorHandler(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchTemplates();
	}, [pageConfig]);

	const handleFormSubmit = () => {
		fetchTemplates();
		setIsTemplateFormOpen(false);
	};

	const syncTemplates = async () => {
		try {
			setIsSyncing(true);
			await apiNms.get('/gupshup/template/sync', { params: { companyId } });
			await fetchTemplates();
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsSyncing(false);
		}
	};

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography.Title level={3}>Template</Typography.Title>
				<div style={{ display: 'flex', gap: '1rem' }}>
					<Button type="primary" onClick={() => setIsTemplateFormOpen(true)}>
						Add Template
					</Button>
					<Button icon={<ReloadOutlined />} type="primary" onClick={syncTemplates} loading={isSyncing}>
						Sync templates
					</Button>
				</div>
			</div>

			<div style={{ height: '65vh' }}>
				<CustomTablePagination
					shouldRowSelection={false}
					columns={gupshupTemplateColumns}
					onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
					data={templates}
					total={totalCount}
					isCatalog={true}
					selectedItem={selectedItem}
					setSelectedItem={setSelectedItem}
					loading={loading}
					emptyTableMessage="No available templates"
					{...pageConfig}
				/>
			</div>

			<Modal
				visible={isTemplateFormOpen}
				onOk={() => setIsTemplateFormOpen(false)}
				onCancel={() => setIsTemplateFormOpen(false)}
				footer={null}
			>
				<GupshupTemplateForm companyId={companyId} onAdd={handleFormSubmit} />
			</Modal>
		</div>
	);
};
