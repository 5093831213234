export const columns = [
	{
		title: 'Warehouse',
		dataIndex: 'warehouseName',
		key: 'warehouseName',
		render: (warehouseName) => warehouseName
	},
	{
		title: 'POC',
		dataIndex: 'warehouseAddress',
		key: 'POC',
		render: (warehouseAddress) => warehouseAddress?.name
	},
	{
		title: 'POC Number',
		dataIndex: 'warehouseAddress',
		key: 'POCNumber',
		render: (warehouseAddress) => warehouseAddress?.phone
	},
	{
		title: 'Address',
		dataIndex: 'warehouseAddress',
		key: 'Address',
		render: (warehouseAddress) =>
			`${warehouseAddress?.addressLine1}, ${warehouseAddress?.addressLine2 ? warehouseAddress?.addressLine2 : ''}, ${
				warehouseAddress?.city
			}, ${warehouseAddress?.state} ${warehouseAddress?.pincode}`
	}
];
