export const keyValuePairForm: Record<string, string> = {
	firstName: 'Store First Name',
	lastName: 'Store last Name',
	city: 'City',
	state: 'State',
	pincode: 'Pincode',
	country: 'Country',
	email: 'Email',
	phone: 'Phone',
	addressLine1: 'Address line 1',
	addressLine2: 'Address line 2',
	GSTIN: 'GSTIN',
	addressPurpose: 'Address purpose'
};
