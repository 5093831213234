import { Button, Modal } from 'antd';
import { ReactElement, useState } from 'react';
import PaintGMapWithMarkers from './PaintGMapWithMarkers';
import styles from './index.module.scss';

interface PodCordinateModalProp {
	coordinate: Array<{
		lat: number;
		lng: number;
		text?: string;
		routeSequenceNumber?: number;
	}>;
	buttonText: string;
	buttonType: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed';
}

export const PodCoordinateModal = (props: PodCordinateModalProp): ReactElement<PodCordinateModalProp> => {
	const { coordinate, buttonText, buttonType = 'link' } = props;

	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

	if (!coordinate || !coordinate.length) {
		return <></>;
	}

	return (
		<>
			<Button
				onClick={() => {
					setIsModalVisible(true);
				}}
				type={buttonType}
			>
				{buttonText}
			</Button>
			<Modal
				title={'Location - Map View'}
				visible={isModalVisible}
				onCancel={() => {
					setIsModalVisible(false);
				}}
				style={{ minWidth: '50vw' }}
			>
				<div className={styles.gMapModal}>
					<PaintGMapWithMarkers locations={coordinate} />
				</div>
			</Modal>
		</>
	);
};
