import { Button, Col, Row } from 'antd';
import dayjs from 'dayjs';
import errorHandler from 'shared/utils/errorHandler';
import { DEFAULT_FILTER, DEFAULT_PAGE_CONFIG } from './interfaces';
import { apiUms } from 'shared/api';
import { commonTopFilterRow } from '../../../../utils/filters';

export function topFilterUtil({
	filter,
	setFilter,
	setPageConfig,
	selectedRowKeys,
	companyId,
	getAllWebhooks,
	setIsModalVisible
}: {
	filter: DEFAULT_FILTER;
	setFilter: React.Dispatch<React.SetStateAction<DEFAULT_FILTER>>;
	setPageConfig: React.Dispatch<React.SetStateAction<DEFAULT_PAGE_CONFIG>>;
	selectedRowKeys: [];
	companyId: string;
	getAllWebhooks: () => void;
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const setDate = (dateArray: dayjs.Dayjs[]) => {
		// setPageConfig();
		setFilter((v: DEFAULT_FILTER) => ({ ...v, startDate: dateArray[0], endDate: dateArray[1] }));
	};

	const markBulkWebhookDisabled = async (): Promise<void> => {
		if (!selectedRowKeys.length) {
			return errorHandler(new Error('Please select webhooks first.'));
		}

		const {
			data: { status, message }
		} = await apiUms.delete('/ums/webhooks', {
			headers: { 'x-company-id': companyId },
			data: {
				webhookIds: selectedRowKeys
			}
		});
		if (!status) {
			return errorHandler(new Error(message));
		}
		getAllWebhooks();
	};
	const topFilterRow = commonTopFilterRow(filter.startDate, filter.endDate);

	return {
		row1: [
			{
				...topFilterRow,
				onChange: (dateStrings: dayjs.Dayjs[]) => setDate(dateStrings)
			}
		],
		row2: [
			<Row gutter={16}>
				<Col>
					<Button onClick={markBulkWebhookDisabled} style={{ fontWeight: 500 }}>
						Mark as disabled
					</Button>
				</Col>
				<Col>
					<Button onClick={() => setIsModalVisible(true)} style={{ fontWeight: 500 }}>
						Add Webhook
					</Button>
				</Col>
			</Row>
		]
	};
}
