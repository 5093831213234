export const flatPricingRowFields = [
	{
		label: 'smsCharges',
		key: 'smsCharges',
		description: 'Pricing for sending SMS to Customers (Per Order)'
	},
	{
		label: 'RTOProcessingCharges',
		key: 'RTOProcessingCharges',
		description: 'RTO Processing Charges (Per Order)'
	},
	{
		label: 'platformCharges',
		key: 'platformCharges',
		description: 'Platform Charges (Per Order)'
	},
	{
		label: 'whatsAppMessageCharges',
		key: 'whatsAppMessageCharges',
		description: 'WhatsApp Message Charges (Per Message)'
	}
];

export const lightningPricingRowFields = [
	{
		label: 'wlBaseCharges',
		key: 'wlBaseCharges',
		description: 'Base Price for first 1/2 Kg (Per Order)'
	},
	{
		label: 'wlPriceUpto2kg',
		key: 'wlPriceUpto2kg',
		description: 'Additional price per 1/2 Kg upto 2Kgs (Per Order)'
	},
	{
		label: 'wlPriceAfter2kg',
		key: 'wlPriceAfter2kg',
		description: 'Additional price per 1/2 Kg after 2Kgs (Per Order)'
	},
	{
		label: 'wlCODHandlingCharges',
		key: 'wlCODHandlingCharges',
		description: 'COD Handling Charges (Per Order)'
	}
];

export const storagePricingRowFields = [
	{
		label: 'storageCharges',
		key: 'storageCharges',
		description: 'Storage Charges'
	},
	{
		label: 'minimumStorageCharges',
		key: 'minimumStorageCharges',
		description: 'Minimum Storage Charges'
	},
	{
		label: 'maximumStorageCharges',
		key: 'maximumStorageCharges',
		description: 'Maximum Storage Charges'
	}
];

export const STORAGE_CHARGE_TYPE_FIELD = 'storageChargeType';

export const storageChargesTypes = [
	{ value: 'perUnitCharges', label: 'Per Unit Charges' },
	{ value: 'perBoxCharges', label: 'Per Box Charges' },
	{ value: 'perPalletStorageCharges', label: 'Per Pallet Charges' },
	{ value: 'perSqFtStorageCharges', label: 'Per Sq Ft Charges' },
	{ value: 'fixedStorageCharges', label: 'Fixed Storage Charges' }
];
