import { Button } from 'antd';
import errorHandler from 'shared/utils/errorHandler';
import { useState } from 'react';
import { apiOms } from 'shared/api';
import { downloadAsFile } from 'helper';

export const PrintLabelDialog = ({ orderDetails }) => {
	const { shippingLabelUrl } = orderDetails;
	const [isLoading, setIsLoading] = useState(false);
	const isPrintLabelButtonDisabled = (orderDetails) => !orderDetails?.invoice?.shippingUrl && !shippingLabelUrl;

	const handlePrintLabel = async () => {
		try {
			var urlRegex = new RegExp(
				'https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)'
			);
			if (shippingLabelUrl.match(urlRegex)) {
				return window.open(shippingLabelUrl, '_blank');
			}

			setIsLoading(true);

			// const res = await getS3Link([orderDetails.invoice.shippingUrl]);
			// const s3Link = res?.data?.data?.list[0];

			// window.open(s3Link, '_blank');

			const type = 'label';
			const res = await apiOms.post(`/download/${type}`, { orderId: [orderDetails.id] }, { responseType: 'blob' });
			downloadAsFile({ data: res.data, fileName: `${type}s`, fileType: 'zip' });
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Button
			type="primary"
			size="large"
			onClick={handlePrintLabel}
			disabled={isPrintLabelButtonDisabled(orderDetails)}
			loading={isLoading}
		>
			Print Label
		</Button>
	);
};
