import { Col, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { getJourney, downloadJourneyCSV, downloadMoreTripsAndMail } from 'shared/api/lightning';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import errorHandler from 'shared/utils/errorHandler';
import JourneyInfo from '../../../../shared/components/JourneyInfo/JourneyInfo';
import { useColumns } from './columns';
import { defaultFilter, defaultPageConfig } from './constants';
import { topFilterUtil } from './utils';
import reactDom from 'react-dom';
import { RequestEmailModal } from 'shared/components/EmailRequestModal';
import _ from 'lodash';
import { getDistanceFromLatLonInKm } from 'shared/services/distanceService';

export function Trips() {
	const [data, setData] = useState([]);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [filter, setFilter] = useState(defaultFilter);
	const [currentCount, setCurrentCount] = useState();
	const [openJourneyInfo, setOpenJourneyInfo] = useState(false);
	const [loading, setLoading] = useState(false);
	const columns = useColumns({ clickTripId: setOpenJourneyInfo });
	const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
	const [isDownloadButtonLoading, setIsDownloadButtonLoading] = useState(false);

	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				filter,
				setFilter,
				setPageConfigDefault,
				onDownloadTrips
			}),
		[filter, setFilter]
	);

	function setPageConfigDefault() {
		setPageConfig((v) => ({ ...v, ...defaultPageConfig }));
	}

	function handleTableChange(_pagination, _filters, sorter) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'id',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	async function onDownloadTrips() {
		try {
			const shouldEmailOpen = await downloadJourneyCSV({ pageConfiguration: pageConfig, filters: filter });
			setIsEmailModalVisible(shouldEmailOpen);
		} catch (err) {
			errorHandler(err);
		}
	}

	const onTripsFormSubmit = async (values) => {
		try {
			setIsDownloadButtonLoading(true);
			await downloadMoreTripsAndMail({ pageConfiguration: pageConfig, filters: filter }, values.email);
		} catch (e) {
		} finally {
			setIsDownloadButtonLoading(false);
			setIsEmailModalVisible(false);
		}
	};

	async function getAllJourneys({ pageConfiguration, filters }) {
		try {
			setLoading(true);
			const { data: journeyData } = await getJourney({ pageConfiguration, filters });

			if (journeyData.status) {
				reactDom.unstable_batchedUpdates(() => {
					const sortedLocationEventsData = journeyData.data.map((dataItem) => {
						// sorting location event to get distance
						let distance = 0;

						/**
						 * Calculating the Distance from locationEvents of Driver which is captured During the trips
						 * Sort the Trips Location Events
						 * Get the distance between first location event and last location event
						 */
						const sortedLocationEvents = _.sortBy(dataItem.locationEvents, 'createdAt');
						if (sortedLocationEvents.length > 1) {
							distance = getDistanceFromLatLonInKm(
								sortedLocationEvents[0]['latitude'],
								sortedLocationEvents[0]['longitude'],
								sortedLocationEvents[sortedLocationEvents.length - 1]['latitude'],
								sortedLocationEvents[sortedLocationEvents.length - 1]['longitude']
							);
						}

						return {
							...dataItem,
							locationEvents: sortedLocationEvents,
							distance
						};
					});
					setData(sortedLocationEventsData);
					setCurrentCount(journeyData.currentCount);
				});
			} else {
				throw new Error(journeyData.message || 'Could not get trips');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		getAllJourneys({ pageConfiguration: pageConfig, filters: filter });
	}, [pageConfig, filter]);

	return (
		<Row className="h-100 p-0">
			<RequestEmailModal
				title="Download Trips"
				visible={isEmailModalVisible}
				onCancel={() => {
					setIsEmailModalVisible(false);
				}}
				footer={[]}
				width={500}
				onFinish={onTripsFormSubmit}
				instructionMessage="Requested data contains too many rows, please enter your email address to receive download url."
				loading={isDownloadButtonLoading}
			/>

			<Col span={24} className="main">
				<TopFilterComponent {...topFilterConfig} />
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={columns}
							data={data}
							onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
							total={currentCount}
							onChange={handleTableChange}
							loading={loading}
							shouldRowSelection={false}
							{...pageConfig}
						/>
					</div>
				</div>
			</Col>
			{openJourneyInfo && <JourneyInfo journeyID={openJourneyInfo} showJourneyInfo={setOpenJourneyInfo} />}
		</Row>
	);
}
