import styles from './index.module.scss';

interface IInsightsDataCard {
	gridAreaClass: string;
	cardName: string;
	data?: string | number;
	svg?: JSX.Element;
	days?: string;
	percentage?: number;
	isUndeliveredOrNdr?: boolean;
}

export const DataCard = ({ gridAreaClass, cardName, data, svg, days, percentage, isUndeliveredOrNdr }: IInsightsDataCard) => {
	return (
		<div className={`${styles.dataCard} ${gridAreaClass}`}>
			<div className={styles.roundBox}>{svg}</div>
			<div className={styles.topcardDiv}>
				<p>{cardName || ''}</p>
				<h3>{data || '0'}</h3>
			</div>
			<p className={styles.percentagePara}>
				<span
					style={{
						color: isUndeliveredOrNdr
							? `${percentage && percentage <= 0 ? 'green' : 'red'}`
							: `${percentage && percentage > 0 ? 'green' : 'red'}`
					}}
				>
					{percentage || 0} %{'  '}
				</span>
				{days || ''}
			</p>
		</div>
	);
};
