import { ICommonFilter } from '../../commonTypes';

export interface IFilter extends ICommonFilter {
	paymentStatus?: string;
}

export const PaymentStatus: { [key: number]: string } = {
	0: 'PENDING',
	1: 'SUCCESS',
	2: 'FAILURE'
};

export const PaymentStatusTagColor: { [key: number]: string } = {
	0: 'orange',
	1: 'green',
	2: 'red'
};
