import { Progress } from 'antd';

interface BulkActionProgressProps {
	isQTaskInProgress: boolean;
	progress: number;
	label: string;
}

function BulkActionProgress(props: BulkActionProgressProps): React.ReactElement {
	const { isQTaskInProgress, progress, label } = props;

	return (
		<>
			{isQTaskInProgress && (
				<>
					<strong>{label}</strong>
					<Progress percent={progress} status="active" style={{ width: '10vw' }} strokeColor="orange" />
				</>
			)}
		</>
	);
}

export default BulkActionProgress;
