import { Tag, Button, Tooltip } from 'antd';
import { getWSINMapFormat } from '../../components/Catalog/constants';
import styles from './index.module.scss';

export const catalogColumns = ({ clickCatalogueId }) => [
	{
		title: 'Serial No',
		dataIndex: 'productId',
		width: 130
	},
	{
		title: 'Item Name',
		dataIndex: 'productTitle',
		width: 150
	},
	{
		title: 'SKU Id',
		dataIndex: 'sku',
		width: 160,
		render: (_v, catalogue) => (
			<Button
				type="link"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					clickCatalogueId(catalogue.id);
				}}
				className={styles.wFull}
			>
				<Tooltip placement="top" title={catalogue.sku}>
					<div className={`${styles.sub} ${styles.wFull} ${styles.ellipsis}`}>{catalogue.sku}</div>
				</Tooltip>
				<div className={styles.sub}>
					{catalogue.active === 1 ? <Tag color="cyan">Active</Tag> : <Tag color="magenta">Inactive</Tag>}
				</div>
			</Button>
		)
	},
	{
		title: 'Category',
		dataIndex: 'category'
	},
	{
		title: 'Wsin',
		dataIndex: 'wsinMapping',
		render: (v, data) => <span>{`${data.wsinMapping ? getWSINMapFormat(data.wsinMapping) : '-'}`}</span>
	},
	{
		title: 'Price',
		dataIndex: 'price'
	},
	{
		title: 'LxBxH (cm)',
		dataIndex: 'mappedDimensions'
	},
	{
		title: 'Weight (gm)',
		dataIndex: 'weight'
	},
	{
		title: 'HSN',
		dataIndex: 'hsnCode'
	},
	{
		title: 'Base Price',
		dataIndex: 'basePrice'
	},
	{
		title: 'Margin (%)',
		dataIndex: 'margin'
	},
	{
		title: 'D2R Category',
		dataIndex: 'd2rCategory'
	}
];
