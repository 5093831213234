import { Button, Col, Modal, Table, Tag } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { PAYMENT_METHOD } from '../../../../constants';
import { apiHms } from '../../../../shared/api';
import { getAssignedOrders } from '../../../../shared/api/lightning';
import { Loading } from '../../../../shared/components/Loading';
import errorHandler from '../../../../shared/utils/errorHandler';
import { REVERSE_ORDER_STATUS_TYPES } from '../constants';
interface IAssignedOrders {
	assignedOrderIds: string[];
	onClose: () => void;
	driverId: string;
}

const assignedOrdersColumn = (handleUnassignOrder: (driverId: any, awb: any) => void) => [
	{
		title: 'AWB',
		dataIndex: 'awb',
		key: 'order.id',
		render: (awb: string) => <span>{awb}</span>
	},
	{
		title: 'Payment Method',
		key: 'order.id',
		render: (order: any) => (
			<span>{`${order.paymentMethod} ${order.paymentMethod === PAYMENT_METHOD.COD ? `(₹${order.totalPrice})` : ''}`}</span>
		)
	},
	{
		title: 'Status',
		dataIndex: 'orderStatus',
		key: 'order.id',
		render: (journeyOrderStatus: number) => (
			<Tag>
				{/* @ts-ignore */}
				{REVERSE_ORDER_STATUS_TYPES[journeyOrderStatus]}
			</Tag>
		)
	},
	{
		title: 'Un Assign',
		render: (data: any) => (
			<Button disabled={data?.orderStatus === 7} onClick={() => handleUnassignOrder(data?.driverId, data?.awb)}>
				Un Assign
			</Button>
		)
	}
];

export const AssignedOrdersModal = (props: IAssignedOrders) => {
	const { assignedOrderIds, onClose, driverId } = props;

	const [assignedOrders, setAssignedOrders] = useState([]);
	const [loading, setLoading] = useState(false);

	const handleShowAssignedOrders = useCallback(async () => {
		try {
			setLoading(true);
			const { data } = await getAssignedOrders(assignedOrderIds, driverId);
			setAssignedOrders(data?.orders);
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}, [assignedOrderIds, driverId]);

	const handleUnAssignOrder = async (driverId: any, awb: any) => {
		try {
			setLoading(true);
			const responseData = await apiHms.post('/order/unassign_order', {
				awb,
				driverId
			});
			if (!responseData || !responseData.status) {
				throw new Error('Server Error');
			}
			return responseData;
		} catch (error) {
			errorHandler(error);
		} finally {
			handleShowAssignedOrders();
			setLoading(false);
		}
	};
	useEffect(() => {
		handleShowAssignedOrders();
	}, [handleShowAssignedOrders]);

	return (
		<>
			<Modal
				bodyStyle={{ height: 500, overflowY: 'scroll', padding: 0 }}
				width={800}
				visible={true}
				onCancel={() => onClose()}
				onOk={() => onClose()}
				title={`Assigned Orders`}
			>
				{loading && <Loading loading={loading} />}
				<Col span={24}>
					<Table pagination={false} columns={assignedOrdersColumn(handleUnAssignOrder)} dataSource={assignedOrders} />
				</Col>
			</Modal>
		</>
	);
};
