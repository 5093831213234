import { uuid4 } from '@sentry/utils';
import { Button, Form, Input, notification, Select } from 'antd';
import { useState } from 'react';
import { apiWms } from '../../../api';
import errorHandler from '../../../utils/errorHandler';
import { Loading } from '../../Loading';
import { IInventoryAdjustmestFormInputFields } from '../components/utils';
import { ScannedStockInventoryAdjustOption } from '../constants';
import styles from '../index.module.scss';
import dayjs from 'dayjs';

interface IScannedStockForm {
	reloadInventory: () => void;
	wsin: string;
	setReloadInventoryList: React.Dispatch<React.SetStateAction<number>>;
}

export const IInventoryAdjustCustomFormFields = {
	date: String(dayjs()),
	quantity: '',
	remark: ''
};

export const ScannedStockForm = (props: IScannedStockForm) => {
	const { wsin, reloadInventory, setReloadInventoryList } = props;
	const [scannedStock, setScannedStock] = useState<typeof IInventoryAdjustCustomFormFields>(() => IInventoryAdjustCustomFormFields);
	const [inventoryAdjustType, setInventoryAdjustType] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	const handleInventoryAdjustmentTypeChange = (value: string) => setInventoryAdjustType(value);
	const handleClear = () => setInventoryAdjustType('');

	const handleFormValues = (value: string, id: string) => {
		setScannedStock({
			...scannedStock,
			[id]: value
		});
	};

	const handleSaveScannedWsin = async () => {
		try {
			setLoading(true);
			const { data } = await apiWms.post('/inventory/scanned-stock', {
				data: [
					{
						id: uuid4(),
						date: scannedStock.date,
						wsin: wsin,
						quantity: scannedStock.quantity,
						actionType: inventoryAdjustType,
						remark: scannedStock.remark
					}
				]
			});
			if (data?.status) {
				notification.success({
					message: 'Inventory Updated Successfully'
				});
			}
		} catch (error: any) {
			errorHandler(error);
		} finally {
			setLoading(false);
			reloadInventory();
			setScannedStock(IInventoryAdjustCustomFormFields);
			setReloadInventoryList((e) => e + 1);
		}
	};

	if (loading) {
		return <Loading loading={loading} />;
	}

	return (
		<Form labelCol={{ span: 4 }} wrapperCol={{ span: 15 }} layout="horizontal" style={{ marginTop: '2rem' }}>
			<Form.Item label="Actions">
				<Select
					placeholder="Select Adjustment type"
					allowClear
					onClear={handleClear}
					onChange={handleInventoryAdjustmentTypeChange}
					defaultValue={inventoryAdjustType || null}
				>
					{ScannedStockInventoryAdjustOption?.map(({ label, value }) => (
						<Select.Option key={value} value={value}>
							{label}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			{IInventoryAdjustmestFormInputFields.map((e) => (
				<Form.Item name={e.name} rules={e.rules} key={e.label} label={e.label}>
					<Input
						type={e.type}
						onChange={(event: any) => handleFormValues(event.target.value, e.name)}
						disabled={Boolean(!inventoryAdjustType)}
						placeholder={e.placeholder}
					/>
				</Form.Item>
			))}
			<div className={styles.formButton}>
				<Form.Item>
					<Button
						disabled={!Boolean(scannedStock.quantity && scannedStock.remark)}
						loading={loading}
						onClick={() => handleSaveScannedWsin()}
						type="primary"
					>
						Submit
					</Button>
				</Form.Item>
			</div>
		</Form>
	);
};
