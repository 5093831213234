import styles from './index.module.scss';
import { Form, Select } from 'antd';
import { PAYMENT_METHOD } from '../../../../../../constants';

const { Option } = Select;

export const OtherDetails = ({ customStyle }) => {
	return (
		<div className={`${customStyle} ${styles.otherDetails}`}>
			<h4 className={styles.heading}>Other Details</h4>

			<Form.Item label="Mode of Payment" name="paymentMethod" rules={[{ required: true, message: 'Please select payment method' }]}>
				<Select style={{ width: '400px' }} size="large">
					<Option value={PAYMENT_METHOD.COD}>COD</Option>
					<Option value={PAYMENT_METHOD.PREPAID}>Prepaid</Option>
				</Select>
			</Form.Item>
		</div>
	);
};
