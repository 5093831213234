import { apiNms, apiNmsOpen } from '.';
import { cleanObject } from '../../helper';

export const getCustomerRequestByToken = (token) => {
	return apiNmsOpen.get('/customerRequest', {
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
};

export const updateCustomerRequestByToken = (token, data) => {
	return apiNmsOpen.post('/customerRequest', data, {
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
};

export const getCustomerRequests = async ({ current = 1, pageSize = 10, sortField = 'createdAt', sortOrder = 'DESC' }, filter) => {
	return apiNms.post('/customerRequests/find', {
		where: cleanObject({ ...filter }, ['', null, undefined]),
		pagination: {
			records: pageSize,
			pageNumber: current
		},
		sortBy: {
			order: sortField,
			by: sortOrder
		}
	});
};

export const createTemplate = async ({ companyId, messageBody, templateName }) => {
	return apiNms.post('/admin/template', { companyId, messageBody, templateName });
};

export const addGupshupCredentials = async ({ companyId, userId, password, whatsAppNumber }) => {
	return apiNms.post('/gupshup/credential', { companyId, userId, password, whatsAppNumber });
};

export const getCompanyGupshupCreds = async (companyId) => {
	return apiNms.get('/gupshup/credential', { params: { companyId } });
};

export const getGupshupTemplates = async ({ current = 1, pageSize = 10, sortField = 'createdAt', sortOrder = 'DESC' }, filter = {}) => {
	return apiNms.post('/admin/templates', {
		where: cleanObject({ ...filter }, ['', null, undefined]),
		pagination: {
			records: pageSize,
			pageNumber: current
		},
		sortBy: {
			order: sortField,
			by: sortOrder
		}
	});
};

export const updateCustomerRequest = async (data) => {
	return apiNms.post('/admin/customerRequest/approvalStatus', data);
};
