export const searchOptions = [
	{ label: 'SKU', value: 'sku' },
	{ label: 'Title', value: 'productTitle' }
];

export const defaultFilter = {
	searchOptionType: searchOptions[0].value,
	searchValue: ''
};

export const getWSINMapFormat = (wsinMap) => {
	let arr = [];
	for (let [key, value] of Object.entries(wsinMap)) {
		arr.push(`${key} x ${value}`);
	}
	return arr.join(' + ');
};
