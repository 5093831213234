import { Button, Switch } from 'antd';
import { useState } from 'react';
import { apiOms } from 'shared/api';
import errorHandler from 'shared/utils/errorHandler';
import styles from './index.module.scss';

export interface IAddressCard {
	id: string;
	index: string;
	isActive: string | boolean | undefined;
	firstName: string;
	lastName: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	state: string;
	pincode: string;
	phone: string;
	email: string;
	gstin: string;
}
export function AddressCard({ address, setIsRetailerModalVisible, setRetailerId }: any) {
	const {
		id,
		index,
		isActive,
		firstName,
		lastName,
		addressLine1,
		addressLine2,
		city,
		state,
		pincode,
		phone,
		email,
		gstin
	}: IAddressCard = address;

	const [isAddressActive, setIsAddressActive] = useState<any>(isActive);
	const [isLoading, setIsLoading] = useState(false);

	const onToggleaddressStatus = async (updatedActiveStatus: any) => {
		try {
			setIsLoading(true);
			await apiOms.patch(`/d2r/store-address/${id}`, { active: updatedActiveStatus });
			setIsAddressActive(updatedActiveStatus);
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className={styles.addressCard}>
			<div className={styles.addressCardInnerContainer}>
				<div className={styles.left}>
					<div style={{ lineBreak: 'anywhere' }}>
						<div className={styles.addressId}>{index}</div>
					</div>
					<div>
						<h5 className={styles.addressType}>{`${firstName} ${lastName}`} </h5>
						<address className={styles.address}>
							{addressLine1}, {addressLine2}, <br />
							{city}, {state}
							<br />
							Pincode : {pincode}
							<br />
							Contact: {phone}
							<br />
							Email: {email}
							<br />
							GST Number: {gstin || 'NA'}
						</address>
						<Button
							type="primary"
							className={styles.editButton}
							onClick={() => {
								setIsRetailerModalVisible(true);
								setRetailerId(id);
							}}
						>
							Edit
						</Button>
					</div>
				</div>
				<div className={styles.right}>
					<Switch checked={isAddressActive} onChange={onToggleaddressStatus} loading={isLoading} />
				</div>
			</div>
		</div>
	);
}
