import { PlusCircleOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Table } from 'antd';
import React, { useState } from 'react';
import { columns } from './columns';
import { originData } from './dummyData';
import styles from './index.module.scss';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, edit, ...restProps }) => {
	const inputNode =
		inputType === 'number' ? (
			<InputNumber defaultValue={record[dataIndex]} onChange={() => edit(record)} />
		) : (
			<Input defaultValue={record[dataIndex]} onChange={() => edit(record)} />
		);
	return (
		<td {...restProps}>
			<Form.Item
				name={dataIndex}
				style={{
					margin: 0
				}}
				rules={[
					{
						required: true,
						message: `Please Input ${title}!`
					}
				]}
			>
				{inputNode}
			</Form.Item>
		</td>
	);
};

export default function EditableTable() {
	const [form] = Form.useForm();
	const [data, setData] = useState([...originData]);
	const [editingKey, setEditingKey] = useState('name');
	const isEditing = (record) => record.key === editingKey;

	const edit = (record) => {
		form.setFieldsValue({
			...record
		});
		setEditingKey(record.key);
	};

	const handleAddItem = () => {
		const [tempData, length] = [[...data], [...data].length];
		tempData.push({
			key: `${length + 1}`,
			serialNo: `${length + 1}`,
			name: `prod ${length + 1}`,
			skuId: `${length + 1}`,
			category: `cat ${length + 1}`,
			price: (length + 1) % 2 === 0 ? 100 : 150,
			LxBxH: '128x145x149',
			weight: (length + 1) % 2 === 0 ? 500 : 350,
			stock: (length + 1) % 2 === 0 ? 240 : 490
		});
		setData([...tempData]);
	};

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: col.dataIndex === 'stock' ? 'number' : 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editable: col.editable,
				editing: isEditing(record),
				edit: edit(record)
			})
		};
	});
	return (
		<Form form={form} component={false}>
			<Table
				components={{
					body: {
						cell: EditableCell
					}
				}}
				bordered
				dataSource={data}
				columns={mergedColumns}
				rowClassName="editable-row"
				pagination={{ position: ['none', 'none'] }}
			/>
			<div className={styles.addNewSubItemIconContainer}>
				<PlusCircleOutlined className={styles.addNewSubItemIcon} onClick={handleAddItem} />
			</div>
		</Form>
	);
}
