export const warehouseRequirementOptions = [
	{
		label: 'I need a warehouse',
		value: 1, // this will not create pseudo warehouse
		description: 'You need warehouse from wherehouse.io and do not want to use your own space as central warehouse'
	},
	{
		label: 'I have a warehouse',
		value: 2, // this will create a pseudo warehouse
		description:
			'You want to use your own address as a central warehouse and do not want to take warehouses from wherehouse.io right now'
	},
	{
		label: 'Both',
		value: 3, // this will create a pseudo warehouse
		description: 'You want to use your own address as a central warehouse as well as take warehouses from wherehouse.io'
	}
];
