import { InventoryAdjustmentForm } from './InventoryAdjustmentForm';
import { IInventoryCustomFromData, InventoryInboundForm } from './InventoryInboundForm';
import { IInventoryAdjustCustomFormFields, ScannedStockForm } from './ScannedStockForm';

interface IInventoryFromHandler {
	formKey: string;
	inventoryId: string;
	reloadInventory: () => void;
	inventoryInboundFormData: typeof IInventoryCustomFromData;
	setInventoryInboundFormData: React.Dispatch<React.SetStateAction<typeof IInventoryCustomFromData>>;
	setInventoryAdjustFormDatarmData: React.Dispatch<React.SetStateAction<typeof IInventoryAdjustCustomFormFields>>;
	inventoryAdjustFormDatarmData: typeof IInventoryAdjustCustomFormFields;
	scannedStockWsin: string;
	fetchScannedStockTrail: () => void;
	reloadList?: () => void;
	setReloadInventoryList: React.Dispatch<React.SetStateAction<number>>;
}

export const InventoryFormHandler = (props: IInventoryFromHandler): JSX.Element => {
	const {
		formKey,
		inventoryId,
		reloadInventory,
		inventoryInboundFormData,
		setInventoryInboundFormData,
		setInventoryAdjustFormDatarmData,
		inventoryAdjustFormDatarmData,
		scannedStockWsin,
		fetchScannedStockTrail,
		setReloadInventoryList
	} = props;

	const INVENTORY_FORMS = {
		'1': (
			<InventoryInboundForm
				formData={inventoryInboundFormData}
				setFormData={setInventoryInboundFormData}
				reloadInventory={reloadInventory}
				inventoryId={inventoryId}
				setReloadInventoryList={setReloadInventoryList}
			/>
		),
		'2': (
			<InventoryAdjustmentForm
				formData={inventoryAdjustFormDatarmData}
				setFormData={setInventoryAdjustFormDatarmData}
				reloadInventory={reloadInventory}
				inventoryId={inventoryId}
				setReloadInventoryList={setReloadInventoryList}
			/>
		),
		'3': (
			<ScannedStockForm
				wsin={scannedStockWsin}
				reloadInventory={fetchScannedStockTrail}
				setReloadInventoryList={setReloadInventoryList}
			/>
		)
	};

	if (!Number(formKey)) {
		return <></>;
	}

	return INVENTORY_FORMS[formKey as keyof typeof INVENTORY_FORMS];
};
