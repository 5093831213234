import { Badge, Col, notification, Select, Button } from 'antd';
import { ORDER_STATUS } from 'constants/index';
import dayjs from 'dayjs';
import { downloadAsFile } from 'helper';
import { apiOms } from 'shared/api';
import { DebouncedCompactSearch } from 'shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import errorHandler from 'shared/utils/errorHandler';
import { orderStatus } from 'shared/utils/orderStatus';
import amplitude from '../../../shared/utils/Amplitude';
import { MAX_DOWNLOAD_LABEL_LIMIT, searchOptionsForMissedOrder } from './constants';
import { searchOptions, defaultFilter } from '../../../shared/utils/constant';
import styles from './index.module.scss';
import exportSvg from 'shared/svgs/export_svg';
import BulkActionProgress from 'shared/components/BulkActionProgress';
import { commonTopFilterRow } from '../../../utils/filters';

const { Option } = Select;
const socketId = localStorage.getItem('socketId');

const calculateAllCount = (counts: any) => {
	return Object.keys(ORDER_STATUS).reduce((allCount, field) => allCount + (!isNaN(counts[field]) ? +counts[field] : 0), 0);
};

const calculateUnserviceableCounts = (counts: any) => {
	let allCount = 0;

	const UNSERVICEABLE_COUNT_FIELDS = [ORDER_STATUS.UNSERVICEABLE];

	UNSERVICEABLE_COUNT_FIELDS.forEach((field) => (allCount += counts[field] || 0));

	return allCount;
};

const calculateNdrCount = (counts: any) => {
	let ndrCount = 0;
	const NDR_FIELDS = [ORDER_STATUS.UNDELIVERED, ORDER_STATUS.RTO_UNDELIVERED];

	NDR_FIELDS.forEach((field) => (ndrCount += +counts[field] || 0));
	return ndrCount;
};

const calculateReturnCount = (counts: any) => {
	let rtoCount = 0;
	const RTO_FIELDS = [
		ORDER_STATUS.RTO_DELIVERED,
		ORDER_STATUS.RTO_IN_TRANSIT,
		ORDER_STATUS.RTO_OUT_FOR_DELIVERY,
		ORDER_STATUS.RTO_PROCESSED,
		ORDER_STATUS.DTO_DELIVERED,
		ORDER_STATUS.DTO_IN_TRANSIT,
		ORDER_STATUS.DTO_OUT_FOR_DELIVERY,
		ORDER_STATUS.DTO_PROCESSED
	];

	RTO_FIELDS.forEach((field) => (rtoCount += +counts[field] || 0));
	return rtoCount;
};

export const sidebarUtil = ({ counts, currentCount, missedOrderCount }: any) => ({
	sideBarMenu: [
		{
			key: '1',
			label: `All  (${calculateAllCount(counts)})`,
			icon: 'order'
		},
		{
			key: '2',
			label: `Missed Orders (${missedOrderCount})`,
			icon: 'missed',
			isLink: true
		},
		// {
		// 	key: '3',
		// 	label: 'Flagged (0)',
		// 	icon: 'flagSvgIcon'
		// },
		{
			key: '4',
			label: `Unserviceable (${calculateUnserviceableCounts(counts)})`,
			icon: 'unserviceable'
		},
		{
			key: '5',
			label: `Returns (${calculateReturnCount(counts)})`,
			icon: 'returnSvgIcon'
		},
		{
			key: '6',
			label: `NDRs (${calculateNdrCount(counts)})`,
			icon: 'ndrSvgIcon'
		},
		{
			key: '7',
			label: 'User Requests (NDR)',
			icon: 'ndr2'
		}
	]
});

const showOrderStatusFilters: any = (sidebarMenuItem: any) => sidebarMenuItem !== '5';

export interface ITopFilterUtil {
	onDownloadOrders?: any;
	onDownloadOrderPickList?: any;
	filter?: any;
	setFilter?: any;
	counts?: any;
	pageConfig?: any;
	setPageConfig?: any;
	setPageConfigDefault?: any;
	sidebarMenuItem?: any;
	selectedRowKeys?: any;
	setLabelEmailModalType?: any;
	setSelectedRowKeys?: any;
	getAllOrders?: any;
	qTaskTrackingId?: any;
	isQTaskInProgress?: any;
	isMissedOrder?: boolean;
	isUnServiceableSection?: boolean;
}

export const topFilterUtil = ({
	onDownloadOrders,
	onDownloadOrderPickList,
	filter,
	setFilter,
	counts,
	setPageConfigDefault,
	sidebarMenuItem,
	selectedRowKeys,
	setLabelEmailModalType,
	setSelectedRowKeys,
	// getAllOrders,
	qTaskTrackingId,
	isQTaskInProgress,
	isMissedOrder = false,
	isUnServiceableSection = false
}: ITopFilterUtil) => {
	const handleStatusChange = (status: any) => {
		setPageConfigDefault();
		setFilter((prevFilter: any) => ({ ...prevFilter, status }));
	};

	const setDate = (startDate: any, endDate: any) => {
		setPageConfigDefault();
		setFilter((v: any) => ({ ...v, startDate, endDate }));
	};
	const bulkDownload = async (type: any) => {
		amplitude.getInstance().logEvent(`Bulk ${type} clicked - orders page`);
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');

			if ((type === 'label' || type === 'invoice') && selectedRowKeys.length > MAX_DOWNLOAD_LABEL_LIMIT) {
				type === 'label' ? setLabelEmailModalType('Label') : setLabelEmailModalType('Invoice');
			} else {
				const res = await apiOms.post(`/download/${type}`, { orderId: selectedRowKeys }, { responseType: 'blob' });
				downloadAsFile({ data: res.data, fileName: `${type}s`, fileType: 'pdf' });
			}
		} catch (error: any) {
			if (error?.response?.data?.type === 'application/json') {
				error.message = `Unable to find ${type}`;
			}

			errorHandler(error);
		}
	};

	const bulkReadyToShip = async (ids: any) => {
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');

			const {
				data: { status, message, order }
			} = await apiOms.post(`/bulkreadytoship/`, {
				socketId,
				orderids: selectedRowKeys.join(',')
			});

			qTaskTrackingId(order);
			if (!status) {
				notification.error({
					message: 'Failed',
					description: message,
					placement: 'topRight'
				});
				return;
			}

			notification.success({
				message: 'Success',
				description: message,
				placement: 'topRight'
			});
		} catch (error: any) {
			if (error?.response?.data?.type === 'application/json') {
				error.message = `Something went wrong!`;
			}

			errorHandler(error);
		}
	};

	const onDownloadShippingManifest = async () => {
		let URL, downloadUrl;
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');
			const { data } = await apiOms.get(`/download/shipping-manifest?orderIds=${selectedRowKeys.join(',')}`, {
				responseType: 'blob'
			});

			// create an object URL from the Blob
			URL = window.URL || window.webkitURL;
			downloadUrl = URL.createObjectURL(data);
			window.open(downloadUrl, '_blank');
		} catch (error: any) {
			errorHandler(error);
			URL?.revokeObjectURL(downloadUrl ?? '');
		}
	};

	const bulkCheckServiceability = async () => {
		try {
			if (!selectedRowKeys.length) throw new Error('Select at least 1 order!');

			const {
				data: { message }
			} = await apiOms.post(`/bulk-check-serviceability`, {
				orderIds: selectedRowKeys
			});

			notification.success({
				message: 'Success',
				description: message || 'Serviceability checked successFully',
				placement: 'topRight'
			});
		} catch (error: any) {
			if (error?.response?.data?.type === 'application/json') {
				error.message = `Something went wrong!`;
			}

			errorHandler(error);
		}
	};

	const getSubActions = () => {
		if (isMissedOrder) {
			return [];
		}

		// bulk action button for unserviceable orders section
		if (isUnServiceableSection) {
			return [
				{
					key: '1',
					label: 'Check Serviceability',
					onClick: () => bulkCheckServiceability(),
					isActive: selectedRowKeys.length > 0
				},
				{
					key: '2',
					isNotButton: true,
					render: () =>
						selectedRowKeys.length !== 0 && (
							<Badge size="default" count={`${selectedRowKeys.length} selected`} style={{ backgroundColor: '#6c757d' }} />
						)
				}
			];
		}

		return [
			{
				key: '1',
				label: 'Print Label',
				onClick: () => bulkDownload('label'),
				isActive: selectedRowKeys.length > 0
			},
			{
				key: '2',
				label: 'Print Invoice',
				onClick: () => bulkDownload('invoice'),
				isActive: selectedRowKeys.length > 0
			},
			{
				key: '3',
				label: 'Mark Ready To Ship',
				onClick: () => bulkReadyToShip(selectedRowKeys),
				isActive: selectedRowKeys.length > 0
			},
			{
				key: '4',
				label: 'Download PickList',
				onClick: () => onDownloadOrderPickList(),
				isActive: selectedRowKeys.length > 0
			},
			{
				key: '5',
				label: 'Download Shipping Manifest',
				onClick: () => onDownloadShippingManifest(),
				isActive: selectedRowKeys.length > 0
			},
			{
				key: '6',
				isNotButton: true,
				render: () =>
					selectedRowKeys.length !== 0 && (
						<Badge size="default" count={`${selectedRowKeys.length} selected`} style={{ backgroundColor: '#6c757d' }} />
					)
			},
			{
				key: '7',
				isNotButton: true,
				render: (progress: any) => <BulkActionProgress label={'RTS'} progress={progress} isQTaskInProgress={isQTaskInProgress} />
			}
		];
	};

	const topFilterRow = commonTopFilterRow(filter.startDate, filter.endDate);

	return {
		row1: [
			{
				...topFilterRow,
				onChange: (dateStrings: any) => setDate(dateStrings[0], dateStrings[1])
			}
		],
		row1children: [
			<Col key={1}>
				<div className={styles.searchContainer}>
					<DebouncedCompactSearch
						defaultSearchOptionType={filter.searchOptionType}
						searchOptions={isMissedOrder ? searchOptionsForMissedOrder : searchOptions}
						debounceTime={700}
						setFilter={setFilter}
						setDefault={setPageConfigDefault}
						selectStyle={{ minWidth: '140px' }}
					>
						<label>Search By:</label>
					</DebouncedCompactSearch>
				</div>
			</Col>,
			// For Order status
			showOrderStatusFilters(sidebarMenuItem) && !isMissedOrder && (
				<div className={styles.orderStatusFilter}>
					<label htmlFor="statusFilter">Status:</label>
					<Select
						id="statusFilter"
						mode="multiple"
						placeholder="Select status"
						maxTagCount="responsive"
						style={{ width: '100%' }}
						allowClear
						value={filter.status}
						onChange={handleStatusChange}
					>
						{Object.values(ORDER_STATUS).map((orderStatusVar) => {
							const count = counts[orderStatusVar] || 0;
							return (
								<Option key={orderStatusVar} disabled={!count}>
									{orderStatus[orderStatusVar]} ({count})
								</Option>
							);
						})}
					</Select>
				</div>
			),
			// For sync status
			isMissedOrder && (
				<div className={styles.orderStatusFilter}>
					<label htmlFor="statusFilter">Status:</label>
					<Select
						id="statusFilter"
						placeholder="Select status"
						style={{ width: '100%' }}
						allowClear
						value={filter.status}
						onChange={handleStatusChange}
					>
						<Option key={''}>All</Option>
						<Option key={1}>Synced</Option>
						<Option key={0}>Not Synced</Option>
					</Select>
				</div>
			)
			// For Cluster
		],
		row2: [
			<Col key={2}>
				<Button style={{ marginTop: '1.5rem' }} onClick={() => onDownloadOrders(selectedRowKeys)}>
					<span>{exportSvg}</span>
					<span style={{ marginLeft: '.4rem', fontSize: '0.85rem' }}>Export</span>
				</Button>
			</Col>
		],
		row4: getSubActions()
	};
};

export function getFilterFromSessionStorage() {
	let filter;
	const storedFilter = sessionStorage.getItem('rangeFilter');

	if (storedFilter !== null) {
		let filterObj = JSON.parse(storedFilter);

		// check if stored filter is expired, if no, set stored filter
		if (new Date(filterObj.expiresAt) > new Date()) {
			filter = filterObj.filter;
			filter = { ...filter, endDate: dayjs() };
		}
		// if the stored filter is expired, delete the filter from session storage
		// and set the filter to default filter
		else {
			sessionStorage.removeItem('rangeFilter');
			filter = defaultFilter;
		}
	} else {
		filter = defaultFilter;
	}
	delete filter.searchValue;
	return filter;
}

export function removeFilterSessionStorage() {
	sessionStorage.removeItem('rangeFilter');
	return defaultFilter;
}

export function getMissedFilterFromSessionStorage() {
	let filter;
	const storedFilter = sessionStorage.getItem('rangeMissedFilter');
	if (storedFilter !== null) {
		let filterObj = JSON.parse(storedFilter);
		// check if stored filter is expired, if no, set stored filter
		if (new Date(filterObj.expiresAt) > new Date()) {
			filter = filterObj.filter;
			filter = { ...filter, endDate: dayjs() };
		}
		// if the stored filter is expired, delete the filter from session storage
		// and set the filter to default filter
		else {
			sessionStorage.removeItem('rangeMissedFilter');
			filter = defaultFilter;
		}
	} else {
		filter = defaultFilter;
	}
	delete filter.searchValue;
	return filter;
}

export function getSidebarMenuItemFromSessionStorage() {
	const storedMenuItem = sessionStorage.getItem('sidebarMenuItem');
	if (storedMenuItem !== null) {
		return parseInt(storedMenuItem);
	}

	return 1;
}

export function setSidebarMenuItemFromSessionStorageToInitial() {
	sessionStorage.setItem('sidebarMenuItem', '1');
}
