import { Button, Switch } from 'antd';
import styles from './index.module.scss';

export function AddressCard() {
	return (
		<div className={styles.addressCard}>
			<div className={`${styles.addressId} ${styles.active}`}>2</div>

			<div className={styles.content}>
				<div className={styles.addressContainer}>
					<h6 className={styles.warehouseName}>Central WhareHuose</h6>
					<div className={styles.address}>
						<p>
							Hotel Parkview, Udyan Path, 24B, <br />
							Sector - 34B, Chandigarh, India.
						</p>
						<p>Pincode: 100011</p>
						<p>POC name: Some name</p>
						<p>POC number: 927639-8386</p>
					</div>
				</div>

				<div className={styles.options}>
					<Button type="text" size="small">
						Edit ▼
					</Button>

					<Switch checkedChildren="OFF" unCheckedChildren="ON" />
				</div>
			</div>
		</div>
	);
}
