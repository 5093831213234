import { useEffect, useRef } from 'react';

export function InfoSlider({ children, onClose }) {
	const sliderRef = useRef(null);

	useEffect(() => {
		function close(e) {
			if (!sliderRef.current?.contains(e.target)) {
				onClose();
			}
		}
		document.addEventListener('click', close);

		return () => document.removeEventListener('click', close);
	}, []);

	return (
		<div ref={sliderRef} onClick={(e) => e.stopPropagation()}>
			{children}
		</div>
	);
}
