import { defaultPageConfig } from '../../../../constants';
import { DebouncedCompactSearch } from '../../../../shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import { searchOptions } from './constants';
import styles from './utils.module.scss';

export const topBarConfig = ({ setPageConfig, filter, setFilter }) => {
	return {
		row5: [
			<div className={styles.topFilter}>
				<div className={styles.topLeftFilter}>
					<div className={styles.searchElement}>
						<DebouncedCompactSearch
							defaultSearchOptionType={filter.searchOptionType}
							searchOptions={searchOptions}
							setFilter={setFilter}
							setDefault={() => setPageConfig(defaultPageConfig)}
							selectStyle={{ minWidth: '120px' }}
							inputStyle={{ width: '200px' }}
						>
							<span>Search By:</span>
						</DebouncedCompactSearch>
					</div>
				</div>
			</div>
		],
		shouldShowRow5: true
	};
};
