import { WalletOutlined } from '@ant-design/icons';
import { Button, Input, Tag, Tooltip } from 'antd';
import errorHandler from 'shared/utils/errorHandler';
import { apiBms } from '../../../shared/api';
import { DebouncedCompactSearch } from '../../../shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import { searchOptions } from '../../../shared/components/Wallet/constants';
import { IWalletFilter } from '../../../shared/components/Wallet/types';
import styles from './index.module.scss';
import { commonTopFilterRow } from '../../../utils/filters';

const onRecharge = async (rechargeAmount: any, setRechargeFormVisibile: any, setRechargeLoading: any) => {
	try {
		if (rechargeAmount < 1) {
			throw new Error('Recharge Amount should be greater than or equal to 500');
		}
		setRechargeLoading(true);
		const response = await apiBms.post(`/razorpay/initiate`, {
			price: rechargeAmount * 100 + rechargeAmount * 2
		});

		if (!response?.data.status) {
			throw new Error(response?.data.message || 'Could not initiate transactions');
		}

		const data = response.data.data;
		const options = {
			key: 'rzp_live_9ImApYcH8noAuf',
			currency: data.currency,
			amount: data.amount,
			name: 'Wherehouse.io',
			description: 'Wallet Recharge',
			image: 'https://wherehouse-prod-content.s3.ap-south-1.amazonaws.com/logo/wherehouse-logo.png',
			order_id: data.id,
			handler: async function (response: any) {
				const handlerData = {
					razorpayOrderId: data.id,
					razorpayPaymentId: response.razorpay_payment_id,
					amount: data.amount,
					razorpaySignature: response.razorpay_signature
				};

				const result = await apiBms.post(`/razorpay/capture`, handlerData);

				alert(result.data.msg);
			}
		};

		const orderObject = new (window as any).Razorpay(options);

		orderObject.open();
	} catch (error) {
		errorHandler(error);
	} finally {
		setRechargeLoading(false);
		setRechargeFormVisibile(false);
	}
};

export function topFilterUtil({
	filter,
	setFilter,
	setPageConfigDefault,
	companyBalance,
	rechargeAmount,
	setRechargeAmount,
	rechargeFormVisibile,
	setRechargeFormVisibile,
	rechargeLoading,
	setRechargeLoading
}: any): any {
	const setDate = (startDate: string, endDate: string) => {
		setPageConfigDefault();
		setFilter((prevFilter: IWalletFilter) => ({ ...prevFilter, start: startDate, end: endDate }));
	};
	const topFilterRow = commonTopFilterRow(filter.start, filter.end);
	return {
		row1: [
			{
				...topFilterRow,
				onChange: (dateStrings: [string, string]) => setDate(...dateStrings)
			}
		],
		row1children: [
			<div>
				<DebouncedCompactSearch
					defaultSearchOptionType={filter.searchOptionType}
					searchOptions={searchOptions}
					setFilter={setFilter}
					setDefault={setPageConfigDefault}
					selectStyle={{ minWidth: '100px' }}
				>
					<span>Search By:</span>
				</DebouncedCompactSearch>
			</div>,

			rechargeFormVisibile ? (
				<div style={{ display: 'flex', marginTop: 22, height: '2rem' }}>
					<Input.Group compact>
						<Input
							name="amount"
							placeholder="Amount"
							type="number"
							onChange={(e) => {
								setRechargeAmount(e.target.value);
							}}
							style={{ width: '6rem', marginRight: '0.5rem' }}
						/>
						<Tooltip title="Note: An additional 2% payment gateway charge will be levied on recharge amount">
							<Button
								onClick={() => {
									onRecharge(rechargeAmount, setRechargeFormVisibile, setRechargeLoading);
								}}
								loading={rechargeLoading}
								type="primary"
							>
								Recharge
							</Button>
						</Tooltip>
					</Input.Group>
				</div>
			) : (
				<div>
					<Button style={{ marginTop: 20, marginLeft: 20 }} type="primary" onClick={() => setRechargeFormVisibile(true)}>
						Recharge Wallet
					</Button>
				</div>
			)
		],

		row2: [
			<div style={{ fontSize: '20px', margin: 'auto', marginTop: '20px' }}>
				<Tag className={styles.balanceBox} icon={<WalletOutlined />}>
					Balance: {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(companyBalance)}
				</Tag>
			</div>
		]
	};
}
