import { useEffect, useState } from 'react';

import { InfoSlider } from 'shared/components/InfoSlider/InfoSlider';
import { Loading } from 'shared/components/Loading';
import styles from '../index.module.scss';
import { ReactComponent as CloseIcon } from '../../../../../shared/svgs/closeIcon.svg';
import { Col } from 'antd';
import { ICreateTransactionObject, IWalletFilter } from '../../../../../shared/components/Wallet/types';
import { FormLayout } from 'shared/components/FormLayout';
import { transactionCodeEnum, transactionType } from '../../../../../shared/components/Wallet/constants';

export function TransactionInfoSlider({
	onCloseInfoSlider,
	filter,
	onCreateTransaction
}: {
	onCloseInfoSlider: any;
	filter: IWalletFilter;
	onCreateTransaction: any;
}): any {
	const [loading, setLoading] = useState(true);

	const onTransactionSave = async (values: ICreateTransactionObject) => {
		setLoading(true);
		await onCreateTransaction({
			...values,
			companyId: filter.companyID,
			amount: values.amount
		});
		setLoading(false);
	};

	useEffect(() => {
		setLoading(false);
	}, []);

	return (
		<InfoSlider onClose={() => onCloseInfoSlider()}>
			<div className={styles.main} style={{ width: '28rem' }}>
				{loading && <Loading loading={loading} />}
				<CloseIcon title="Close" className="closeBtn" onClick={() => onCloseInfoSlider()} />

				<Col className={styles.transactionInfoContainer}>
					<div className={styles.paddedDiv}>
						<span style={{ fontWeight: 'bold' }}>Company Name:</span> {filter.companyName}
						<Col className={styles.transactionForm}>
							{!loading && (
								<FormLayout
									onClose={() => onCloseInfoSlider()}
									formTitle={''}
									onSave={(values: any) => onTransactionSave(values)}
									isClose={false}
									formFields={[
										{
											name: 'description',
											id: 'description',
											label: 'Description',
											required: true,
											placeholder: 'Enter Description'
										},
										{
											name: 'amount',
											id: 'amount',
											label: 'Amount',
											required: true,
											placeholder: 'Enter Amount'
										},
										{
											name: 'type',
											id: 'type',
											label: 'Type',
											required: true,
											placeholder: 'Enter Type',
											fieldType: 'select',
											options: transactionType
										},
										{
											name: 'code',
											id: 'code',
											label: 'Code',
											required: true,
											placeholder: 'Enter Code',
											fieldType: 'select',
											options: transactionCodeEnum
										}
									]}
									initialValues={{
										description: '',
										amount: '',
										type: '',
										code: ''
									}}
									saveButtonText={'Save'}
									closeButtonText={'Close'}
									customSubmitComponent={null}
									className=""
								/>
							)}
						</Col>
					</div>
				</Col>
			</div>
		</InfoSlider>
	);
}
