import styles from '../TrackDetails/index.module.scss';
import WherehouseLogo from 'shared/svgs/trackingPageSvg/wherehouse_logo_svg.svg';

import { TrackingSideDrawer } from './TrackingSideDrawer';

export const Header = ({ brandsTrackingDesign }) => {
	return (
		<>
			<nav className={styles.navbar} style={{ background: `${brandsTrackingDesign.pc || '#03045e'}` }}>
				<div className={styles.imageClass}>
					<img
						alt="wherehouse logo"
						src={
							brandsTrackingDesign?.hl?.data || typeof brandsTrackingDesign?.hl === 'string'
								? brandsTrackingDesign?.hl
								: WherehouseLogo
						}
					/>
				</div>
				{brandsTrackingDesign?.links?.length > 0 && (
					<>
						{brandsTrackingDesign?.links.length > 0 && (
							<TrackingSideDrawer data={brandsTrackingDesign?.links} font={brandsTrackingDesign.hf} />
						)}
						<div className={styles.navbarLinks} style={{ display: 'flex', gap: '20px' }}>
							{brandsTrackingDesign?.links.map((e) => {
								const link = e?.linkURL?.includes('http') ? e?.linkURL : `https://${e.linkURL}`;
								return (
									<span style={{ fontWeight: 'bold' }}>
										<a style={{ color: `${brandsTrackingDesign.hf}`, fontWeight: 'bold' }} href={link}>
											{e.linkName}
										</a>
									</span>
								);
							})}
						</div>
					</>
				)}
			</nav>
		</>
	);
};
