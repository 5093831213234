import styles from './index.module.scss';
import dayjs from 'dayjs';
import { Form, Select, Input, Row, Col } from 'antd';
import { PAYMENT_METHOD } from '../../../../../../constants';
import { useContext, useState } from 'react';
import { AuthContext } from '../../../../../../shared/contexts/Auth';
import { IManualOrderFields } from 'utils/typescript/types/orders';
const { Option } = Select;

export const OtherDetails: React.FC<IManualOrderFields> = ({ customStyle, existOrderData, setSpecialDiscount, isD2ROrder }) => {
	const [paymentMethod, setPaymentMethod] = useState(existOrderData?.paymentMode);
	const { authState } = useContext(AuthContext);

	return (
		<div className={`${customStyle} ${styles.otherDetails}`}>
			<h4 className={styles.heading}>Other Details</h4>
			<Row gutter={30}>
				<Col span={10}>
					<Form.Item
						label="Mode of Payment"
						name="paymentMethod"
						initialValue={existOrderData?.paymentMode}
						rules={[{ required: true, message: 'Please select payment method' }]}
					>
						<Select
							onChange={(value) => {
								setPaymentMethod(value);
							}}
							style={{ width: '400px' }}
							size="middle"
						>
							<Option value={PAYMENT_METHOD.COD}>COD</Option>
							<Option value={PAYMENT_METHOD.PREPAID}>Prepaid</Option>
							{isD2ROrder && <Option value={PAYMENT_METHOD.CREDIT}>Credit</Option>}
						</Select>
					</Form.Item>
				</Col>

				{paymentMethod === PAYMENT_METHOD.CREDIT && (
					<Col span={10}>
						<Form.Item
							label="Due Date (D2R Order Credit Due Date)"
							name="duedate"
							initialValue={dayjs(existOrderData?.d2rDueDate).format('YYYY-MM-DD')}
							rules={[{ required: true, message: 'Please select due date' }]}
						>
							{existOrderData?.d2rDueDate ? (
								<Input defaultValue={dayjs(existOrderData?.d2rDueDate).format('YYYY-MM-DD')} type="date" />
							) : (
								<Input min={dayjs().format('YYYY-MM-DD')} max={dayjs().add(60, 'days').format('YYYY-MM-DD')} type="date" />
							)}
						</Form.Item>
					</Col>
				)}

				<Col span={10}>
					<Form.Item label="Eway Bill No." name="ewayBill" initialValue={existOrderData?.ewayBill}>
						<Input placeholder="000000111111" />
					</Form.Item>
				</Col>

				<Col span={10}>
					<Form.Item
						// initialValue={dayjs(existOrderData?.createdAt).format('YYYY-MM-DDThh:mm')}
						label="Backdate (If invoice is being created for any backdate)"
						name="backdate"
					>
						<Input
							{...(existOrderData?.createdAt && {
								defaultValue: dayjs(existOrderData?.createdAt).format('YYYY-MM-DDThh:mm:ss')
							})}
							type="datetime-local"
						/>
					</Form.Item>
				</Col>
				{Boolean(Number(authState?.profile?.shouldShowSpecialDiscount)) && (
					<Col span={10}>
						<Form.Item label="Special Discount" name="specialDiscount">
							<Input
								onChange={(e) => setSpecialDiscount(e.target.value)}
								type="number"
								placeholder="Enter Special Discount"
							/>
						</Form.Item>
					</Col>
				)}
			</Row>
		</div>
	);
};
