export interface IInsights {
	sideBarMenu: {
		key: string;
		label: string;
		icon: string;
	}[];
}

export const IInsightCardName = Object.freeze({
	TOTAL_ORDERS: 'Orders this month',
	REVENUE: 'Revenue this month (INR)',
	TODAY_ORDERS: "Today's Orders",
	TODAY_REVENUE: "Today's Revenue (INR)",
	UNDELIVERED_ORDERS: 'Undelivered orders (NDR)',
	AVERAGE_TAT: 'Average TAT',
	NUMBER_OF_ORDERS_CANCELED: 'Cancelled orders count'
});

export const IInsightHeading = Object.freeze({
	TOP_RTO_REASON: 'RTO Top Reasons',
	COURIER_SPLIT: 'Courier Split',
	ORDER_DELIVERED_FROM_WAREHOUSE: 'Orders at Wherehouses'
});

export interface IInsightsOrdercount {
	status: boolean;
	todaysOrders: number;
	totalOrders: number;
	lastMonthOrders: number;
	yesterdayOrders: number;
}

export interface IInsightsTotalRevenue {
	status: boolean;
	todaysRevenue: {
		revenue: number;
	};
	totalRevenue: {
		revenue: number;
	};
	lastMontheRevenue: { revenue: number };
	yesterdaysRevenue: { revenue: number };
}

export interface IInsightsCourier {
	status: boolean;
	courierSplit: {
		delivery_partner_id: string;
		total: string | number;
	}[];
}

export const IInsightDaysCount = Object.freeze({
	LAST_THIRTY_DAYS: 'Since last 30 days',
	YESTERDAY: 'Since yesterday'
});

export const InsightAPIarray = [
	'/insights/revenue',
	'/insights/orders',
	'/insights/top-products',
	'/insights/rto-top-reasons',
	'/insights/warehouse-split',
	'/insights/courier',
	'/insights/undelivered-orders',
	'/insights/state-wise',
	'/insights/cancelled-orders'
];
