import { Row, Col } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Sidebar } from 'shared/components/AppLayout/Sidebar';
import { ADMIN_MENU_OPTIONS, getAdminSubMenuWrapper, sidebarUtil } from './utils';
import { SideDrawer } from '../../../shared/components/SideDrawer/SideDrawer';
import { AuthContext } from 'shared/contexts/Auth';
import { useHistory, useLocation } from 'react-router-dom';

export const adminRoute = '/admin/list';

export function Admin() {
	const {
		authState: { profile }
	} = useContext(AuthContext);
	const { search } = useLocation();
	const history = useHistory();
	const ADMIN_SUB_PAGE_MAPPER = getAdminSubMenuWrapper();
	let tab = search.split('=')[1];
	if (!tab) {
		tab = 'Companies';
	}
	const [sidebarMenuItem, setSidebarMenuItem] = useState(ADMIN_MENU_OPTIONS[tab]);

	useEffect(() => {
		let val;
		val = Object.keys(ADMIN_MENU_OPTIONS).find((key) => ADMIN_MENU_OPTIONS[key] === sidebarMenuItem);
		history.push({
			pathname: '/admin/list',
			search: `?tab=${val}`
		});
	}, [sidebarMenuItem]);

	return (
		<Row className="h-100 p-0">
			<SideDrawer placement="left">
				<Sidebar
					sideBarMenu={sidebarUtil(profile.user_type)}
					bottomButtons={null}
					selectedMenu={sidebarMenuItem}
					onMenuItemSelect={setSidebarMenuItem}
				/>
			</SideDrawer>
			<Col sm={24} lg={20} className={`scrollable`}>
				{ADMIN_SUB_PAGE_MAPPER[sidebarMenuItem]}
			</Col>
		</Row>
	);
}
