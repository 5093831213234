import React from 'react';
import { Button } from 'antd';
import { images as channelImages } from 'shared/utils/channel';
import { isSortField } from 'shared/utils/table';
import { commonColumns } from 'shared/utils/columns';

export const useColumns = ({ dictionary, syncMissedOrder, sortField, sortOrder }) => {
	return [
		commonColumns.createAt(sortField, sortOrder),
		commonColumns.staticRenderFieldWithSortOption('Order Id', 'shopOrderId', sortField, sortOrder, false, {}, 120),
		commonColumns.staticRenderFieldWithSortOption('Channel', 'shopType', sortField, sortOrder, true, channelImages, 100),
		commonColumns.staticRenderField('Description', 'description'),
		commonColumns.staticRenderField('Reason', 'reason', false, dictionary, 100),
		{
			title: 'Action',
			dataIndex: 'synced',
			render: (status, order) => (
				<>
					<Button
						type="primary"
						shape="round"
						size="medium"
						disabled={status}
						onClick={() => {
							syncMissedOrder(order);
						}}
					>
						{Boolean(status) ? `Synced` : `Sync`}
					</Button>
				</>
			),
			width: 150,
			sorter: true,
			sortOrder: isSortField(sortField, sortOrder, 'status')
		}
	];
};
