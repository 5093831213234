import { fetchApi } from '../../../../helper/fetchApi';
import useQuery from '../../../../helper/useQuery';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
const { REACT_APP_SHOPIFY_API_KEY } = process.env;

export function InstallationRedirectComponent() {
	const query = useQuery();
	const state = uuidv4();
	const [isProcessCompleted, setIsProcessCompleted] = useState(false);

	const redirectToPermissionPage = (shop) => {
		const redirect_uri = `${window.location.origin}/shopify-redirect?redirect-type=permission`;
		const scope =
			'read_products,write_products,read_product_listings,read_orders,write_orders,read_all_orders,read_inventory,write_inventory,read_locations,read_fulfillments,write_fulfillments,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_shipping,write_shipping,read_discounts,read_customers';
		const permissionLink = `https://${shop}/admin/oauth/authorize?client_id=${REACT_APP_SHOPIFY_API_KEY}&scope=${scope}&redirect_uri=${redirect_uri}&state=${state}&grant_options[]=value`;

		window.open(permissionLink);
	};

	const postShopifyData = async (query) => {
		try {
			const shop = query.get('shop');
			const hmac = query.get('hmac');

			const data = { shop, hmac, state };

			await fetchApi({
				url: '/ums/shopify-integration/installation',
				method: 'post',
				data
			});

			setIsProcessCompleted(true);
			redirectToPermissionPage(shop);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		postShopifyData(query);
	}, []);

	return <div>{isProcessCompleted ? <p>App installed successfully.</p> : <p>Loading...</p>}</div>;
}
