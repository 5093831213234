import { Button } from 'antd';
import { useState } from 'react';
import { UploadBulkModal } from '../../../../shared/components/UploadBulkModal';
import { MAX_ROWS_EXCEED_ERROR_MSG, bulkExternalRetailersPreviewRoute } from '../../../../shared/components/UploadBulkPreview/constants';
import { ERetailerStoreType, retailClusters } from '../../../../constants/minq';
import styles from './index.module.scss';

export function ExternalRetailers() {
	const [uploadModalVisible, setUploadModalVisible] = useState(false);
	function handleUpload() {
		setUploadModalVisible(true);
	}
	return (
		<div className={styles.page}>
			<Button onClick={handleUpload}>Upload Retailers</Button>
			<UploadBulkModal
				instructionMessage={
					<ul className="instructions">
						<li>Types Of Retailers {Object.keys(ERetailerStoreType).join(', ')}</li>
						<li>Clusters - {retailClusters.join(', ')} </li>
						<li>Class - A,B,C,D or E </li>
						<br />
						Note: {MAX_ROWS_EXCEED_ERROR_MSG}
					</ul>
				}
				templatePath={process.env.REACT_APP_EXCEL_EXTERNAL_RETAILER_TEMPLATE}
				previewPath={bulkExternalRetailersPreviewRoute}
				modalTitle="Bulk D2R Retailer Data"
				isModalVisible={uploadModalVisible}
				setIsModalVisible={setUploadModalVisible}
				shouldShowDownloadSample={true}
				companyId={null}
			></UploadBulkModal>
		</div>
	);
}
