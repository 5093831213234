import { Col, Row } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TopFilterComponent } from '../../../../shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from '../../../../shared/components/CustomTablePagination';
import errorHandler from '../../../../shared/utils/errorHandler';
import { defaultFilter, defaultPageConfig } from './constants';
import { IFilter } from './types';
import { topFilterUtil } from './utils';
import { IPageConfiguration } from '../../commonTypes';
import { getInvoices } from '../../../../shared/api/d2r';
import { D2RInvoiceSlider } from '../../../../shared/components/D2RInvoiceSlider';
import d2rInvoiceColumns from './columns';
import { companyType } from '../D2r';
import { apiBms } from '../../../../shared/api';
import dayjs from 'dayjs';

export default function Invoices({ company }: { readonly company: Readonly<companyType> }) {
	const [data, setData] = useState<any[]>([]);
	const [currentCount, setCurrentCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<IFilter>(defaultFilter);
	const [pageConfig, setPageConfig] = useState<IPageConfiguration>(defaultPageConfig);
	const [showD2RInvoiceSlider, setShowD2RInvoiceSlider] = useState<string>('');
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [isVoucherXmlLoading, setIsVoucherXmlLoading] = useState(false);
	const [isVoucherExcelLoading, setIsVoucherExcelLoading] = useState(false);
	const columns = d2rInvoiceColumns(setShowD2RInvoiceSlider);

	const setPageConfigDefault = useCallback(() => {
		setPageConfig(defaultPageConfig);
	}, []);

	function onCSVDownload() {
		getInvoices(pageConfig, filter, 1);
	}

	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				filter,
				setFilter,
				setPageConfigDefault,
				selectedRowKeys,
				onCSVDownload,
				onDownloadOrdersVoucherXml,
				onDownloadOrdersVoucherExcel,
				isVoucherXmlLoading,
				isVoucherExcelLoading
			}),
		[filter, setFilter, setPageConfigDefault, selectedRowKeys, isVoucherXmlLoading, isVoucherExcelLoading]
	);

	async function onDownloadOrdersVoucherXml() {
		try {
			if (selectedRowKeys.length === 0) {
				throw new Error('No order is selected.');
			}
			setIsVoucherXmlLoading(true);
			const { data } = await apiBms.post('/tally-voucher/download/:xml', { orderIds: selectedRowKeys });

			if (data.success) {
				const blob = new Blob([data.xml], { type: 'text/xml' });
				const url = URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.download = `InvoiceVoucher${dayjs().format()}`;
				link.href = url;
				link.click();
				setIsVoucherXmlLoading(false);
			} else {
				throw new Error("Voucher for some orders can't be created. Download excel to know the reason.");
			}
		} catch (err) {
			setIsVoucherXmlLoading(false);
			errorHandler(err);
		}
	}
	async function onDownloadOrdersVoucherExcel() {
		try {
			if (selectedRowKeys.length === 0) {
				throw new Error('No order is selected.');
			}

			setIsVoucherExcelLoading(true);
			const { data } = await apiBms.post('/tally-voucher/download/:excel', { orderIds: selectedRowKeys }, { responseType: 'blob' });

			const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
			let a = document.createElement('a');
			a.href = URL.createObjectURL(blob);
			a.download = `InvoiceVoucher${dayjs().format()}`;
			a.click();
			setIsVoucherExcelLoading(false);
		} catch (err) {
			setIsVoucherExcelLoading(false);
			errorHandler(err);
		}
	}

	async function getAllInvoices(pageConfiguration: any, filters: any) {
		try {
			setLoading(true);
			const response = await getInvoices(pageConfiguration, filters);
			if (response?.data.status) {
				setData(response.data.invoices);
				setCurrentCount(response.data.currentCount);
			} else {
				throw new Error(response?.data.message || 'Could not get payments');
			}
		} catch (error: unknown) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		setFilter((prevFilter: IFilter) => ({ ...prevFilter, company }));
	}, [company]);

	useEffect(() => {
		getAllInvoices(pageConfig, filter);
	}, [filter, pageConfig]);

	return (
		<Row className="h-100 p-0">
			<Col span={24} className="main">
				<TopFilterComponent {...topFilterConfig} />
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={columns}
							data={data}
							onChangePage={(current: any, pageSize: any) => setPageConfig((v) => ({ ...v, current, pageSize }))}
							current={Number(pageConfig.current)}
							total={currentCount}
							loading={loading}
							shouldRowSelection={true}
							selectedItem={undefined}
							catalog={undefined}
							selectedRowKeys={undefined}
							setSelectedRowKeys={setSelectedRowKeys}
							setSelectedItem={undefined}
							pageSize={Number(pageConfig.pageSize)}
							sortField={pageConfig.sortField}
							sortOrder={pageConfig.sortField}
						/>
					</div>
				</div>
			</Col>
			{showD2RInvoiceSlider && (
				<D2RInvoiceSlider
					orderId={showD2RInvoiceSlider}
					onClose={() => {
						setShowD2RInvoiceSlider('');
					}}
					key={'#0'}
				/>
			)}
		</Row>
	);
}
