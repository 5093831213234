import dayjs from 'dayjs';
import { ICommonFilter, IPageConfiguration } from '../../commonTypes';

export const searchOptions = [{ value: 'amount', label: 'Amount' }];

export const defaultFilter: ICommonFilter = {
	start: dayjs().subtract(30, 'day'),
	end: dayjs(),
	searchOptionType: searchOptions[0].value
};

export const defaultPageConfig: IPageConfiguration = {
	current: 1,
	pageSize: 10,
	sortField: 'createdAt',
	sortOrder: 'DESC'
};
