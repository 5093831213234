import { Form, Input, Button, Card, Alert, Col } from 'antd';
import { baseApi } from 'shared/api';
import { useState } from 'react';
export const deleteUserDataRoute = `/delete-user-data`;

export function DeleteUserData(props = {}) {
	const [messageField, setMessageField] = useState('');
	const [form] = Form.useForm();
	const onFinish = async (values) => {
		const {
			data: { message }
		} = await baseApi.post(`/delete-user-data`, {
			values
		});

		setMessageField(message);
	};

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '100vh',
				padding: '20px'
			}}
		>
			<Card style={{ width: '100%', maxWidth: '500px' }} title="User data deletion request- please enter your phone number">
				<Form
					form={form}
					name="basic"
					labelCol={{
						span: 8
					}}
					wrapperCol={{
						span: 16
					}}
					onFinish={onFinish}
				>
					<Form.Item
						label="phone"
						name="phone"
						rules={[
							{
								required: true,
								message: 'Please input your phone!'
							}
						]}
					>
						<Input allowClear />
					</Form.Item>

					<Form.Item
						wrapperCol={{
							offset: 8,
							span: 16
						}}
					>
						<Button type="primary" htmlType="submit" wrapperCol={{ offset: 8, span: 16 }}>
							Submit
						</Button>
					</Form.Item>
				</Form>
				{messageField.length > 1 && (
					<Alert style={{ width: '100%', marginTop: '20px' }} message={messageField} type="success" closable />
				)}
			</Card>
		</div>
	);
}
