import { Button, Form, Input } from 'antd';
import styles from './index.module.scss';

const formFields = [
	{ name: 'bankName', label: 'Name of Bank', placeholder: 'HDFC Bank' },
	{ name: 'accountNumber', label: 'Account Number', placeholder: 'JHG89721839' },
	{ name: 'ifscCode', label: 'IFSC Code', placeholder: 'JHGJH87678' }
];

export function BankDetails() {
	return (
		<div className={styles.bankDetails}>
			<Form layout="vertical" className={styles.form}>
				{formFields.map(({ name, label, placeholder }) => {
					return (
						<Form.Item name={name} label={label}>
							<Input placeholder={placeholder} className={styles.input} />
						</Form.Item>
					);
				})}

				<p className={styles.info}>*Please verify bank details before adding your account</p>

				<Form.Item>
					<Button type="primary" htmlType="submit">
						Save Changes
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}
