import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import errorHandler from 'shared/utils/errorHandler';
import styles from './index.module.scss';

/**
 *
 * @param modalTitle (Title of the modal)
 * @param instructionMessage (Instruction for bulk upload)
 * @param templatePath (Path for downloading sample file)
 * @param previewPath (Path for preview uploaded File)
 * @param isModalVisible (Flag for modal should visible or not)
 * @param setIsModalVisible (Function to set visible flag)
 *
 * @returns
 */
export const BeatsModal = ({ modalTitle, instructionMessage, templatePath, isModalVisible, setIsModalVisible }) => {
	const [excelFile, setExcelFile] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleCloseModal = () => setIsModalVisible(false);

	const handleDownloadSample = () => {
		window.open('https://wh-staging-s3.s3.ap-south-1.amazonaws.com/excel/DTR+Orders+Sample.xlsx', 'blank');
	};
	const handleDownloadBeat = () => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
			setIsModalVisible(false);
			window.open(templatePath, 'blank');
		}, 2000);
	};

	const deleteFile = () => {
		setExcelFile(null);
	};

	/**
	 * Called When user upload ean excel file
	 */
	const handleFileChange = (e) => {
		try {
			const file = e.target?.files[0];

			if (!file) {
				throw new Error('No file selected');
			}

			setExcelFile(file);
		} catch (error) {
			errorHandler(error);
		}
	};

	return (
		<>
			<Modal
				title={modalTitle}
				visible={isModalVisible}
				onCancel={handleCloseModal}
				okText="Download Beat"
				onOk={handleDownloadBeat}
				centered
				className={styles.uploadBulkModal}
				confirmLoading={loading}
			>
				<h5 className={styles.heading}>Instructions to upload</h5>

				{/* Instruction Part */}
				{instructionMessage}

				<div className={styles.buttonsContainer}>
					<Button onClick={handleDownloadSample}>Download Sample</Button>

					{excelFile?.name ? (
						<div className={styles.fileDetails}>
							<p className={styles.fileName}>{excelFile.name}</p>
							<Button danger icon={<DeleteOutlined />} onClick={deleteFile} />
						</div>
					) : (
						<label htmlFor="bulk-upload" className={styles.uploadLabel}>
							Upload Orders
							<input
								id="bulk-upload"
								className={styles.uploadButton}
								size="60"
								accept=".xlsx"
								type="file"
								onChange={handleFileChange}
								value={excelFile ? excelFile : ''}
							/>
						</label>
					)}
				</div>
			</Modal>
		</>
	);
};
