export default (
	<svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.6724 1.75231C16.4701 1.63423 16.2196 1.63423 16.0173 1.74287C16.0028 1.75231 14.4661 2.55526 11.8456 2.55526C10.4197 2.55526 9.5622 2.03571 8.65656 1.48782C7.67384 0.892691 6.65741 0.278675 5.00028 0.278675C3.21309 0.278675 2.04251 0.713204 1.32956 1.14302V0.651806C1.32956 0.292841 1.03089 0 0.664778 0C0.298668 0 0 0.292841 0 0.651806V17.4098C0 17.7687 0.298668 18.0616 0.664778 18.0616C1.03089 18.0616 1.32956 17.7687 1.32956 17.4098V15.6622C1.40663 15.6244 1.46925 15.5724 1.52224 15.511C1.5656 15.4638 2.57722 14.3019 5.06291 14.3019C6.2913 14.3019 7.16804 14.7459 8.09294 15.2182C9.14792 15.7566 10.3378 16.3659 12.1298 16.3659C14.9141 16.3659 16.6339 15.256 16.7061 15.2087C16.8892 15.0859 17 14.8876 17 14.6703V2.3191C17 2.08294 16.8748 1.8704 16.6724 1.75231ZM15.6753 3.28263V14.283C15.1309 14.5522 13.8688 15.0623 12.1298 15.0623C10.6653 15.0623 9.71153 14.5758 8.70473 14.0657C7.67866 13.5414 6.61887 13.003 5.06291 13.003C3.29498 13.003 2.08586 13.5037 1.32474 13.9996V2.7867C1.40663 2.74891 1.47407 2.69223 1.52706 2.63083C1.5367 2.62139 2.45197 1.58228 5.00028 1.58228C6.28167 1.58228 7.10059 2.07821 7.96288 2.59777C8.94078 3.18817 10.0487 3.85886 11.8504 3.85886C13.5364 3.85414 14.8322 3.5613 15.6753 3.28263Z"
			fill="#231F20"
		/>
		<path
			d="M8.671 10.0367C9.03711 10.0367 9.33578 9.74383 9.33578 9.38486V5.27095C9.33578 4.91198 9.03711 4.61914 8.671 4.61914C8.30489 4.61914 8.00623 4.91198 8.00623 5.27095V9.38486C8.01104 9.74855 8.30489 10.0367 8.671 10.0367Z"
			fill="#231F20"
		/>
		<path
			d="M8.20374 11.2178C8.07849 11.3406 8.01105 11.5059 8.01105 11.6807C8.01105 11.8507 8.07849 12.016 8.20374 12.1388C8.32898 12.2616 8.49759 12.3325 8.67101 12.3325C8.84925 12.3325 9.01785 12.2616 9.13346 12.1435C9.26353 12.0255 9.33578 11.8554 9.33578 11.6854C9.33578 11.5154 9.26353 11.35 9.13828 11.2225C8.88778 10.9722 8.46387 10.9722 8.20374 11.2178Z"
			fill="#231F20"
		/>
	</svg>
);
