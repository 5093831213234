import axios from 'axios';
const { REACT_APP_UMS_API_URL } = process.env;

export const fetchApi = async ({ url, method = 'get', data = null }) => {
	try {
		const { data: responseData } = await axios({
			method,
			url: REACT_APP_UMS_API_URL + url,
			data
		});

		return responseData;
	} catch (error) {
		const errorMessage = error?.response?.data?.responseMessage || error?.message || 'Some error occurred. Try again after some time.';
		throw new Error(errorMessage);
	}
};
