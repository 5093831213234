import { apiNms, apiUms } from './index';

export const obtainProfileData = () => {
	return apiUms.get('/ums/profile/seller');
};

export const obtainSellerProfileData = (companyId) => {
	return apiNms.get('/admin/seller/profile', { params: { companyId } });
};

export const updateProfileDetails = (data) => {
	return apiUms.put('/ums/profile/seller', data);
};

export const updatePreferenceDetails = (data) => {
	return apiUms.put('/ums/profile/seller/preferences', data);
};

export const updatePreferenceDetailAdmin = (data) => {
	return apiNms.put('/admin/seller/preference', data);
};

export const changePassword = (data) => {
	return apiUms.put('/ums/password', data);
};
