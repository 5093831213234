import { Modal } from 'antd';

import { WarehouseCard } from './Warehouses';
import { EPanelPreferences } from '../constants';

interface WHmodal {
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	warehouse: object[];
	onOk: (updatedValue: any, fieldName: any, rtoOtherWh?: boolean) => Promise<void>;
}
export const WarehouseModal = ({ setIsModalVisible, warehouse, onOk }: WHmodal) => {
	return (
		<Modal
			bodyStyle={{
				padding: '3rem',
				display: 'grid',
				gridTemplateColumns: '1fr 1fr',
				gap: '1rem',
				height: '70vh',
				overflow: 'scroll'
			}}
			width="90vw"
			visible={true}
			onCancel={() => setIsModalVisible(false)}
			onOk={() => {
				onOk(true, EPanelPreferences.RTO_OTHER_WAREHOUSE, true);
			}}
		>
			{warehouse.length > 0 &&
				warehouse.map((warehouse, index) => {
					return <WarehouseCard key={index} warehouse={{ ...warehouse, index: index + 1 }} />;
				})}
		</Modal>
	);
};
