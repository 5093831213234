import { useContext } from 'react';
import { AuthContext } from '../../../shared/contexts/Auth';
import { Inventory } from '../../../app/AppSeller/Inventory';

export const inventoryRoute = '/inventory';

export function InventoryWMS() {
	const { authState } = useContext(AuthContext);

	return <Inventory isWMS={true} companyID={authState.selectedCompanyIds} />;
}
