export const COLOR_RANGE = ['#eafffe', '#d9fafd', '#dff7fe', '#cff5ff', '#cceef7', '#b9ebfb', '#83e1f6', '#60e5f1', '#0495e3', '#0546a1'];

export const DEFAULT_COLOR = '#EEE';

export const INDIA_TOPO_JSON = require('./india.topo.json');

export const geographyStyle = {
	default: {
		outline: 'none'
	},
	hover: {
		fill: '#ccc',
		transition: 'all 250ms',
		outline: 'none'
	},
	pressed: {
		outline: 'none'
	}
};
