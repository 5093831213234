import dayjs from 'dayjs';
import { TransactionCodeValueMap, TransactionTypeValueMap } from './constants';

export default function useColumns() {
	return [
		{
			title: 'Created At',
			dataIndex: 'createdAt',
			render: (createdAt: string) => <div className="text-center">{dayjs(createdAt).format('MMM D, YYYY h:mm A')}</div>,
			width: 150,
			sorter: true
		},
		{
			title: 'Type',
			dataIndex: 'type',
			sorter: true,
			render: (code: '0' | '1') => <div className="text-center">{TransactionTypeValueMap[code]}</div>
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			render: (amount: number) => <div className="text-center">{String(amount)}</div>,
			sorter: true
		},
		{
			title: 'Balance',
			dataIndex: 'balance',
			render: (balance: number) => <div className="text-center">{String(balance)}</div>
		},
		{
			title: 'Code',
			dataIndex: 'code',
			render: (code: '001' | '100' | '101' | '200') => (
				<div className="text-center">{`${TransactionCodeValueMap[code]} (${code})`}</div>
			)
		},
		{
			title: 'Description',
			dataIndex: 'description',
			render: (description: string) => <div className="text-center">{description}</div>
		}
	];
}
