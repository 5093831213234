import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import readXlsxFile from 'read-excel-file';
import { apiBms, apiCms, apiD2r, apiHms, apiOms, apiWms } from 'shared/api';
import errorHandler from 'shared/utils/errorHandler';
import { SHOP_TYPE } from '../../../constants';
import { Loading } from '../Loading';
import { BulkTable } from './components/BulkTable';
import { ErrorComponent } from './components/ErrorComponentPreview';
import { SuccessComponent } from './components/SuccessComponentPreview';
import { columnAlphabetToFieldNameMap, MAX_ROWS_ALLOWED, MAX_ROWS_EXCEED_ERROR_MSG } from './constants';
import { Prompt } from 'react-router-dom';

import styles from './index.module.scss';
import { sanitizeData, sanitizeExcelData, validateData } from './utils';
const { confirm } = Modal;

export interface ILocationProp {
	location: {
		state: {
			file: any;
			companyId: any;
			shopId: any;
		};
	};
}

export const BulkPreview = (props: ILocationProp) => {
	const history = useHistory();
	const goBackConfirmationMessage = 'Are you sure you want to leave? All your changes will be lost.';
	const [tableRows, setTableRows] = useState(null);
	const [tableColumns, setTableColumns] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isExcelPreviewLoading, setIsExcelPreviewLoading] = useState(false);
	const [showSuccessComponent, setShowSuccessComponent] = useState(false);
	const [showErrorComponent, setShowErrorComponent] = useState(false);
	const [errorsArray, setErrorsArray] = useState([]);
	const [successArray, setSuccessArray] = useState([]);
	const previewConfigName = history.location.pathname.split('/')[2];

	/**
	 * Read Excel File and set ColumnData and rwsData for table
	 */
	const readFile = async () => {
		try {
			if (!props.location.state) {
				return history.push('/');
			}

			const file = props.location.state.file;
			setIsExcelPreviewLoading(true);
			const excelData = await readXlsxFile(file);
			setIsExcelPreviewLoading(false);
			let { tableRows, tableColumns } = sanitizeExcelData(excelData, previewConfigName);
			if (tableRows.length > MAX_ROWS_ALLOWED) {
				throw new Error(MAX_ROWS_EXCEED_ERROR_MSG);
			}

			setTableRows(tableRows);
			setTableColumns(tableColumns as any);
		} catch (error) {
			errorHandler(error);
			history.push('/');
		} finally {
			setIsExcelPreviewLoading(false);
		}
	};

	useEffect(() => {
		readFile();

		const handleRefresh = (event: any) => {
			console.log('beforeunload attempted....');
			event.preventDefault();
			event.returnValue = goBackConfirmationMessage;
			return goBackConfirmationMessage;
		};

		window.addEventListener('beforeunload', handleRefresh);

		return () => {
			window.removeEventListener('beforeunload', handleRefresh);
		};
	}, []);

	/**
	 * On Submit of excel data
	 */
	const handleOnSubmitClick = async () => {
		try {
			setIsLoading(true);
			const { validatedData, doesErrorExists } = validateData(tableRows, previewConfigName);

			if (doesErrorExists) {
				setTableRows(validatedData);
				throw new Error('Please clear all errors');
			}

			const sanitizedData = sanitizeData(validatedData, previewConfigName);
			let responseData;
			switch (previewConfigName) {
				case 'catalogues': {
					const {
						data: { response: catalogueResponse }
					} = await apiCms.post(
						'/catalogue/bulk',
						{ catalogues: sanitizedData, shopType: SHOP_TYPE.MANUAL },
						{ timeout: 600000 }
					);
					responseData = catalogueResponse;
					break;
				}
				case 'orders': {
					const {
						data: { response: orderResponse }
					} = await apiOms.post('/order/bulk', { orders: sanitizedData }, { timeout: 600000 });
					responseData = orderResponse;
					break;
				}
				case 'inventory': {
					const {
						data: { response: inventoryResponse }
					} = await apiWms.post(
						'/inventory/bulk',
						{ inventory: sanitizedData, companyIds: props.location.state.companyId },
						{ timeout: 600000 }
					);
					responseData = inventoryResponse;
					break;
				}
				case 'wsin-upload': {
					const {
						data: { response: wsinResponse }
					} = await apiWms.post('/wsin/bulk/generate', {
						names: sanitizedData,
						companyId: props.location.state.companyId
					});
					responseData = wsinResponse;
					break;
				}
				case 'wsin-map': {
					const {
						data: { response: wsinMapResponse }
					} = await apiCms.post('/catalogue/bulk/map-wsin', {
						companyId: props.location.state.companyId,
						data: sanitizedData
					});
					responseData = wsinMapResponse;
					break;
				}
				case 'lightning-orders': {
					const {
						data: { response: lightningOrdersResponse }
					} = await apiHms.post('/lightning/orders/bulk', { orders: sanitizedData });
					responseData = lightningOrdersResponse;
					break;
				}
				case 'cod-remittance': {
					const {
						data: { response: codRemittanceResponse }
					} = await apiBms.post('/cod-remittance/status/bulk', { codOrders: sanitizedData });
					responseData = codRemittanceResponse;
					break;
				}
				case 'd2r-discount': {
					const {
						data: { response: discountResponse }
					} = await apiCms.post(`/catalogue/pricing/${props.location.state.companyId}/${props.location.state?.shopId}`, {
						excelData: sanitizedData
					});
					responseData = discountResponse;
					break;
				}
				case 'd2r-beats': {
					const {
						data: { response: beatsResponse }
					} = await apiOms.post(`d2r/bulk-upload-beats/${props.location.state.companyId}`, {
						excelData: sanitizedData
					});
					responseData = beatsResponse;
					break;
				}
				case 'shipping-charge-adjustment': {
					const { data } = await apiBms.post('/charges/adjust-shipping-charges', { shippingChargeAdjustments: sanitizedData });
					responseData = data.data;
					break;
				}
				case 'external-retailers': {
					const {
						data: { response: externalRetailerResponse }
					} = await apiD2r.post('/bulk/external-retailers', { excelData: sanitizedData });
					responseData = externalRetailerResponse;
					break;
				}
				default:
			}

			console.log(responseData);

			const delay = () => {
				return new Promise((res, rej) => {
					setTimeout(() => {
						res(1);
					}, 2000);
				});
			};
			await delay();
			const { errorArray, executeErrors, executeSuccess } = responseData;

			if (!errorArray.length) {
				if (!executeErrors.length) return setShowSuccessComponent(true);

				// executeErrors has values so display it
				setErrorsArray(executeErrors ?? []);
				setSuccessArray(executeSuccess ?? []);
				setShowErrorComponent(true);
			}

			if (errorArray.length) {
				for (const errorObject of errorArray) {
					const { row, col, reason, value } = errorObject;
					const fieldName = (columnAlphabetToFieldNameMap as any)[previewConfigName][col];

					validatedData[row][fieldName] = { value, errorMessage: reason };
				}
				setTableRows(validatedData);
			}

			throw new Error('Please fix errors to proceed.');
		} catch (error) {
			console.log('!!!!!Error in submit!!!!!', error);
			errorHandler(error);
		} finally {
			setIsLoading(false);
		}
	};

	const showCancelConfirmation = () => {
		confirm({
			title: `Are you sure you want to cancel bulk ${previewConfigName} upload?`,
			icon: <ExclamationCircleOutlined />,
			okText: 'Yes',
			cancelText: 'No',
			onOk() {
				history.goBack();
			}
		});
	};

	if (isExcelPreviewLoading) {
		return <Loading loading={true} text={'This may take a while'} />;
	}

	return (
		<div className={styles.bulkPreviewPage}>
			{showSuccessComponent ? (
				<SuccessComponent previewConfigName={previewConfigName} history={history} />
			) : showErrorComponent ? (
				<ErrorComponent
					previewConfigName={previewConfigName}
					history={history}
					errorsArray={errorsArray}
					successArray={successArray}
				/>
			) : (
				tableRows &&
				tableColumns && (
					<Fragment>
						<Prompt when={true} message={goBackConfirmationMessage} />
						<div className={styles.header}>
							<h5>{`Preview ${previewConfigName} before submitting`}</h5>
							<div className={styles.actionButtons}>
								<Button className={styles.cancelButton} onClick={showCancelConfirmation}>
									Cancel
								</Button>

								<Button type="primary" onClick={handleOnSubmitClick} loading={isLoading}>
									Submit
								</Button>
							</div>
						</div>

						<div className={styles.bulkTable}>
							<BulkTable
								previewConfigName={previewConfigName}
								columns={tableColumns}
								dataSource={tableRows}
								setTableRows={setTableRows}
							/>
						</div>
					</Fragment>
				)
			)}
		</div>
	);
};
