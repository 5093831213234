import dayjs from 'dayjs';
import { ITaskCenterFilter, IPageConfiguration } from './types';

export const searchOptions = [{ value: 'status', label: 'STATUS' }];

export const defaultFilter: ITaskCenterFilter = {
	start: dayjs().subtract(30, 'day'),
	end: dayjs(),
	searchOptionType: searchOptions[0].value
};

export const defaultPageConfig: IPageConfiguration = {
	current: 1,
	pageSize: 10,
	sortField: 'createdAt',
	sortOrder: 'DESC'
};

export enum taskStatus {
	IN_PROGRESS = 'in-progress',
	COMPLETED = 'completed',
	FAILED = 'failed'
}

export const tagColor = {
	[taskStatus.IN_PROGRESS]: 'orange',
	[taskStatus.COMPLETED]: 'green',
	[taskStatus.FAILED]: 'red'
};
