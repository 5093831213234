import { get } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AppLayout } from 'shared/components/AppLayout';
import { Loading } from 'shared/components/Loading';
import {
	bulkD2rDiscountPreviewRoute,
	bulkCODRemittancePreviewRoute,
	bulkShippingAdjustmentPreviewRoute,
	bulkWSINMappingPreviewRoute,
	bulkWSINUploadPreviewRoute,
	bulkD2rBeatsPreviewRoute,
	bulkExternalRetailersPreviewRoute
} from 'shared/components/UploadBulkPreview/constants';
import { BulkPreview } from 'shared/components/UploadBulkPreview/Preview';
import { AuthContext } from 'shared/contexts/Auth';
import { Admin, adminRoute } from './Admin';
import { CreateSubAdmin, createSubAdminRoute } from './AdminIUser/CreateAdminUser';
import { Billing, billingRoute } from './Billing';
import { Brands, brandsRoute } from './Brands';
import { D2r, d2rRoute } from './D2R/D2r';
import { Lightning, lightningRoute } from './Lightning';
import { ndrRoute } from './Ndr';
import { topBar } from './utils';

export const AppRoutes = [
	'/',
	'/admin',
	'/app',
	lightningRoute,
	brandsRoute,
	adminRoute,
	createSubAdminRoute,
	ndrRoute,
	billingRoute,
	bulkWSINUploadPreviewRoute,
	bulkWSINMappingPreviewRoute,
	bulkCODRemittancePreviewRoute,
	bulkShippingAdjustmentPreviewRoute,
	d2rRoute,
	bulkD2rDiscountPreviewRoute,
	bulkD2rBeatsPreviewRoute,
	bulkExternalRetailersPreviewRoute
];

export function App() {
	const currentRoute = get(useLocation(), 'pathname');

	const [loading, setLoading] = useState(false);
	const { authActions, authState } = useContext(AuthContext);

	useEffect(() => {
		setLoading(true);
		authActions.obtainProfileData().finally(() => setLoading(false));
	}, []);

	return (
		<>
			<Loading loading={loading} />
			{authState.profileLoaded && (
				<AppLayout currentRoute={currentRoute} topBar={topBar}>
					<Switch>
						<Route exact path={['/app', '/', '/admin']} render={() => <Redirect to={adminRoute} />} />
						<Route exact path={adminRoute} component={Admin} />
						<Route exact path={createSubAdminRoute} component={CreateSubAdmin} />
						<Route exact path={lightningRoute} component={Lightning} />
						<Route exact path={brandsRoute} component={Brands} />
						<Route exact path={d2rRoute} component={D2r} />
						<Route exact path={billingRoute} component={Billing} />
						<Route
							exact
							path={[
								bulkWSINUploadPreviewRoute,
								bulkWSINMappingPreviewRoute,
								bulkCODRemittancePreviewRoute,
								bulkShippingAdjustmentPreviewRoute,
								bulkD2rDiscountPreviewRoute,
								bulkD2rBeatsPreviewRoute,
								bulkExternalRetailersPreviewRoute
							]}
							component={BulkPreview}
						/>
						<Redirect from="*" to="/" />
					</Switch>
				</AppLayout>
			)}
		</>
	);
}
