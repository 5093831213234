import dayjs from 'dayjs';
import { IWalletFilter, IPageConfiguration } from './types';

export const searchOptions = [
	// { value: 'type', label: 'TYPE' },
	{ value: 'description', label: 'Description' }
];

export const defaultFilter: IWalletFilter = {
	start: dayjs().subtract(30, 'day'),
	end: dayjs(),
	searchOptionType: searchOptions[0].value
};

export const defaultPageConfig: IPageConfiguration = {
	current: 1,
	pageSize: 10,
	sortField: 'createdAt',
	sortOrder: 'DESC'
};

export const transactionType = [
	{
		label: 'CREDIT',
		value: 'CREDIT'
	},
	{
		label: 'DEBIT',
		value: 'DEBIT'
	}
];

export const transactionCodeEnum = [
	{
		label: 'Miscellaneous Transaction',
		value: 'MISC_TRANSACTION'
	},
	{
		label: 'Recharge via direct payment to Wherehouse',
		value: 'RECHARGE_VIA_DIRECT_PAYMENT'
	},
	{
		label: 'Wallet recharge through COD amount adjustment',
		value: 'RECHARGE_VIA_COD_AMOUNT'
	},
	{
		label: 'Debit against monthly billing',
		value: 'DEBIT_MONTHLY_BILLING'
	}
];

export const TransactionTypeValueMap = {
	'1': 'CREDIT',
	'0': 'DEBIT'
};

export const TransactionCodeValueMap = {
	'001': 'Miscellaneous Transaction',
	'100': 'Manual recharge to Wherehouse',
	'101': 'Wallet recharge through COD amount adjustment',
	'102': 'Wallet recharge via Razorpay',
	'200': 'Debit against monthly billing',
	'201': 'WHEREHOUSE_LIGHTNING_SHIPPING_CHARGES',
	'202': 'PLATFORM_CHARGES',
	'203': 'SMS_CHARGES',
	'204': 'WHATSAPP_MESSAGES_CHARGES',
	'205': 'Storage Charges'
};
