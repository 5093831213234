export const addressFields = [
	{
		label: 'First Name',
		placeholder: 'John',
		name: ['address', 'firstName'],
		id: 'firstName',
		rules: [{ required: true, message: 'first name is required' }],
		disabled: false
	},
	{
		label: 'Last Name',
		placeholder: 'Doe',
		name: ['address', 'lastName'],
		id: 'lastName',
		rules: [{ required: false }],
		disabled: false
	},
	{
		label: 'Email',
		placeholder: 'noreply@wherehouse.io',
		name: ['address', 'email'],
		id: 'email',
		rules: [{ required: false }],
		disabled: false
	},
	{
		label: 'Address Line 1',
		placeholder: '',
		name: ['address', 'addressLine1'],
		id: 'address1',
		rules: [{ required: true, message: 'Required' }],
		disabled: false
	},
	{
		label: 'Address Line 2',
		placeholder: '',
		name: ['address', 'addressLine2'],
		id: 'address2',
		rules: [{ required: true, message: 'Required' }],
		disabled: false
	},
	{
		label: 'City',
		placeholder: 'Mumbai',
		name: ['address', 'city'],
		id: 'city',
		rules: [{ required: true, message: 'City is required field' }],
		disabled: true
	},
	{
		label: 'State',
		placeholder: 'Maharashtra',
		name: ['address', 'state'],
		id: 'state',
		rules: [{ required: true, message: 'State is required field' }],
		disabled: true
	},
	{
		label: 'Pincode',
		placeholder: '110004',
		name: ['address', 'pincode'],
		id: 'pincode',
		rules: [
			{ required: true, message: 'Pincode is required!' },
			{ len: 6, message: 'Invalid Pincode' }
		],
		disabled: true
	},
	{
		label: 'Country',
		placeholder: 'India',
		name: ['address', 'country'],
		id: 'country',
		rules: [{ required: true, message: 'Country is required field' }],
		disabled: true
	}
];
