import { Col, notification, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { defaultPageConfig } from '../../../constants';
import { apiCms, apiWms } from '../../api';
import { Sidebar } from '../AppLayout/Sidebar';
import { TopFilterComponent } from '../AppLayout/TopFilterComponent';
import { CustomTablePagination } from '../CustomTablePagination';
import { SideDrawer } from '../SideDrawer/SideDrawer';
import useInventory from '../../hooks/useInventory';
import errorHandler from '../../utils/errorHandler';
import { inventoryColumns } from '../../columns/Inventory/inventoryColumn';
import { envs } from '../../utils/env';
import { RequestEmailModal } from '../EmailRequestModal';
import { InventoryPopup } from '../InventoryInfoSlider/InventoryTrailAndDetails';
import { UploadBulkModal } from '../UploadBulkModal';
import { bulkInventoryPreviewRoute, MAX_ROWS_EXCEED_ERROR_MSG } from '../UploadBulkPreview/constants';
import { defaultFilter } from './constants';
import { ScanAndAddInventoryRoute } from './ScanAndAddInventoryModal';
import { sidebarUtil, topFilterUtil } from './utils';

interface InventoryProps {
	isWMS: boolean;
	companyID: any;
}

export function Inventory({ isWMS = false, companyID = '' }: Readonly<InventoryProps>) {
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [filter, setFilter] = useState(defaultFilter);
	const [warehouses, setWarehouses] = useState([]);
	const [reloadInventoryList, setReloadInventoryList] = useState(0);
	const [bulkInventoryUploadModal, setBulkInventoryUploadModal] = useState(false);
	const [openScannedStockInfo, setOpenScannedStockInfo] = useState<string | null>('');
	const [scannedStockWsin, setScannedStockWsin] = useState('');
	const [shouldShowRequestEmailModal, setShouldShowRequestEmailModal] = useState(false);

	const [inventorySnapshotDate, setInventorySnapshotDate] = useState(undefined);

	const sidebarConfig = useMemo(() => sidebarUtil(warehouses), [warehouses]);

	const history = useHistory();

	const historyLocation = useLocation();

	let queryValue = historyLocation['search'].split('=')[1];
	if (queryValue == 'undefined') {
		if (sidebarConfig.sideBarMenu[0]) {
			queryValue = sidebarConfig.sideBarMenu[0]['key'];
		}
	}
	const [sidebarMenuItem, setSidebarMenuItem] = useState(queryValue);

	const { inventory, inventoryCount, loading } = useInventory({
		pageConfig,
		filter,
		warehouseID: sidebarMenuItem,
		companyID,
		reloadInventoryList
	});

	const handleScanAddInventoryButton = () => history.push(ScanAndAddInventoryRoute);

	const columnsConfig = useMemo(
		() =>
			inventoryColumns({
				sortField: pageConfig.sortField,
				sortOrder: pageConfig.sortOrder,
				clickInventoryId: setOpenScannedStockInfo,
				setWsin: setScannedStockWsin
			}),
		[pageConfig.sortField, pageConfig.sortOrder]
	);

	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				sidebarMenuItem,
				filter,
				inventorySnapshotDate,
				setFilter,
				referenceCompanyId: companyID,
				setPageConfigDefault,
				setBulkInventoryUploadModal,
				setInventorySnapshotDate,
				isWMS,
				handleScanAddInventoryButton,
				setShouldShowRequestEmailModal
			}),
		[sidebarMenuItem, filter, inventorySnapshotDate, isWMS, companyID]
	);
	function setPageConfigDefault() {
		setPageConfig(defaultPageConfig);
	}

	function handleTableChange(pagination: any, filters: any, sorter: any) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'createdAt',
			sortOrder: !sorter.order || sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	const onDownloadOrders = async (mailData: any) => {
		try {
			// reference id is warehouse id in case of brand panel and companyId for wms panel
			const referenceId = isWMS ? companyID.join(',') : sidebarMenuItem;
			const snapshotDate = inventorySnapshotDate ? `/${inventorySnapshotDate}` : '';

			const { data } = await apiCms.post(`/inventory/bulk/csv/${referenceId}${snapshotDate}?appType=${envs.appType}`, {
				email: mailData.email
			});

			if (data.status) {
				notification.success({
					message: `Success`,
					description: data?.message,
					placement: 'topRight'
				});
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setShouldShowRequestEmailModal(false);
		}
	};

	useEffect(() => {
		async function fetchWarehouses() {
			try {
				let {
					data: { warehouses: fetchedWarehouses }
				} = await apiWms.get('/company/warehouses');
				fetchedWarehouses = fetchedWarehouses.filter(({ active }: any) => active);
				setWarehouses(fetchedWarehouses);
			} catch (error) {
				errorHandler(error);
			}
		}
		fetchWarehouses();
	}, []);

	useEffect(() => {
		setSidebarMenuItem(queryValue);
	}, [queryValue]);

	useEffect(() => {
		history.push({
			pathname: '/inventory',
			search: `?tab=${sidebarMenuItem}`
		});
	}, [sidebarMenuItem, history]);

	return (
		<>
			<RequestEmailModal
				title={`Download Inventory Details`}
				visible={shouldShowRequestEmailModal}
				onCancel={() => {
					setShouldShowRequestEmailModal(false);
				}}
				footer={[]}
				width={500}
				onFinish={(values: any) => onDownloadOrders(values)}
				instructionMessage="Requested data contains too many orders, please enter your email address to receive url."
			/>
			{isWMS ? (
				<UploadBulkModal
					instructionMessage={
						<ul className="instructions">
							<li>Download the excel template by clicking "Download Sample" button below</li>
							<li>Edit file with inventory data and upload </li>
							<br />
							Note: {MAX_ROWS_EXCEED_ERROR_MSG}
						</ul>
					}
					templatePath={process.env.REACT_APP_EXCEL_INVENTORY_TEMPLATE}
					previewPath={bulkInventoryPreviewRoute}
					modalTitle="Bulk Inventory Inbound"
					isModalVisible={bulkInventoryUploadModal}
					setIsModalVisible={setBulkInventoryUploadModal}
					companyId={companyID}
				/>
			) : null}
			<Row className="h-100 p-0">
				{!isWMS && (
					<SideDrawer placement="left">
						<Sidebar
							sideBarMenu={sidebarConfig.sideBarMenu}
							bottomButtons={null}
							selectedMenu={sidebarMenuItem}
							onMenuItemSelect={setSidebarMenuItem}
						/>
					</SideDrawer>
				)}
				<Col sm={24} lg={isWMS ? 24 : 20} className="main">
					<TopFilterComponent {...topFilterConfig} />
					<div className="innerDiv">
						<div className="div w-100 h-100">
							<CustomTablePagination
								columns={columnsConfig}
								data={inventory}
								showSorterTooltip={true}
								{...pageConfig}
								onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
								total={inventoryCount}
								onChange={handleTableChange}
								loading={loading}
								emptyTableMessage="Inventory not found!"
								bordered
							/>
						</div>
						{openScannedStockInfo && (
							<InventoryPopup
								isWMS={isWMS}
								setReloadInventoryList={setReloadInventoryList}
								onOk={() => setOpenScannedStockInfo(null)}
								onClose={() => setOpenScannedStockInfo(null)}
								inventoryId={openScannedStockInfo}
								wsin={scannedStockWsin}
							/>
						)}
					</div>
				</Col>
			</Row>
		</>
	);
}
