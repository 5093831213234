import { Button, Input, notification } from 'antd';
import { useState } from 'react';
import { apiOms } from '../../../../api';
import errorHandler from '../../../../utils/errorHandler';

export function OrderWeightChange({ data }) {
	const [updateWeight, setUpdateWeight] = useState('');
	const [buttonLoading, setButtonLoading] = useState(false);
	const handleUpdatedWeight = async () => {
		try {
			setButtonLoading(true);
			const response = await apiOms.post('/update-weight', {
				weight: updateWeight,
				orderId: data?.id,
				companyId: data?.companyId
			});
			if (response.status) {
				notification.success({
					message: 'Successfully changed weight',
					description: response.message,
					placement: 'topRight'
				});
			} else {
				throw new Error(response.message || 'Failed to change weight');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setButtonLoading(false);
		}
	};
	return (
		<>
			<Input.Group style={{ display: 'flex', marginBottom: '2rem', flexDirection: 'column', gap: '1.5rem' }}>
				<Input
					onChange={(e) => setUpdateWeight(e.target.value)}
					defaultValue={data.weight}
					style={{ width: '100%' }}
					placeholder="Change order Weight"
				/>
				<Button
					style={{ width: '100%' }}
					type="primary"
					shape="round"
					loading={buttonLoading}
					disabled={!updateWeight || updateWeight === data.weight}
					onClick={handleUpdatedWeight}
				>
					Update Weight
				</Button>
			</Input.Group>
		</>
	);
}
