import { ORDER_STATUS } from '../../../constants';

export const DEFAULT_WL_MAX_WEIGHT = '3000'; // 3kg is default WL weight

export enum EPanelPreferences {
	// brand panel preferences
	HIDE_ITEM_NAME = 'hideItemLabel',
	FULFILLMENT_ON_TRANSIT = 'fulfillOnTransit',
	MAX_WEIGHT_WL = 'maxWeightWL',
	FULFILL_WL_ONLY = 'fulfillWLOnly',
	PRINT_WSIN_LABEL = 'printWSINLabel',
	FLAG_DISCOUNTED_COD_ORDERS = 'flagDiscountedCOD',
	PHONE_ON_RETURN_ADDRESS = 'phoneOnReturnAddress',
	PREFER_MICRO_OVER_PSEUDO = 'preferMicroOverPseudo',
	ACCESS_TOKEN = 'accessToken',
	LIGHTNING_ACCESS = 'lightningAccess',
	INTEGRATION_PASSWORD = 'integrationPassword',
	TRACKING_LAYOUT = 'trackingLayout',
	WHATSAPP_MODULES = 'waModules',
	SHOW_LOGO_AND_NAME = 'showBrandLogoAndName',
	HIDE_PHONE_NUMBER = 'hidePhoneNumber',
	RTO_OTHER_WAREHOUSE = 'rtoToOtherWarehouse',
	HSN_IN_INVOICE_PDF = 'hsnInInvoicePdf',
	CANCEL_SHOPIFY_ORDERS = 'cancelShopifyOrders',
	MARK_COD_PAID_SHOPIFY = 'markCodPaidOnShopify',
	A4_SIZE_INVOICE = 'a4SizeInvoice',
	INVOICE_SAME_AS_ORDER_ID = 'invoiceSameAsOrderId',
	SEND_MAIL_ON_RTS = 'sendMailOnRTS',
	STOP_EMAIL_SERVICE = 'stopEmailService',
	GOSWIFT_COURIER = 'goswiftCourier',
	PRINT_ITEM_LABEL = 'printItemLabel',
	RTO_TO_OTHER_WAREHOUSE = 'rtoToOtherWarehouse',
	SHIPERFECTO_COURIER = 'shiperfectoCourier',
	RTO_INVENTORY_UPDATE_SHOPIFY = 'rtoInventoryUpdateShopify',

	// admin panel preference
	IS_D2R_ACCOUNT = 'isD2RAccount',
	SEND_SMS_ON_STATUS_UPDATES = 'sendSmsOnStatusUpdates',
	SHOULD_SHOW_SPECIAL_DISCOUNT = 'shouldShowSpecialDiscount'
}

export interface IPreferenceRowFields {
	label: EPanelPreferences;
	key: EPanelPreferences;
	description: string;
}

export const preferenceRowFields: IPreferenceRowFields[] = [
	{
		label: EPanelPreferences.HIDE_ITEM_NAME,
		key: EPanelPreferences.HIDE_ITEM_NAME,
		description: 'Hide Item Name in Shipping Label'
	},
	{
		label: EPanelPreferences.FULFILLMENT_ON_TRANSIT,
		key: EPanelPreferences.FULFILLMENT_ON_TRANSIT,
		description: 'Fulfill Shopify orders on "In Transit"'
	},
	{
		label: EPanelPreferences.MARK_COD_PAID_SHOPIFY,
		key: EPanelPreferences.MARK_COD_PAID_SHOPIFY,
		description: 'Mark COD order as paid at shopify when delivered'
	},
	{
		label: EPanelPreferences.FULFILL_WL_ONLY,
		key: EPanelPreferences.FULFILL_WL_ONLY,
		description: 'Fulfill Lightning orders only'
	},
	{
		label: EPanelPreferences.PRINT_WSIN_LABEL,
		key: EPanelPreferences.PRINT_WSIN_LABEL,
		description: 'Show WSIN in shipping label'
	},
	{
		label: EPanelPreferences.FLAG_DISCOUNTED_COD_ORDERS,
		key: EPanelPreferences.FLAG_DISCOUNTED_COD_ORDERS,
		description: 'Flag COD order without coupon'
	},
	{
		label: EPanelPreferences.PHONE_ON_RETURN_ADDRESS,
		key: EPanelPreferences.PHONE_ON_RETURN_ADDRESS,
		description: 'Hide phone number from return address(Shipping Label)'
	},
	{
		label: EPanelPreferences.PREFER_MICRO_OVER_PSEUDO,
		key: EPanelPreferences.PREFER_MICRO_OVER_PSEUDO,
		description: 'Prefer Micro Warehouse over Pseudo Warehouse'
	},
	{
		label: EPanelPreferences.HIDE_PHONE_NUMBER,
		key: EPanelPreferences.HIDE_PHONE_NUMBER,
		description: 'Hide phone number from forward address(Shipping Label)'
	},
	{
		label: EPanelPreferences.RTO_OTHER_WAREHOUSE,
		key: EPanelPreferences.RTO_OTHER_WAREHOUSE,
		description: 'RTOs to other warehouses for Clickpost'
	},
	{
		label: EPanelPreferences.HSN_IN_INVOICE_PDF,
		key: EPanelPreferences.HSN_IN_INVOICE_PDF,
		description: 'Show HSN in Order invoice'
	},
	{
		label: EPanelPreferences.CANCEL_SHOPIFY_ORDERS,
		key: EPanelPreferences.CANCEL_SHOPIFY_ORDERS,
		description: 'Cancel orders on Shopify when cancelled on Wherehouse.io'
	},
	{
		label: EPanelPreferences.A4_SIZE_INVOICE,
		key: EPanelPreferences.A4_SIZE_INVOICE,
		description: 'Generate invoice in A4 size'
	},
	{
		label: EPanelPreferences.INVOICE_SAME_AS_ORDER_ID,
		key: EPanelPreferences.INVOICE_SAME_AS_ORDER_ID,
		description: 'Invoice Id should be same as order id'
	},
	{
		label: EPanelPreferences.STOP_EMAIL_SERVICE,
		key: EPanelPreferences.STOP_EMAIL_SERVICE,
		description: 'Avoid sending email to customers'
	},
	{
		label: EPanelPreferences.SEND_MAIL_ON_RTS,
		key: EPanelPreferences.SEND_MAIL_ON_RTS,
		description: 'Notify customer on RTS via Shopify'
	},
	{
		label: EPanelPreferences.MAX_WEIGHT_WL,
		key: EPanelPreferences.MAX_WEIGHT_WL,
		description: 'Maximum Weight Limit in WL (In gms)'
	},
	{
		label: EPanelPreferences.GOSWIFT_COURIER,
		key: EPanelPreferences.GOSWIFT_COURIER,
		description: 'Set Goswift Courior Partner Priority'
	},
	{
		label: EPanelPreferences.SHIPERFECTO_COURIER,
		key: EPanelPreferences.SHIPERFECTO_COURIER,
		description: 'Set Shiperfecto Courior Partner Priority'
	},
	{
		label: EPanelPreferences.RTO_INVENTORY_UPDATE_SHOPIFY,
		key: EPanelPreferences.RTO_INVENTORY_UPDATE_SHOPIFY,
		description: 'Update inventory at shopify location for RTO orders'
	}
];

export const adminPreferenceRowFields = [
	{
		label: EPanelPreferences.SEND_SMS_ON_STATUS_UPDATES,
		key: EPanelPreferences.SEND_SMS_ON_STATUS_UPDATES,
		description: 'Send SMS on order updates'
	},
	{
		label: EPanelPreferences.SHOULD_SHOW_SPECIAL_DISCOUNT,
		key: EPanelPreferences.SHOULD_SHOW_SPECIAL_DISCOUNT,
		description: 'Option for Brand to offer special discounts through the Manual Order Form.'
	},
	{
		label: EPanelPreferences.IS_D2R_ACCOUNT,
		key: EPanelPreferences.IS_D2R_ACCOUNT,
		description: 'Is D2R Account or Not'
	}
];

export const statusUpdateOptions = [
	{ label: 'Placed', value: ORDER_STATUS.PLACED },
	{ label: 'In Transit', value: ORDER_STATUS.IN_TRANSIT },
	{ label: 'Out For Delivery', value: ORDER_STATUS.OUT_FOR_DELIVERY },
	{ label: 'Delivered', value: ORDER_STATUS.DELIVERED }
];

export enum EWaModule {
	NDR = 'ndr',
	WA_NOTIFICATON = 'waNotif'
}

export const defaultWaModule = {
	[EWaModule.NDR]: { isActive: false, triggerStatus: ['UNDELIVERED'] },
	[EWaModule.WA_NOTIFICATON]: {
		isActive: false,
		triggerStatus: [ORDER_STATUS.PLACED, ORDER_STATUS.IN_TRANSIT, ORDER_STATUS.OUT_FOR_DELIVERY, ORDER_STATUS.DELIVERED]
	}
};

export const goSwiftsCouriorPartners = [
	{
		value: null,
		label: 'Default'
	},
	{
		value: 'DELHIVERY',
		label: 'DELHIVERY'
	},
	{
		value: 'BLUEDART',
		label: 'BLUEDART'
	},
	{
		value: 'EKART',
		label: 'EKART'
	},
	{
		value: 'SMARTR',
		label: 'SMARTR'
	},
	{
		value: 'AMAZON',
		label: 'AMAZON'
	}
];

export const shiperfectoCouriorPartners = [
	{
		value: 'BLUEDART',
		label: 'BLUEDART'
	},
	{
		value: 'DELHIVERY',
		label: 'DELHIVERY'
	}
];
