import { Button, Form, Select, Input, notification } from 'antd';
import { useState } from 'react';
import { adjustInventory } from '../../../api/inventory';
import errorHandler from '../../../utils/errorHandler';
import { Loading } from '../../Loading';
import { IInventoryAdjustmestFormInputFields, STOCK_TYPES } from '../components/utils';
import { InventoryAdjustmentOptions } from '../constants';
import { IInventoryAdjustCustomFormFields } from './ScannedStockForm';
import styles from '../index.module.scss';

const IActionsNames = {
	REMOVE: 'Remove'
};

interface IInventoryAdjustmentForm {
	inventoryId?: string;
	reloadInventory: () => void;
	formData: typeof IInventoryAdjustCustomFormFields;
	setFormData: React.Dispatch<React.SetStateAction<typeof IInventoryAdjustCustomFormFields>>;
	setReloadInventoryList: React.Dispatch<React.SetStateAction<number>>;
}

export const InventoryAdjustmentForm = (props: IInventoryAdjustmentForm) => {
	const { inventoryId, reloadInventory, formData, setFormData, setReloadInventoryList } = props;

	const [inventoryAdjustmentType, setInventoryAdjustmentType] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const handleClear = () => setInventoryAdjustmentType('');
	const handleInventoryAdjustmentTypeChange = (e: string) => setInventoryAdjustmentType(e);

	const handleFormValues = (value: string, name: string) => {
		setFormData({
			...formData,
			[name]: value
		});
	};

	const handleAdjustInventory = async (actionName?: string | undefined) => {
		try {
			// plz note: only super admins are allowed to adjust inventory
			setLoading(true);
			if (actionName === IActionsNames.REMOVE) {
				formData.quantity = String(Number(-Number(formData.quantity)));
			}
			const { data } = await adjustInventory({
				date: formData.date,
				quantity: formData.quantity,
				remark: formData.remark,
				inventoryId: inventoryId,
				inventoryAdjustmentType: inventoryAdjustmentType
			});
			if (data.status) {
				notification.success({
					message: 'Inventory adjusted',
					description: data.message,
					placement: 'topRight'
				});
			} else {
				throw new Error(data.message || 'Could not adjust inventory');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			reloadInventory();
			setFormData(IInventoryAdjustCustomFormFields);
			setReloadInventoryList((e) => e + 1);
			setLoading(false);
		}
	};

	if (loading) {
		return <Loading loading={loading} />;
	}

	return (
		<Form labelCol={{ span: 4 }} wrapperCol={{ span: 15 }} layout="horizontal" style={{ marginTop: '2rem' }}>
			<Form.Item label="Actions">
				<Select
					placeholder="Select Adjustment type"
					allowClear
					onClear={handleClear}
					onChange={handleInventoryAdjustmentTypeChange}
					defaultValue={inventoryAdjustmentType || null}
				>
					{InventoryAdjustmentOptions?.map(({ label, value }) => (
						<Select.Option key={value} value={value}>
							{label}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			{IInventoryAdjustmestFormInputFields.map((e) => (
				<Form.Item name={e.name} rules={e.rules} key={e.label} label={e.label}>
					<Input
						type={e.type}
						onChange={(event: any) => handleFormValues(event.target.value, e.name)}
						disabled={Boolean(!inventoryAdjustmentType)}
						placeholder={e.placeholder}
					/>
				</Form.Item>
			))}
			<div className={styles.formButton}>
				{inventoryAdjustmentType === STOCK_TYPES.CURRENT_STOCK && (
					<>
						<Form.Item>
							<Button onClick={() => handleAdjustInventory()} type="primary">
								Add
							</Button>
						</Form.Item>
						<Form.Item>
							<Button onClick={() => handleAdjustInventory(IActionsNames.REMOVE)} type="dashed">
								Remove
							</Button>
						</Form.Item>
					</>
				)}
				{inventoryAdjustmentType === STOCK_TYPES.SAFE_STOCK && (
					<Form.Item>
						<Button onClick={() => handleAdjustInventory()} type="primary">
							Submit
						</Button>
					</Form.Item>
				)}
			</div>
		</Form>
	);
};
