export const sidebarUtil = {
	sideBar: [
		{
			key: '1',
			label: 'COD Remittance',
			icon: 'allSvgIcon'
		},
		{
			key: '2',
			label: 'Wallet',
			icon: 'allSvgIcon'
		},
		{
			key: '3',
			label: 'Pricing',
			icon: 'allSvgIcon'
		},
		{
			key: '4',
			label: 'Actions',
			icon: 'allSvgIcon'
		},
		{
			key: '5',
			label: 'NDR',
			icon: 'allSvgIcon'
		},
		{
			key: '6',
			label: 'Payment',
			icon: 'allSvgIcon'
		}
	]
};

export const BillingPageOptions = ['CODRemittance', 'Wallet', 'Pricing', 'Actions', 'NDR', 'Payments'];
