import { ArrowLeftOutlined } from '@ant-design/icons';
import { uuid4 } from '@sentry/utils';
import { Button, Col, Switch, Row, Form, notification } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { inventoryRoute } from '../../../app/AppSeller/Inventory';
import { apiWms } from '../../api';
import { AuthContext } from '../../contexts/Auth';
import errorHandler from '../../utils/errorHandler';
import { CustomTablePagination } from '../CustomTablePagination';
import styles from './index.module.scss';
import { scanAndAddInventorycolumn } from './ScanAndAddInventoryColumn';
export interface IScannedStock {
	wsin: string;
	id: string;
	quantity: number;
	actionType: string;
	reason?: string;
}

export const InventoryActionType = {
	INBOUND: 'Inbound',
	OUTBOUND: 'Outbound'
};

export const ScanAndAddInventoryRoute = '/inventory/scan';

export const ScanAndAddInventory = () => {
	const [inputText, setInputText] = useState<string>('');
	const { authState } = useContext(AuthContext);
	const [scannedWsin, setScannedWsin] = useState<IScannedStock[]>(() => {
		const storedValues = localStorage.getItem(`scanned-stock-${authState?.profile?.id}`);
		return storedValues ? JSON.parse(storedValues) : [];
	});
	const [stockActionType, setStockActionType] = useState<string>('');
	const inputRef = useRef<HTMLInputElement>(null);
	const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
	const history = useHistory();

	const handleEnter = (event: any) => {
		if (event.key !== 'Enter') {
			return;
		}

		const isWsinAlreadyExist = scannedWsin.find((e: IScannedStock) => e.wsin === inputText && e.actionType === stockActionType);
		if (isWsinAlreadyExist) {
			setScannedWsin(
				scannedWsin.map((e: IScannedStock) => {
					if (e.wsin === inputText && e.actionType === stockActionType) {
						return {
							...e,
							quantity: e.quantity + 1
						};
					}
					return e;
				})
			);
			setInputText('');
			return;
		}
		setScannedWsin([
			...scannedWsin,
			{
				wsin: inputText,
				id: uuid4(),
				quantity: 1,
				actionType: stockActionType
			}
		]);
		setInputText('');
	};

	const handleRemoveScannedWsin = (id: string) => setScannedWsin(scannedWsin.filter((e) => e.id !== id));

	const handleScannedWsinQuantity = (id: string, shouldQtyInc: boolean) => {
		setScannedWsin(scannedWsin.map((e) => (e.id === id ? { ...e, quantity: shouldQtyInc ? ++e.quantity : --e.quantity } : e)));
	};

	const handleStockActiontype = (action: string) => {
		setStockActionType((e) => (Boolean(e) ? '' : action));
	};

	const redirectToInventoryPanel = () => {
		history.push(inventoryRoute);
	};

	const handleSaveScannedWsin = async () => {
		const filteredStock = scannedWsin.filter((e) => !e.reason);
		if (!filteredStock.length) return;
		try {
			setSaveButtonLoading(true);
			const { data } = await apiWms.post('/inventory/scanned-stock', {
				data: filteredStock
			});
			if (data?.status) {
				setScannedWsin(data?.response);
				notification.success({
					message: 'Inventory Uploaded'
				});
			}
		} catch (error: any) {
			errorHandler(error);
		} finally {
			setSaveButtonLoading(false);
		}
	};

	useEffect(() => {
		if (stockActionType) {
			inputRef.current?.focus();
		}
	}, [stockActionType]);

	useEffect(() => {
		localStorage.setItem(`scanned-stock-${authState?.profile?.id}`, JSON.stringify(scannedWsin)); // TODO add debounce to store data in localstorage
	}, [scannedWsin, authState?.profile?.id]);

	return (
		<>
			<Row className={`h-100 p-0 ${styles.scanInventory}`}>
				<Col span={4} className={styles.sidebar}>
					<Button className={styles.backButton} icon={<ArrowLeftOutlined />} onClick={redirectToInventoryPanel}>
						Go back
					</Button>
				</Col>

				<Col span={20} className={styles.outerContainer}>
					<Row className={`${styles.topHeading}`}>
						<div className={styles.topHeadingDiv}>
							<input
								placeholder={stockActionType ? 'Scan Now' : 'Toggle Switch to scan'}
								value={inputText}
								style={{ width: '17rem' }}
								className={`${styles.wsinInput} ${stockActionType && styles.inputBlink}`}
								onKeyUp={handleEnter}
								onChange={(e: any) => setInputText(e.target.value)}
								autoFocus
								disabled={!Boolean(stockActionType)}
								ref={inputRef}
							/>
							<Form.Item style={{ marginBottom: '0' }} label="Inbound">
								<Switch
									checkedChildren="ON"
									unCheckedChildren="OFF"
									disabled={Boolean(stockActionType && stockActionType === InventoryActionType.OUTBOUND)}
									checked={Boolean(stockActionType && stockActionType === InventoryActionType.INBOUND)}
									onChange={() => handleStockActiontype(InventoryActionType.INBOUND)}
								/>
							</Form.Item>
							<Form.Item style={{ marginBottom: '0' }} label="Outbound">
								<Switch
									checkedChildren="ON"
									unCheckedChildren="OFF"
									disabled={Boolean(stockActionType && stockActionType === InventoryActionType.INBOUND)}
									checked={Boolean(stockActionType && stockActionType === InventoryActionType.OUTBOUND)}
									onChange={() => handleStockActiontype(InventoryActionType.OUTBOUND)}
								/>
							</Form.Item>
						</div>
						<Button
							loading={saveButtonLoading}
							onClick={handleSaveScannedWsin}
							disabled={Boolean(scannedWsin.length === 0)}
							type="primary"
						>
							Upload
						</Button>
					</Row>
					<Row className={styles.tableRow}>
						<CustomTablePagination
							shouldRowSelection={false}
							selectedItem={undefined}
							catalog={undefined}
							selectedRowKeys={undefined}
							setSelectedRowKeys={undefined}
							setSelectedItem={undefined}
							emptyTableMessage="Toogle Inbound or Outbound Button to Scan Barcode."
							pageSize={Number(100)}
							columns={scanAndAddInventorycolumn(handleScannedWsinQuantity, handleRemoveScannedWsin)}
							data={scannedWsin}
						/>
					</Row>
				</Col>
			</Row>
		</>
	);
};
