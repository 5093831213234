import dayjs from 'dayjs';
import { IFilter } from './types';
import { IPageConfiguration } from '../../commonTypes';

export const searchOptions = [{ value: 'awb', label: 'AWB' }];

export const defaultFilter: IFilter = {
	start: dayjs().subtract(30, 'day'),
	end: dayjs(),
	searchOptionType: searchOptions[0].value
};

export const defaultPageConfig: IPageConfiguration = {
	current: 1,
	pageSize: 10,
	sortField: 'createdAt',
	sortOrder: 'DESC'
};

export const codRemittanceStatusColor = {
	processing: '#000000',
	due: '#eab308',
	paid: 'rgba(149, 191, 70, 1)'
};
