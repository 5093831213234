import { Card, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { getCustomerRequestByToken } from '../../shared/api/nms';
import headerLogoImg from '../../shared/images/header_logo.svg';
import errorHandler from '../../shared/utils/errorHandler';
import { FORM_LINK_STATUS, getFormattedShippingAddress } from './constants';
import { CustomerRequestDetails } from './CustomerRequestDetails';
import CustomerRequestForm from './CustomerRequestForm';
import styles from './index.module.scss';

export function CustomerRequest() {
	const [customerRequest, setCustomerRequest] = useState();
	const [order, setOrder] = useState();
	const [loading, setLoading] = useState(true);

	const { token } = useParams();

	const getOrderDetails = async () => {
		try {
			setLoading(true);
			const { data } = await getCustomerRequestByToken(token);

			ReactDOM.unstable_batchedUpdates(() => {
				if (data?.customerRequest?.requestType) {
				}
				setCustomerRequest(data.customerRequest);
				setOrder(data?.order);
			});
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getOrderDetails();
	}, []);

	const isFormAlreadySubmitted = customerRequest?.formLinkStatus === FORM_LINK_STATUS.FILLED;

	return (
		<div className={styles.container}>
			<nav className={styles.topNav}>
				<Row>
					<Col span={4}>
						<a className="navbar-brand" href="https://www.wherehouse.io/">
							<img src={headerLogoImg} alt="logo" />
						</a>
					</Col>
				</Row>
			</nav>

			{isFormAlreadySubmitted ? (
				<CustomerRequestDetails customerRequest={customerRequest} order={order} />
			) : (
				<>
					<Card title="Order Details" loading={loading} style={{ margin: '1rem' }}>
						<Row>
							<Col span={8}>Buyer's Name</Col>
							<Col span={16}>{order?.customerName}</Col>
							<Col span={8}>Phone Number</Col>
							<Col span={16}>{order?.customerPhone}</Col>
							<Col span={8}>Addresss</Col>
							<Col span={16}>{getFormattedShippingAddress(order?.shippingAddress)}</Col>
						</Row>
					</Card>
					<Card loading={loading} style={{ margin: '1rem' }}>
						{!isFormAlreadySubmitted && (
							<CustomerRequestForm
								token={token}
								getOrderDetails={getOrderDetails}
								customerRequest={customerRequest}
								order={order}
							/>
						)}
					</Card>
				</>
			)}
		</div>
	);
}
