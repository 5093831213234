import dayjs from 'dayjs';
import { ICommonFilter, IPageConfiguration } from '../../commonTypes';

export const searchOptions = [
	{ value: 'invoiceId', label: 'Invoice Id' },
	{ value: 'retailerName', label: 'Retailer Name' },
	{ value: 'salesmanName', label: 'Salesman Name' },
	{ value: 'salesmanPhone', label: 'Salesman Phone' },
	{ value: 'paymentMode', label: 'Payment Mode' }
];

export const defaultFilter: ICommonFilter = {
	start: dayjs().subtract(30, 'day'),
	end: dayjs(),
	searchOptionType: searchOptions[0].value
};

export const defaultPageConfig: IPageConfiguration = {
	current: 1,
	pageSize: 10,
	sortField: 'createdAt',
	sortOrder: 'DESC'
};
