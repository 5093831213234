import { Col, Row } from 'antd';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { downloadCataloguesCSV, getCompanyCatalog } from 'shared/api/catalog';
import { Sidebar } from 'shared/components/AppLayout/Sidebar';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { CatalogueInfoSlider } from 'shared/components/CatalogueInfoSlider';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { SideDrawer } from 'shared/components/SideDrawer/SideDrawer';
import { AuthContext } from 'shared/contexts/Auth';
import errorHandler from 'shared/utils/errorHandler';
import { defaultPageConfig } from '../../../constants';
import { catalogColumns } from '../../../shared/columns/Catalog/catalogColumns';
import { defaultFilter } from './constants';
import { topBarConfig, useSidebarMenu } from './utils';

export const catalogRoute = '/catalogs';

export function Catalog(props) {
	const { location } = props;
	const searchQuery = new URLSearchParams(location?.search);
	const queryString = searchQuery.get('q');
	const { authState } = useContext(AuthContext);
	const [search, setSearch] = useState('');
	const [catalogList, setCatalogList] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [searchCatalogList, setSearchCatalogList] = useState([]);
	const [selectedItem, setSelectedItem] = useState();
	const sideBarMenu = useSidebarMenu();
	const [sidebarMenuItem, setSidebarMenuItem] = useState(null);
	const [loading, setLoading] = useState(false);
	const [openCatalogueInfo, setOpenCatalogueInfo] = useState(false);
	const [reloadCatalogueList, setReloadCatalogueList] = useState(0);
	const columnsConfig = useMemo(() => catalogColumns({ clickCatalogueId: setOpenCatalogueInfo }), [setOpenCatalogueInfo]);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [filter, setFilter] = useState(defaultFilter);

	useEffect(() => {
		setLoading(true);
		if (sidebarMenuItem) {
			getCompanyCatalog({ ...pageConfig, shopType: sidebarMenuItem, search, filter }, authState.selectedCompanyIds)
				.then(({ data: { catalogue, totalCount } }) => {
					setTotalCount(totalCount);
					setCatalogList(
						[...catalogue].map((item) => ({
							...item,
							mappedDimensions: `${item?.dimensions?.length}x${item?.dimensions?.breadth}x${item?.dimensions?.height}`,
							key: item.catalogId
						}))
					);
				})
				.catch((err) => console.log(`err`, err))
				.finally(() => {
					setLoading(false);
				});
		}
	}, [pageConfig, sidebarMenuItem, search, reloadCatalogueList, filter, authState.selectedCompanyIds]);

	useEffect(() => {
		if (queryString) {
			setSearchCatalogList(
				[...catalogList].filter((item) => item.skuId.includes(`${queryString}`) || item.productTitle.includes(`${queryString}`))
			);
		} else {
			setSearchCatalogList([]);
		}
	}, [queryString]);

	useEffect(() => {
		setSearchCatalogList(catalogList.filter((item) => item.shopType === sidebarMenuItem));
		setPageConfig((pageConfig) => ({ ...pageConfig, ...defaultPageConfig }));
	}, [sidebarMenuItem]);

	useEffect(() => {
		setSidebarMenuItem(sideBarMenu[0]?.key);
	}, [sideBarMenu]);

	async function onDownloadCatalogues(shopType) {
		try {
			setLoading(true);
			await downloadCataloguesCSV(shopType, authState.selectedCompanyIds);
		} catch (err) {
			errorHandler(err);
		} finally {
			setLoading(false);
		}
	}

	return (
		<Row className="h-100 p-0">
			<SideDrawer placement="left">
				<Sidebar
					sideBarMenu={sideBarMenu}
					bottomButtons={null}
					selectedMenu={sidebarMenuItem}
					onMenuItemSelect={setSidebarMenuItem}
				/>
			</SideDrawer>

			<Col sm={24} lg={20} className="main">
				<TopFilterComponent {...topBarConfig(sidebarMenuItem, setSearch, onDownloadCatalogues, setPageConfig, filter, setFilter)} />
				<Fragment>
					<div className="innerDiv">
						<div className="div w-100 h-100">
							<CustomTablePagination
								columns={columnsConfig}
								onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
								data={searchCatalogList[0] ? searchCatalogList : catalogList}
								total={searchCatalogList[0] ? searchCatalogList.length : totalCount}
								isCatalog={true}
								selectedItem={selectedItem}
								setSelectedItem={setSelectedItem}
								loading={loading}
								{...pageConfig}
							/>
						</div>
						{openCatalogueInfo && (
							<CatalogueInfoSlider
								reloadList={() => setReloadCatalogueList((prevValue) => prevValue + 1)}
								catalogueId={openCatalogueInfo}
								onClose={() => setOpenCatalogueInfo(null)}
								shouldEdit={false}
							/>
						)}
					</div>
				</Fragment>
			</Col>
		</Row>
	);
}
