import { ErrorBoundary } from '@sentry/react';
import { Col, notification } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { defaultPageConfig } from '../../../constants';
import { getCustomerRequests, updateCustomerRequest } from '../../api/nms';
import { TopFilterComponent } from '../AppLayout/TopFilterComponent';
import { CustomTablePagination } from '../CustomTablePagination';
import { Fallback } from '../ErrorBoundryFallback';
import errorHandler from '../../utils/errorHandler';
import { getAdminCRcolumns, getSellerCRcolumns } from './customerRequestColumns';
import { topFilterUtil } from './utils';

const defaultFilter = {
	startDate: dayjs().subtract(1, 'month'),
	endDate: dayjs()
};

export const NdrCustomerRequests = ({ isAdmin }) => {
	const [customerRequests, setCustomerRequests] = useState([]);

	const [totalCount, setTotalCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [filter, setFilter] = useState(defaultFilter);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const fetchCustomerRequests = async () => {
		setLoading(true);
		try {
			const { data } = await getCustomerRequests(pageConfig, filter);
			if (!data.status) {
				throw new Error(data.message);
			}

			setTotalCount(data.totalCount);
			setCustomerRequests(data.customerRequests);
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCustomerRequests();
	}, [pageConfig, filter]);

	function handleTableChange(_pagination, _filter, sorter) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'id',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	const handleNdrApprovalStatusChange = async (updatedData) => {
		setLoading(true);
		try {
			if (updatedData?.customerRequestIds?.length === 0) {
				throw new Error('Select at least one ');
			}
			const { data } = await updateCustomerRequest(updatedData);

			if (!data.status) {
				throw new Error(data.message || data.responseMessage);
			}
			notification.success({
				message: 'Success',
				description: data.message,
				placement: 'topRight'
			});
			fetchCustomerRequests();
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	};

	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				filter,
				setFilter,
				setPageConfig,
				onDownloadOrders: () => {},
				setPageConfigDefault: setPageConfig,
				isAdmin,
				selectedRowKeys,
				handleNdrApprovalStatusChange
			}),
		[filter, selectedRowKeys]
	);

	const outerColSpanProp = isAdmin
		? { span: 24 }
		: {
				sm: 24,
				lg: 20
		  };

	return (
		<ErrorBoundary fallback={<Fallback fallBackMessage="Sorry, Failed to load data" />}>
			<Col {...outerColSpanProp} className="main">
				<TopFilterComponent {...topFilterConfig} />
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={isAdmin ? getAdminCRcolumns(handleNdrApprovalStatusChange) : getSellerCRcolumns()}
							data={customerRequests}
							showSorterTooltip={true}
							{...pageConfig}
							onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
							total={totalCount}
							onChange={handleTableChange}
							loading={loading}
							selectedRowKeys={selectedRowKeys}
							setSelectedRowKeys={setSelectedRowKeys}
						/>
					</div>
				</div>
			</Col>
		</ErrorBoundary>
	);
};
