import { Col, Button, notification } from 'antd';
import { assignCluster } from 'shared/api/lightning';
import { FormLayout } from 'shared/components/FormLayout';
import errorHandler from 'shared/utils/errorHandler';
import styles from '../index.module.scss';

export function AssignCluster({ clusters, drivers, setDrivers }) {
	async function assignNewCluster(values) {
		try {
			const { data } = await assignCluster(values);
			if (!data.status) throw new Error(data.message);
			if (data.status) {
				setDrivers((prevDrivers) =>
					prevDrivers.map((driver) => {
						if (driver.id === data.data.id) {
							return {
								key: data.data.id,
								value: data.data.id,
								label: `${data.data.name} - ${data.data.phone}`,
								...data.data
							};
						}
						return driver;
					})
				);
				notification.success({
					message: 'Successfully assigned cluster',
					description: data.message,
					placement: 'topRight'
				});
			}
		} catch (error) {
			errorHandler(error);
		}
	}

	function handleOnSave(values) {
		assignNewCluster(values);
	}

	return (
		<Col span={8}>
			<FormLayout
				className={styles.form}
				formTitle={'Assign Cluster to Driver'}
				isClose={false}
				onSave={handleOnSave}
				formFields={[
					{
						label: 'Driver',
						placeholder: 'Select a Driver',
						fieldType: 'select',
						name: 'driverId',
						id: 'driverId',
						required: true,
						showSearch: true,
						optionFilterProp: 'children',
						filterOption: (input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
						options: drivers
					},
					{
						label: 'Cluster',
						placeholder: 'Select a Cluster',
						fieldType: 'select',
						name: 'clusterId',
						id: 'clusterId',
						required: true,
						showSearch: true,
						optionFilterProp: 'children',
						filterOption: (input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
						options: clusters
					}
				]}
				customSubmitComponent={
					<Button type="primary" shape="round" size="large" htmlType="submit">
						{'Save'}
					</Button>
				}
			/>
		</Col>
	);
}
