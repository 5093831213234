import dayjs from 'dayjs';
import styles from './index.module.scss';
export const TrailModal = ({ data }) => {
	return (
		<div className={styles.modalDiv}>
			<div>
				<div>
					<h5>Date and Time</h5>
					<p>{dayjs(data?.createdAt).format('DD MMM, hh:mm A')}</p>
				</div>
				<div>
					<h5>City</h5>
					<p>{data?.city ? `City: ${data?.city}` : ''}</p>
				</div>
			</div>
			<div>
				<h5>Status</h5>
				<p>{data?.description}</p>
			</div>
		</div>
	);
};
