import {
	customFieldValidatorForCatalogue,
	customFieldValidatorForCODRemittance,
	customFieldValidatorForD2rBeats,
	customFieldValidatorForD2rDiscount,
	customFieldValidatorForInventory,
	customFieldValidatorForLightningOrder,
	customFieldValidatorForOrder,
	customFieldValidatorForShippingChargeAdjustment,
	customFieldValidatorForWSINMapping,
	customFieldValidatorForWSINUpLoad,
	customFieldValidatorForExternalRetailers
} from './validations';

/**
 * Routes constants
 */
export const bulkCataloguePreviewRoute = `/bulk/catalogues`;

export const bulkInventoryPreviewRoute = '/bulk/inventory';

export const bulkWSINUploadPreviewRoute = `/bulk/wsin-upload`;

export const bulkWSINMappingPreviewRoute = `/bulk/wsin-map`;

export const bulkOrderPreviewRoute = '/bulk/orders';

export const bulkLightningOrderPreviewRoute = '/bulk/lightning-orders';

export const bulkCODRemittancePreviewRoute = '/bulk/cod-remittance';

export const bulkShippingAdjustmentPreviewRoute = '/bulk/shipping-charge-adjustment';
export const bulkD2rDiscountPreviewRoute = '/bulk/d2r-discount';

export const bulkD2rBeatsPreviewRoute = '/bulk/d2r-beats';

export const bulkExternalRetailersPreviewRoute = '/bulk/external-retailers';
/**
 * For Catalogue bulk constants
 */
export const fieldNameToColumnAlphabetMapperForCatalogue = {
	itemName: 'A',
	productId: 'B',
	sku: 'C',
	hsnCode: 'D',
	category: 'E',
	status: 'F',
	price: 'G',
	length: 'H',
	breadth: 'I',
	height: 'J',
	weight: 'K',
	sgst: 'L',
	cgst: 'M',
	igst: 'N',
	utgst: 'O',
	basePrice: 'P',
	margin: 'Q',
	distributorMargin: 'R'
};

export const excelCatalogueFieldNames = [
	'ItemName',
	'ProductId',
	'sku',
	'HsnCode',
	'Category',
	'Status',
	'Price(inclusive of GST)',
	'Length(cm)',
	'Breadth(cm)',
	'Height(cm)',
	'Weight(gm)',
	'Sgst(%)',
	'Cgst(%)',
	'Igst(%)',
	'Utgst(%)',
	'BasePrice',
	'Margin',
	'DistributorMargin'
];

export const columnAlphabetToFieldNameMapperForCatalogue = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForCatalogue)) {
	columnAlphabetToFieldNameMapperForCatalogue[columnAlphabet] = fieldName;
}

export const NUMERIC_FIELDS_CATALOGUE = [
	'price',
	'length',
	'breadth',
	'height',
	'weight',
	'sgst',
	'cgst',
	'igst',
	'utgst',
	'basePrice',
	'margin',
	'distributorMargin'
];

export const NUMERIC_FIELDS_EXTERNAL_RETAILERS = [
	'listingPrice',
	'noOfOutlets',
	'avgOnboardingTime',
	'avgRepeatFrequency',
	'avgOrderValue',
	'avgOrderSize',
	'lastOrderSize',
	'lastOrderValue'
];

/**
 * For Orders Bulk Upload
 */
export const fieldNameToColumnAlphabetMapperForOrder = {
	id: 'A',
	length: 'B',
	breadth: 'C',
	height: 'D',
	weight: 'E',
	paymentMethod: 'F',
	itemName: 'G',
	itemQuantity: 'H',
	discount: 'I',
	// this fields is added later so key name is after w(shippingPrice), but would like to display after discount
	itemPrice: 'X',
	sku: 'J',
	name: 'K',
	phone: 'L',
	email: 'M',
	line1: 'N',
	line2: 'O',
	city: 'P',
	state: 'Q',
	pincode: 'R',
	awb: 'S',
	deliveryPartner: 'T',
	shippingLabelUrl: 'U',
	invoiceUrl: 'V',
	shippingPrice: 'W',
	ewayBill: 'Y',
	warehouseId: 'Z',
	backdate: 'AA',
	purchaseOrderNumber: 'AB',
	isb2bOrder: 'AC',
	isD2ROrder: 'AD',
	beatId: 'AE',
	dueDate: 'AF'
};

export const excelOrderFieldNames = [
	'id',
	'Length(cm)',
	'Width(cm)',
	'Height(cm)',
	'Weight(g)',
	'Payment Method',
	'Item Name',
	'Item Quantity',
	'discount',
	'Item Price',
	'sku',
	'Name',
	'Phone',
	'Email',
	'Line 1',
	'Line 2',
	'city',
	'state',
	'pincode',
	'awb',
	'delivery partner',
	'shipping label url',
	'invoice url',
	'Shipping Price',
	'Eway Bill',
	'Warehouse Id',
	'Backdate (dd/mm/yyyy)',
	'Purchase Order Number',
	'Is B2B Order (yes / no)',
	'Is D2R Order (yes / no)',
	'Beat Id (UUID)',
	'Due Date (YYYY-MM-DD)'
];

export const columnAlphabetToFieldNameMapperForOrder = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForOrder)) {
	columnAlphabetToFieldNameMapperForOrder[columnAlphabet] = fieldName;
}

export const NUMERIC_FIELDS_ORDERS = [
	'length',
	'breadth',
	'height',
	'weight',
	'itemQuantity',
	'discount',
	'phone',
	'pincode',
	'shippingPrice',
	'itemPrice'
];

export const NUMERIC_FIELDS_SHIPPING_ADJUSTMENT = ['shippingPrice'];

/**
 * FOR Inventory Upload
 */
export const fieldNameToColumnAlphabetMapperForInventory = {
	wsin: 'A',
	inboundAccepted: 'B',
	inboundRejected: 'C',
	remarks: 'D'
};

export const excelInventoryFieldNames = ['WSIN', 'Inbound Accepted', 'Inbound Rejected', 'Remarks'];

export const columnAlphabetToFieldNameMapperForInventory = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForInventory)) {
	columnAlphabetToFieldNameMapperForInventory[columnAlphabet] = fieldName;
}

export const NUMERIC_FIELDS_INVENTORY = ['inboundAccepted', 'inboundRejected'];

/**
 * FOR WSIN Upload constants
 */
export const fieldNameToColumnAlphabetMapperForWSINUpload = {
	productName: 'A',
	wsin: 'B'
};

export const excelWSINUploadFieldNames = ['ProductName', 'WSIN'];

export const columnAlphabetToFieldNameMapperForWSINUpload = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForWSINUpload)) {
	columnAlphabetToFieldNameMapperForWSINUpload[columnAlphabet] = fieldName;
}

export const NUMERIC_FIELDS_WSIN_UPLOAD = [];

/**
 * FOR WSIN Map constants
 */
export const fieldNameToColumnAlphabetMapperForWSINMapping = {
	catalogueId: 'A',
	productTitle: 'B',
	productType: 'C',
	wsinMap: 'D'
};

/**
 * FOR Shipping charge constants
 */
export const fieldNameToColumnAlphabetMapperForShippingChargeAdjustment = {
	awb: 'A',
	shippingPrice: 'B',
	isDto: 'C'
};

export const fieldNameToColumnAlphabetMapperForBeatsUpload = {
	Name: 'A',
	Description: 'B',
	salesmanId: 'C',
	retailerIds: 'D'
};
export const columnAlphabetToFieldNameMapperForD2rBeats = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForBeatsUpload)) {
	columnAlphabetToFieldNameMapperForD2rBeats[columnAlphabet] = fieldName;
}

export const fieldNameToColumnAlphabetMapperForExternalRetailerUpload = {
	name: 'A',
	address: 'B',
	pincode: 'C',
	cluster: 'D',
	phone: 'E',
	GST: 'F',
	ownerName: 'G',
	type: 'H',
	class: 'I',
	listingPrice: 'J',
	noOfOutlets: 'K',
	avgOnboardingTime: 'L',
	avgPaymentCycle: 'M',
	primaryProductCategory: 'N',
	secondaryProductCategory: 'O',
	tertiaryProductCategory: 'P',
	avgRepeatFrequency: 'Q',
	avgOrderValue: 'R',
	avgOrderSize: 'S',
	lastOrderDate: 'T',
	lastOrderSize: 'U',
	lastOrderValue: 'V',
	isActive: 'W',
	coveredBy: 'X'
};
export const columnAlphabetToFieldNameMapperForExternalRetailer = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForExternalRetailerUpload)) {
	columnAlphabetToFieldNameMapperForExternalRetailer[columnAlphabet] = fieldName;
}

export const excelWSINMappingFieldNames = ['Catalogue Id', 'Product Title', 'Product Type', 'Wsin Map'];

export const shippingChargeAdjustmentFieldNames = ['AWB', 'Shipping Price', 'is DTO'];

export const columnAlphabetToFieldNameMapperForWSINMapping = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForWSINMapping)) {
	columnAlphabetToFieldNameMapperForWSINMapping[columnAlphabet] = fieldName;
}

export const columnAlphabetToFieldNameMapperForShippingChargeAdjustment = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForShippingChargeAdjustment)) {
	columnAlphabetToFieldNameMapperForShippingChargeAdjustment[columnAlphabet] = fieldName;
}

export const NUMERIC_FIELDS_WSIN_MAPPING = [];

/**
 * For Lightning Orders Bulk Upload
 */
export const fieldNameToColumnAlphabetMapperForLightningOrder = {
	referenceId: 'A',
	quantity: 'B',
	weight: 'C',
	totalPrice: 'D',
	paymentMethod: 'E',
	shippingPersonName: 'F',
	shippingPersonPhone: 'G',
	shippingPersonEmail: 'H',
	shippingLine1: 'I',
	shippingLine2: 'J',
	shippingCity: 'K',
	shippingState: 'L',
	shippingPincode: 'M',
	awb: 'N'
};

export const excelLightningOrderFieldNames = [
	'Reference Id',
	'Quantity',
	'Weight (g)',
	'Price',
	'Payment Method',
	'Customer Name',
	'Customer Phone',
	'Customer Email',
	'Address Line 1',
	'Address Line 2',
	'City',
	'State',
	'Pincode',
	'AWB'
];

export const columnAlphabetToFieldNameMapperForLightningOrder = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForLightningOrder)) {
	columnAlphabetToFieldNameMapperForLightningOrder[columnAlphabet] = fieldName;
}

export const NUMERIC_FIELDS_LIGHTNING_ORDERS = ['quantity', 'weight', 'totalPrice'];

export const NUMERIC_FIELDS_D2R_DISCOUNT = [];

/**
 * For COD Remittance Bulk Upload
 */
export const fieldNameToColumnAlphabetMapperForCODRemittance = {
	awb: 'A',
	codAmount: 'B',
	remittanceStatus: 'C'
};

export const fieldNameToColumnAlphabetMapperForD2rDiscount = {
	catalogueId: 'A',
	discount: 'B'
};

export const excelCODRemittanceFieldNames = ['AWB', 'COD Amount', 'Remittance Status'];

export const excelD2rDiscountFieldNames = ['catalogueId', 'discount'];

export const excelD2rBeatsFieldNames = ['Name', 'Description', 'SalesmanId', 'RetailerIds'];

export const excelExternalRetailerFieldNames = [
	'name',
	'address',
	'pincode',
	'cluster',
	'phone',
	'GST',
	'ownerName',
	'type',
	'class',
	'listingPrice',
	'noOfOutlets',
	'avgOnboardingTime',
	'avgPaymentCycle',
	'primaryProductCategory',
	'secondaryProductCategory',
	'tertiaryProductCategory',
	'avgRepeatFrequency',
	'avgOrderValue',
	'avgOrderSize',
	'lastOrderDate',
	'lastOrderSize',
	'lastOrderValue',
	'isActive',
	'coveredBy'
];

export const columnAlphabetToFieldNameMapperForCODRemittance = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForCODRemittance)) {
	columnAlphabetToFieldNameMapperForCODRemittance[columnAlphabet] = fieldName;
}

export const columnAlphabetToFieldNameMapperForD2rDiscount = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForD2rDiscount)) {
	columnAlphabetToFieldNameMapperForD2rDiscount[columnAlphabet] = fieldName;
}

export const NUMERIC_FIELDS_COD_REMITTANCE = [];

/**
 * Validation Constants
 */
export const MAX_ROWS_ALLOWED = 100;
export const MAX_ROWS_EXCEED_ERROR_MSG = `Maximum ${MAX_ROWS_ALLOWED} rows are allowed at a time`;

/**
 * Mapping constants
 */
export const fieldNamesMap = {
	catalogues: excelCatalogueFieldNames,
	orders: excelOrderFieldNames,
	inventory: excelInventoryFieldNames,
	'wsin-upload': excelWSINUploadFieldNames,
	'wsin-map': excelWSINMappingFieldNames,
	'lightning-orders': excelLightningOrderFieldNames,
	'cod-remittance': excelCODRemittanceFieldNames,
	'shipping-charge-adjustment': shippingChargeAdjustmentFieldNames,
	'd2r-discount': excelD2rDiscountFieldNames,
	'd2r-beats': excelD2rBeatsFieldNames,
	'external-retailers': excelExternalRetailerFieldNames
};

export const fieldNameToColumnAlphabetMap = {
	catalogues: fieldNameToColumnAlphabetMapperForCatalogue,
	orders: fieldNameToColumnAlphabetMapperForOrder,
	inventory: fieldNameToColumnAlphabetMapperForInventory,
	'wsin-upload': fieldNameToColumnAlphabetMapperForWSINUpload,
	'wsin-map': fieldNameToColumnAlphabetMapperForWSINMapping,
	'lightning-orders': fieldNameToColumnAlphabetMapperForLightningOrder,
	'cod-remittance': fieldNameToColumnAlphabetMapperForCODRemittance,
	'shipping-charge-adjustment': fieldNameToColumnAlphabetMapperForShippingChargeAdjustment,
	'd2r-discount': fieldNameToColumnAlphabetMapperForD2rDiscount,
	'd2r-beats': fieldNameToColumnAlphabetMapperForBeatsUpload,
	'external-retailers': fieldNameToColumnAlphabetMapperForExternalRetailerUpload
};

export const numericFieldsMap = {
	catalogues: NUMERIC_FIELDS_CATALOGUE,
	orders: NUMERIC_FIELDS_ORDERS,
	inventory: NUMERIC_FIELDS_INVENTORY,
	'wsin-upload': NUMERIC_FIELDS_WSIN_UPLOAD,
	'wsin-map': NUMERIC_FIELDS_WSIN_MAPPING,
	'lightning-orders': NUMERIC_FIELDS_LIGHTNING_ORDERS,
	'cod-remittance': NUMERIC_FIELDS_COD_REMITTANCE,
	'shipping-charge-adjustment': NUMERIC_FIELDS_SHIPPING_ADJUSTMENT,
	'd2r-discount': NUMERIC_FIELDS_D2R_DISCOUNT,
	'd2r-beats': [],
	'external-retailers': NUMERIC_FIELDS_EXTERNAL_RETAILERS
};

export const validationMap = {
	catalogues: customFieldValidatorForCatalogue,
	orders: customFieldValidatorForOrder,
	inventory: customFieldValidatorForInventory,
	'wsin-upload': customFieldValidatorForWSINUpLoad,
	'wsin-map': customFieldValidatorForWSINMapping,
	'lightning-orders': customFieldValidatorForLightningOrder,
	'cod-remittance': customFieldValidatorForCODRemittance,
	'shipping-charge-adjustment': customFieldValidatorForShippingChargeAdjustment,
	'd2r-discount': customFieldValidatorForD2rDiscount,
	'd2r-beats': customFieldValidatorForD2rBeats,
	'external-retailers': customFieldValidatorForExternalRetailers
};

/**
 * First index from where actual data will start,above this index instructions will be written in excel
 */
export const validationFirstIndexMap = {
	catalogues: 'ItemName',
	orders: 'id',
	inventory: 'wsin',
	'wsin-upload': 'productname',
	'wsin-map': 'catalogue id',
	'lightning-orders': 'reference id',
	'cod-remittance': 'awb',
	'shipping-charge-adjustment': 'awb',
	'd2r-discount': 'catalogueid',
	'd2r-beats': 'name',
	'external-retailers': 'name'
};

export const columnAlphabetToFieldNameMap = {
	catalogues: columnAlphabetToFieldNameMapperForCatalogue,
	orders: columnAlphabetToFieldNameMapperForOrder,
	inventory: columnAlphabetToFieldNameMapperForInventory,
	'wsin-upload': columnAlphabetToFieldNameMapperForWSINUpload,
	'wsin-map': columnAlphabetToFieldNameMapperForWSINMapping,
	'lightning-orders': columnAlphabetToFieldNameMapperForLightningOrder,
	'cod-remittance': columnAlphabetToFieldNameMapperForCODRemittance,
	'shipping-charge-adjustment': columnAlphabetToFieldNameMapperForShippingChargeAdjustment,
	'd2r-discount': columnAlphabetToFieldNameMapperForD2rDiscount,
	'd2r-beats': columnAlphabetToFieldNameMapperForD2rBeats,
	'external-retailers': columnAlphabetToFieldNameMapperForExternalRetailer
};
