import { Table } from 'antd';
import { columns } from './columns';

export function Clusters({ clusters }) {
	return (
		<>
			<Table dataSource={clusters} columns={columns} />
		</>
	);
}
