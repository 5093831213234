import { Row } from 'antd';
import React from 'react';

const NoCompany = () => {
	return (
		<Row className="h-100 p-0" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
			<h2>Please select a company from search bar</h2>
		</Row>
	);
};

export default NoCompany;
