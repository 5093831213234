export const searchOptions = [
	{ value: 'name', label: 'User Name' },
	{ value: 'email', label: 'User Email' }
];

export const userRoleEnum = [
	{
		label: 'Ops',
		value: 'ops'
	},
	{
		label: 'Finance',
		value: 'finance'
	}
];

export const UserRoleType = {
	OPS: 'ops',
	FINANCE: 'finance'
};
