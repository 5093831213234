import { Button, Col, Form, notification, Select } from 'antd';
import { useState } from 'react';
import { apiOms } from 'shared/api';
import errorHandler from 'shared/utils/errorHandler';

export interface IReassignWarehouse {
	warehouses: any;
	order: any;
	reloadList: any;
	reloadOrder: any;
}
export function ReassignWarehouse({
	warehouses = [],
	order,
	reloadList = function () {
		return;
	},
	reloadOrder = function () {
		return;
	}
}: Readonly<IReassignWarehouse>) {
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [form] = Form.useForm();

	async function onClickReassignWarehouse() {
		try {
			setIsButtonLoading(true);
			const newWarehouseID = form.getFieldValue('newWarehouseID');
			const { data } = await apiOms.post('/reassign-warehouse', {
				newWarehouseID,
				orderID: order.id
			});
			if (data.status) {
				notification.success({
					message: 'Successfully reassigned warehouse',
					description: data.message,
					placement: 'topRight'
				});
				reloadOrder();
				reloadList();
			} else {
				throw new Error(data.message || 'Failed to reassign warehouse');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsButtonLoading(false);
		}
	}

	return (
		<Col span={24} style={{ marginBottom: '1rem' }}>
			<Form form={form}>
				<Form.Item name="newWarehouseID" rules={[{ required: true, message: 'Please select a warehouse' }]}>
					<Select
						defaultValue={order.warehouseId}
						value={order.warehouseId}
						onChange={(value) => setIsButtonDisabled(value === order.warehouseId)}
					>
						{warehouses.map((warehouse: any) => {
							return (
								<Select.Option value={warehouse.warehouseId} key={warehouse.id}>
									{warehouse.profile.name}
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>

				<Button
					style={{ width: '100%' }}
					type="primary"
					size="middle"
					shape="round"
					htmlType="button"
					onClick={onClickReassignWarehouse}
					disabled={isButtonDisabled}
					loading={isButtonLoading}
				>
					Reassign Warehouse
				</Button>
			</Form>
		</Col>
	);
}
