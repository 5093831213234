import { Col, notification } from 'antd';
import { createDriver } from 'shared/api/lightning';
import errorHandler from 'shared/utils/errorHandler';
import { createUserForm } from '../utils';

export function CreateDriver({ clusters, setDrivers }) {
	async function createNewDriver(values) {
		try {
			const { data } = await createDriver(values);
			if (!data.status) throw new Error(data.message);
			if (data.status) {
				setDrivers((drivers) =>
					drivers.concat({
						key: data.data.id,
						value: data.data.id,
						label: `${data.data.name} - ${data.data.phone}`,
						...data.data
					})
				);
				notification.success({
					message: 'Driver created',
					description: data.message,
					placement: 'topRight'
				});
			}
		} catch (error) {
			errorHandler(error);
		}
	}

	function handleOnSave(values) {
		createNewDriver(values);
	}

	return <Col span={8}>{createUserForm({ formTitle: 'Create Driver', onSave: handleOnSave, clusters: clusters })}</Col>;
}
