import { FileImageOutlined, UploadOutlined, CheckCircleFilled } from '@ant-design/icons';
import { Alert, Button, Divider, Form, Input, Modal, notification, Tooltip, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { apiOms } from 'shared/api';
import errorHandler from 'shared/utils/errorHandler';
import { ORDER_STATUS } from '../../../../constants';
import { convertToBase64 } from '../utils/convertToBase64';

const ModalForm = ({ orderData, button, returnInfoFlag, isModalVisible, setIsModalVisible }) => {
	const [files, setFiles] = useState({});
	const [isApprovalButtonLoading, setIsApprovalButtonLoading] = useState(false);
	const [returnInfoData, setReturnInfoData] = useState(null); // true when return is approved and modal just displays return info
	const [order, setOrder] = useState();
	const [itemUploadStatus, setItemUploadStatus] = useState([]);
	const [form] = Form.useForm();
	let formObj = {};
	useEffect(() => {
		if (orderData.status === ORDER_STATUS.DTO_DELIVERED) {
			getDTOOrderInfo();
		} else if (orderData.status === ORDER_STATUS.RTO_DELIVERED) {
			setOrder(orderData);
		}
	}, [orderData]);

	useEffect(() => {
		if (returnInfoFlag) {
			getReturnInfo();
		} else {
			setReturnInfoData(null);
		}
	}, [isModalVisible]);

	const getReturnInfo = async () => {
		const { data } = await apiOms.get(`/return-info?splitOrderId=${orderData.id}`);
		setReturnInfoData(data.data);
	};
	const getDTOOrderInfo = async () => {
		const { data } = await apiOms.get(`/dto_items_info?splitOrderId=${orderData.id}`);
		setOrder(data.data);
	};
	//to override the internal antd http calls
	const dummyReq = (options) => {
		const { onSuccess } = options;
		onSuccess();
	};

	const onFileChange = ({ fileList, productName }) => {
		try {
			const file = fileList[0]?.originFileObj;

			//becauase for one upload onFileChange is called many times, so to avoid it
			if (!file) return;

			const img = new Image();
			img.src = URL.createObjectURL(file);
			setFiles((files) => ({
				...files,
				[productName]: {}
			}));

			img.onload = () => {
				convertToBase64(file, productName, img, setFiles);
			};
		} catch (error) {
			errorHandler(error);
		}
	};

	// update the flag pointing to index of return item
	const changeupload = (itemIndex) => {
		const _itemUploadStatus = [...itemUploadStatus];
		_itemUploadStatus[itemIndex] = true;

		setItemUploadStatus(_itemUploadStatus);
	};

	const onFormSubmit = async (values) => {
		try {
			setIsApprovalButtonLoading(true);
			// formObj.returnInfo = processReturnOrder(order, form, arr);
			formObj.returnInfo = values;
			formObj.damagedItemsImage = files;
			formObj.orderData = orderData;
			formObj.returnType = orderData.status === ORDER_STATUS.DTO_DELIVERED ? 'DTO' : 'RTO';

			let data = await apiOms.post('/process-returns', formObj);
			let response = data.data;

			// if (true) {
			if (response.status) {
				notification.success({ message: response.message });
				setIsApprovalButtonLoading(false);
				window.location.reload();
			} else {
				notification.error({ message: response.message });
			}
		} catch (error) {
			setIsApprovalButtonLoading(false);
			errorHandler(error);
		} finally {
			setIsApprovalButtonLoading(false);
		}
	};

	return (
		<Modal
			title="Approval for RTO"
			visible={isModalVisible}
			onCancel={() => {
				setIsModalVisible(false);
			}}
			footer={[]}
			width={1500}
		>
			<Form form={form} size="small" layout="vertical" onFinish={onFormSubmit} in>
				{button === 'Approve RTO' || button === 'Approve DTO' ? (
					<Alert message="Add Damaged / Missing Products (If any)" type="warning" />
				) : (
					<Alert style={{ textAlign: 'center' }} message="RTO Order Info" type="success" />
				)}
				{returnInfoData ? (
					<table style={{ width: '100%', margin: '15px' }}>
						<tr>
							<th>Title</th>
							<th>SKU</th>
							<th>WSIN</th>
							<th>Missing</th>
							<th>Damaged</th>
							<th>Image</th>
						</tr>
						{returnInfoData?.map((item, index) => (
							<tr key={index} style={{ verticalAlign: 'inherit' }}>
								<td>
									<h6 style={{ fontSize: '15px', marginBlock: '0.5rem' }}>{item.sku}</h6>
								</td>
								<td>
									<h6 style={{ fontSize: '15px', marginBlock: '0.5rem' }}>{item.missingQuantity}</h6>
								</td>
								<td>
									<h6 style={{ fontSize: '15px', marginBlock: '0.5rem' }}>{item.damageQuantity}</h6>
								</td>
								<td>
									<Button
										style={{ border: 'none' }}
										disabled={item.imageLocation === 'NA' ? true : false}
										icon={<FileImageOutlined />}
										href={item.imageLocation}
										target="_blank"
									/>
								</td>
							</tr>
						))}
					</table>
				) : (
					<table style={{ width: '100%', margin: '15px', fontSize: '12px' }}>
						<tr>
							<th>Title</th>
							<th>SKU</th>
							<th>WSIN</th>
							<th>Missing</th>
							<th>Damaged</th>
						</tr>

						{order?.items.map((item, itemIndex) =>
							Object.entries(item?.wsinMapping || {}).map(([wsin, quantity]) => (
								<tr key={wsin} style={{ verticalAlign: 'inherit' }}>
									<td>
										<Tooltip placement="top" title={item.title}>
											<h6 style={{ fontSize: '1.5vmin', textOverflow: 'ellipsis' }}>{item.title}</h6>
										</Tooltip>
									</td>
									<td>
										<h6 style={{ fontSize: '1.5vmin' }}>{item.sku}</h6>
									</td>
									<td>
										<h6 style={{ fontSize: '1.5vmin' }}>{wsin}</h6>
									</td>
									<td>
										{/* <Form.Item name={`${item.sku}-missing`} style={{ width: '65%' }}> */}
										<Form.Item name={[item.sku, wsin, 'missing']} style={{ width: '85%' }}>
											<Input
												type="number"
												size="large"
												placeholder={`Out of ${item.quantity * quantity}`}
												suffix={` / ${item.quantity * quantity}`}
												max={item.quantity * quantity}
												min={0}
												value={0}
											/>
										</Form.Item>
									</td>
									<td>
										{/* <Form.Item name={`${item.sku}-damaged`} style={{ width: '65%' }}> */}
										<Form.Item name={[item.sku, wsin, 'damaged']} style={{ width: '85%' }}>
											<Input
												type="number"
												size="large"
												placeholder={`Out of ${item.quantity * quantity}`}
												suffix={` / ${item.quantity * quantity}`}
												max={item.quantity * quantity}
												min={0}
												value={0}
											/>
										</Form.Item>
									</td>
									<td>
										<Tooltip title="Please upload the picture of the damaged or missing package" placement="right">
											<Upload
												customRequest={dummyReq}
												accept="image/*"
												multiple={false}
												onChange={({ fileList }) => {
													onFileChange({ fileList, productName: `${wsin}` });
													changeupload(itemIndex);
												}}
												style={{ marginBlock: '15px' }}
												showUploadList={false}
											>
												<Button
													type="text"
													size="middle"
													icon={
														itemUploadStatus[itemIndex] ? (
															<CheckCircleFilled style={{ color: '#228B22' }} />
														) : (
															<UploadOutlined />
														)
													}
												></Button>
											</Upload>
										</Tooltip>
									</td>
									<Divider />
								</tr>
							))
						)}
					</table>
				)}
				{button === 'Approve RTO' || button === 'Approve DTO' ? (
					<Form.Item>
						<Button
							type="primary"
							size="large"
							block
							htmlType="submit"
							loading={isApprovalButtonLoading}
							style={{ position: 'sticky', top: '0px' }}
						>
							Approve
						</Button>
					</Form.Item>
				) : null}
			</Form>
		</Modal>
	);
};

export default ModalForm;
