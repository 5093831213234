import { apiOms } from './index';
import { IPageConfiguration, ITaskCenterFilter } from '../components/TaskCenter/types';
import errorHandler from '../../shared/utils/errorHandler';

export function getTaskCenterList({ pageConfiguration, filters }: { pageConfiguration: IPageConfiguration; filters: ITaskCenterFilter }) {
	try {
		const { searchOptionType, searchValue, companyID } = filters;
		const { current, pageSize, sortField, sortOrder } = pageConfiguration;
		return apiOms.post(`task-center/find`, {
			where: {
				searchOptionType: searchOptionType,
				searchValue: searchValue?.trim()
			},
			pagination: {
				records: pageSize,
				pageNumber: current
			},
			sortBy: {
				order: sortField,
				by: sortOrder
			},
			companyId: companyID
		});
	} catch (error) {
		errorHandler(error);
	}
}
