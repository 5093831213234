import { useEffect, useState } from 'react';
import { apiWms } from '../../../../shared/api';
import errorHandler from 'shared/utils/errorHandler';
import styles from './index.module.scss';
import { WarehouseCard } from './components/WarehouseCard';
import { Button } from 'antd';
import { RequestNewMicroWHModal } from './components/RequestNewMicroWHModal';
import { RequestOwnWHModal } from './components/RequestOwnWHModal';

export function Warehouses() {
	const [warehouses, setWarehouses] = useState([]);
	const [isRequestNewMicroWHModalVisible, setRequestNewMicroWHModalVisible] = useState(false);
	const [isRequestOwnModalVisible, setIsRequestOwnModalVisible] = useState(false);

	const fetchWarehouses = async () => {
		try {
			const {
				data: { warehouses }
			} = await apiWms.get('/company/warehouses');

			setWarehouses(warehouses);
		} catch (error) {
			errorHandler(error);
		}
	};

	useEffect(() => {
		fetchWarehouses();
	}, []);

	return (
		<div className={styles.warehousesPage}>
			<RequestNewMicroWHModal isModalVisible={isRequestNewMicroWHModalVisible} setIsModalVisible={setRequestNewMicroWHModalVisible} />

			<RequestOwnWHModal isModalVisible={isRequestOwnModalVisible} setIsModalVisible={setIsRequestOwnModalVisible} />

			<div className={styles.actionButtonsContainer}>
				<Button size="large" type="primary" onClick={() => setRequestNewMicroWHModalVisible(true)}>
					Request a new Micro-WH
				</Button>

				<Button size="large" onClick={() => setIsRequestOwnModalVisible(true)}>
					Add my own Micro-WH
				</Button>
			</div>

			<div className={styles.warehousesList}>
				{warehouses.map((warehouse, index) => {
					return <WarehouseCard key={index} warehouse={{ ...warehouse, index: index + 1 }} />;
				})}
			</div>
		</div>
	);
}
