export default (
	<svg
		viewBox="64 64 896 896"
		focusable="false"
		class=""
		data-icon="code-sandbox"
		width="18"
		height="18"
		fill="currentColor"
		aria-hidden="true"
	>
		<path d="M709.6 210l.4-.2h.2L512 96 313.9 209.8h-.2l.7.3L151.5 304v416L512 928l360.5-208V304l-162.9-94zM482.7 843.6L339.6 761V621.4L210 547.8V372.9l272.7 157.3v313.4zM238.2 321.5l134.7-77.8 138.9 79.7 139.1-79.9 135.2 78-273.9 158-274-158zM814 548.3l-128.8 73.1v139.1l-143.9 83V530.4L814 373.1v175.2z"></path>
	</svg>
);
