import styles from './index.module.scss';
import arrow from '../../../shared/svgs/trackingPageSvg/arrow.svg';
import { Button, Collapse, Modal, notification, Timeline, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import reactDom from 'react-dom';
import errorHandler from '../../../shared/utils/errorHandler';
import { baseApi } from '../../../shared/api';
import { uuid4 } from '@sentry/utils';
import dayjs from 'dayjs';
import { orderStatus } from '../../../shared/utils/orderStatus';
import { estimatedDeliveryDateHandler, getTrackingId } from '../utils';
import { FulfillmentProviderLogos, FulfillmentProviders, ORDER_STATUS, shipperProviderLogos } from '../../../constants';
import { groupBy } from 'lodash';
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';
import { TrailModal } from '../components/TrailModal';
import { commonTrackingLayout } from '../../AppSeller/SettingsPage/CustomizeTrackingPage/utils/index';
import { Header } from '../components/Header';
import { addIndexInTrail } from '../../../shared/components/OrderInfoSlider/utils';
const { Panel } = Collapse;
export const trackRoute = '/track/:awb';

export const TrackDetails = ({ setLoading, loading }) => {
	const { awb } = useParams();
	const history = useHistory();
	const [AWB, setAWB] = useState(awb);
	const [dateHandler, setDateHandler] = useState();
	const [trackingData, setTrackingData] = useState([]);
	const [trailData, setTrailData] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState('');
	const [brandsTrackingDesign, setBrandsDesign] = useState(
		() => JSON.parse(localStorage.getItem('tracking-layout')) || commonTrackingLayout
	);
	const [isOrderDelivered, setIsOrderDelivered] = useState(null);

	const pushAWB = () => {
		if (!AWB) {
			notification.error({
				message: 'Please Enter Tracking Number'
			});
			return;
		}
		try {
			if (!AWB || AWB.length < 3 || AWB.length > 20) {
				throw new Error('Invalid AWB');
			}
			history.push(`/track/${AWB}`);
		} catch (error) {
			errorHandler(error);
		}
	};

	const handleDateFormatter = (data) => {
		const formatedDate = estimatedDeliveryDateHandler(data, data?.deliveryPartnerId);
		setDateHandler(formatedDate);
	};

	const getTrackingData = async (trackingNumber) => {
		try {
			const {
				data: { data }
			} = await baseApi.get(`/order/${trackingNumber}`);
			if (data.timeline) {
				reactDom.unstable_batchedUpdates(() => {
					data.timeline = [...data.timeline.reverse()];
					const indexedTrail = addIndexInTrail(data?.timeline, 'status');
					const updatedTrail = Object.values(groupBy(indexedTrail, 'index'));
					setTrailData(updatedTrail);
					handleIsOrderDelivered(data.timeline);
				});
			}
			setTrackingData(data || []);

			if (data?.pageLayout) {
				localStorage.setItem(`tracking-layout`, JSON.stringify(data?.pageLayout));
				setBrandsDesign(data.pageLayout);
			}
			handleDateFormatter(data);
		} catch (error) {
			notification.error({
				message: 'Invalid Tracking Number',
				description: 'Kindly enter a valid Tracking Number',
				placement: 'topRight'
			});
			setTrackingData([]);
		} finally {
			setTimeout(() => {
				setLoading(false);
			}, 500);
		}
	};
	const handleEnter = (event) => {
		if (event.key === 'Enter') {
			pushAWB();
		}
	};

	const handleIsOrderDelivered = (trails) => {
		trails = trails.filter((e) => e.status === ORDER_STATUS.DELIVERED)?.[0];
		if (trails) {
			setIsOrderDelivered({
				date: dayjs(trails?.createdAt).date(),
				month: dayjs(trails?.createdAt).format('MMMM'),
				year: dayjs(trails?.createdAt).year()
			});
		}
	};

	useEffect(() => {
		setLoading(true);
		document.title = 'Track Package | Wherehouse';
		if (awb) {
			getTrackingData(AWB)
				.then((data) => {
					//Try Block
				})
				.catch((error) => {
					//Catch Block
				});
		} else setLoading(false);

		return () => {
			document.title = 'Wherehouse';
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [awb]);

	return (
		<div id={styles.rootParentOfTrackingPage} style={{ background: `${brandsTrackingDesign.bg || '#DEDDE6'}` }} className="scrollable">
			{!loading && <Header brandsTrackingDesign={brandsTrackingDesign} />}
			{!trackingData?.shipping && !loading && (
				<div className={styles.invalidTrack}>
					<div className={styles.inputField}>
						<input
							defaultValue={AWB}
							onChange={(e) => setAWB(e.target.value)}
							placeholder="Enter Your AWB Number here"
							type={'text'}
							onKeyPress={handleEnter}
						/>
						<button style={{ background: `${brandsTrackingDesign.bc}` }} onClick={pushAWB}>
							Track
						</button>
					</div>
					<div>
						<img
							alt="Invalid Tracking number logo"
							src="https://wherehouse-prod-content.s3.ap-south-1.amazonaws.com/assets/tracking_page/invalidawb.png"
						/>
						<b>Kindly Enter a valid AWB Number</b>
					</div>
				</div>
			)}
			{trackingData?.shipping && !loading && (
				<div className={styles.parentDivOfTrackingPage}>
					<section className={styles.gridSection}>
						<div className={styles.leftSection}>
							<div className={styles.leftTravelDetails}>
								<div>
									<h1 style={{ color: `${brandsTrackingDesign.sc}` }}>{trackingData?.pickup?.state}</h1>
									<img alt="arrow" src={arrow} />
									<h1 style={{ color: `${brandsTrackingDesign.sc}` }}>{trackingData?.shipping?.state}</h1>
								</div>
								<div>
									<div>
										<h1>{isOrderDelivered ? isOrderDelivered?.date : dateHandler?.day || 0}</h1>
										<h3>
											{isOrderDelivered ? isOrderDelivered?.month : dateHandler?.month || '-'}
											{isOrderDelivered ? isOrderDelivered?.year : dateHandler?.year || '0000'}
										</h3>
									</div>
									<div>{isOrderDelivered ? <h5>Delivered On</h5> : <h5>{dateHandler?.textToShow}</h5>}</div>
								</div>
								<div>
									<h5>Order Status</h5>
									<b style={{ color: `${brandsTrackingDesign.sc}` }}>{orderStatus[trackingData.status]}</b>
								</div>
							</div>
							<div className={styles.trailSection}>
								<Timeline className="custom-timeline">
									{trailData.map((t) => (
										<>
											<Timeline.Item key={uuid4()}>
												<b>{`${orderStatus[t?.[0]?.status].toUpperCase()}`}</b>
												<div style={{ fontSize: '12px' }}>
													<div>{dayjs(t?.[0]?.createdAt).format('DD MMM, hh:mm A')}</div>

													{t?.length === 1 && (
														<>
															<Typography.Text type="secondary">{t?.[0]?.description}</Typography.Text>
															<Typography.Paragraph type="secondary">
																{t?.[0]?.city ? `City: ${t?.[0]?.city}` : null}
															</Typography.Paragraph>
														</>
													)}
												</div>
												{t?.length > 1 && (
													<Button
														id={styles.mobileViewModal}
														onClick={() => setIsModalVisible(t?.[0].status)}
														style={{
															marginTop: '1rem'
														}}
													>
														<span style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
															<CaretDownOutlined />
															<span>More Updates Available</span>
														</span>
													</Button>
												)}
												{t?.[0]?.status === isModalVisible && (
													<Modal
														onCancel={() => setIsModalVisible(false)}
														style={{ marginTop: '-2rem' }}
														title={`${orderStatus[t?.[0]?.status]}`}
														className={styles.trailModal}
														bodyStyle={{ width: '100%', height: '80vh', overflow: 'scroll' }}
														visible={true}
														footer={[]}
													>
														{t?.length > 1 &&
															isModalVisible &&
															t.map((e) => {
																return (
																	<TrailModal
																		data={e}
																		isModalVisible={isModalVisible}
																		setIsModalVisible={setIsModalVisible}
																	/>
																);
															})}
													</Modal>
												)}

												<Typography.Paragraph id={styles.bigScreenCollapse}>
													<Collapse
														bordered={false}
														defaultActiveKey={['1']}
														expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
														style={{
															borderRadius: '8px',
															border: 'none',
															overflow: 'hidden',
															marginTop: '1rem',
															maxWidth: '500px'
														}}
													>
														{t?.length > 1 && (
															<Panel header={`Show more (${t.length})`}>
																<table
																	class="table table-striped table-hover"
																	style={{ fontSize: '0.7rem' }}
																>
																	<thead>
																		<tr>
																			<th style={{ width: '120px' }} scope="col">
																				Date and time
																			</th>
																			<th scope="col" style={{ minWidth: '3rem' }}>
																				City
																			</th>
																			<th scope="col">Status</th>
																		</tr>
																	</thead>
																	<tbody>
																		{t.map((e) => {
																			return (
																				<tr className={styles.tableData}>
																					<td>{dayjs(e?.createdAt).format('DD MMM, hh:mm A')}</td>
																					<td>{e?.city ? `City: ${e?.city || 'NA'}` : 'NA'}</td>
																					<td>{e?.description || 'NA'}</td>
																				</tr>
																			);
																		})}
																	</tbody>
																</table>
															</Panel>
														)}
													</Collapse>
												</Typography.Paragraph>
											</Timeline.Item>
										</>
									))}
								</Timeline>
							</div>
						</div>
						<div className={styles.rightSection}>
							<div className={styles.inputField} style={{ border: `1px solid ${brandsTrackingDesign.pc}` }}>
								<input
									defaultValue={getTrackingId(AWB)}
									onChange={(e) => setAWB(e.target.value)}
									placeholder="Enter Your AWB Number here"
									type={'text'}
									onKeyPress={handleEnter}
								/>
								<button style={{ background: `${brandsTrackingDesign.bc}` }} onClick={pushAWB}>
									Track
								</button>
							</div>
							<div className={styles.orderDetails}>
								<div className={styles.topImageSectioninTravelDetails}>
									<div className={styles.brandLogoImage}>
										<img
											alt="fullfillment provider logo"
											src={
												trackingData?.deliveryPartnerId === FulfillmentProviders.SHIPERFECTO &&
												trackingData.whCreds &&
												trackingData.shipper
													? shipperProviderLogos[String(trackingData?.shipper).toUpperCase()] ||
													  brandsTrackingDesign?.hl?.data
													: FulfillmentProviderLogos[trackingData?.deliveryPartnerId] ||
													  brandsTrackingDesign?.hl?.data
											}
										/>
									</div>
									<div className={styles.orderDetailsRightSection}>
										{trackingData?.shipper ? (
											<>
												<h1 id={styles.headingForResponsivness}>SHIPPER / COURIER</h1>
												<p id={styles.paraForResponsivness}>
													{(
														<>
															{trackingData?.deliveryPartnerId === 'goswift' && trackingData.whCreds
																? ''
																: trackingData?.deliveryPartnerId?.toUpperCase()}{' '}
															<span>({trackingData?.shipper})</span>
														</>
													) || '-'}
												</p>
											</>
										) : (
											<>
												<h1 id={styles.headingForResponsivness}>COURIER</h1>
												<p style={{ color: `${brandsTrackingDesign.sc}` }} id={styles.paraForResponsivness}>
													{trackingData?.deliveryPartnerId?.toUpperCase() || '-'}
												</p>
											</>
										)}
										<div></div>
										<h1 id={styles.headingForResponsivness}>ORDER ID</h1>
										<p style={{ color: `${brandsTrackingDesign.sc}` }} id={styles.paraForResponsivness}>
											{trackingData?.shopOrderId || '-'}
										</p>
									</div>
								</div>
								<div className={styles.orderDetailsBottomDashedDiv}></div>
								<div>
									<h1>AWB NO</h1>
									<p style={{ color: `${brandsTrackingDesign.sc}` }}>{trackingData?.trackingNumber || '-'}</p>
								</div>
								<p style={{ color: `${brandsTrackingDesign.sc}` }} className={styles.commonBoxHeading}>
									Order Details
								</p>
							</div>
						</div>
					</section>
				</div>
			)}
		</div>
	);
};
