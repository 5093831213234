import { Col, Input, notification, Row, Select, Typography } from 'antd';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import {
	flatPricingRowFields,
	lightningPricingRowFields,
	storageChargesTypes,
	storagePricingRowFields,
	STORAGE_CHARGE_TYPE_FIELD
} from './constants';
import { Loading } from '../../../../shared/components/Loading';
import { AutoCompleteInput } from '../../../../shared/components/AutoCompleteInput.js/AutoCompleteInput';
import { getCompanies } from '../../../../shared/api/lightning';
import NoCompany from '../../../../app/AppAdmin/NoCompany/NoCompany';
import { defaultPricingFields, IPricingRowFieldItem } from './types';
import errorHandler from '../../../../shared/utils/errorHandler';
import { updatePricingFields } from 'shared/api/billing';
import { usePricingDetails } from './usePricingDetails';
import debounce from '../../../../utils/debounce';

const { Option } = Select;

export default function Pricings() {
	const [pricingFields, setPricingFields] = useState(defaultPricingFields);
	const [loading, setLoading] = useState(false);
	const [company, setCompany] = useState(() => {
		const companyDetails = localStorage.getItem(`company-details`);
		return companyDetails ? JSON.parse(companyDetails) : { id: undefined, name: undefined };
	});
	const [customState, setCustomState] = useState({
		key: '',
		value: ''
	});

	const { pricing, revalidate } = usePricingDetails(company.id);

	const handlePricingChange = async (fieldName: string, updatedValue: string) => {
		try {
			setLoading(true);
			setPricingFields({ [fieldName]: updatedValue, ...pricingFields });
			await updatePricingFields({
				companyId: company.id,
				field: fieldName,
				value: updatedValue
			});

			notification.success({
				message: 'Success',
				description: 'Pricings updated successfully',
				placement: 'topRight'
			});
		} catch (error) {
			errorHandler(error);
		} finally {
			revalidate();
			setLoading(false);
		}
	};

	const createpricingFieldObject = (pricingData: any) => {
		const pricingFieldObject: any = {};
		flatPricingRowFields.forEach((field) => {
			pricingFieldObject[field.key] = pricingData[field.key];
		});
		lightningPricingRowFields.forEach((field) => {
			pricingFieldObject[field.key] = pricingData[field.key];
		});
		storagePricingRowFields.forEach((field) => {
			pricingFieldObject[field.key] = pricingData[field.key];
		});
		pricingFieldObject[STORAGE_CHARGE_TYPE_FIELD] = pricingData[STORAGE_CHARGE_TYPE_FIELD];

		return pricingFieldObject;
	};

	const handleDebounce = debounce(handlePricingChange, 500);

	useEffect(() => {
		localStorage.setItem(`company-details`, JSON.stringify(company));
	}, [company]);

	useEffect(() => {
		if (pricing) {
			const updatedFields = createpricingFieldObject(pricing);
			setPricingFields(updatedFields);
		}
	}, [pricing]);

	useEffect(() => {
		customState.key && customState.value && handleDebounce(customState.key, customState.value);
	}, [customState]);

	function PricingCards(item: IPricingRowFieldItem) {
		return (
			<Col span={24} key={item.key}>
				<Row className={styles.preferenceRow}>
					<Col span={10}>
						<span className="description">{item.description}</span>
					</Col>
					<Col span={2}>
						<Input
							value={pricingFields[item.key as keyof typeof pricingFields]}
							type="number"
							onChange={(e) => {
								setPricingFields((prevValue) => ({ ...prevValue, [item.key]: e.target.value }));
								setCustomState((prevObj) => ({ ...prevObj, key: item.key, value: e.target.value }));
							}}
							placeholder={pricingFields[item.key as keyof typeof pricingFields]}
						/>
					</Col>
				</Row>
			</Col>
		);
	}

	return (
		<>
			<Loading loading={loading} />
			<Col className={styles.topHeader}>
				<Col span={6}>
					<AutoCompleteInput
						getOptions={getCompanies}
						entityName="companies"
						selectedFilterItems={company && { entityId: company.id, name: company.name }}
						responseFieldPath="companies"
						optionKeyPath="entityId"
						optionLabelPath="name"
						optionValuePath="entityId"
						placeholder="Search company"
						handleSelect={(op: any) => setCompany({ id: op?.entityId, name: op?.name })}
					/>
				</Col>
			</Col>

			{!company.name ? (
				<NoCompany></NoCompany>
			) : (
				<Col className={styles.contentPage}>
					<Col span={24} className={styles.pricingDiv}>
						<Typography.Title level={4}>Flat Charges</Typography.Title>
						{flatPricingRowFields.map((item) => {
							return PricingCards(item);
						})}
					</Col>
					<Col span={24} className={styles.pricingDiv}>
						<Typography.Title level={4}>Wherehouse Lightning Charges</Typography.Title>
						{lightningPricingRowFields.map((item) => {
							return PricingCards(item);
						})}
					</Col>

					<Col span={24} className={styles.pricingDiv}>
						<Typography.Title level={4}>Storage Charges</Typography.Title>
						<Row className={styles.preferenceRow}>
							<Col span={10}>
								<span className="description">Storage Charge Type</span>
							</Col>
							<Col span={2}>
								<Select
									value={pricingFields[STORAGE_CHARGE_TYPE_FIELD]}
									onChange={(type) => {
										setPricingFields((prevValue) => ({ ...prevValue, [STORAGE_CHARGE_TYPE_FIELD]: type }));
										setCustomState((prevObj) => ({ ...prevObj, key: STORAGE_CHARGE_TYPE_FIELD, value: type }));
									}}
									style={{ minWidth: '150px' }}
								>
									{storageChargesTypes.map(({ label, value }) => (
										<Option value={value}>{label}</Option>
									))}
								</Select>
							</Col>
						</Row>

						{storagePricingRowFields.map((item) => {
							return PricingCards(item);
						})}
					</Col>
				</Col>
			)}
		</>
	);
}
