import { Button, Col, Form, Input, notification, Row, Select } from 'antd';
import { useState } from 'react';
import { getAllOmsWarehouses } from '../../../../shared/api/lightning';
import { attachedWarehouseToCompany } from '../../../../shared/api/warehouse';
import { AutoCompleteInput } from '../../../../shared/components/AutoCompleteInput.js/AutoCompleteInput';
import errorHandler from '../../../../shared/utils/errorHandler';

const { Option } = Select;

export function AttachWhForm({ reload, companyId }) {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const handleFinish = async (values) => {
		const { warehouseId, warehouseType, gstNumber, fssaiNumber } = values;
		setLoading(true);
		try {
			const {
				data: { status, message }
			} = await attachedWarehouseToCompany({ companyId, warehouseId: warehouseId, warehouseType, gstNumber, fssaiNumber });
			if (!status) return errorHandler(new Error(message));
			reload();
			notification.success({
				message: 'Warehouse attached',
				description: message,
				placement: 'topRight'
			});
		} catch (error) {
			errorHandler(error);
		}
		setLoading(false);
	};

	return (
		<Form form={form} onFinish={handleFinish} layout="vertical">
			<Row style={{ padding: '12px 12px 12px 8px' }} gutter={16}>
				<Col sm={12} md={6} lg={5}>
					<Form.Item label="Warehouse" name="warehouseId" rules={[{ required: true, message: 'Please select warehouse' }]}>
						<AutoCompleteInput
							getOptions={getAllOmsWarehouses}
							entityName="Warehouses"
							optionKeyPath="id"
							optionLabelPath="name"
							optionValuePath="id"
							responseFieldPath="warehouses"
							placeholder="Search for warehouses"
							handleSelect={(op) => form.setFieldsValue({ warehouseId: op?.id })}
						/>
					</Form.Item>
				</Col>
				<Col sm={12} md={6} lg={5}>
					<Form.Item name="gstNumber" label="GST Number" rules={[{ required: true, message: 'Please Enter Gst number' }]}>
						<Input placeholder="gst" />
					</Form.Item>
				</Col>
				<Col sm={12} md={6} lg={5}>
					<Form.Item name="fssaiNumber" label="Fssai Number" rules={[{ required: false, message: 'Please Enter Fssai number' }]}>
						<Input placeholder="Fssai Number" />
					</Form.Item>
				</Col>
				<Col sm={12} md={6} lg={5}>
					<Form.Item
						name="warehouseType"
						label="Warehouse Type"
						rules={[{ required: true, message: 'Please select warehouse Type' }]}
					>
						<Select style={{ width: '100%' }} placeholder="Warehouse type">
							<Option value="micro">micro</Option>
							<Option value="central">central</Option>
							<Option value="pseudo">pseudo</Option>
						</Select>
					</Form.Item>
				</Col>
				<Col sm={12} md={6} lg={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Button type="primary" htmlType="submit" loading={loading}>
						submit
					</Button>
				</Col>
			</Row>
		</Form>
	);
}
