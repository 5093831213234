export const originData = [];

for (let i = 0; i < 3; i++) {
	originData.push({
		key: i.toString(),
		serialNo: i.toString(),
		name: `prod ${i}`,
		skuId: i.toString(),
		category: `cat ${i}`,
		price: i % 2 === 0 ? 100 : 150,
		LxBxH: '128x145x149',
		weight: i % 2 === 0 ? 500 : 350,
		stock: i % 2 === 0 ? 240 : 490
	});
}
