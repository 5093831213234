import { Col, Row } from 'antd';
import React from 'react';

import styles from './index.module.scss';

export const awaitingPickupRoute = '/awaiting-pickup';

const tempData = [
	{
		companyName: 'Delhivery',
		img: 'https://picsum.photos/seed/picsum/84/84',
		value: 10
	},
	{
		companyName: 'DTDC',
		img: 'https://picsum.photos/seed/picsum/84/84',
		value: 5
	},
	{
		companyName: 'Xpressbees',
		img: 'https://picsum.photos/seed/picsum/84/84',
		value: 14
	},
	{
		companyName: 'Shadowfax',
		img: 'https://picsum.photos/seed/picsum/84/84',
		value: 20
	},
	{
		companyName: 'Dunzo',
		img: 'https://picsum.photos/seed/picsum/84/84',
		value: 30
	},
	{
		companyName: 'Wefast',
		img: 'https://picsum.photos/seed/picsum/84/84',
		value: 3
	}
];

export function AwaitingPickup() {
	return (
		<Row justify="center" align="middle" className={styles.main}>
			<Col span={18}>
				<div className={styles.title}>Awaiting Pickup</div>
			</Col>
			<Col span={18}>
				<Row justify="space-between" align="middle">
					{tempData.map((item) => (
						<Col span={10} className={styles.card}>
							<Row align="middle" justify="center">
								<Col span={12}>
									<img src={item.img} alt="" />
								</Col>
								<Col span={12}>
									<Row align="middle" justify="center">
										<Col span={24}>
											<div className={styles.companyName}>{item.companyName}</div>
										</Col>
										<Col span={24}>
											<div className={styles.value}>{item.value}</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					))}
				</Row>
			</Col>
		</Row>
	);
}
