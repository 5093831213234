import { Radio } from 'antd';

interface IRadioButtons {
	defaultValue: number | string;
	handleChange: (event: any) => void;
	color: string;
}

export const SearchByButtons = (props: IRadioButtons) => {
	const { defaultValue, handleChange, color } = props;

	return (
		<div style={{ display: 'flex', marginTop: '1rem', gap: '1rem' }}>
			<strong style={{ color }}>Search by:</strong>
			<Radio.Group onChange={handleChange} value={defaultValue}>
				<Radio style={{ color }} value={1}>
					AWB
				</Radio>
				<Radio style={{ color }} value={2}>
					Mobile
				</Radio>
				<Radio style={{ color }} value={3}>
					Email
				</Radio>
			</Radio.Group>
		</div>
	);
};
