export const retailClusters = [
	'Gurgaon',
	'East Delhi',
	'West Delhi',
	'North Delhi',
	'South Delhi',
	'Central Delhi',
	'Noida',
	'Greater Noida',
	'Ghaziabad',
	'Faridabad'
];

export enum ERetailerStoreType {
	MT = 'MT',
	SMTP = 'SMTP',
	SMTU = 'SMTU',
	GT = 'GT',
	SHOP = 'SHOP',
	HOTEL = 'HOTEL',
	RESTAURANT = 'RESTAURANT',
	CAFE = 'CAFE',
	INSTITUTION = 'INSTITUTION',
	CORPORATE = 'CORPORATE',
	HIGHWAY = 'HIGHWAY',
	CHEMIST = 'CHEMIST',
	HOSPTIAL = 'HOSPTIAL',
	CANTEEN = 'CATEEN',
	GOVT = 'GOVT'
}
