import { Button, Col, Row, Space } from 'antd';
import BulkActionProgress from 'shared/components/BulkActionProgress';
import { DatePicker } from 'shared/components/DatePickers';
import styles from './index.module.scss';
import Row4ActionButton from './Row4ActionButton';

export interface ITopFilterComponent {
	row1Filter?: any[];
	row1?: any;
	row2?: any;
	row3?: any;
	row4?: any;
	row5?: any;
	row1children?: any[];
	shouldShowRow5?: boolean;
	memoizedProgress?: any;
	isQTaskInProgress?: any;
	isBulkSync?: any;
	handleBulkSync?: any;
}
export function TopFilterComponent(props: Readonly<ITopFilterComponent>) {
	const {
		row1Filter = [],
		row1,
		row2,
		row3,
		row4,
		row5,
		row1children = [],
		shouldShowRow5 = false,
		memoizedProgress,
		isQTaskInProgress,
		isBulkSync,
		handleBulkSync
	} = props;
	return (
		<div className={`${styles.main}`}>
			{row2 && (
				<Row justify="space-between" className={styles.row1}>
					<Col className={styles.date}>
						{row1Filter?.map((children) => children)}
						{row1 && (
							<div className={styles.center}>
								{row1.map(({ label, onChange, type, value, showLabel = true, ...rest }: any, i: number) =>
									label ? (
										<div style={{ display: 'flex', flexDirection: 'column' }} key={label.split(' ')[0]}>
											{showLabel && <span className={styles.filterByDateTitle}>{label}</span>}
											{type === 'range' ? (
												<DatePicker.RangePicker
													onChange={onChange}
													{...rest}
													showTime={{
														format: 'HH:mm'
													}}
													format="YYYY-MM-DD HH:mm"
												/>
											) : (
												<DatePicker mode="date" onChange={onChange} {...rest} />
											)}
										</div>
									) : (
										<></>
									)
								)}

								{row1children.map((children) => children)}
							</div>
						)}
					</Col>
					<Col className={styles.download}>{row2.map((item: any) => item)}</Col>
				</Row>
			)}

			{row3 && (
				<Row justify="space-between" className={styles.row2}>
					<Col span={12}>
						<Space size={22}>
							{row3.map((item: any) => (
								<Button
									size="large"
									type={item.selected ? 'primary' : 'default'}
									shape="round"
									key={item.key}
									onClick={() => item.onClick(item.key)}
								>
									{item.label}
								</Button>
							))}
						</Space>
					</Col>
				</Row>
			)}

			{row4 && (
				<Row justify="space-between" className={`${styles.row3}`}>
					<Col span={12}>
						<Space size={22}>
							{row4.map((item: any, index: number) => {
								if (!item) {
									return null;
								}
								if (item.isNotButton) {
									return item.render(memoizedProgress);
								} else {
									return <Row4ActionButton key={item?.key} item={item} />;
								}
							})}
						</Space>
					</Col>
				</Row>
			)}

			<div className={styles.BSProgress}>
				{isBulkSync?.length > 0 && (
					<Button size="small" type="primary" className={styles.bulkSyncOrders} onClick={handleBulkSync}>
						Bulk Sync
					</Button>
				)}
				<BulkActionProgress label={'Bulk Sync Progress'} isQTaskInProgress={isQTaskInProgress} progress={memoizedProgress} />
			</div>
			{row5 && shouldShowRow5 && <Row className={styles.row4}>{row5}</Row>}
		</div>
	);
}
