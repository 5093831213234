import { Modal, Tag, Typography } from 'antd';
import { CUSTOMER_REQUEST_TYPE, getFormattedShippingAddress } from '../../../CustomerRequest/constants';
import styles from './index.module.scss';

export const NdrInfoModal = ({ data }: any) => {
	const fieldsToShowInNdrModal = [
		{
			name: 'Form Status:',
			value: 'formLinkStatus'
		},
		{
			name: 'Approval Status:',
			value: 'approvalStatus'
		},
		{
			name: 'Request Type:',
			value: 'requestType'
		}
	];
	return (
		<>
			<Modal title="Check status" visible={true}>
				<div className={styles.modalParentDiv}>
					{fieldsToShowInNdrModal.map((e) => (
						<div className={styles.childDiv}>
							<strong>{e.name}</strong>
							<Tag>{data?.[e.value] || 'NA'}</Tag>
						</div>
					))}
				</div>
				<div className={styles.childDiv}>
					<strong>Request/Reason:</strong>
					{data.requestType === CUSTOMER_REQUEST_TYPE.UPDATE_DETAILS ? (
						<div>
							<Typography.Paragraph>Phone Number: {data.alternatePhone}</Typography.Paragraph>
							<Typography.Paragraph>
								Preferred Date: {new Date(data.deferredDeliveryDate).toLocaleDateString()}
							</Typography.Paragraph>
							<Typography.Paragraph>Address: {getFormattedShippingAddress(data.alternateAddress)}</Typography.Paragraph>
						</div>
					) : data.requestType === CUSTOMER_REQUEST_TYPE.CANCEL_ORDER ? (
						<div>
							<Typography.Paragraph>Issue Type: {data.issueType}</Typography.Paragraph>
							<Typography.Paragraph>Issue Description: {data.issueDescription}</Typography.Paragraph>
						</div>
					) : (
						<div>No Details Available</div>
					)}
				</div>
			</Modal>
		</>
	);
};
