import { Pagination, Table } from 'antd';
import styles from './index.module.scss';

interface ICustomTablePagination {
	onChangePage?: (current: any, pageSize: any) => void;
	total?: any;
	onChange?: (pagination: any, filters: any, sorter: any) => void;
	loading?: boolean;
	bordered?: true;
	showSorterTooltip?: boolean;
	data?: any;
	columns?: any;
	current?: any;
	pageSize?: any;
	selectedItem?: any;
	catalog?: any;
	selectedRowKeys?: any;
	setSelectedRowKeys?: any;
	setSelectedItem?: any;
	emptyTableMessage?: any;
	shouldRowSelection?: any;
	showPagination?: any;
	// sortField? :any;
	// sortOrder? :any;
	// showSorterTooltop?: boolean;
	[x: string]: any;
}

export function CustomTablePagination({
	columns,
	data,
	current = 1,
	total = 0,
	pageSize = 10,
	onChangePage = (current, pageSize) => {},
	selectedItem = undefined,
	catalog = undefined,
	selectedRowKeys,
	setSelectedRowKeys,
	setSelectedItem = undefined,
	emptyTableMessage = 'Nothing to do here',
	shouldRowSelection = true,
	showPagination = true,
	...tableProps
}: Readonly<ICustomTablePagination>) {
	return (
		<div className={styles.main}>
			{data?.length || tableProps.loading ? (
				<>
					<Table
						// tableLayout={`${catalog ? "auto" : "fixed"}`}
						rowSelection={
							shouldRowSelection ? (
								{
									type: 'checkbox',
									selectedRowKeys,
									preserveSelectedRowKeys: true,
									onChange:
										setSelectedRowKeys || ((_, selectedRow) => (setSelectedItem ? setSelectedItem(selectedRow) : null))
								}
							) : (
								<></>
							)
						}
						columns={columns}
						dataSource={data}
						pagination={false}
						rowKey={(record) => record.id}
						className={styles.table}
						scroll={{ y: 'calc(100% - 3.5rem)', x: 'calc(100% - 3.5rem)' }}
						{...tableProps}
					/>
					{showPagination && (
						<Pagination
							current={current}
							total={total}
							pageSize={pageSize}
							showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
							className={styles.pagination}
							onChange={onChangePage}
							showSizeChanger
							pageSizeOptions={[10, 50, 100, 200, 300]}
						/>
					)}
				</>
			) : (
				<div className="noDataDefaultComponent">{emptyTableMessage}</div>
			)}
		</div>
	);
}
