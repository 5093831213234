import { Switch } from 'antd';
import { useState } from 'react';
import { apiWms } from 'shared/api';
import errorHandler from 'shared/utils/errorHandler';
import styles from './index.module.scss';

export function WarehouseCard({ warehouse }) {
	const {
		id,
		index,
		warehouseType,
		invoicePrefix,
		active,
		profile: { addressLine1, addressLine2, city, state, pincode, phone, name }
	} = warehouse;

	const [isWarehouseActive, setIsWarehouseActive] = useState(active);
	const [isLoading, setIsLoading] = useState(false);

	const onToggleWarehouseStatus = async (updatedActiveStatus) => {
		try {
			setIsLoading(true);

			await apiWms.patch(`/warehouse/${id}/status`, { active: updatedActiveStatus });
			setIsWarehouseActive(updatedActiveStatus);
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className={styles.warehouseCard}>
			<div className={styles.warehouseCardInnerContainer}>
				<div className={styles.left}>
					<div style={{ lineBreak: 'anywhere' }}>
						<div className={styles.warehouseId}>{index}</div>
					</div>
					<div>
						<h5 className={styles.warehouseType}>{name} </h5>
						<h6> {warehouseType.charAt(0).toUpperCase() + warehouseType.slice(1)} warehouse</h6>
						<address className={styles.address}>
							{addressLine1}, {addressLine2}, <br />
							{city}, {state}
							<br />
							Pincode : {pincode}
							<br />
							Contact: {phone}
						</address>

						<p className={styles.invoicePrefix}>Invoice Prefix: {invoicePrefix}</p>
					</div>
				</div>

				<div className={styles.right}>
					<Switch checked={isWarehouseActive} onChange={onToggleWarehouseStatus} loading={isLoading} />
				</div>
			</div>
		</div>
	);
}
