import { Drawer, notification } from 'antd';
import { useState } from 'react';
import { FormLayout } from '../FormLayout';
import { apiHms } from 'shared/api';

export interface ISalesmanInfoSlider {
	isSalesmanDrawerVisible: boolean;
	salesmanData: any;
	setIsSalesmanDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setReloadSalesmanList: React.Dispatch<React.SetStateAction<number>>;
	salesmanId: string | number;
}

export const SalesmanInfoSlider = ({
	isSalesmanDrawerVisible,
	salesmanData,
	setIsSalesmanDrawerVisible,
	setReloadSalesmanList,
	salesmanId
}: ISalesmanInfoSlider) => {
	const handleCloseDrawer = () => {
		setIsSalesmanDrawerVisible(false);
	};

	const onSalesmanEditFormSubmit = async (values: any) => {
		try {
			const { data } = await apiHms.patch(`/admin/salesman/update`, { ...values, salesmanId });
			if (data?.status) {
				notification.success({
					message: 'Success',
					description: data?.message,
					placement: 'topRight'
				});
			}
		} catch (error: any) {
			const msg: string =
				error?.response?.data?.responseMessage || error?.response?.data?.message || error?.message || 'Something went wrong';
			notification.error({
				message: 'Failed',
				description: msg,
				placement: 'topRight'
			});
		} finally {
			setReloadSalesmanList((prev: number) => prev + 1);
			setIsSalesmanDrawerVisible(false);
		}
	};

	return (
		<Drawer visible={isSalesmanDrawerVisible} onClose={handleCloseDrawer}>
			{salesmanData ? (
				<div>
					<FormLayout
						formTitle={'Edit Salesman Details'}
						onSave={(values: any) => onSalesmanEditFormSubmit(values)}
						onClose={() => setIsSalesmanDrawerVisible(false)}
						formFields={[
							{
								name: 'name',
								id: 'name',
								label: 'Name',
								placeholder: 'Enter name'
							},
							{
								name: 'phone',
								id: 'phone',
								label: 'Phone',
								placeholder: 'Enter Phone'
							}
						]}
						initialValues={{
							name: salesmanData[0]?.name,
							phone: salesmanData[0]?.phone
						}}
					/>
				</div>
			) : (
				<></>
			)}
		</Drawer>
	);
};
