import { InboxOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { useCallback, useRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import errorHandler from '../../../../../shared/utils/errorHandler';
import styles from './index.module.scss';
const minCroppedWidth = 10;
const minCroppedHeight = 10;
const allowedImageFileTypes = /(\/jpg|\/jpeg|\/png|\/webp)$/i;

export const UploadFileModal = ({ isModalVisible, setIsModalVisible, setFiles, modalName, maxCroppedWidth, maxCroppedHeight }) => {
	const [image, setImage] = useState();
	const [imgFile, setImgFile] = useState();
	const cropperRef = useRef(null);
	const cropperInstance = useRef(null);
	const handleInputChange = ({ fileList }) => {
		try {
			if (!fileList.length) {
				return setFiles((files) => ({ ...files, [modalName]: [] }));
			}
			const file = fileList.pop().originFileObj;
			if (!allowedImageFileTypes.exec(file.type)) {
				throw new Error('Only images are allowed');
			}
			setImgFile(file);
			const img = new Image();

			img.src = URL.createObjectURL(file);
			setImage(img.src);
		} catch (error) {
			errorHandler(error);
		}
	};
	const getCropData = () => {
		setFiles((files) => ({
			...files,
			[modalName]: [{ data: cropperInstance.current.getCroppedCanvas().toDataURL(), name: imgFile.name, url: image }]
		}));
	};
	const onCrop = useCallback(
		(event) => {
			var width = event.detail.width;
			var height = event.detail.height;

			// }
		},
		[cropperInstance]
	);
	const handleOnOk = () => {
		getCropData();
		setIsModalVisible(!isModalVisible);
	};
	return (
		<Modal
			title={`Upload ${modalName}`}
			visible={isModalVisible}
			onOk={handleOnOk}
			maskClosable={false}
			onCancel={() => setIsModalVisible(!isModalVisible)}
		>
			<div className={styles.cropperBox}>
				{image && (
					<Cropper
						style={{ height: 200, width: '100%', objectFit: 'contain !important' }}
						width="100%"
						src={image}
						aspectRatio={3 / 1}
						checkCrossOrigin={false}
						guides={true}
						crop={onCrop}
						onInitialized={(instance) => {
							cropperInstance.current = instance;
						}}
						ref={cropperRef}
						dragMode="move"
						viewMode={1}
					/>
				)}
				<Dragger onChange={handleInputChange} style={{ height: 400, width: '100%' }}>
					<p className="ant-upload-drag-icon">
						<InboxOutlined />
					</p>
					<p className="ant-upload-text">Click or drag file to this area to upload</p>
					<p className="ant-upload-hint">
						Support for a single upload. Strictly prohibit from uploading company data or other band files
					</p>
				</Dragger>
			</div>
		</Modal>
	);
};
