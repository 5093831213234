import { Form, Input, Button, Select, Row, Col, Drawer } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ORDER_STATUS, MISSED_ORDER_STATUS } from '../../../constants/index';
import { orderStatus } from '../../utils/orderStatus';
import { getFilterFromSessionStorage } from '../../../app/AppSeller/Orders/utils';
import styles from './index.module.scss';
import { useEffect, useRef, useCallback } from 'react';

export interface IMultipleFilterComponent {
	openFilterPanel: boolean | undefined;
	setOpenFilterPanel: any;
	setFilter: any;
	filterFormValue: any;
	setFilterFormValue: any;
	searchOptions: any[];
	showOrderStatusFilters: any;
	isMissedOrder?: boolean;
	filter: any;
	sidebarMenuItem?: any;
	counts?: any;
	isAdmin?: boolean;
	isWms?: boolean;
	showClusterStatusFilters?: any;
	clusterData?: any;
	status: any;
	setStatus: any;
	clusterState?: any;
	setClusterState?: any;
}
export function MultipleFilterComponent({
	openFilterPanel,
	setOpenFilterPanel,
	setFilter,
	filterFormValue,
	setFilterFormValue,
	searchOptions = [],
	showOrderStatusFilters,
	isMissedOrder = false,
	filter,
	sidebarMenuItem,
	counts,
	isAdmin = false,
	isWms = false,
	showClusterStatusFilters,
	clusterData,
	status,
	setStatus,
	clusterState,
	setClusterState = () => {}
}: Readonly<IMultipleFilterComponent>) {
	const { Option } = Select;
	const [form]: any = Form.useForm();

	const inputRef = useRef<any>(null);
	const addFilterBtnRef = useRef<any>(null);

	/**
	 * comparing values from form and filterFormValue state to re-render the form
	 * whenever a tag on main screen is closed, filterFormValue state is changed, this should re-render the form based on new filter value
	 */
	useEffect(() => {
		const val = form.getFieldsValue();
		let formResetValues: any = {};

		const { cluster } = val;

		if (cluster?.length > 0) {
			formResetValues['cluster'] = clusterState;
		} else {
			form.resetFields(['cluster']);
		}

		if (status.length === 0) {
			form.resetFields(['status']);
		} else if (status?.length) {
			formResetValues['status'] = status; // statusUpdated;
		}

		if (filterFormValue) {
			const users = val?.users;
			let filterObj = [];
			if (users)
				filterObj = users.filter((item: any) => {
					const { filterKey, filterValue } = item !== undefined && item;
					return filterFormValue.hasOwnProperty(filterKey) || filterFormValue[filterKey] === filterValue;
				});
			else {
				// if users doesnt exist, but formValue state has some value- like in case of sidebar menu change
				filterObj = Object.entries(filterFormValue).map(([filterKey, filterValue]) => ({ filterKey, filterValue }));
			}
			formResetValues['users'] = filterObj;
		}
		form.setFieldsValue(formResetValues);
	}, [filterFormValue, status, clusterState, Number(sidebarMenuItem)]);

	/**
	 * retrieving values from session storage and re-render filter form based on the stored values
	 * changing format obtained from session storage as needed by antd form
	 *
	 */
	useEffect(() => {
		let sessionFilter = getFilterFromSessionStorage();
		const statusFromSessionStorage = sessionFilter?.status;
		let formResetValues: any = {};

		if (statusFromSessionStorage?.length > 0) {
			formResetValues['status'] = statusFromSessionStorage;
		}

		const storedFormValue = sessionFilter?.filterFormValue;

		if (storedFormValue) {
			// dynamic form fields
			const restOfFields = Object.entries(storedFormValue).map(([filterKey, filterValue]) => ({ filterKey, filterValue }));
			formResetValues['users'] = restOfFields;
		}

		!isAdmin && !isWms && !isMissedOrder && form.setFieldsValue(formResetValues);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFormValueChange = (changedValues: any, values: any) => {
		let formVal = {};
		const users = values?.users || [];
		const updatedStatus = values?.status;
		const updatedCluster = values?.cluster;

		formVal = {
			// dynamic form fields
			...users.reduce((finalObj: any, item: any) => {
				if (item !== undefined) {
					const { filterKey, filterValue } = item;
					return filterValue?.length > 0 ? { ...finalObj, [filterKey]: filterValue } : finalObj;
				}
				return finalObj;
			}, {})
		};

		setFilterFormValue(formVal);

		setStatus(updatedStatus || []);

		setClusterState(updatedCluster || []);
	};
	const handleResetFilter = () => {
		setFilterFormValue({});
		// remove status
		setStatus([]);
		// remove cluster
		setClusterState([]);
		form.resetFields();

		setFilter((prevFilter: any) => {
			const updatedFilter = {
				filterFormValue: {},
				status: [],
				cluster: []
			};

			const newFilter = {
				...prevFilter,
				...updatedFilter
			};

			/* deleting status or cluster array if their length is zero */
			const filteredFilter = Object.keys(newFilter).reduce((filterObject: any, key: any) => {
				if (!(Array.isArray(newFilter[key]) && newFilter[key].length === 0)) {
					filterObject[key] = newFilter[key];
				}
				return filterObject;
			}, {});

			return filteredFilter;
		});
	};
	const handleApplyFilter = () => {
		setFilter((prevFilter: any) => {
			const updatedFilter = {
				filterFormValue,
				status,
				cluster: clusterState
			};

			const newFilter = {
				...prevFilter,
				...updatedFilter
			};

			const filteredFilter = Object.keys(newFilter).reduce((filterObject: any, key: any) => {
				if (!(Array.isArray(newFilter[key]) && newFilter[key].length === 0)) {
					filterObject[key] = newFilter[key];
				}
				return filterObject;
			}, {});

			return filteredFilter;
		});

		setOpenFilterPanel(false);
	};
	const onClose = () => {
		setOpenFilterPanel(false);
	};

	/**
	 * using synthetic click to render the first row of form
	 * focus at beginning in input field
	 */
	useEffect(() => {
		const isFilterRowPresent = window.document.getElementsByClassName('common-filter-skeleton').length > 0;
		if (openFilterPanel && !isFilterRowPresent && addFilterBtnRef.current) {
			addFilterBtnRef.current.click();
		}

		if (openFilterPanel && !form?.users) {
			setTimeout(() => {
				inputRef.current.focus({ cursor: 'start' });
			}, 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openFilterPanel]);

	const openDrawerKeyPress = useCallback((event: any) => {
		if (event.key === 'f' || event.key === 'F') {
			event.stopPropagation();
			setOpenFilterPanel(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * pressing 'f' or 'F' opens the filter - key binding
	 */
	useEffect(() => {
		window.addEventListener('keydown', openDrawerKeyPress);
		return () => {
			window.removeEventListener('keydown', openDrawerKeyPress);
		};
	}, []);

	return (
		<Drawer onClose={onClose} placement="right" visible={openFilterPanel} width="400px" title="Select one or more filters">
			<Form form={form} layout="horizontal" onValuesChange={handleFormValueChange} onFinish={handleApplyFilter}>
				<Form.List name="users">
					{(fields, { add, remove }) => (
						<>
							{fields.map(({ key, name, ...restField }) => (
								<Row key={key} className={'common-filter-skeleton'}>
									<Col span={10} style={{ marginRight: 10 }}>
										<Form.Item {...restField} name={[name, 'filterKey']}>
											<Select placeholder="select filter">
												{searchOptions?.map(({ label, value }) => (
													<Select.Option key={value} value={value}>
														{label}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col span={11} style={{ marginRight: 9 }}>
										<Form.Item {...restField} name={[name, 'filterValue']}>
											<Input ref={inputRef} placeholder="enter value" allowClear />
										</Form.Item>
									</Col>
									<Col span={1}>
										<Form.Item {...restField}>
											<MinusCircleOutlined onClick={() => remove(name)} />
										</Form.Item>
									</Col>
								</Row>
							))}
							<Form.Item>
								<Button ref={addFilterBtnRef} type="dashed" onClick={add} block icon={<PlusOutlined />}>
									Add more filters
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>
				<Form.Item name="status" label={sidebarMenuItem !== '5' && 'Status'}>
					{(() => {
						if (!isMissedOrder && !isAdmin) {
							return (
								showOrderStatusFilters(sidebarMenuItem) && (
									<Select mode="multiple" placeholder="Select status" style={{ width: '100%' }} allowClear>
										{Object.values(ORDER_STATUS).map((orderStatusVar) => (
											<Option key={orderStatusVar} disabled={!counts[orderStatusVar]} value={orderStatusVar}>
												{orderStatus[orderStatusVar]} ({counts[orderStatusVar] || 0})
											</Option>
										))}
									</Select>
								)
							);
						} else if (isMissedOrder && !isAdmin) {
							return (
								<Select maxTagCount="responsive" placeholder="Select Order Status" style={{ width: '100%' }} allowClear>
									{Object.values(MISSED_ORDER_STATUS).map((orderStatus, index) => (
										<Option key={index === 2 ? '' : index} value={orderStatus}>
											{orderStatus}
										</Option>
									))}
								</Select>
							);
						} else {
							return (
								<Select
									mode="multiple"
									maxTagCount="responsive"
									placeholder="Select Order Status"
									style={{ width: '100%' }}
									allowClear
								>
									{Object.keys(ORDER_STATUS).map((orderStatus) => (
										<Select.Option key={orderStatus} value={orderStatus}>
											{orderStatus}
										</Select.Option>
									))}
								</Select>
							);
						}
					})()}
				</Form.Item>
				{isWms && (
					<Form.Item name="cluster" label="Cluster">
						{isWms && showClusterStatusFilters(sidebarMenuItem) && (
							<Select placeholder="Select Cluster" style={{ width: '100%' }} allowClear>
								{clusterData.map((clusterItem: any) => {
									return (
										<Option key={clusterItem.clusterId} value={clusterItem.clusterId}>
											{clusterItem.clusterName} ({clusterItem.clusterPincode})
										</Option>
									);
								})}
							</Select>
						)}
					</Form.Item>
				)}
				<div className={styles.buttonGroup}>
					<Button type="primary" className={styles.filterButton} htmlType="submit" size="middle">
						Apply
					</Button>
					<Button style={{ outline: 'none' }} className={styles.filterButton} onClick={handleResetFilter} size="middle">
						Reset
					</Button>
				</div>
			</Form>
		</Drawer>
	);
}
