import { notification } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import reactDom from 'react-dom';
import { baseApi } from '../api';
import { AuthContext } from '../contexts/Auth';

interface IReturnPolling {
	progress: number;
	isQTaskInProgress: boolean;
	trackQueueTaskIdInBulk: (taskId: string) => void;
}

/**
 *
 * @param queueName
 * @param localstorageId You get this from the response after any bulk Task
 * @description Read about Polling first.
 * https://levelup.gitconnected.com/polling-in-javascript-ab2d6378705a
 */
export const usePolling = (queueName: string, localstorageId: string): IReturnPolling => {
	const { authState } = useContext(AuthContext);
	const [progress, setProgress] = useState<number>(0);
	const [isQTaskInProgress, setisQTaskInProgress] = useState<boolean>(false);

	const trackQueueTaskIdInBulk = useCallback(
		(taskId: string) => {
			reactDom.unstable_batchedUpdates(() => {
				setisQTaskInProgress(true);
			});
			localStorage.setItem(`${localstorageId}${authState.profile.id}`, taskId);
			const interval = setInterval(async () => {
				try {
					const { data } = await baseApi.get(`queue/${queueName}/${taskId}`);

					if (data.progress === 100) {
						clearInterval(interval);
						localStorage.removeItem(`${localstorageId}${authState.profile.id}`);

						reactDom.unstable_batchedUpdates(() => {
							setisQTaskInProgress(false);
							setProgress(0);
						});

						notification.success({ message: 'Success' });
					} else {
						setProgress((prev: number) => (prev > Number(data.progress.toFixed(0)) ? prev : Number(data.progress.toFixed(0))));
					}
				} catch (error) {
					clearInterval(interval);
					reactDom.unstable_batchedUpdates(() => {
						setisQTaskInProgress(false);
						setProgress(0);
					});

					localStorage.removeItem(`${localstorageId}${authState.profile.id}`);
				}
			}, 1000);
		},
		[localstorageId, authState.profile.id, queueName]
	);

	useEffect(() => {
		const taskIdFromLocalStorage = localStorage.getItem(`queueName${authState.profile.id}`);
		if (taskIdFromLocalStorage) {
			trackQueueTaskIdInBulk(taskIdFromLocalStorage);
		}
	}, [authState.profile.id, trackQueueTaskIdInBulk]);

	return { progress, isQTaskInProgress, trackQueueTaskIdInBulk };
};
