export const searchOptionsForMissedOrder = [
	{ value: 'shopOrderId', label: 'Order ID' },
	{ value: 'shopType', label: 'Shop Type' }
];

export const MAX_DOWNLOAD_ORDER_LIMIT = 300;

export const MAX_DOWNLOAD_LABEL_LIMIT = 30;
export const OrderPageOptions: Record<string, any> = {
	All: 1,
	'missed-order': 2,
	Unservicable: 4,
	returns: 5,
	NDRs: 6,
	'ndr-customer-requests': 7
};

export const orderPageOptionsWithCommonTable = ['1', '3', '4', '5', '6'];
