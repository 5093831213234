import { useState, useEffect } from 'react';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import errorHandler from 'shared/utils/errorHandler';
import { shippingPackageColumn } from './column';
import styles from './index.module.scss';
import { defaultFilter } from './constants';
import { defaultPageConfig } from '../../../../constants';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { topBarConfig } from './utils';
import { apiOms } from 'shared/api';
import { cleanObject } from 'helper';
import PackageModal from './components/PackageModal.tsx';
import reactDom from 'react-dom';

const ShippingPackages = () => {
	const [data, setData] = useState([]);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [loading, setLoading] = useState(false);
	const [filter, setFilter] = useState(defaultFilter);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [editPackageData, setEditPackageData] = useState({});
	function setPageConfigDefault() {
		setPageConfig((previousConfig) => ({ ...previousConfig, ...defaultPageConfig }));
	}

	const getShippingPackages = async (filter, pageSize, current) => {
		const {
			data: { data }
		} = await apiOms.post(`/get-shipping-packages`, {
			where: cleanObject({ ...filter }, ['', null, undefined]),
			pagination: {
				records: pageSize,
				pageNumber: current
			}
		});

		reactDom.unstable_batchedUpdates(() => {
			setData(data);
			setLoading(false);
		});
	};

	useEffect(() => {
		(async function () {
			try {
				setLoading(true);
				await getShippingPackages(filter, pageConfig.pageSize, pageConfig.current);
			} catch (error) {
				setLoading(false);
				errorHandler(error);
			}
		})();
	}, [filter, pageConfig]);

	const showEditModal = (item) => {
		reactDom.unstable_batchedUpdates(() => {
			setEditPackageData(item);
			setIsModalVisible(true);
		});
	};

	return (
		<div className={styles.main}>
			<TopFilterComponent
				{...topBarConfig({
					setPageConfig,
					filter,
					setFilter,
					isModalVisible,
					setIsModalVisible,
					getShippingPackages,
					editPackageData,
					setPageConfigDefault
				})}
			/>
			{isModalVisible ? (
				<PackageModal
					setIsModalVisible={setIsModalVisible}
					isModalVisible={isModalVisible}
					getShippingPackages={getShippingPackages}
					packageData={editPackageData}
					setEditPackageData={setEditPackageData}
				/>
			) : null}
			<div className="div w-100 h-100">
				<CustomTablePagination
					columns={shippingPackageColumn(showEditModal)}
					data={data}
					showSorterTooltip={true}
					{...pageConfig}
					onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
					total={data ? data.length : 0}
					loading={loading}
					emptyTableMessage="No shipping packages yet!"
					shouldRowSelection={false}
					bordered
				/>
			</div>
		</div>
	);
};

export default ShippingPackages;
