import { Col } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiOms } from '../../../../shared/api';
import { TopFilterComponent } from '../../../../shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from '../../../../shared/components/CustomTablePagination';
import errorHandler from '../../../../shared/utils/errorHandler';
import { defaultFilter, retailerColumn, retailersFilter } from './utils';
import { defaultPageConfig } from '../../../../constants';
import { companyType } from '../D2r';
import { RetailerInfoSlider } from '../../../../shared/components/RetailerInfoSlider/RetailerInfoSlider';

export const Retailer = ({ company }: { company: companyType }) => {
	const [loading, setLoading] = useState(false);
	const [retailers, setRetailers] = useState([]);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [count, setCount] = useState(0);
	const [filter, setFilter] = useState(defaultFilter);
	const setPageConfigDefault = useCallback(() => {
		setPageConfig(defaultPageConfig);
	}, []);
	const [isRetailerModalVisible, setIsRetailerModalVisible] = useState<boolean>(false);
	const [retailerId, setRetailerId] = useState<string | number>('');
	const [reloadRetailerList, setReloadRetailerList] = useState<number>(0);

	const topFilterConfig = useMemo(
		() =>
			retailersFilter({
				company,
				setPageConfigDefault,
				setFilter,
				filter
			}),
		[company, filter, pageConfig]
	);
	const columnsConfig = useMemo(() => retailerColumn(setIsRetailerModalVisible, setRetailerId), []);

	const searchParamConstruct = (): URLSearchParams => {
		const searchParam = new URLSearchParams();
		const { searchOptionType, searchValue } = filter;
		const { current, pageSize } = pageConfig;

		if (searchOptionType && searchValue) {
			searchParam.append('searchOptionType', searchOptionType);
			searchParam.append('searchValue', searchValue);
		}
		searchParam.append('currentPage', current + '');
		searchParam.append('pageSize', pageSize + '');
		return searchParam;
	};

	const fetchData = async () => {
		try {
			if (company?.id) {
				setLoading(true);

				const searchParamVal: URLSearchParams = searchParamConstruct();

				const { data } = await apiOms.get(`/d2r/retailers/${company.id}?${searchParamVal.toString()}`);

				if (data?.status) {
					setRetailers(data.retailersData);
					setCount(Number(data.count));
				}
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [company, filter, pageConfig, reloadRetailerList]);

	return (
		<Col sm={24} lg={24} span={24} className="main">
			<TopFilterComponent {...topFilterConfig} />
			<div className="innerDiv">
				<div className="div w-100 h-100">
					<CustomTablePagination
						columns={columnsConfig}
						data={retailers}
						total={count}
						loading={loading}
						onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
						{...pageConfig}
						shouldRowSelection={false}
						selectedItem={undefined}
						catalog={undefined}
						selectedRowKeys={undefined}
						setSelectedRowKeys={undefined}
						setSelectedItem={undefined}
					/>
				</div>
				{isRetailerModalVisible && (
					<RetailerInfoSlider
						isRetailerModalVisible={isRetailerModalVisible}
						retailerData={retailers.filter((R: any) => R.id === retailerId)}
						setIsRetailerModalVisible={setIsRetailerModalVisible}
						company={company}
						setReloadRetailerList={setReloadRetailerList}
					/>
				)}
			</div>
		</Col>
	);
};
