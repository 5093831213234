export default (
	<svg
		viewBox="64 64 896 896"
		focusable="false"
		class=""
		data-icon="customer-service"
		width="18"
		height="18"
		fill="currentColor"
		aria-hidden="true"
	>
		<path d="M512 128c-212.1 0-384 171.9-384 384v360c0 13.3 10.7 24 24 24h184c35.3 0 64-28.7 64-64V624c0-35.3-28.7-64-64-64H200v-48c0-172.3 139.7-312 312-312s312 139.7 312 312v48H688c-35.3 0-64 28.7-64 64v208c0 35.3 28.7 64 64 64h184c13.3 0 24-10.7 24-24V512c0-212.1-171.9-384-384-384zM328 632v192H200V632h128zm496 192H696V632h128v192z"></path>
	</svg>
);
