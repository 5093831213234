import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import styles from './index.module.scss';
import debounce from 'utils/debounce';
import { getHSNCodes } from 'shared/api/catalog';

export function HsnFormItem({ loading, showTitle = true, isLarge = true }) {
	const [hsnCodes, setHSNCodes] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');

	async function getHsnCodes(searchQuery) {
		const { data } = await getHSNCodes(searchQuery);
		setHSNCodes(data.hsnCodes);
		return 1;
	}

	useEffect(() => {
		debounce((searchQuery) => getHsnCodes(searchQuery), 500)(searchQuery);
	}, [searchQuery]);

	return (
		<div className="hsn-select">
			<Form.Item
				name="hsnCode"
				label={showTitle ? 'HSN Code' : ''}
				rules={[{ required: true, message: 'HSN Code is required!' }]}
				className={isLarge ? styles.formItem : styles.formLayoutItem}
			>
				<Select
					showSearch
					size={isLarge ? 'large' : 'middle'}
					placeholder="HSN Code"
					value={searchQuery}
					onSearch={(value) => setSearchQuery(value)}
					onChange={(value) => {
						// console.log('!!!!!Value Changed!!!!!', value);
					}}
					className={styles.select}
					disabled={loading}
					optionFilterProp="children"
					filterOption={(input, option) => option.value.indexOf(input) >= 0}
				>
					{hsnCodes?.map((code) => (
						<Select.Option value={code.split(' -')[0]}>{code}</Select.Option>
					))}
				</Select>
			</Form.Item>
		</div>
	);
}
