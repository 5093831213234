import dayjs from 'dayjs';
import { DEFAULT_FILTER, DEFAULT_PAGE_CONFIG } from './interfaces';

export const defaultFilter: DEFAULT_FILTER = {
	startDate: dayjs().subtract(30, 'day'),
	endDate: dayjs()
};

export const defaultPageConfig: DEFAULT_PAGE_CONFIG = {
	current: 1,
	pageSize: 10
};

export enum WEBHOOK_EVENT_TYPE {
	LIGHTNING_STATUS_UPDATE = 'lightningStatus/update',
	OMS_STATUS_UPDATE = 'omsStatus/update'
}
