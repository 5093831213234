const { getFormattedShippingAddress, CUSTOMER_REQUEST_TYPE } = require('./constants');

export const ConfirmationModalContent = ({ requestType, newCustomerRequest }) => {
	if (requestType === 'UPDATE_DETAILS') {
		const address = getFormattedShippingAddress(newCustomerRequest.address);
		console.log({ address });

		const deferredDeliveryDate = new Date(newCustomerRequest?.deferredDeliveryDate?.toString()).toDateString();
		return (
			<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0.5rem' }}>
				<span>Name</span>
				<span>
					{newCustomerRequest?.address?.firstName} {newCustomerRequest?.address?.lastName}
				</span>

				<span>Preferred Delivery Date</span>
				<span>{deferredDeliveryDate}</span>

				<span>Alternate Number</span>
				<span>{newCustomerRequest?.alternatePhone}</span>

				<span>Address</span>
				<span>{address}</span>
			</div>
		);
	}
	if (requestType === CUSTOMER_REQUEST_TYPE.CANCEL_ORDER) {
		return (
			<>
				<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
					<span>Reason</span>
					<span>{newCustomerRequest.issueType.split('_').join(' ').toLowerCase()}</span>

					<span>Description</span>
					<span>{newCustomerRequest.issueDescription}</span>
				</div>
			</>
		);
	}
	return null;
};
