import { Form, Modal, notification } from 'antd';
import React, { useEffect } from 'react';
import reactDom from 'react-dom';
import { apiOms } from 'shared/api';
import errorHandler from 'shared/utils/errorHandler';
import { defaultFilter } from '../constants';
import { defaultPageConfig } from '../../../../../constants';
import PackageDetails from './PackageDetails';

export interface IPackageModal {
	setIsModalVisible?: any;
	isModalVisible?: any;
	getShippingPackages?: any;
	packageData?: {} | any;
	setEditPackageData?: any;
	setReloadCount?: any;
}
const PackageModal = ({
	setIsModalVisible,
	isModalVisible,
	getShippingPackages,
	packageData = {},
	setEditPackageData,
	setReloadCount
}: IPackageModal) => {
	const [form] = Form.useForm();
	const handleCancel = () => {
		reactDom.unstable_batchedUpdates(() => {
			setIsModalVisible(false);
			setEditPackageData && setEditPackageData({});
		});
	};

	useEffect(() => {
		if (packageData) {
			form.setFieldsValue(packageData);
			form.setFieldsValue({ default: packageData?.default ? '1' : '0', active: packageData?.active ? '1' : '0' });
		}
	}, [form]);

	const createPackageType = async (values: any) => {
		try {
			const { status } = await apiOms.post('/shipping-packages', { shippingPackage: values });
			if (status) {
				notification.success({
					message: 'Success'
				});
				reactDom.unstable_batchedUpdates(() => {
					setIsModalVisible(false);
					setEditPackageData?.({}); // setEditPackageData &&
				});
				getShippingPackages && (await getShippingPackages(defaultFilter, defaultPageConfig.pageSize, defaultPageConfig.current));
			}
		} catch (error) {
			errorHandler(error);
			setIsModalVisible(true);
		} finally {
			setReloadCount?.((prevCount: any) => prevCount + 1); // setReloadCount &&
		}
	};

	const editPackageType = async (values: any) => {
		try {
			const { status } = await apiOms.patch('/shipping-packages', { id: packageData.id, updatedData: values });
			if (status) {
				notification.success({
					message: 'Success'
				});
				reactDom.unstable_batchedUpdates(() => {
					setIsModalVisible(false);
					setEditPackageData?.({}); // setEditPackageData &&
				});
				getShippingPackages && (await getShippingPackages(defaultFilter, defaultPageConfig.pageSize, defaultPageConfig.current));
			}
		} catch (error) {
			errorHandler(error);
			setIsModalVisible(true);
		}
	};
	return (
		<Modal
			title={Object.keys(packageData).length > 0 ? 'Edit Package Type' : 'Create New Package'}
			visible={isModalVisible}
			onCancel={handleCancel}
			okText={Object.keys(packageData).length > 0 ? 'Save' : 'Create'}
			cancelText="Cancel"
			onOk={() => {
				form.validateFields().then((values) => {
					Object.keys(packageData).length > 0 ? editPackageType(values) : createPackageType(values);
				});
			}}
		>
			<Form layout={'vertical'} form={form} requiredMark={true} onFinish={createPackageType}>
				<PackageDetails packageData={packageData} />
			</Form>
		</Modal>
	);
};

export default PackageModal;
