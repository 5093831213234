import { ErrorBoundary } from '@sentry/react';
import { Col, notification, Row } from 'antd';
import { downloadAsFile } from 'helper';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import reactDom from 'react-dom';
import { useHistory } from 'react-router-dom';
import {
	downloadLablesAndMail,
	downloadMoreOrdersAndMail,
	downloadOrdersCSV,
	downloadOrdersPickList,
	getCompanyOrders
} from 'shared/api/orders';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { CatalogueInfoSlider } from 'shared/components/CatalogueInfoSlider';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { OrderInfoSlider } from 'shared/components/OrderInfoSlider';
import { bulkOrderPreviewRoute, MAX_ROWS_EXCEED_ERROR_MSG } from 'shared/components/UploadBulkPreview/constants';
import errorHandler from 'shared/utils/errorHandler';
import { EVENT_NAMES } from 'utils/analytics';
import { defaultPageConfig, ILocalStorageID, IQueueNames, ORDER_STATUS } from '../../../constants';
import { RequestEmailModal } from '../../../shared/components/EmailRequestModal';
import { NdrCustomerRequests } from '../../../shared/components/NdrCustomerRequests.js/CustomerRequests';
import { UploadBulkModal } from '../../../shared/components/UploadBulkModal';
import amplitude from '../../../shared/utils/Amplitude';
import { envs } from '../../../shared/utils/env';
import { Fallback } from '../ErrorBoundaryFallback';
import { useColumns } from './columns';
import { CommonSideBar } from './CommmonSideBar';
import { OrderPageOptions, orderPageOptionsWithCommonTable } from './constants';
import { MissedOrders } from './MissedOrders';
import { usePolling } from '../../../shared/hooks/usePolling';
import { getFilterFromSessionStorage, sidebarUtil, topFilterUtil } from './utils';
import { apiNms } from '../../../shared/api';
import { NdrInfoModal } from './components/NdrInfoModal';
export const orderRoute = '/orders';

const RenderCurrentTabTable = ({ tabIndex, tableType, children }: any) => {
	if (tableType === 'Orders' && orderPageOptionsWithCommonTable.includes(tabIndex)) {
		return children;
	}
	if (tableType === 'missed-order' && tabIndex === '2') {
		return children;
	}
	if (tableType === 'CustomerRequests' && tabIndex === '7') {
		return children;
	}
	return null;
};

export function Orders() {
	const [isUploadBulkOrderModalVisible, setIsUploadBulkOrderModalVisible] = useState(false);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [data, setData] = useState([]);
	const [currentCount, setCurrentCount] = useState(0);
	const [missedOrderCount, setMissedOrderCount] = useState(0);
	const [counts, setCounts] = useState<any>({});
	const [openOrderInfo, setOpenOrderInfo] = useState<boolean | null>(false);
	const [openProductOrderInfo, setOpenProductOrderInfo] = useState<boolean | null>(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [sidebarMenuItem, setSidebarMenuItem] = useState(() => sessionStorage.getItem('sidebarMenuItem') ?? '1');
	const [filter, setFilter] = useState<any>(getFilterFromSessionStorage);
	const [isEmailModalVisible, setIsEmailModalVisible] = useState<boolean | undefined>(false);
	const [labelEmailModalType, setLabelEmailModalType] = useState('');
	const [isDownloadButtonLoading, setIsDownloadButtonLoading] = useState(false);
	const [isOrdersLoading, setIsOrdersLoading] = useState(false);
	const [reloadOrders, setReloadOrders] = useState(0);
	const [customerId, setCustomerId] = useState('');
	const [customerRequestData, setCustomerRequestData] = useState({});

	const { trackQueueTaskIdInBulk, progress, isQTaskInProgress } = usePolling(IQueueNames.BULK_RTS, ILocalStorageID.BULK_RTS);
	const abortControllerRef = useRef<any>(null);
	const history = useHistory();
	const socketId = localStorage.getItem('socketId');

	const columns = useColumns({
		clickOrderId: setOpenOrderInfo,
		clickProductId: setOpenProductOrderInfo,
		sortField: pageConfig.sortField,
		sortOrder: pageConfig.sortOrder,
		setCustomerId: setCustomerId
	});
	const memoizedProgress = useMemo(() => progress, [progress]);
	const sidebarConfig = useMemo(() => sidebarUtil({ counts, currentCount, missedOrderCount }), [counts, currentCount, missedOrderCount]);
	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				counts,
				filter,
				setFilter,
				pageConfig,
				setPageConfigDefault,
				onDownloadOrders,
				onDownloadOrderPickList,
				sidebarMenuItem,
				selectedRowKeys,
				setPageConfig,
				qTaskTrackingId: trackQueueTaskIdInBulk,
				isQTaskInProgress,
				setLabelEmailModalType,
				setSelectedRowKeys,
				isUnServiceableSection: sidebarMenuItem === '2'
			}),
		[counts, filter, pageConfig, sidebarMenuItem, selectedRowKeys, isQTaskInProgress]
	);

	useEffect(() => {
		setSelectedRowKeys([]);
		// setting expiry time in 6h form now
		const rangeFilter = {
			expiresAt: new Date(new Date().getTime() + 21600000),
			filter: filter
		};
		sessionStorage.setItem('rangeFilter', JSON.stringify(rangeFilter));
	}, [filter]);

	useEffect(() => {
		const sideMenuIndex = Number(sidebarMenuItem);
		switch (sideMenuIndex) {
			case OrderPageOptions['All']:
				// we should not set default filters because filter might be coming from session
				// storage (set initially in usestate).. overwriting default filter to handle falsy value of filter obj
				setFilter((filter: any) => ({ ...filter, status: [] }));
				break;
			case OrderPageOptions['Unservicable']:
				setFilter((filter: any) => ({ ...filter, status: [ORDER_STATUS.UNSERVICEABLE] }));
				break;
			case OrderPageOptions['NDRs']:
				setFilter((filter: any) => ({ ...filter, status: [ORDER_STATUS.UNDELIVERED, ORDER_STATUS.RTO_UNDELIVERED] }));
				break;
			case OrderPageOptions['returns']:
				setFilter((filter: any) => ({
					...filter,
					status: [
						ORDER_STATUS.RTO_IN_TRANSIT,
						ORDER_STATUS.RTO_DELIVERED,
						ORDER_STATUS.RTO_OUT_FOR_DELIVERY,
						ORDER_STATUS.RTO_PROCESSED,
						ORDER_STATUS.DTO_IN_TRANSIT,
						ORDER_STATUS.DTO_DELIVERED,
						ORDER_STATUS.DTO_OUT_FOR_DELIVERY,
						ORDER_STATUS.DTO_PROCESSED
					]
				}));
				break;
			default:
				break;
		}
	}, [sidebarMenuItem]);

	useEffect(() => {
		async function getAllOrders() {
			try {
				setIsOrdersLoading(true);
				const { data: ordersData } = await getCompanyOrders(pageConfig, filter, abortControllerRef);
				if (ordersData.status) {
					reactDom.unstable_batchedUpdates(() => {
						setData(ordersData.order);
						setCounts(ordersData.count || {});
						setCurrentCount(ordersData.currentCount || 0);
						setMissedOrderCount(ordersData.missedOrderCount || 0);
						setIsOrdersLoading(false);
					});
					abortControllerRef.current = null;
				} else {
					throw new Error(ordersData.message || 'Failed to get orders');
				}
			} catch (error: any) {
				if (error.message === 'canceled') return;
				setIsOrdersLoading(false);
				notification.error({
					message: 'Error',
					description: error.message,
					placement: 'topRight'
				});
			}
		}
		getAllOrders();
		return () => abortControllerRef?.current?.abort();
	}, [pageConfig, filter, reloadOrders]);

	async function getCustomerRequest() {
		try {
			const {
				data: { data }
			} = await apiNms.get(`/customerRequests/find/${customerId}`);
			setCustomerRequestData(data);
		} catch (error) {
			errorHandler(error);
		}
	}

	function handleTableChange(_pagination: any, _filters: any, sorter: any) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'id',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	function setPageConfigDefault() {
		setPageConfig((v) => ({ ...v, ...defaultPageConfig }));
	}

	async function onDownloadOrders(data: any) {
		amplitude.getInstance().logEvent(EVENT_NAMES.DOWNLOAD_ORDER_EXCEL);
		try {
			if (filter?.status && counts[filter?.status] === 0) {
				throw new Error('There are no orders available for the selected filter');
			}

			const csvFilter = { ...filter, appType: envs.appType };

			const shouldEmailOpen = await downloadOrdersCSV(csvFilter, data);
			setIsEmailModalVisible(shouldEmailOpen);
		} catch (err) {
			errorHandler(err);
		}
	}

	async function onDownloadOrderPickList() {
		try {
			setIsOrdersLoading(true);

			if (!selectedRowKeys?.length) {
				throw new Error('Please select at least one order to generate pick list excel');
			}

			let orderPickListData = await downloadOrdersPickList(selectedRowKeys);
			downloadAsFile({ data: orderPickListData.data, fileName: `PickList`, fileType: 'xlsx' });
		} catch (err) {
			errorHandler(err);
		} finally {
			setIsOrdersLoading(false);
		}
	}

	const onOrdersFormSubmit = async (values: any) => {
		try {
			setIsDownloadButtonLoading(true);
			const csvFilter = { ...filter, appType: envs.appType };
			await downloadMoreOrdersAndMail(csvFilter, values.email, socketId);
		} catch (e) {
		} finally {
			setIsDownloadButtonLoading(false);
			setIsEmailModalVisible(false);
		}
	};

	const onLabelFormSubmit = async (values: any, type: any) => {
		try {
			setIsDownloadButtonLoading(true);
			await downloadLablesAndMail(selectedRowKeys, values.email, type, socketId);
		} catch (e) {
		} finally {
			setIsDownloadButtonLoading(false);
			setLabelEmailModalType('');
		}
	};

	useEffect(() => {
		customerId && getCustomerRequest();
	}, [customerId]);

	useEffect(() => {
		let tabValue;
		tabValue = Object.keys(OrderPageOptions).find((key) => OrderPageOptions[key] == sidebarMenuItem);
		history.push({
			pathname: '/orders',
			search: `?tab=${tabValue}`
		});
		sessionStorage.setItem('sidebarMenuItem', String(sidebarMenuItem));
	}, [sidebarMenuItem]);

	return (
		<Row className="p-0" style={{ height: '100%' }}>
			<RequestEmailModal
				title="Download Orders"
				visible={isEmailModalVisible}
				onCancel={() => {
					setIsEmailModalVisible(false);
				}}
				footer={[]}
				width={500}
				onFinish={onOrdersFormSubmit}
				instructionMessage="Requested data contains too many rows, please enter your email address to receive download url."
				loading={isDownloadButtonLoading}
			/>

			<RequestEmailModal
				title={`Download ${labelEmailModalType}s`}
				visible={Boolean(labelEmailModalType.length)}
				onCancel={() => {
					setLabelEmailModalType('');
				}}
				footer={[]}
				width={500}
				onFinish={(values) => onLabelFormSubmit(values, labelEmailModalType)}
				instructionMessage="Requested data contains too many orders, please enter your email address to receive url."
				loading={isDownloadButtonLoading}
			/>

			<UploadBulkModal
				instructionMessage={
					<ul className={'instructions'}>
						<li>Download the excel template by clicking "Download Sample" button below</li>
						<li>Edit file with order data and upload </li>
						<br />
						Note: {MAX_ROWS_EXCEED_ERROR_MSG}
					</ul>
				}
				templatePath={process.env.REACT_APP_EXCEL_ORDER_TEMPLATE}
				previewPath={bulkOrderPreviewRoute}
				modalTitle="Bulk Order Upload"
				isModalVisible={isUploadBulkOrderModalVisible}
				setIsModalVisible={setIsUploadBulkOrderModalVisible}
			/>
			<CommonSideBar
				sidebarMenuItem={sidebarMenuItem}
				setSidebarMenuItem={setSidebarMenuItem}
				isCommonOrder={true}
				sidebarConfig={sidebarConfig}
				setIsUploadBulkOrderModalVisible={setIsUploadBulkOrderModalVisible}
			/>

			<RenderCurrentTabTable tabIndex={sidebarMenuItem} tableType="Orders">
				<ErrorBoundary fallback={<Fallback fallBackMessage="Sorry, Failed to load data" />}>
					<Col sm={24} lg={20} className="main">
						<TopFilterComponent {...topFilterConfig} memoizedProgress={memoizedProgress} />
						<div className="innerDiv">
							<div className="div w-100 h-100">
								<CustomTablePagination
									columns={columns}
									data={data}
									showSorterTooltip={true}
									{...pageConfig}
									onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
									total={currentCount}
									onChange={handleTableChange}
									loading={isOrdersLoading}
									selectedRowKeys={selectedRowKeys}
									setSelectedRowKeys={setSelectedRowKeys}
									emptyTableMessage={'Nothing to display here'}
								/>
								{openProductOrderInfo && (
									<CatalogueInfoSlider catalogueId={openProductOrderInfo} onClose={() => setOpenProductOrderInfo(null)} />
								)}
								{openOrderInfo && (
									<OrderInfoSlider
										orderId={openOrderInfo}
										setReloadOrders={setReloadOrders}
										onClose={() => setOpenOrderInfo(null)}
									/>
								)}
							</div>
						</div>
					</Col>
				</ErrorBoundary>
			</RenderCurrentTabTable>
			<RenderCurrentTabTable tabIndex={sidebarMenuItem} tableType={'missed-order'}>
				<MissedOrders />
			</RenderCurrentTabTable>
			<RenderCurrentTabTable tabIndex={sidebarMenuItem} tableType="CustomerRequests">
				<NdrCustomerRequests isAdmin={false} />
			</RenderCurrentTabTable>
			{customerId && <NdrInfoModal data={customerRequestData} />}
		</Row>
	);
}
