import { Alert } from 'antd';
import { Fragment } from 'react';
import useInvoiceValidator from './useInvoiceValidator';

export const InvoiceValidator = () => {
	const { canIntegrateStore, hasActiveWarehouse, isValidInvoicing } = useInvoiceValidator();

	return (
		<Fragment>
			{!isValidInvoicing ? (
				<Alert style={{ marginBottom: '0.7rem' }} message="Complete Invoice Details to continue." type="error" showIcon />
			) : !hasActiveWarehouse ? (
				<Alert
					style={{ marginBottom: '0.7rem' }}
					message="Please contact Wherehouse team to associate a warehouse against your account."
					type="error"
					showIcon
				/>
			) : null}
		</Fragment>
	);
};
