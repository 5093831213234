import dayjs from 'dayjs';
import { ENmsMessageStatus, ENmsSenderType } from './constant';
import styles from './index.module.scss';
import { MessageBox } from './MessageBox';
import iphoneFrame from '../../../../images/iphone.png';

interface IChatUiInterface {
	message: {
		createdAt: string | Date;
		messageText: string;
		receiverPhone: string | number;
		senderPhone: string | number;
		senderType: string;
		status: ENmsMessageStatus;
		updatedAt: string | Date;
	}[];
}

export const ChatUi = ({ message }: IChatUiInterface) => {
	return (
		<>
			<div className={styles.cardStyles}>
				<img alt="" src={iphoneFrame} />
				<div className={styles.chatDiv}>
					{message.map((e, i) => (
						<>
							<MessageBox
								key={i}
								mobileNumber={e.senderPhone}
								time={dayjs(e.createdAt).format('hh:mm')}
								message={e.messageText.split('\n').join(' ')}
								boxPosition={e.senderType === ENmsSenderType.CUSTOMER ? 'left' : 'right'}
								backGroundColor={e.senderType === ENmsSenderType.CUSTOMER ? '#fff' : '#e1ffc7'}
								borderRadius={e.senderType === ENmsSenderType.CUSTOMER ? '0px 14px 7px 7px' : '14px 0px 7px 7px'}
							/>
						</>
					))}
				</div>
			</div>
		</>
	);
};
