export enum ENmsMessageStatus {
	CREATED = 'CREATED',
	SENT = 'SENT',
	FAILED = 'FAILED',
	RECEIVED = 'RECEIVED'
}

export enum ENmsSenderType {
	CUSTOMER = 'CUSTOMER',
	WHEREHOUSE = 'WHEREHOUSE',
	SELLER = 'SELLER'
}

export enum ISocketConstant {
	CONNECTION = 'connection',
	RESPONSE = 'response',
	ORDER_MESSAGE = 'orderMessages',
	STATUS_CHANGE = 'statusChangeInMessage',
	MESSAGE_UPDATE = 'messageUpdate',
	TASK_STATUS_CHANGE = 'taskStatusChange',
	NEW_SOCKET_ID = 'newSocketId',
	TASK_CENTER_PROGRESS_UPDATE = 'taskCenterProgressUpdate'
}
