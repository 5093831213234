export function isSortField(sortField, sortOrder, type) {
	if (sortField === type) {
		if (sortOrder === 'DESC') {
			return 'descend';
		} else {
			return 'ascend';
		}
	} else {
		return null;
	}
}

export function tableSorter(currentValue, previouseValue) {
	return previouseValue > currentValue ? 1 : -1;
}
