import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { getClusterOwners } from 'shared/api/lightning';
import { getUser, userColumns } from '../utils';

export function ClusterOwner() {
	const [clusterOwner, setClusterOwner] = useState([]);

	useEffect(() => {
		getUser({ setUser: setClusterOwner, getUser: getClusterOwners, userType: 'cluster owners' });
	}, []);

	return (
		<>
			<Table dataSource={clusterOwner} columns={userColumns({})} />
		</>
	);
}
