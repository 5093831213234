export default (
	<svg
		viewBox="64 64 896 896"
		focusable="false"
		class=""
		data-icon="wallet"
		width="18"
		height="18"
		fill="currentColor"
		aria-hidden="true"
	>
		<path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 464H528V448h312v128zm0 264H184V184h656v200H496c-17.7 0-32 14.3-32 32v192c0 17.7 14.3 32 32 32h344v200zM580 512a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"></path>
	</svg>
);
