import React, { useMemo } from 'react';
import GoogleMapReact from 'google-map-react';

import MarkerComponent from './MarkerComponent';

interface GMapReactProps {
	locations: Array<{
		lat: number;
		lng: number;
		text?: string;
		routeSequenceNumber?: number;
	}>;
}

/**
 * plots array of coordinates along with description on Google Map
 * @param props containing coordinates and correspoding description as array
 * @returns
 */
export default function PaintGMapWithMarkers(props: GMapReactProps): React.ReactElement<GMapReactProps> {
	// The Rule: default coords will be of Rashtrapati Bhawan
	const { locations } = props;

	const memoizedDefaultCenter = useMemo(() => {
		const defaultLat = locations ? locations[0]?.lat : 0;
		const defaultLng = locations ? locations[0]?.lng : 0;
		return { lat: defaultLat, lng: defaultLng };
	}, [locations]);

	if (!locations?.length) {
		return <></>;
	}

	return (
		<GoogleMapReact
			bootstrapURLKeys={{ key: String(process.env.REACT_APP_GMAP_API_KEY) }}
			defaultCenter={memoizedDefaultCenter}
			style={{ width: '100%', height: '100%' }}
			defaultZoom={11}
		>
			{locations.map(({ lat, lng, text, routeSequenceNumber }) => (
				<MarkerComponent
					lat={lat}
					lng={lng}
					routeSequenceNumber={routeSequenceNumber}
					position={{ lat, lng }}
					text={`${text || ''}`}
				/>
			))}
		</GoogleMapReact>
	);
}
