import NoCompany from 'app/AppAdmin/NoCompany/NoCompany';
import { Card } from 'antd';
import { GupshupCredentialsForm } from './GupshupCredentialsForm';
import { GupshupTemplates } from './GupshupTemplates';

export const WhatsAppService = ({ brand }) => {
	return (
		<>
			{brand.id ? (
				<div className="h-100 p-0" style={{ margin: '0 1rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
					<Card style={{ width: '100%' }}>
						<GupshupCredentialsForm companyId={brand.id} />
					</Card>

					<Card>
						<GupshupTemplates companyId={brand.id} />
					</Card>
				</div>
			) : (
				<NoCompany />
			)}
		</>
	);
};
