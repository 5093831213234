import { Button, Tag } from 'antd';
import styles from './utils.module.scss';

export const wsinColumns = () => [
	{
		title: 'Product Name',
		dataIndex: 'name'
	},
	{
		title: 'WSIN',
		dataIndex: 'wsin'
		// render: (v, data) => (
		// 	<div className={styles.skuDetail} style={{ overflowWrap: 'break-word !important' }}>
		// 		<div className={styles.wrapper}>
		// 			<Button
		// 				type="link"
		// 				onClick={(e) => {
		// 					e.preventDefault();
		// 					e.stopPropagation();
		// 				}}
		// 			>
		// 				<div className={styles.sub}>{data.wsin}</div>
		// 				<div className={styles.sub}>
		// 					{data.active === 1 ? <Tag color="cyan">Active</Tag> : <Tag color="magenta">Inactive</Tag>}
		// 				</div>
		// 			</Button>
		// 		</div>
		// 	</div>
		// )
	},
	{
		title: 'Active',
		dataIndex: 'active',
		render: (v, items) => {
			return items.active ? <Tag color="blue">Active</Tag> : <Tag color="red">In Active</Tag>;
		}
	}
];
