import styles from './index.module.scss';
import WherehouseLogo from '../../shared/svgs/trackingPageSvg/wherehouse_logo_svg.svg';
import RiderLogo from '../../shared/svgs/trackingPageSvg/rider_logo.svg';
import bottomLogoWithId from '../../shared/svgs/trackingPageSvg/bottom_scooter_logo.svg';
import { useLayoutEffect, useState } from 'react';
import { Button, notification } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { baseApi } from '../../shared/api';
import { commonTrackingLayout } from '../AppSeller/SettingsPage/CustomizeTrackingPage/utils';
import { locationSvg } from '../../shared/svgs/location_svg';
import { Header } from './components/Header';
import { Loading } from '../../shared/components/Loading';
import { ICommonTrackingRadioButton, ITrackingSearchByFields } from './utils';
import { SearchByButtons } from './components/RadioButtons';
import errorHandler from '../../shared/utils/errorHandler';
import { CustomTablePagination } from '../../shared/components/CustomTablePagination';
import { trackingColumn } from './components/TrackingColumn';
export const trackLandingRoute = '/track';

export const TrackLanding = () => {
	const [AWB, setAWB] = useState('');
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const search = useLocation().search;
	const encodedCompanyId = new URLSearchParams(search).get('t');
	const [brandsTrackingDesign, setBrandsDesign] = useState(() => commonTrackingLayout);
	const [orders, setOrders] = useState([]);
	const [radioButtonValue, setRadioButtonValue] = useState(() => ICommonTrackingRadioButton);
	const [buttonLoading, setButtonLoading] = useState(false);

	const handleInput = async () => {
		try {
			setButtonLoading(true);
			if (!AWB) {
				notification.error({
					message: 'Please Enter Tracking Number'
				});
				return;
			}
			if (!AWB || AWB.length < 3 || AWB.length > 40) {
				throw new Error('Invalid details');
			} else if (radioButtonValue.value !== 1) {
				const { data } = await baseApi.post(`/get-orders`, {
					getOrderDetailsBy: radioButtonValue.value,
					orderEmailOrPhone: AWB,
					xHash: encodedCompanyId
				});
				if (data?.status) {
					setOrders(data?.data);
				}
				return;
			} else {
				setLoading(true);
				setTimeout(() => {
					setLoading(false);
					history.push(`/track/${AWB}`);
				}, 2000);
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setButtonLoading(false);
		}
	};
	const handleEnter = (event) => {
		if (event.key === 'Enter') {
			handleInput();
		}
	};

	const handleRadioButtonChange = (event) => {
		const { value } = event.target;
		setRadioButtonValue({
			...radioButtonValue,
			name: ITrackingSearchByFields[value],
			value
		});
	};

	useLayoutEffect(() => {
		if (encodedCompanyId) {
			setLoading(true);
			const fetchBrandsData = async () => {
				try {
					const {
						data: { data }
					} = await baseApi.get(`/brand-profile/${encodedCompanyId}`);
					localStorage.setItem(`tracking-layout`, JSON.stringify(data));
					setBrandsDesign(data);
				} catch (error) {
				} finally {
					setLoading(false);
				}
			};
			fetchBrandsData();
		}
	}, [encodedCompanyId]);

	if (loading) {
		return <Loading loading={loading}></Loading>;
	}

	return encodedCompanyId ? (
		<>
			<Header NavbarHeight={'60vh'} brandsTrackingDesign={brandsTrackingDesign} />
			<section className={styles.bodySection} style={{ background: brandsTrackingDesign.pc }}>
				<div className={styles.layoutBody}>
					<div className={styles.searchCard}>
						<h1>Order Tracking</h1>
						<p> Enter your Order {radioButtonValue?.name} and track your package</p>
						<SearchByButtons color="white" handleChange={handleRadioButtonChange} defaultValue={radioButtonValue.value} />
						<div className={styles.inputField} style={{ border: `1px solid ${brandsTrackingDesign.pc}` }}>
							<input
								onChange={(e) => setAWB(e.target.value)}
								placeholder={`Enter Your ${radioButtonValue.name} here`}
								type={'text'}
								onKeyPress={handleEnter}
							/>
							<Button
								style={{ background: `${brandsTrackingDesign.pc}`, color: 'white' }}
								loading={buttonLoading}
								onClick={handleInput}
							>
								Track
							</Button>
						</div>
					</div>
				</div>
				{orders.length > 0 ? (
					<div className={styles.tableDiv}>
						<CustomTablePagination
							emptyTableMessage="No shipping packages yet!"
							columns={trackingColumn()}
							data={orders}
							shouldRowSelection={false}
							showPagination={false}
						/>
					</div>
				) : (
					<div className={styles.bottomLargeDiv}>
						<div style={{ color: `${brandsTrackingDesign.pc}` }}>{locationSvg}</div>
						<p>Enter Tracking details in the search box above to view order details</p>
					</div>
				)}
			</section>
		</>
	) : (
		<div className={`${styles.mainParentDiv} ${styles.parentDivWithImage}`}>
			<img src={WherehouseLogo} alt="Logo" className={styles.wherehouseLogo} />
			<div className={styles.rightRiderLogo}>
				<img alt="rider logo" src={RiderLogo} />
			</div>
			<div className={styles.rightSideBox}>
				<div className={styles.headingTop}>
					<h1>
						CONNECTING PEOPLE WITH WHEREHOUSE <span>LIGHTNING</span>
					</h1>
				</div>
				<div className={styles.searchBox} id={styles.searchBoxWithId}>
					<input
						onKeyDown={handleEnter}
						onChange={(e) => setAWB(e.target.value)}
						type="text"
						placeholder="Enter AWB Number Here"
					/>
					<Button loading={loading} onClick={handleInput}>
						Track
					</Button>
				</div>
			</div>
			<div className={styles.bottomScooterLogo} id={styles.bottomLogoWithId}>
				<img alt="bottom logo" src={bottomLogoWithId} />
			</div>
		</div>
	);
};
