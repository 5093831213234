import { useEffect, useState } from 'react';
import { Button, Col, List, Row, Select, notification } from 'antd';
import styles from './index.module.scss';

import { Loading } from 'shared/components/Loading';

import { ReactComponent as CloseIcon } from '../../svgs/closeIcon.svg';
import { InfoSlider } from '../InfoSlider/InfoSlider';
import errorHandler from 'shared/utils/errorHandler';

import { apiOms } from '../../api';

export function BeatsInfoSlider({ beatId, companyId, onClose }) {
	const [loading, setLoading] = useState(false);
	const [retailers, setRetailers] = useState([]);
	const [assignedRetailers, setAssignedRetailers] = useState([]);
	const [selectedRetailes, setSelectedRetailes] = useState([]);

	async function handleAddRetailers() {
		try {
			if (!selectedRetailes.length) {
				throw new Error('Please select atleast 1 retailer');
			}
			setLoading(true);
			const { data } = await apiOms.post(`/d2r/beat-retailer`, {
				beatId,
				retailerIds: selectedRetailes
			});

			notification.success({ message: data.message });
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
			fetchBeatRetailersDetails();
			setSelectedRetailes([]);
		}
	}

	async function handleUnassignRetailer(item) {
		try {
			setLoading(true);
			const { data } = await apiOms.delete(`/d2r/beat-retailer/?beatRetailerId=${item.id}`);

			notification.success({ message: data.message });
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
			fetchBeatRetailersDetails();
		}
	}

	async function fetchBeatRetailersDetails() {
		try {
			setLoading(true);
			const allRetailers = await apiOms.get(`/d2r/store-address/list/${companyId}`);
			const assignedRetailers = await apiOms.get(`/d2r/beat-retailer/?beatId=${beatId}`);
			setAssignedRetailers(assignedRetailers?.data?.beats);
			const assignedRetailerIds = assignedRetailers?.data?.beats.map((beat) => beat?.retailerId);
			setRetailers(allRetailers?.data?.storeAddressData.filter((retailer) => !assignedRetailerIds.includes(retailer.id)));
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchBeatRetailersDetails();
	}, [beatId]);

	return (
		<InfoSlider onClose={() => {}}>
			<div className={styles.main} style={{ width: '28rem' }}>
				{loading && <Loading loading={loading} />}
				<CloseIcon title="Close" className="closeBtn" onClick={onClose} />

				<Col className={styles.retailerContainer}>
					<Row className={styles.paddedRow}>
						<Col span={24}>
							<Row className={styles.row1}>
								<span className="title">Retailers</span>
							</Row>

							<Row>
								<strong>Add Retailers</strong>
							</Row>
							<Row style={{ margin: '1rem 0rem 2rem 0rem' }}>
								<Col span={16}>
									<span className="title">
										<Select
											mode={'tags'}
											placeholder="Please select"
											allowClear
											onChange={(retailers) => {
												setSelectedRetailes(retailers);
											}}
											style={{ width: '100%' }}
										>
											{retailers?.map((storeAddressData) => (
												<Select.Option key={storeAddressData.id} value={storeAddressData.id}>
													{storeAddressData?.firstName + storeAddressData?.lastName}
												</Select.Option>
											))}
										</Select>
									</span>
								</Col>
								<Button onClick={handleAddRetailers} style={{ marginLeft: '0.5rem' }}>
									{' '}
									Submit
								</Button>
							</Row>

							<Row>
								<strong>Assigned Retailers</strong>
							</Row>

							<Row style={{ margin: '0.5rem 0' }}>
								<List
									dataSource={assignedRetailers}
									renderItem={(item) => (
										<List.Item>
											<span>
												{item?.retailer?.firstName} {item?.retailer?.lastName}{' '}
											</span>
											<Button
												size="small"
												style={{ marginLeft: '2rem' }}
												onClick={() => handleUnassignRetailer(item)}
											>
												Un-assign
											</Button>
										</List.Item>
									)}
								/>
							</Row>
						</Col>
					</Row>
				</Col>
			</div>
		</InfoSlider>
	);
}
