import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { Sidebar } from '../../../shared/components/AppLayout/Sidebar';
import { SideDrawer } from '../../../shared/components/SideDrawer/SideDrawer';
import { EVENT_NAMES } from '../../../utils/analytics';
import amplitude from '../../../shared/utils/Amplitude';

export const CommonSideBar = ({ setSidebarMenuItem, sidebarMenuItem, isCommonOrder, setIsUploadBulkOrderModalVisible, sidebarConfig }) => {
	const history = useHistory();
	return (
		<SideDrawer placement="left">
			<Sidebar
				sideBarMenu={sidebarConfig?.sideBarMenu}
				bottomButtons={
					isCommonOrder && (
						<>
							<Button
								type="primary"
								size="large"
								onClick={() => {
									amplitude.getInstance().logEvent(EVENT_NAMES.APPSELLER_CREATE_ORDER);
									history.push('/orders/new');
								}}
							>
								Create Order
							</Button>
							<Button
								icon={<UploadOutlined />}
								type="primary"
								size="large"
								onClick={() => {
									amplitude.getInstance().logEvent(EVENT_NAMES.APPSELLER_UPLOAD_BULK_ORDER);
									setIsUploadBulkOrderModalVisible(true);
								}}
							>
								Upload Bulk Orders
							</Button>
						</>
					)
				}
				selectedMenu={sidebarMenuItem}
				onMenuItemSelect={setSidebarMenuItem}
			/>
		</SideDrawer>
	);
};
