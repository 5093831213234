import React, { useContext } from 'react';
import { Row, Col, notification } from 'antd';
import logoWhite from 'shared/images/logo_white.png';
import styles from './index.module.scss';
import { ProfileForm } from 'shared/components/ProfileForm';
import { AuthContext } from 'shared/contexts/Auth';
import { withRouter } from 'react-router-dom';
import { verifyOtpRoute } from 'app/AppSeller/VerifyOtp';
import { useHistory } from 'react-router-dom';

function SignUpComponent(props) {
	const {
		authState,
		authActions: { signUp }
	} = useContext(AuthContext);
	const history = useHistory();

	const onFinish = async (values) => {
		try {
			await signUp({ ...values }, authState.role);

			history.push({
				pathname: verifyOtpRoute,
				state: {
					attempt: 1
				}
			});
		} catch (err) {
			notification.error({
				message: 'Signup Error',
				description: err,
				placement: 'topRight'
			});
		}
	};
	return (
		<Row className="h-100">
			<Col span={8} id={styles.leftComponent}>
				<img src={logoWhite} alt="logo" className={styles.topLogoImage} />
				<div className={styles.leftTitle}>
					<p>Craft unmatched</p>
					<p>shopping experiences</p>
					<p>for your customers</p>
					<p className="t2">
						with <span>BETTER CONTROL</span>
					</p>
				</div>
			</Col>
			<Col lg={16} sm={24} id={styles.signupContainer}>
				<div className={styles.subTitle}>Welcome! Please Sign Up to continue</div>

				<div className={styles.profileFormContainer}>
					<ProfileForm onFinish={onFinish} isSignup={true} isWms={authState.role === 'warehouse'} />
				</div>
			</Col>
		</Row>
	);
}
export const SignUp = withRouter(SignUpComponent);
export const signupRoute = '/signup';
