import reactDom from 'react-dom';
import { Input, Select } from 'antd';
import debounce from 'utils/debounce';
import { useRef } from 'react';

export interface IPropDebounceSearch {
	children?: any;
	debounceTime?: number;
	defaultSearchOptionType?: any;
	searchOptions?: any;
	setDefault?: any;
	setFilter?: React.Dispatch<React.SetStateAction<any>> | Function;
	selectStyle?: { [x: string]: string };
	inputStyle?: { [x: string]: string };
	placholder?: string;
	className?: string;
	[x: string]: any;
}
export function DebouncedCompactSearch({
	children,
	debounceTime = 500,
	defaultSearchOptionType,
	searchOptions,
	setDefault = function () {
		return;
	},
	setFilter = function () {
		return;
	},
	selectStyle = { minWidth: '150px' },
	inputStyle = { width: '150px' },
	placholder = 'Enter Value'
}: IPropDebounceSearch) {
	const searchRef = useRef<any>(null);

	function handleSearchOptionChange(updatedSearchOptionType: any) {
		setDefault();
		setFilter((prevFilter: any) => ({
			...prevFilter,
			searchOptionType: updatedSearchOptionType,
			searchValue: searchRef.current?.input?.value || ''
		}));
	}

	const debouncedSearchChange = debounce(function ({ target: { value } }: any) {
		reactDom.unstable_batchedUpdates(function () {
			setDefault();
			setFilter((prevFilter: any) => ({ ...prevFilter, searchValue: value }));
		});
	}, debounceTime);

	return (
		<>
			{children}
			<Input.Group compact style={{ display: 'flex' }}>
				<Select defaultValue={defaultSearchOptionType} onChange={handleSearchOptionChange} style={selectStyle}>
					{searchOptions?.map(({ label, value }: any) => (
						<Select.Option key={value} value={value}>
							{label}
						</Select.Option>
					))}
				</Select>
				<Input allowClear onChange={debouncedSearchChange} placeholder={placholder} style={inputStyle} ref={searchRef} />
			</Input.Group>
		</>
	);
}
