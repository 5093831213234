export const TrailData = [
	{
		createdAt: '2022-01-03T06:41:57.176Z',
		status: 'PLACED',
		description: 'Order Placed',
		city: null
	},
	{
		createdAt: '2022-01-03T06:44:33.103Z',
		status: 'READY_TO_SHIP',
		description: 'Ready to ship from P14,Arihant Hospital, Sector 52, Gurgaon, Haryana, India having AWB I30023105579',
		city: null
	},
	{
		createdAt: '2022-01-04T14:22:38.532Z',
		status: 'IN_TRANSIT',
		description: 'Order in transit',
		city: null
	},
	{
		createdAt: '2022-01-05T10:23:46.531Z',
		status: 'OUT_FOR_DELIVERY',
		description: 'Order out for delivery',
		city: null
	},
	{
		createdAt: '2022-01-05T13:47:40.179Z',
		status: 'UNDELIVERED',
		description: 'Order undelivered Reason: Undelivered due to RECEIVER NOT AVAILABLE-(CIR)',
		city: null
	},
	{
		createdAt: '2022-01-06T12:25:41.178Z',
		status: 'DELIVERED',
		description: 'Order delivered',
		city: null
	}
];
