import NoCompany from 'app/AppAdmin/NoCompany/NoCompany';
import { Catalog } from 'shared/components/Catalog';

export const Products = ({ brand, setAdminCatalogueSelectedKeys, adminCatalogueSelectedKeys }) => {
	return (
		<>
			{brand.id ? (
				<Catalog
					isAdminView={true}
					companyId={brand?.id}
					setAdminCatalogueSelectedKeys={setAdminCatalogueSelectedKeys}
					adminCatalogueSelectedKeys={adminCatalogueSelectedKeys}
				/>
			) : (
				<NoCompany />
			)}
		</>
	);
};
