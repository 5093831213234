import { Row, Button } from 'antd';
import { orderStatusColor } from 'shared/utils/orderStatus';
import { REVERSE_ORDER_STATUS_TYPES } from '../constants';

export function useColumns({ clickOrderId }) {
	return [
		{
			title: 'Date',
			dataIndex: 'createdAt',
			render: (text) => <div className="text-center">{new Date(text).toLocaleDateString()}</div>,
			width: 150,
			sorter: true
		},
		{
			title: 'AWB',
			dataIndex: 'awb',
			width: 200,
			render: (awb) => (
				<Row justify="center">
					<Button
						type="link"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							clickOrderId(awb);
						}}
					>
						{awb}
					</Button>
				</Row>
			)
		},
		{
			title: 'Order Status',
			dataIndex: 'orderStatus',
			render: (orderStatus) => (
				<div className="text-center" style={{ color: `${orderStatusColor[REVERSE_ORDER_STATUS_TYPES[orderStatus]]}` }}>
					{REVERSE_ORDER_STATUS_TYPES[orderStatus]}
				</div>
			)
		},
		{
			title: 'OTP',
			dataIndex: 'otp',
			render: (otp) => <div className="text-center">{otp}</div>
		},
		{
			title: 'Attempt',
			dataIndex: 'attempt',
			render: (attempt) => <div className="text-center">{attempt}</div>
		}
	];
}
