import { Button, Col, Form, notification, Popconfirm, Select } from 'antd';
import React, { useContext, useState } from 'react';
import { cloneOrder, regenerateInvoiceAndLabel, revertOrderStatusToPlaced } from '../../../../api/orders';
import { AuthContext } from '../../../../contexts/Auth';
import { envs } from '../../../../utils/env';
import errorHandler from '../../../../utils/errorHandler';
import { ORDER_STATUS } from '../../../../../constants';
import { useHistory } from 'react-router-dom';

enum ORDER_ACTIONS {
	CLONE_ORDER = 'Clone Order',
	CLONE_EDIT_ORDER = 'Clone & Edit Order',
	REGENERATE_INVOICE_LABEL = 'Regenerate Invoice and Shipping Label',
	SHOW_WHATSAPP_MESSAGES = 'Show Whatsapp messages',
	GENERATE_CN_DN = 'Generate Credit and Debit Note',
	REVERT_ORDER_STATUS_TO_PLACED = 'Revert Order Status To PLACED',
	CHANGE_ITEMS_QUANTITY = 'Update order Items Quantity'
}
export interface IOrderActions {
	balance?: number;
	orderId: string;
	setReloadOrders: React.Dispatch<React.SetStateAction<number>>;
	setIsWhatsappChatVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setIsCreditDebitNodeModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setIsPaidAmountFormVisible?: React.Dispatch<React.SetStateAction<boolean>>;
	setUpdateItemsQtyModal: React.Dispatch<React.SetStateAction<boolean>>;
	isWhatsappChatVisible: boolean;
	isD2rOrder: boolean;
	shouldCapturePayment?: boolean;
	isCDNAlreadyGenerated: string | boolean;
	order: any;
	isAdminPanel?: boolean;
}
export default function OrderActions({
	orderId,
	setReloadOrders,
	setIsWhatsappChatVisible,
	isWhatsappChatVisible,
	setIsCreditDebitNodeModalVisible,
	isD2rOrder,
	isCDNAlreadyGenerated,
	setUpdateItemsQtyModal,
	order,
	isAdminPanel = false
}: Readonly<IOrderActions>) {
	const [form] = Form.useForm();
	const [submitButtonLoading, setSubmitButtonLoading] = useState<boolean>(false);
	const [isPaidAmountFormVisible, setIsPaidAmountFormVisible] = useState(false);
	const { authState } = useContext(AuthContext);
	const history = useHistory();

	const isNdrActive = JSON.parse(authState?.['profile']['waModules'])?.['ndr']['isActive'];

	const commonActions = {
		[ORDER_ACTIONS.CLONE_ORDER]: async function (orderID: string) {
			try {
				setSubmitButtonLoading(true);
				const { data } = await cloneOrder(orderID);
				notification.success({
					message: 'Clone Order',
					description: data.message,
					placement: 'topRight'
				});
			} catch (error) {
				errorHandler(error);
			} finally {
				setSubmitButtonLoading(false);
				setReloadOrders((v: number) => v + 1);
			}
		},
		[ORDER_ACTIONS.CLONE_EDIT_ORDER]: async function () {
			try {
				setSubmitButtonLoading(true);
				history.push({
					pathname: '/orders/new',
					state: order
				});
			} catch (error) {
				errorHandler(error);
			} finally {
				setSubmitButtonLoading(false);
				setReloadOrders((v: number) => v + 1);
			}
		},
		[ORDER_ACTIONS.REGENERATE_INVOICE_LABEL]: async function (orderID: string) {
			try {
				setSubmitButtonLoading(true);
				const { data } = await regenerateInvoiceAndLabel(orderID);
				if (data.status) {
					notification.success({
						message: 'Successfully regenerated Invoice and Label',
						description: data.message,
						placement: 'topRight'
					});
				} else {
					throw new Error(data.message || 'Failed to regenerate invoice and label');
				}
			} catch (error) {
				errorHandler(error);
			} finally {
				setSubmitButtonLoading(false);
				setReloadOrders((v: number) => v + 1);
			}
		},
		[ORDER_ACTIONS.SHOW_WHATSAPP_MESSAGES]: function () {
			setIsWhatsappChatVisible(true);
		}
	};

	const adminActions = {
		[ORDER_ACTIONS.REVERT_ORDER_STATUS_TO_PLACED]: async function (orderID: string) {
			try {
				setSubmitButtonLoading(true);
				const { data } = await revertOrderStatusToPlaced(orderID);
				if (data.status) {
					notification.success({
						message: 'Successfully changed order status to PLACED',
						description: data.message,
						placement: 'topRight'
					});
				} else {
					throw new Error(data.message || 'Failed to bring back order status to PLACED');
				}
			} catch (error) {
				errorHandler(error);
			} finally {
				setSubmitButtonLoading(false);
				setReloadOrders((v: number) => v + 1);
			}
		},
		[ORDER_ACTIONS.CHANGE_ITEMS_QUANTITY]: function () {
			setUpdateItemsQtyModal(true);
		},
		[ORDER_ACTIONS.GENERATE_CN_DN]: function () {
			setIsCreditDebitNodeModalVisible(true);
		}
	};

	const handleOnClear = () => {
		if (isWhatsappChatVisible) {
			setIsWhatsappChatVisible(false);
		}
	};

	const returnActionsObject = () => {
		if (envs.appType === 'AppAdmin') {
			return { ...commonActions, ...adminActions };
		}

		return commonActions;
	};

	const actions = returnActionsObject();

	return (
		<Col span={24}>
			<Form form={form}>
				<Form.Item name="actions" rules={[{ required: true, message: 'Order actions' }]}>
					<Select
						onChange={() => {
							if (isPaidAmountFormVisible) setIsPaidAmountFormVisible(false);
						}}
						onClear={handleOnClear}
						placeholder="Order actions"
						allowClear
						style={{ width: '100%' }}
					>
						{Object.keys(actions).map((action) => {
							if (action === ORDER_ACTIONS.SHOW_WHATSAPP_MESSAGES && !isNdrActive) {
								return null;
							}
							if (action === ORDER_ACTIONS.GENERATE_CN_DN && (!isD2rOrder || isCDNAlreadyGenerated)) {
								return null;
							}
							if (action === ORDER_ACTIONS.CHANGE_ITEMS_QUANTITY && order?.status !== ORDER_STATUS.PLACED) {
								return null;
							}
							return (
								<Select.Option value={action} key={action}>
									{action}
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item noStyle shouldUpdate={(previousValue, currentValue) => previousValue.actions !== currentValue.actions}>
					{({ getFieldValue }) => {
						if (getFieldValue('actions') === ORDER_ACTIONS.SHOW_WHATSAPP_MESSAGES) {
							const actionHandler = actions[getFieldValue('actions') as keyof typeof actions];
							actionHandler('');
							return null;
						}
						return getFieldValue('actions') !== undefined ? (
							<Popconfirm
								title="Are you sure?"
								okText="Yes"
								cancelText="No"
								onConfirm={() => actions[getFieldValue('actions') as keyof typeof actions](orderId)}
							>
								<Button
									style={{ width: '100%' }}
									type="primary"
									size="middle"
									shape="round"
									htmlType="button"
									loading={submitButtonLoading}
								>
									Submit
								</Button>
							</Popconfirm>
						) : null;
					}}
				</Form.Item>
			</Form>
		</Col>
	);
}
