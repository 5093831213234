import { useEffect, useState } from 'react';
import { getCompanyWarehouses } from '../../../../shared/api/warehouse';
import errorHandler from '../../../../shared/utils/errorHandler';
import { WarehouseCard } from '../../../AppSeller/SettingsPage/Warehouses/components/WarehouseCard';
import { AttachWhForm } from './AttachWhForm';
import styles from './index.module.scss';

export function Warehouses({ brand }) {
	const [attachedWarehouses, setAttachedWarehouses] = useState([]);
	const [reloadAttachedWarehouses, setReloadAttachedWarehouses] = useState(0);

	const getWarehouses = async (brandId) => {
		try {
			const {
				data: { warehouses }
			} = await getCompanyWarehouses(brandId);
			setAttachedWarehouses(warehouses);
		} catch (error) {
			errorHandler(error);
		}
	};

	useEffect(() => {
		getWarehouses(brand.id);
	}, [brand.id, reloadAttachedWarehouses]);

	return (
		<div>
			<div className={styles.attachmentForm}>
				<AttachWhForm companyId={brand.id} reload={() => setReloadAttachedWarehouses((prev) => prev + 1)} />
			</div>

			<div className={styles.warehousesList}>
				{attachedWarehouses.map((warehouse, index) => {
					return (
						<div key={index} style={{ background: 'white' }}>
							<WarehouseCard warehouse={{ ...warehouse, index: index + 1 }} />
						</div>
					);
				})}
			</div>
		</div>
	);
}
