import { Badge, Table, TableColumnsType } from 'antd';
import styles from './index.module.scss';
interface ExpandedDataType {
	key: React.Key;
	date: string;
	name?: string;
	upgradeNum: string;
	Quantity?: number;
}

export const STATUS_WISE_ROW_COLORS = {
	Packed: 'greenRow',
	Processing: 'blueRow',
	Pending: 'redRow'
};

export const expandedRowRender = ({ items_to_scan }: any) => {
	const columns: TableColumnsType<ExpandedDataType> = [
		{ title: 'WSIN', dataIndex: 'wsin', key: 'wsin' },
		{ title: 'Item Name', dataIndex: 'name', key: 'name' },
		{
			title: 'Quantity To Scan',
			dataIndex: 'qty',
			key: 'name',
			render: (data) => <Badge className="site-badge-count-109" count={data || 0} style={{ backgroundColor: '#52c41a' }} />
		},
		{
			title: 'Scanned Quantity',
			key: 'name',
			render: (data) => {
				return (
					<Badge
						style={{
							backgroundColor: data?.scanned_qty === data?.qty ? '#52c41a' : data?.scanned_qty === 0 ? 'red' : 'geekblue'
						}}
						showZero
						count={data?.scanned_qty || 0}
					/>
				);
			}
		},
		{
			title: 'Status',
			key: 'state',
			render: (data) => {
				return (
					<Badge
						status={data?.scanned_qty === data?.qty ? 'success' : data?.scanned_qty === 0 ? 'error' : 'processing'}
						text={data?.scanned_qty === data?.qty ? 'Scanned' : data?.scanned_qty === 0 ? 'Not Scanned' : 'Partially Scanned'}
					/>
				);
			}
		}
	];
	return (
		<>
			<Table
				columns={columns}
				rowClassName={(data: any) =>
					styles[
						STATUS_WISE_ROW_COLORS[
							data?.scanned_qty === 0 ? 'Pending' : data?.scanned_qty < data?.qty ? 'Processing' : 'Packed'
						]
					]
				}
				dataSource={items_to_scan || []}
				pagination={false}
			/>
		</>
	);
};
