function reverseObject(obj) {
	return Object.freeze(Object.entries(obj).reduce((accumulator, [key, value]) => ({ ...accumulator, [value]: key }), {}));
}

export const CLUSTER_MAPPING = {
	'001': 'C1',
	'002': 'C2',
	'003': 'C3',
	'004': 'C4',
	'005': 'C5',
	'006': 'C6'
};

export const ORDER_STATUS_TYPES = {
	PLACED: 1,
	PICKED_UP: 2,
	RECEIVED: 3,
	ASSIGNED: 4,
	OUT_FOR_DELIVERY: 5,
	UNDELIVERED: 6,
	DELIVERED: 7,
	RTO_DELIVERED: 8,
	UNATTEMPTED: 9,
	UNASSIGNED: 10,
	RTO_IN_TRANSIT: 11,
	DTO_PLACED: 12,
	DTO_IN_TRANSIT: 13,
	DTO_PICKED: 14,
	DTO_RECEIVED: 15,
	DTO_DELIVERED: 16,
	DTO_LOST: 17,
	CANCELLED: 18
};

export const JOURNEY_STATUS = {
	IN_PROGRESS: '1',
	ON_HOLD: '2',
	COMPLETED: '3'
};

export const REVERSE_ORDER_STATUS_TYPES = reverseObject(ORDER_STATUS_TYPES);

export const REVERSE_CLUSTER_MAPPING = reverseObject(CLUSTER_MAPPING);

export const REVERSE_JOURNEY_STATUS_MAPPING = reverseObject(JOURNEY_STATUS);

export const DRIVER_STATUS_MAPPING = {
	1: 'Idle',
	2: 'Orders Assigned',
	3: 'In Journey'
};

export const journeyStatusColor = {
	IN_PROGRESS: '#facc15',
	ON_HOLD: '#84cc16',
	COMPLETED: 'rgba(149, 191, 70, 1)'
};
