export const CUSTOMER_REQUEST_TYPE = Object.freeze({
	UPDATE_DETAILS: 'UPDATE_DETAILS',
	CANCEL_ORDER: 'CANCEL_ORDER'
});

export const ISSUE_TYPE = Object.freeze({
	ORDER_PLACED_BY_MISTAKE: 'ORDER_PLACED_BY_MISTAKE',
	AVAILABLE_FOR_LOWER_PRICE: 'AVAILABLE_FOR_LOWER_PRICE',
	CHANGED_MY_MIND: 'CHANGED_MY_MIND',
	NO_LONGER_NEEDED: 'NO_LONGER_NEEDED',
	OTHER: 'OTHER'
});

export const FORM_LINK_STATUS = Object.freeze({
	SENT: 'SENT',
	FILLED: 'FILLED',
	VISITED: 'VISITED'
});

export const getFormattedShippingAddress = (address) => {
	let result = '';

	if (!address) {
		return 'NA';
	}
	if (typeof address === 'object') {
		const { addressLine1, addressLine2, city, state, country } = address;
		result += [addressLine1, addressLine2, city, state, country].join(', ');
	} else {
		result = address;
	}

	return result;
};
