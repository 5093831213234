import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const antIcon = <LoadingOutlined style={{ fontSize: 38 }} spin />;

export default function Spinner() {
	return (
		<div
			style={{
				position: 'absolute',
				top: '40%'
			}}
		>
			<Spin indicator={antIcon} />
		</div>
	);
}
