import { Button } from 'antd';
import { envs } from '../../../../shared/utils/env';
import { DebouncedCompactSearch } from '../../../../shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import { companyType } from '../D2r';
import { jsxElementKeys } from '../../../../shared/utils/constant';

const { omsBaseUrl } = envs;
interface IRetailersFilter {
	company: companyType;
	setPageConfigDefault: any;
	setFilter: any;
	filter: any;
}

export const searchOptions: any = [
	{ value: 'storeName', label: 'store name' },
	{ value: 'phone', label: 'phone' }
];

export interface ICommonFilterRetail {
	searchOptionType: string;
	searchValue?: string;
}
export const defaultFilter: ICommonFilterRetail = {
	searchOptionType: searchOptions[0].value
};

export const retailersFilter = ({ company, setPageConfigDefault, setFilter, filter }: IRetailersFilter) => {
	return {
		row1: [],
		row1children: [
			<div key={jsxElementKeys.SEARCH_CONTAINER}>
				<DebouncedCompactSearch
					defaultSearchOptionType={filter.searchOptionType}
					searchOptions={searchOptions}
					setFilter={setFilter}
					setDefault={setPageConfigDefault}
				>
					<span>Search By:</span>
				</DebouncedCompactSearch>
			</div>
		],
		row2: [
			<div key={'3'} style={{ display: 'flex', gap: '1rem' }}>
				<Button disabled={!company.id} type="primary" onClick={() => handleDownloadRetailers(company.id)}>
					Download Retailers
				</Button>
			</div>
		]
	};
};

export const retailerColumn = (setIsRetailerModalVisible: Function, setRetailerId: Function) => [
	{
		title: 'Name',
		render: (data: any) => (
			<Button
				type="link"
				onClick={() => {
					setIsRetailerModalVisible(true);
					setRetailerId(data?.id);
				}}
			>
				<span>{`${data?.firstName} ${data?.lastName}`}</span>
			</Button>
		)
	},
	{
		title: 'Email',
		render: (data: any) => <span>{data?.email}</span>
	},
	{
		title: 'Phone',
		render: (data: any) => <span>{data?.phone}</span>
	},
	{
		title: 'GSTIN',
		render: (data: any) => <span>{data?.GSTIN}</span>
	},
	{
		title: 'Address',
		render: (data: any) => (
			<span>{`${data?.addressLine1}, ${data.addressLine2}, ${data?.city}, ${data.state}, ${data.country}, ${data.pincode}`}</span>
		)
	}
];

export function handleDownloadRetailers(companyId: null | string) {
	const url = new URL(`${omsBaseUrl}/csv/d2r/retailers/${companyId}`);
	window.open(url);
}
