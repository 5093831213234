export const formatInPercentage = (lastCount: number, currentCount: number) => {
	if (!lastCount && !currentCount) return 0;
	if (!lastCount) return 100;
	const result = ((currentCount - lastCount) / lastCount) * 100;
	return Number(result.toFixed(2));
};

export const convertToInternationalCurrencySystem = (labelValue: number | string) => {
	// Nine Zeroes for Billions
	return Math.abs(Number(labelValue)) >= 1.0e9
		? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
		: // Six Zeroes for Millions
		Math.abs(Number(labelValue)) >= 1.0e6
		? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
		: // Three Zeroes for Thousands
		Math.abs(Number(labelValue)) >= 1.0e3
		? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
		: Math.abs(Number(labelValue));
};
