import { Button, Input, Menu, Dropdown, notification, Spin } from 'antd';
import { STORE_LOGOS, WORDPRESS, UNICOMMERCE, EASYECOM, instructionLink, SHIPWAY } from '../../../../../../../constants';
import { useState } from 'react';
import { postIntegrateStore } from 'shared/api/settings';
import styles from './index.module.scss';
import errorHandler from 'shared/utils/errorHandler';
import useInvoiceValidator from 'shared/components/InvoiceValidator/useInvoiceValidator';
import { EVENT_NAMES } from 'utils/analytics';
import amplitude from '../../../../../../../shared/utils/Amplitude';
import { LoadingOutlined } from '@ant-design/icons';

const icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const menuItems = [
	{ label: 'Sync Catalogue', value: 'catalogue' },
	{ label: 'Sync Orders', value: 'order' }
];

const salesChannelFields: any = {
	shopify: {
		shop: 'Shop',
		key: 'API Key',
		secret: 'API Secret',
		webhook_client_id: 'Integrity Verifier'
	},
	wordpress: {
		shop: 'Shop',
		key: 'API Key',
		secret: 'API Secret'
	},
	unicommerce: {
		shop: 'Tenant',
		key: 'Username',
		secret: 'Password',
		webhook_client_id: 'Facility Code',
		uc_client_id: 'Client ID',
		shipping_provider_code: 'Shipping Provider Code'
	},
	easyecom: {
		key: 'User ID',
		secret: 'Password',
		webhook_client_id: 'Carrier ID'
	},
	shipway: {
		key: 'Email',
		secret: 'Licence Key'
	},
	amazon: {
		key: 'API Key'
	}
};

const menu = (shopType: any, syncData: any) => {
	return (
		<Menu>
			{menuItems.map(({ label, value }, index) => {
				return (
					<Menu.Item key={value}>
						<Button type="text" size="small" onClick={() => syncData(shopType, value)}>
							{label}
						</Button>
					</Menu.Item>
				);
			})}
		</Menu>
	);
};

export function IntegrationsCard({ _visibleFields, syncOrderData, isSyncLoading, syncShopType, ...props }: any) {
	const [isConnectButtonLoading, setIsConnectButtonLoading] = useState(false);
	const [showApiKeyInputBox, setShowApiKeyInputBox] = useState(false);
	const [currentSalesChannel, setCurrentSalesChannel] = useState({ ...props.salesChannel });
	const { canIntegrateStore } = useInvoiceValidator();

	const handleConnectClick = async () => {
		try {
			setIsConnectButtonLoading(true);
			if (!showApiKeyInputBox) {
				return setShowApiKeyInputBox(true);
			}

			if (!canIntegrateStore) {
				throw new Error('Please complete Invoicing section to proceed.');
			}

			// this code is not needed
			let payload: any = {
				key: currentSalesChannel['key'],
				type: currentSalesChannel['type'],
				shop: currentSalesChannel['shop'],
				secret: currentSalesChannel['secret'],
				webhook_client_id: currentSalesChannel['webhook_client_id']
			};

			if (currentSalesChannel['type'] === WORDPRESS) {
				amplitude.getInstance().logEvent(EVENT_NAMES.WORDPRESS_CONNECT);
				payload = {
					...payload,
					webhook_client_id: currentSalesChannel['secret']
				};
			}

			if (currentSalesChannel['type'] === UNICOMMERCE) {
				amplitude.getInstance().logEvent(EVENT_NAMES.UNICOMMERCE);
				payload = {
					...payload,
					uc_client_id: currentSalesChannel['uc_client_id'],
					shipping_provider_code: currentSalesChannel['shipping_provider_code']
				};
			}

			if (currentSalesChannel['type'] === EASYECOM) {
				amplitude.getInstance().logEvent(EVENT_NAMES.EASYECOM);
				payload = {
					...payload,
					shop: currentSalesChannel['key']
				};
			}

			if (currentSalesChannel['type'] === SHIPWAY) {
				amplitude.getInstance().logEvent(EVENT_NAMES.SHIPWAY);
				payload = {
					...payload,
					shop: currentSalesChannel['key'],
					webhook_client_id: currentSalesChannel['secret']
				};
			}

			await postIntegrateStore(payload);

			notification.success({
				message: 'Success',
				description: 'Store integrated successfully.',
				placement: 'topRight'
			});
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsConnectButtonLoading(false);
		}
	};

	const handleFormInputChange = (event: any) => {
		const fieldName = event.target.name;

		setCurrentSalesChannel((currentSalesChannel: any) => ({
			...currentSalesChannel,
			[fieldName]: event.target.value
		}));
	};

	const toggleAccordion = (e: any) => {
		e.preventDefault();
		setShowApiKeyInputBox(!showApiKeyInputBox);
	};

	const syncData = async (shopType: any, type: any) => {
		if (type === 'order') {
			await syncOrderData(shopType);
		}
	};

	return (
		<div className={styles.integrationsCardOuterContainer}>
			<div className={styles.integrationsCardPrimaryContent}>
				<div className={styles.imageContainer}>
					<img className={styles.partnerImage} src={STORE_LOGOS[currentSalesChannel.type]} alt={currentSalesChannel.type} />
				</div>

				<div className={styles.content}>
					<div className={styles.left}>
						<div>
							<h6 className={styles.partnerName}>{currentSalesChannel.type}</h6>
							{instructionLink[currentSalesChannel.type] ? (
								<a href={instructionLink[currentSalesChannel.type]} target="__blank">
									Instructions
								</a>
							) : (
								<div></div>
							)}
						</div>

						<Button size="small" type="primary" style={{ borderRadius: '0.25rem', width: '5rem' }} onClick={toggleAccordion}>
							{showApiKeyInputBox ? 'Hide' : 'Show'}
						</Button>
					</div>

					<div className={styles.right}>
						{isSyncLoading && syncShopType === currentSalesChannel.type ? (
							<Spin indicator={icon} />
						) : (
							<Dropdown overlay={menu(currentSalesChannel.type, syncData)} placement="bottomLeft">
								<Button size="small" type="text" className={styles.dropDownText}>
									Sync Data ▼
								</Button>
							</Dropdown>
						)}

						{/* <Switch checkedChildren="ON" unCheckedChildren="OFF" checked onChange={handleSwitchChange} /> */}
					</div>
				</div>
			</div>

			{showApiKeyInputBox && (
				<div className={styles.integrationsCardSecondaryContent}>
					<form>
						{Object.entries(salesChannelFields[currentSalesChannel.type]).map(([name, label]: any) => {
							return (
								<div className={styles.fieldContainer} key={name}>
									<span>{label}</span>
									<Input
										required
										name={name}
										placeholder="XXX-XXX-XXX"
										value={currentSalesChannel[name]}
										onChange={handleFormInputChange}
									/>
								</div>
							);
						})}
						<div className={styles.submit}>
							<Button
								size="small"
								type="primary"
								style={{ borderRadius: '0.25rem' }}
								onClick={handleConnectClick}
								loading={isConnectButtonLoading}
							>
								Connect
							</Button>
						</div>
					</form>
					{/* <a href="/" className={styles.connectAccount}>
						How do I connect my own account?
					</a> */}
				</div>
			)}
		</div>
	);
}
