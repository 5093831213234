import { Col, Row } from 'antd';
import NoCompany from 'app/AppAdmin/NoCompany/NoCompany';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { getWSINData } from 'shared/api/catalog';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { wsinColumns } from './columns';
import { defaultFilter } from './constants';
import { defaultPageConfig } from '../../../../constants';
import { topBarConfig } from './utils';

export function WSIN({ location, brand }) {
	const [search, setSearch] = useState('');
	const [wsinList, setWSINList] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [selectedItem, setSelectedItem] = useState();
	const [loading, setLoading] = useState(false);
	const wsinColumnsConfig = useMemo(() => wsinColumns(), []);

	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [filter, setFilter] = useState(defaultFilter);
	const searchRef = useRef();

	useEffect(
		function fetchWSIN() {
			setLoading(true);
			if (brand?.id) {
				getWSINData({ ...pageConfig, companyId: brand?.id, search, filter })
					.then(({ data: { wsins, totalCount } }) => {
						setWSINList(wsins);
						setTotalCount(totalCount);
					})
					.catch((err) => console.log(`err`, err))
					.finally(() => {
						setLoading(false);
					});
			}
		},
		[pageConfig, search, filter, brand]
	);

	useEffect(function setPageConfiguration() {
		setPageConfig((pageConfig) => ({ ...pageConfig, ...defaultPageConfig }));
	}, []);

	return (
		<>
			{brand.id ? (
				<Row className="h-100 p-0">
					<Col span={24} className="main">
						<TopFilterComponent
							{...topBarConfig({
								setSearch,
								setPageConfig,
								filter,
								setFilter,
								searchRef
							})}
						/>
						<Fragment>
							<div className="innerDiv">
								<div className="div w-100 h-100">
									<CustomTablePagination
										shouldRowSelection={false}
										columns={wsinColumnsConfig}
										onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
										data={wsinList}
										total={totalCount}
										isCatalog={true}
										selectedItem={selectedItem}
										setSelectedItem={setSelectedItem}
										loading={loading}
										{...pageConfig}
									/>
								</div>
							</div>
						</Fragment>
					</Col>
				</Row>
			) : (
				<NoCompany />
			)}
		</>
	);
}
