import { Button, Tag } from 'antd';

export const shippingPackageColumn = (showEditModal) => {
	return [
		{
			title: 'Name',
			dataIndex: 'name'
		},
		{
			title: 'Code',
			dataIndex: 'code'
		},
		{
			title: 'Package Length',
			dataIndex: 'packageLength'
		},
		{
			title: 'Package Width',
			dataIndex: 'packageWidth'
		},
		{
			title: 'Package Height',
			dataIndex: 'packageHeight'
		},
		{
			title: 'Package Weight',
			dataIndex: 'packageWeight'
		},
		{
			title: 'Default',
			dataIndex: 'default',
			render: (v, items) => (items.default ? <Tag color="blue">Yes</Tag> : <Tag color="red">No</Tag>)
		},
		{
			title: 'Active',
			dataIndex: 'active',
			render: (v, items) => (items.active ? <Tag color="blue">Yes</Tag> : <Tag color="red">No</Tag>)
		},
		{
			title: 'Actions',
			render: (v, items) => (
				<Button size="small" type="primary" onClick={() => showEditModal(items)}>
					Edit
				</Button>
			)
		}
	];
};
