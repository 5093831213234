export const defaultPricingFields = {
	smsCharges: '',
	codHandlingCharges: '',
	platformCharges: '',
	whatsAppMessageCharges: '',
	lightningBaseCharges: '',
	lightningPriceUpto2Kgs: '',
	lightningPriceAfter2Kgs: '',
	storageChargeType: ''
};

export interface IPricingRowFieldItem {
	label: string;
	key: string;
	description: string;
}
