import { Button, notification } from 'antd';
import { useState, useContext } from 'react';
import { updateProfileDetails, changePassword } from 'shared/api/profile';
import { ResetForm } from 'shared/components/ResetForm';
import { AuthContext } from 'shared/contexts/Auth';
import errorHandler from 'shared/utils/errorHandler';
import { EVENT_NAMES } from 'utils/analytics';
import { ProfileForm } from '../../../../shared/components/ProfileForm';
import styles from './index.module.scss';
import amplitude from '../../../../shared/utils/Amplitude';

export function Profile() {
	const [isFormDisabled, setIsFormDisabled] = useState(true);
	const [isResetForm, setIsResetForm] = useState(false);

	const {
		authState: { profile },
		authActions
	} = useContext(AuthContext);

	const handleEditButtonClick = () => {
		amplitude.getInstance().logEvent(EVENT_NAMES.EDIT_USER_PROFILE);
		setIsFormDisabled(false);
		setIsResetForm(false);
	};

	const handleResetButtonClick = () => {
		amplitude.getInstance().logEvent(EVENT_NAMES.EDIT_USER_PASSWORD);
		setIsResetForm(true);
		setIsFormDisabled(true);
	};

	const handleResetCloseButtonClick = () => {
		setIsResetForm(false);
		setIsFormDisabled(true);
	};

	const onFinish = async (updateProfileValues) => {
		try {
			await updateProfileDetails(updateProfileValues);

			notification.success({
				message: 'Success',
				description: 'Profile updated successfully.',
				placement: 'topRight'
			});

			setIsFormDisabled(true);

			// refetch profile information
			authActions.obtainProfileData();
		} catch (error) {
			errorHandler(error);
		}
	};

	const resetPassword = async (formValues) => {
		try {
			await changePassword(formValues);

			notification.success({
				message: 'Success',
				description: 'Password changed successfully.',
				placement: 'topRight'
			});
		} catch (error) {
			errorHandler(error);
		} finally {
			handleResetCloseButtonClick();
		}
	};

	//   useEffect(() => {
	//     getProfileData()
	// }, []);

	return (
		<div className={styles.profilePage}>
			<div className={styles.header}>
				<h4 className={styles.title} id={styles.titleId}>
					{isResetForm ? 'Change Password' : 'Profile Details'}
				</h4>
				{!isResetForm && (
					<div className={styles.buttonsForResponsivness}>
						{isFormDisabled && (
							<Button type="primary" onClick={handleEditButtonClick}>
								Edit
							</Button>
						)}
						<Button type="primary" onClick={handleResetButtonClick}>
							Change Password
						</Button>
					</div>
				)}
			</div>

			{isResetForm ? (
				<ResetForm onFinish={(formValues) => resetPassword(formValues)} onCancel={() => handleResetCloseButtonClick()} />
			) : (
				<ProfileForm isSignup={false} onFinish={onFinish} isFormDisabled={isFormDisabled} initialValues={profile} />
			)}
			<div style={{ marginBottom: '40px' }}></div>
		</div>
	);
}
