export const D2RsubSectionsMap = {
	RETAIL_DISCOUNT: '1',
	BEATS: '2',
	RETAILERS: '3',
	EXTERNAL_RETAILERS: '4',
	RETAILER_ENGINE: '5',
	SALESMEN: '6',
	INVOICES: '7'
};

export const sidebarUtilD2r = {
	sideBar: [
		{
			key: '1',
			label: 'Retail Discount',
			icon: 'allSvgIcon',
			isNew: false
		},
		{
			key: '2',
			label: 'Beats',
			icon: 'allSvgIcon',
			isNew: false
		},
		{
			key: '3',
			label: 'Retailers',
			icon: 'allSvgIcon',
			isNew: false
		},
		{
			key: '4',
			label: 'External Retailers',
			icon: 'allSvgIcon',
			isNew: false
		},
		{
			key: '5',
			label: 'Retailer Engine',
			icon: 'allSvgIcon',
			isNew: false
		},
		{
			key: '6',
			label: 'Salesmen',
			icon: 'allSvgIcon',
			isNew: false
		},
		{
			key: '7',
			label: 'Invoices',
			icon: 'allSvgIcon',
			isNew: false
		}
	]
};
