import { Col, notification, Row } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getBmsWalletTransaction, createBmsWalletTransaction } from '../../../../shared/api/bms';
import { TopFilterComponent } from '../../../../shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from '../../../../shared/components/CustomTablePagination';
import errorHandler from '../../../../shared/utils/errorHandler';
import useColumns from '../../../../shared/components/Wallet/columns';
import { defaultFilter, defaultPageConfig } from '../../../../shared/components/Wallet/constants';
import { TransactionInfoSlider } from './Transaction/TransactionInfoSlider';
import { IWalletFilter, IPageConfiguration, ICreateTransactionObject } from '../../../../shared/components/Wallet/types';
import { topFilterUtil } from './utils';

export default function BmsWallet() {
	const [data, setData] = useState<any[]>([]);
	const [companyBalance, setCompanyBalance] = useState<number>(0);
	const [showAddTransactionInfoSlider, setShowAddTransactionInfoSlider] = useState<boolean>(false);
	const [currentCount, setCurrentCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<IWalletFilter>(defaultFilter);
	const [companies, setCompanies] = useState([]);
	const [pageConfig, setPageConfig] = useState<IPageConfiguration>(defaultPageConfig);
	const columns = useColumns();

	const setPageConfigDefault = useCallback(() => {
		setPageConfig(defaultPageConfig);
	}, []);

	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				filter,
				setFilter,
				setPageConfigDefault,
				companies,
				setCompanies,
				setShowAddTransactionInfoSlider,
				companyBalance
			}),
		[filter, companies, setFilter, setPageConfigDefault, setCompanies, companyBalance]
	);

	const getAllTransactions = async ({ pageConfiguration, filters }: any) => {
		try {
			setLoading(true);
			const response = await getBmsWalletTransaction({ pageConfiguration, filters });
			if (response?.data.status) {
				setData(response.data.data);
				setCurrentCount(response.data.count);
				setCompanyBalance(response.data.balance);
			} else {
				throw new Error(response?.data.message || 'Could not get transactions');
			}
		} catch (error: unknown) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getAllTransactions({ pageConfiguration: pageConfig, filters: filter });
	}, [filter, pageConfig]);

	const onCreateTransaction = async (createTransactionObject: ICreateTransactionObject) => {
		try {
			await createBmsWalletTransaction(createTransactionObject);
			setShowAddTransactionInfoSlider(false);
			notification.success({
				message: 'Transaction Creation',
				description: 'SuccessFully Created',
				placement: 'topRight'
			});
			getAllTransactions({ pageConfiguration: pageConfig, filters: filter });
		} catch (error: any) {
			notification.error({
				message: 'Transaction Creation',
				description: typeof error.message === 'string' ? error.message : 'Something went wrong',
				placement: 'topRight'
			});
		}
	};

	return (
		<>
			<Row className="h-100 p-0">
				<Col span={24} className="main">
					<TopFilterComponent {...topFilterConfig} />

					<div className="innerDiv">
						<div className="div w-100 h-100">
							<CustomTablePagination
								columns={columns}
								data={data}
								onChangePage={(current: any, pageSize: any) => setPageConfig((v) => ({ ...v, current, pageSize }))}
								current={Number(pageConfig.current)}
								total={currentCount}
								loading={loading}
								shouldRowSelection={false}
								selectedItem={undefined}
								catalog={undefined}
								selectedRowKeys={undefined}
								setSelectedRowKeys={undefined}
								setSelectedItem={undefined}
								pageSize={Number(pageConfig.pageSize)}
								sortField={pageConfig.sortField}
								sortOrder={pageConfig.sortField}
							/>
						</div>
					</div>
					{showAddTransactionInfoSlider && (
						<TransactionInfoSlider
							filter={filter}
							onCloseInfoSlider={() => {
								setShowAddTransactionInfoSlider(false);
							}}
							onCreateTransaction={(values: ICreateTransactionObject) => onCreateTransaction(values)}
						/>
					)}
				</Col>
			</Row>
		</>
	);
}
