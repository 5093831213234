import { Card, Popover } from 'antd';
import { ChromePicker } from 'react-color';
import tickSvg from '../../svgs/tick_mark';
import styles from './index.module.scss';
export const ColorPicker = ({ color, handleChange, colorName, colorPickerColors }) => {
	return (
		<Card bodyStyle={{ padding: 0 }}>
			<div className={styles.colorPickerParentDiv} style={{ display: 'flex', justifyContent: 'space-around', padding: '0.6rem' }}>
				<div
					onClick={() => handleChange({ name: colorName, value: colorPickerColors.first })}
					style={{ background: `${colorPickerColors.first}` }}
					className={styles.colorPickerDiv}
				>
					{color === colorPickerColors.first && tickSvg}
				</div>
				<div
					onClick={() => handleChange({ name: colorName, value: colorPickerColors.second })}
					style={{ background: `${colorPickerColors.second}` }}
					className={styles.colorPickerDiv}
				>
					{color === colorPickerColors.second && tickSvg}
				</div>
				<div
					onClick={() => handleChange({ name: colorName, value: colorPickerColors.third })}
					style={{ background: `${colorPickerColors.third}` }}
					className={styles.colorPickerDiv}
				>
					{color === colorPickerColors.third && tickSvg}
				</div>
				<div
					onClick={() => handleChange({ name: colorName, value: colorPickerColors.fourth })}
					style={{ background: `${colorPickerColors.fourth}` }}
					className={styles.colorPickerDiv}
				>
					{color === colorPickerColors.fourth && tickSvg}
				</div>
				<div style={{ height: '34px', border: '1px solid #f0f0f0' }}></div>
				<Popover
					placement="topRight"
					style={{ padding: 0 }}
					content={
						<ChromePicker
							width="100%"
							color={color}
							onChange={(updatedColor) => handleChange({ name: colorName, value: updatedColor.hex })}
						/>
					}
				>
					<div className={styles.colorPickerDiv} style={{ background: `${color}` }}>
						{!Object.values(colorPickerColors)?.includes(color) && tickSvg}
					</div>
				</Popover>
			</div>
		</Card>
	);
};
