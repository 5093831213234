import { Select } from 'antd';

interface ISelectDropDown {
	data: any[];
	setData: React.Dispatch<React.SetStateAction<null>>;
	disabled: boolean;
}

export const SelectDropDown = (props: ISelectDropDown): JSX.Element => {
	const { data, setData, disabled } = props;

	const handleChange = (e: any) => {
		setData(e);
	};

	const handleClear = () => {
		setData(null);
	};

	return (
		<>
			{data?.length > 0 && (
				<Select
					disabled={disabled}
					allowClear
					onClear={handleClear}
					size="middle"
					onChange={handleChange}
					placeholder="Courier partners"
					style={{ width: '100%' }}
				>
					{data.map((e: any, i: number) => (
						<Select.Option value={JSON.stringify({ cp_name: e['aggregator'], cp_id: e['courier_company_id'] })} key={i}>
							{e['courier_name']}
							{!e[`isPriceAndEddAvailabe`]
								? `- ${e[`aggregator`] || 'NA'}`
								: `(${'₹' + e['freight_charge'] || null}) - (${e['etd'] || 'NA'})`}
						</Select.Option>
					))}
				</Select>
			)}
		</>
	);
};
