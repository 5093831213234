import { Col, notification } from 'antd';
import { adminRoute } from 'app/AppAdmin/Admin/Admin';
import { createSubAdminForm } from 'app/AppAdmin/Admin/utils';
import { useHistory } from 'react-router-dom';
import { createSubAdminUser } from 'shared/api/companies';
import errorHandler from 'shared/utils/errorHandler';

export const createSubAdminRoute = '/admin/list?tab=CreateUser';

export function CreateSubAdmin() {
	const history = useHistory();

	async function createNewSubAdmin(values) {
		try {
			const { data } = await createSubAdminUser(values);
			if (!data.status) throw new Error(data.message);
			notification.success({
				message: 'Sub Admin Created',
				description: data.message,
				placement: 'topRight'
			});
			history.push(`${adminRoute}?tab=Users`);
			window.location.reload();
		} catch (error) {
			errorHandler(error);
		}
	}

	return (
		<div style={{ paddingLeft: 10 }}>
			<Col span={8}>{createSubAdminForm({ formTitle: 'Create Sub Admin', onSave: createNewSubAdmin })}</Col>
		</div>
	);
}
