import { Table, Tag } from 'antd';
import { checkFulfillStatus } from './utils';

export const FulfillmentColumns = (): (Exclude<Parameters<typeof Table>[0]['columns'], undefined>[number] & {
	editable?: boolean;
	dataIndex?: string;
})[] => [
	{ title: 'AWB', dataIndex: 'delivery_awb', key: 'delivery_awb', width: 200 },
	{ title: 'Customer Name', dataIndex: 'customer_name', key: 'customer_name', width: 200 },
	{ title: 'Delivery Partner', dataIndex: 'delivery_partner', key: 'delivery_partner', width: 200 },
	{ title: 'Package Assigned', dataIndex: 'package_type', key: 'delivery_partner', width: 200 },
	{
		title: 'Order Packed Status',
		key: 'delivery_awb',
		render: (data) => {
			const fulfillStatus = checkFulfillStatus(data?.items_to_scan || [], data?.status);
			return (
				<Tag style={{ fontWeight: 'bold' }} color={fulfillStatus?.O as any}>
					{fulfillStatus?.status}
				</Tag>
			);
		},
		width: 200
	}
];
