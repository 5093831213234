import amplitude from 'amplitude-js';

amplitude.getInstance().init(
	'd0b1f4c1e7dcc9c342374a686a59616b',
	null,
	{
		includeUtm: true,
		includeGclid: true,
		includeReferrer: true
	},
	function () {
		console.log('Amplitude initialized');
	}
);

export default amplitude;
