import { Button, Col, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { searchOptions } from './constants';
import reactDom from 'react-dom';
import debounce from 'utils/debounce';
import styles from './index.module.scss';

export const defaultFilter = {
	searchOptionType: searchOptions[0].value
};

export interface ISalesmanFilter {
	searchOptionType: string;
	searchValue?: string;
}

interface ISalesmanUtils {
	setIsCreateSalesmanModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setPageConfigDefault: any;
	setFilter: any;
	filter: ISalesmanFilter;
}

export const salesmanFilters = (props: ISalesmanUtils) => {
	const handleSearchOptionChange = (updatedSearchOptionType: string) => {
		props.setPageConfigDefault();
		props.setFilter((prevFilter: any) => ({ ...prevFilter, searchOptionType: updatedSearchOptionType, searchValue: '' }));
	};

	const debouncedSearchChange = debounce(({ target: { value } }: any) => {
		reactDom.unstable_batchedUpdates(() => {
			props.setPageConfigDefault();
			props.setFilter((prevFilter: any) => ({ ...prevFilter, searchValue: value }));
		});
	}, 700);

	return {
		row1: [],
		row1children: [
			<Col key={'1'}>
				<div className={styles.searchContainer}>
					<span>Search By:</span>
					<Input.Group compact>
						<Select
							defaultValue={props.filter.searchOptionType}
							value={props.filter.searchOptionType}
							onChange={handleSearchOptionChange}
							style={{ minWidth: '150px' }}
						>
							{searchOptions.map(({ value, label }) => (
								<Select.Option key={value} value={value}>
									{label}
								</Select.Option>
							))}
						</Select>
						<Input style={{ width: '200px' }} allowClear onChange={debouncedSearchChange} placeholder="Search" />
					</Input.Group>
				</div>
			</Col>
		],
		row2: [
			<div key={'2'} style={{ display: 'flex', gap: '1rem' }}>
				<Button type="primary" onClick={() => props.setIsCreateSalesmanModalVisible(true)}>
					Create Salesman
				</Button>
			</div>
		]
	};
};

export const d2rSalesmanColumns = (
	setIsSalesmanDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>,
	setSalesmanId: React.Dispatch<React.SetStateAction<string | number>>
) => [
	{
		title: 'Name',
		render: (data: any) => (
			<Button
				type="link"
				onClick={() => {
					setIsSalesmanDrawerVisible(true);
					setSalesmanId(data?.id);
				}}
			>
				<span>{`${data?.name}`}</span>
			</Button>
		)
	},
	{
		title: 'Phone',
		render: (data: any) => <div className="text-center">{data?.phone}</div>
	},
	{
		title: 'Created At',
		render: (data: any) => <div className="text-center">{dayjs(new Date(data.createdAt)).format('MMM D, YYYY h:mm A')}</div>
	}
];
