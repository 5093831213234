import { AutoComplete, Button, notification, Tag } from 'antd';
import { WalletOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { getCompanies } from '../../../../shared/api/lightning';
import { DebouncedCompactSearch } from '../../../../shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import errorHandler from '../../../../shared/utils/errorHandler';
import debounce from '../../../../utils/debounce';
import { searchOptions } from '../../../../shared/components/Wallet/constants';
import { IWalletFilter, ITopFilterUtil } from '../../../../shared/components/Wallet/types';
import { commonTopFilterRow } from '../../../../utils/filters';

export function topFilterUtil({
	filter,
	setFilter,
	setPageConfigDefault,
	companies,
	setCompanies,
	setShowAddTransactionInfoSlider,
	companyBalance
}: ITopFilterUtil): any {
	async function getAllCompanies(searchValue: string) {
		try {
			const { data }: { data: { status: boolean; companies: { entityId: string; name: string }[] } } = await getCompanies(
				searchValue
			);
			if (data.status) {
				setCompanies(
					data.companies.map((company) => ({
						key: company.entityId,
						value: company.entityId,
						label: company.name,
						...company
					}))
				);
			} else throw new Error(`Couldn't get companies`);
		} catch (error) {
			errorHandler(error);
		}
	}

	const setDate = (startDate: string, endDate: string) => {
		setPageConfigDefault();
		setFilter((prevFilter: IWalletFilter) => ({ ...prevFilter, start: startDate, end: endDate }));
	};

	const handleCompanyChange = (company?: string) => {
		setPageConfigDefault();

		// When clicked on clear button
		if (!company) {
			setFilter((prevFilter: IWalletFilter) => ({ ...prevFilter, companyID: undefined, companyName: undefined }));
		}
		// When a company is selected
		else {
			const { id, name }: { id: string; name: string } = JSON.parse(company);
			setFilter((prevFilter: IWalletFilter) => ({ ...prevFilter, companyID: id, companyName: name }));
		}
	};

	const debouncedCompanySearchChange = debounce((company: string) => {
		if (company.trim()) {
			getAllCompanies(company);
		} else {
			setCompanies([]);
		}
	}, 500);

	function onAddTransaction() {
		if (filter.companyID) {
			setShowAddTransactionInfoSlider(true);
		} else {
			notification.error({
				message: 'Company Selection',
				description: 'Please select company to add Transaction.',
				placement: 'topRight'
			});
		}
	}
	const topFilterRow = commonTopFilterRow(filter.start, filter.end);
	return {
		row1: [
			{
				...topFilterRow,
				onChange: (dateStrings: [string, string]) => setDate(...dateStrings)
			}
		],
		row1children: [
			<div className={styles.topRow} key={1}>
				<DebouncedCompactSearch
					defaultSearchOptionType={filter.searchOptionType}
					searchOptions={searchOptions}
					setFilter={setFilter}
					setDefault={setPageConfigDefault}
					selectStyle={{ minWidth: '100px' }}
				>
					<span>Search By:</span>
				</DebouncedCompactSearch>
			</div>,
			<div className={styles.topRow} key={1}>
				<span>Companies:</span>
				<div>
					<AutoComplete
						placeholder="Search for a company"
						onSearch={debouncedCompanySearchChange}
						onClear={() => handleCompanyChange()}
						onSelect={handleCompanyChange}
						style={{ width: '200px' }}
						allowClear
						value={filter.companyName}
						notFoundContent="No companies found"
					>
						{companies.map(({ key, value, label }) => {
							return (
								<AutoComplete.Option key={key} value={JSON.stringify({ id: value, name: label })}>
									{label}
								</AutoComplete.Option>
							);
						})}
					</AutoComplete>
				</div>
			</div>,
			<div>
				<Button style={{ marginTop: 20 }} type="primary" onClick={() => onAddTransaction()}>
					Add Transaction
				</Button>
			</div>
		],
		row2: [
			<div style={{ fontSize: '20px', marginTop: '1.4rem' }}>
				<Tag
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '2rem',
						fontSize: '15px'
					}}
					icon={<WalletOutlined />}
				>
					Balance: {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(companyBalance)}
				</Tag>
			</div>
		]
	};
}
