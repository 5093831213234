import errorHandler from '../../utils/errorHandler';

const { useContext, useState, useEffect } = require('react');
const { updatePreferenceDetails, updatePreferenceDetailAdmin, obtainSellerProfileData } = require('../../api/profile');
const { AuthContext } = require('../../contexts/Auth');

export const useSellerProfile = ({ isAdmin, companyId }) => {
	const {
		authState: { profile },
		authActions
	} = useContext(AuthContext);

	const [profileData, setProfileData] = useState(null);

	const fetchSellerProfileForAdmin = async () => {
		try {
			if (!companyId) {
				throw new Error('Please select a brand');
			}
			const { data } = await obtainSellerProfileData(companyId);
			if (!data.status) {
				throw new Error(data?.message);
			}
			setProfileData(data.profile);
		} catch (error) {
			errorHandler(error);
		}
	};

	const fetchSellerProfileForSeller = async () => {
		try {
			await authActions.obtainProfileData();
		} catch (error) {
			errorHandler(error);
		}
	};
	const fetchData = isAdmin ? fetchSellerProfileForAdmin : fetchSellerProfileForSeller;

	// listens to update in profile value from context
	useEffect(() => {
		if (!isAdmin) {
			setProfileData(profile);
		}
	}, [profile, isAdmin]);

	useEffect(() => {
		if (isAdmin) {
			fetchSellerProfileForAdmin();
		}
	}, [companyId]);

	const updatePreference = async (previousePreferences, fieldName, updatedValue) => {
		const updatedPreferenceFields = { ...previousePreferences };
		updatedPreferenceFields[fieldName] = updatedValue;
		if (isAdmin) {
			await updatePreferenceDetailAdmin({ companyId, profileType: fieldName, profileValue: updatedValue });
		} else {
			await updatePreferenceDetails(updatedPreferenceFields);
		}
	};

	// for admin panel
	useEffect(() => {
		fetchData();
	}, []);

	return {
		profile: profileData,
		revalidate: fetchData,
		updatePreference
	};
};
