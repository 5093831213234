export const convertToBase64 = (file, productName, img, setFiles) => {
	let reader = new FileReader();
	let result;

	//after async reading of the file sets the result to the result variable inside reader
	reader.onloadend = () => {
		result = reader.result;

		setFiles((files) => ({
			...files,
			[productName]: { data: reader.result, name: file.name, url: img.src }
		}));
	};

	//this function reads the file in a async way and gives back a base64 data in the result
	reader.readAsDataURL(file);
	return result;
};
