import React, { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Orders, orderRoute } from './Orders';
import { AppLayout } from 'shared/components/AppLayout';
import { AuthContext } from 'shared/contexts/Auth';
import { Loading } from 'shared/components/Loading';
import { dashboardRoute, Dashboard } from './Dashboard';
import { topBar } from './utils';
import { manualOrderRoute } from 'app/AppWms/Orders';
import { SettingsPage, settingsRoute } from './SettingsPage';
import { Catalog, catalogRoute } from './Catalog';
import { InventoryWMS, inventoryRoute } from './Inventory';
import { bulkInventoryPreviewRoute } from 'shared/components/UploadBulkPreview/constants';
import { BulkPreview } from 'shared/components/UploadBulkPreview/Preview';
import { ScanAndAddInventory, ScanAndAddInventoryRoute } from '../../shared/components/Inventory/ScanAndAddInventoryModal';
import { FulfillmentWms, fulfullmentRoute } from './Fulfillment/Fulfillment';
import { ManualOrder } from 'app/AppSeller/Orders';
export const AppRoutes = [
	'/',
	'/wms',
	'/app',
	dashboardRoute,
	orderRoute,
	catalogRoute,
	manualOrderRoute,
	settingsRoute,
	inventoryRoute,
	bulkInventoryPreviewRoute,
	ScanAndAddInventoryRoute,
	fulfullmentRoute
];

export function App() {
	const currentRoute = get(useLocation(), 'pathname');

	const [loading, setLoading] = useState(false);
	const { authActions, authState } = useContext(AuthContext);

	useEffect(() => {
		setLoading(true);
		authActions.obtainProfileData().finally(() => setLoading(false));
	}, []);

	return (
		<>
			<Loading loading={loading} />
			{authState.profileLoaded && (
				<AppLayout currentRoute={currentRoute} topBar={topBar}>
					<Switch>
						<Route exact path={['/app', '/', '/wms']} render={() => <Redirect to={orderRoute} />} />
						<Route exact path={dashboardRoute} component={Dashboard} />
						<Route exact path={orderRoute} component={Orders} />
						<Route exact path={catalogRoute} component={Catalog} />
						<Route exact path={inventoryRoute} component={InventoryWMS} />
						<Route exact path={manualOrderRoute} component={ManualOrder} />
						<Route exact path={settingsRoute} component={SettingsPage} />
						<Route exact path={bulkInventoryPreviewRoute} component={BulkPreview} />
						<Route exact path={ScanAndAddInventoryRoute} component={ScanAndAddInventory} />
						<Route exact path={fulfullmentRoute} component={FulfillmentWms} />
						<Redirect from="*" to="/" />
					</Switch>
				</AppLayout>
			)}
		</>
	);
}
