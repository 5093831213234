import { Row, Col, Button } from 'antd';
import { useState } from 'react';
import { Sidebar } from 'shared/components/AppLayout/Sidebar';
import { getLightningSubPageMapper, sidebarUtil } from './utils';
import { UploadOutlined } from '@ant-design/icons';
import { UploadBulkModal } from '../../../shared/components/UploadBulkModal';
import { bulkLightningOrderPreviewRoute, MAX_ROWS_EXCEED_ERROR_MSG } from '../../../shared/components/UploadBulkPreview/constants';

export const orderRoute = '/orders';

export function Orders() {
	const [isUploadBulkOrderModalVisible, setIsUploadBulkOrderModalVisible] = useState(false);
	const [sidebarMenuItem, setSidebarMenuItem] = useState(1);
	const LIGHTNING_SUB_PAGE_MAPPER = getLightningSubPageMapper();

	return (
		<Row className="h-100 p-0">
			{isUploadBulkOrderModalVisible && (
				<UploadBulkModal
					instructionMessage={
						<ul className={'instructions'}>
							<li>Download the excel template by clicking "Download Sample" button below</li>
							<li>Edit file with order data and upload </li>
							<br />
							Note: {MAX_ROWS_EXCEED_ERROR_MSG}
						</ul>
					}
					templatePath={process.env.REACT_APP_EXCEL_LIGHTNING_ORDER_TEMPLATE}
					previewPath={bulkLightningOrderPreviewRoute}
					modalTitle="Bulk Lightning Order Upload"
					isModalVisible={isUploadBulkOrderModalVisible}
					setIsModalVisible={setIsUploadBulkOrderModalVisible}
				/>
			)}
			<Col span={4} className="sideBar scrollable">
				<Sidebar
					sideBarMenu={sidebarUtil.sideBar}
					selectedMenu={sidebarMenuItem}
					onMenuItemSelect={setSidebarMenuItem}
					bottomButtons={
						<Button
							icon={<UploadOutlined />}
							type="primary"
							size="large"
							onClick={() => setIsUploadBulkOrderModalVisible(true)}
						>
							Upload Bulk Orders
						</Button>
					}
				/>
			</Col>
			<Col span={20} className={`scrollable`}>
				{LIGHTNING_SUB_PAGE_MAPPER[sidebarMenuItem]}
			</Col>
		</Row>
	);
}
