import { Button, Col, Input, Select } from 'antd';
import reactDom from 'react-dom';
import debounce from '../debounce';

export const topFilterUtil = ({ filter, setFilter, setPageConfigDefault, searchOptions, addSubAdmin }) => {
	const handleSearchOptionChange = (updatedSearchOptionType) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, searchOptionType: updatedSearchOptionType, searchValue: '' }));
	};

	const debouncedSearchChange = debounce(({ target: { value } }) => {
		reactDom.unstable_batchedUpdates(() => {
			setPageConfigDefault();
			setFilter((prevFilter) => ({ ...prevFilter, searchValue: value }));
		});
	}, 700);

	return {
		row1: [],
		row1children: [
			<Col>
				<span>Search By:</span>
				<Input.Group compact>
					<Select defaultValue={filter.searchOptionType} onChange={handleSearchOptionChange} style={{ minWidth: '150px' }}>
						{searchOptions.map(({ value, label }) => (
							<Select.Option value={value}>{label}</Select.Option>
						))}
					</Select>
					<Input style={{ width: '200px' }} allowClear onChange={debouncedSearchChange} placeholder="Search" />
				</Input.Group>
			</Col>
		],
		row2: addSubAdmin
			? [
					<Col key={1}>
						<Button type="primary" onClick={() => addSubAdmin()}>
							Add Sub Admin
						</Button>
					</Col>
			  ]
			: []
	};
};
