import { apiOms, apiUms } from './index';

export const getSalesChannels = () => {
	return apiUms.get('/ums/ts/stores');
};

export const postIntegrateStore = (body) => {
	return apiUms.post('/ums/ts/integrate-store', body);
};

export const syncOrderForShopType = (shopType) => {
	return apiOms.get(`/syncOrders/${shopType}`);
};
