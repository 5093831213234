import { Tag } from 'antd';
import dayjs from 'dayjs';

import { PaymentStatus, PaymentStatusTagColor } from './types';

export default function useColumns() {
	return [
		{
			title: 'Timestamp',
			dataIndex: 'createdAt',
			render: (text: string) => <div className="text-center">{dayjs(new Date(text)).format('YYYY-MM-DD hh:mm:ss')}</div>,
			width: 150,
			sorter: true
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			render: (amount: number) => <div className="text-center">₹{Number(amount).toFixed(2)}</div>
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: (status: number) => (
				<div className="text-center">
					<Tag color={PaymentStatusTagColor[status]}>{PaymentStatus[status]}</Tag>
				</div>
			)
		},
		{
			title: 'Message',
			dataIndex: 'message',
			render: (message: string) => <div className="text-center">{message}</div>
		},
		{
			title: 'Reference Id',
			dataIndex: 'extMetadata',
			render: (extMetadata: any) => <div className="text-center">{extMetadata?.data?.merchantTransactionId || ''}</div>
		},
		{
			title: 'Gateway',
			dataIndex: 'gateway',
			render: (gateway: string) => <div className="text-center">{gateway}</div>
		},
		{
			title: 'Order Id',
			dataIndex: 'order_id',
			render: (order_id: string) => <div className="text-center">{order_id}</div>
		}
	];
}
