import ReactApexChart from 'react-apexcharts';
import styles from './index.module.scss';

interface IPieChart {
	heading?: string;
	data?: any;
}

export const PieChart = ({ heading, data }: IPieChart) => {
	return (
		<div className={styles.pieChart}>
			<div className={styles.topHeading}>
				<p>{heading}</p>
			</div>
			{data && <ReactApexChart options={data.options} series={data.series} type="pie" width={'100%'} />}
			<p className={styles.last30Days}>Last 30 Days</p>
		</div>
	);
};
