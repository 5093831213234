import { Result, Button } from 'antd';

export const SuccessComponent = ({ previewConfigName, history }) => {
	return (
		<Result
			status="success"
			title={`All ${previewConfigName} executed successfully!`}
			extra={[
				<Button type="primary" key="console" onClick={history.goBack}>
					{`Go to ${previewConfigName} page`}
				</Button>
			]}
		/>
	);
};
