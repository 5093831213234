import { Button, Space, Typography } from 'antd';
import { CUSTOMER_REQUEST_TYPE, getFormattedShippingAddress } from '../../../app/CustomerRequest/constants';
import { EApprovalStatus } from './utils';

export const commonColumns = [
	{
		dataIndex: ['splitOrder', 'shopOrderId'],
		title: 'Order Id',
		width: 100
	},

	{
		dataIndex: ['splitOrder', 'deliveryPartnerId'],
		title: 'Delivery Partner',
		width: 150
	},
	{
		dataIndex: 'requestType',
		title: 'Request Type',
		width: 100,
		render: (requestType) => <div>{requestType?.split('_').join(' ') ?? 'UAP'}</div>
	},
	{
		dataIndex: 'approvalStatus',
		title: 'Approval status',
		width: 100,
		render: (approvalStatus) => <div>{approvalStatus ?? 'UAP'}</div>
	},
	{
		dataIndex: 'formLinkStatus',
		title: 'Form Link Status',
		width: 100
	},
	{
		title: 'Request/Reason',
		align: 'left',
		width: 150,

		render: (_v, data) => {
			if (data.requestType === CUSTOMER_REQUEST_TYPE.UPDATE_DETAILS) {
				return (
					<div>
						<Typography.Paragraph>Phone Number: {data.alternatePhone}</Typography.Paragraph>
						<Typography.Paragraph>
							Preferred Date: {new Date(data.deferredDeliveryDate).toLocaleDateString()}
						</Typography.Paragraph>
						<Typography.Paragraph>Address: {getFormattedShippingAddress(data.alternateAddress)}</Typography.Paragraph>
					</div>
				);
			}
			if (data.requestType === CUSTOMER_REQUEST_TYPE.CANCEL_ORDER) {
				return (
					<div>
						<Typography.Paragraph>Issue Type: {data.issueType}</Typography.Paragraph>
						<Typography.Paragraph>Issue Description: {data.issueDescription}</Typography.Paragraph>
					</div>
				);
			}
			return <div>Not Available</div>;
		}
	}
];

export const getSellerCRcolumns = () => {
	return commonColumns;
};

export const getAdminCRcolumns = (onApprovalStatusUpdate) => {
	const handleStatusChange = (crId, status) => () => {
		onApprovalStatusUpdate({ customerRequestIds: [crId], newApprovalStatus: status });
	};
	return [
		...commonColumns,
		{
			title: 'Actions',
			width: 200,
			render: (_, data) => (
				<Space>
					<Button
						disabled={data.approvalStatus === EApprovalStatus.PROCESSED}
						size="small"
						type="primary"
						onClick={handleStatusChange(data.id, EApprovalStatus.PROCESSED)}
					>
						Processed
					</Button>
					<Button
						size="small"
						disabled={data.approvalStatus === EApprovalStatus.APPROVED}
						onClick={handleStatusChange(data.id, EApprovalStatus.APPROVED)}
					>
						Approved
					</Button>
					<Button
						size="small"
						danger
						disabled={data.approvalStatus === EApprovalStatus.DISAPPROVED}
						onClick={handleStatusChange(data.id, EApprovalStatus.DISAPPROVED)}
					>
						Disapproved
					</Button>
				</Space>
			)
		}
	];
};
