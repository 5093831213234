import { notification } from 'antd';
import { MAX_DOWNLOAD_TRIPS_LIMIT } from 'app/AppAdmin/Lightning/Trips/constants';
import { MAX_DOWNLOAD_ORDER_LIMIT } from 'app/AppSeller/Orders/constants';
import { cleanObject } from 'helper';
import { envs } from 'shared/utils/env';
const { hmsBaseUrl } = envs;
const { apiHms, apiWms, apiUms } = require('.');

export function getLightningOrdersSearchParams({ filters = {}, pageConfiguration = {} }) {
	const { startDate, endDate, searchOptionType, searchValue, status, company, paymentMethod } = filters;
	const { current, pageSize, sortField, sortOrder } = pageConfiguration;

	const searchParams = {
		start: new Date(startDate),
		end: new Date(endDate),
		paymentMethod,
		company,
		orderStatus: status,
		[searchOptionType]: searchValue ? String(searchValue).trim() : undefined,
		records: pageSize,
		pageNumber: current,
		order: sortField,
		by: sortOrder
	};

	for (const [key, value] of Object.entries(searchParams)) {
		if (!value) delete searchParams[key];
	}

	return searchParams;
}

export function getOrders({ pageConfiguration, filters, awb, trail, isLightningPanel }) {
	if (awb) {
		const isTrailFlag = trail ? true : false;
		return isLightningPanel
			? apiHms.get(`/lightning/orders/${awb}?trail=${isTrailFlag}`)
			: apiHms.get(`/admin/orders/${awb}?trail=${isTrailFlag}`);
	}

	const searchParams = getLightningOrdersSearchParams({ filters, pageConfiguration });

	return isLightningPanel
		? apiHms.get(`/lightning/orders`, { params: searchParams })
		: apiHms.get(`/admin/orders`, { params: searchParams });
}

export async function downloadOrdersCSV(params, companyId) {
	try {
		let subRoute = 'admin';
		// companyId only comes when request is coming from lighting panel
		if (companyId) {
			params['company'] = companyId;
			subRoute = 'lightning';
		}
		const searchParams = getLightningOrdersSearchParams({ filters: params });
		const url = new URL(`${hmsBaseUrl}/${subRoute}/csv/orders/download`);
		url.search = new URLSearchParams(searchParams);

		const countUrl = new URL(`${hmsBaseUrl}/${subRoute}/orders/filter/count`);
		countUrl.search = new URLSearchParams(searchParams);

		/**
		 * Get Order Count
		 */
		const {
			data: { ordersCount }
		} = await apiHms.get(countUrl);

		if (ordersCount && ordersCount > MAX_DOWNLOAD_ORDER_LIMIT) {
			// Open a modal to ask user to enter email
			return true;
		}

		window.open(url);
		return false;
	} catch (err) {
		notification.error({
			message: 'Download CSV Error',
			description: 'Please try again after some time.',
			placement: 'topRight'
		});
	}
}

export async function downloadMoreOrdersAndMail(params, email, companyId) {
	try {
		let subRoute = 'admin';
		// companyId only comes when request is coming from lighting panel
		if (companyId) {
			params['company'] = companyId;
			subRoute = 'lightning';
		}
		const searchParams = getLightningOrdersSearchParams({ filters: params });

		const mailUrl = new URL(`${hmsBaseUrl}/${subRoute}/csv/orders/download/email`);
		mailUrl.search = new URLSearchParams(searchParams);

		const { data } = await apiHms.post(mailUrl, {
			email
		});

		notification.success({
			message: 'Download Orders',
			description: data?.message,
			placement: 'topRight'
		});

		return true;
	} catch (e) {
		console.log('!!!!!Error printed here in sending mail for downloaded orders!!!!!!', e);
		throw e;
	}
}

export function getJourney({ pageConfiguration, filters, journeyID }) {
	if (journeyID) {
		return apiHms.get(`/admin/journey/${journeyID}`);
	}

	const { startDate, endDate, status, searchOptionType, searchValue } = filters;
	const { current, pageSize, sortField, sortOrder } = pageConfiguration;
	const searchParams = new URLSearchParams();
	if (searchValue) {
		searchParams.append(searchOptionType, searchValue.trim());
	}
	if (status) {
		searchParams.append('journeyStatus', status);
	}

	if (startDate && endDate) {
		searchParams.append('start', startDate);
		searchParams.append('end', endDate);
	}
	if (current) {
		searchParams.append('pageNumber', current);
	}
	if (pageSize) {
		searchParams.append('records', pageSize);
	}
	if (sortField) {
		searchParams.append('order', sortField);
	}
	if (sortOrder) {
		searchParams.append('by', sortOrder);
	}
	return apiHms.get(`/admin/journey?${searchParams.toString()}`);
}

export async function downloadJourneyCSV({ pageConfiguration, filters }) {
	try {
		const { startDate, endDate, status, searchOptionType, searchValue } = filters;
		const { sortField, sortOrder } = pageConfiguration;
		const searchParams = new URLSearchParams();
		if (searchValue) {
			searchParams.append(searchOptionType, searchValue.trim());
		}

		if (status) {
			searchParams.append('journeyStatus', status);
		}

		if (startDate && endDate) {
			searchParams.append('start', startDate);
			searchParams.append('end', endDate);
		}

		if (sortField) {
			searchParams.append('order', sortField);
		}

		if (sortOrder) {
			searchParams.append('by', sortOrder);
		}

		const url = new URL(`${hmsBaseUrl}/admin/journey/csv`);
		url.search = searchParams;

		const countUrl = new URL(`${hmsBaseUrl}/admin/journey/filter/count`);
		countUrl.search = searchParams;

		/**
		 * Get Order Count
		 */
		const {
			data: { journeyCount }
		} = await apiHms.get(countUrl);

		if (journeyCount && journeyCount > MAX_DOWNLOAD_TRIPS_LIMIT) {
			// Open a modal to ask user to enter email
			return true;
		}

		window.open(url);
		return false;
	} catch (err) {
		console.log('!!!Error!!!!', err);
		notification.error({
			message: 'Download CSV Error',
			description: 'Please try again after some time.',
			placement: 'topRight'
		});
	}
}

export async function downloadMoreTripsAndMail({ pageConfiguration, filters }, email) {
	const { startDate, endDate, status, searchOptionType, searchValue } = filters;
	const { sortField, sortOrder } = pageConfiguration;
	const searchParams = new URLSearchParams();
	if (searchValue) {
		searchParams.append(searchOptionType, searchValue.trim());
	}

	if (status) {
		searchParams.append('journeyStatus', status);
	}

	if (startDate && endDate) {
		searchParams.append('start', startDate);
		searchParams.append('end', endDate);
	}

	if (sortField) {
		searchParams.append('order', sortField);
	}

	if (sortOrder) {
		searchParams.append('by', sortOrder);
	}

	const mailUrl = new URL(`${hmsBaseUrl}/admin/journey/download/email`);
	mailUrl.search = searchParams;

	const { data } = await apiHms.post(mailUrl, {
		email
	});

	notification.success({
		message: 'Download Trips',
		description: data?.message,
		placement: 'topRight'
	});

	return true;
}

export function getClusters() {
	return apiHms.get(`/admin/clusters`);
}

export function getWarehouses() {
	return apiHms.get(`/admin/warehouses`);
}

export function getClusterOwners() {
	return apiHms.get(`/admin/cluster-owner`);
}

export function getWarehouseUser() {
	return apiHms.get(`/admin/warehouse-user`);
}

export function createDriver(driverObj) {
	return apiHms.post(`/admin/create-driver`, driverObj);
}

export function createClusterOwner(clusterOwnerObj) {
	return apiHms.post(`/admin/create-cluster-owner`, clusterOwnerObj);
}

export function createWarehouseUser(warehouseUserObj) {
	return apiHms.post(`/admin/create-warehouse-owner`, warehouseUserObj);
}

export function createCluster(clusterObj) {
	return apiHms.post(`/admin/create-cluster`, clusterObj);
}

export function assignCluster(data) {
	return apiHms.post(`/admin/assign-cluster`, data);
}

export function createWarehouse(warehouseObj) {
	return apiHms.post(`/admin/create-warehouse`, warehouseObj);
}

export function getAllOmsWarehouses(warehouseName) {
	return apiWms.get(`/warehouse/find`, { params: { warehouseName } });
}

export function fetchDriverInfo(driverId) {
	return apiHms.get(`/admin/driver/${driverId}`);
}

export function getAllDrivers() {
	return apiHms.get(`/admin/drivers`);
}

export function getDrivers({ current = 1, pageSize = 10, sortField = 'createdAt', sortOrder = 'DESC' }, filter) {
	return apiHms.post('/admin/drivers', {
		where: cleanObject({ ...filter }, ['', null, undefined]),
		pagination: {
			records: pageSize,
			pageNumber: current
		},
		sortBy: {
			order: sortField,
			by: sortOrder
		}
	});
}
export function getAssignedOrders(assignedOrderIds, driverId) {
	return apiHms.post('/admin/drivers/assigned-orders', {
		assignedOrderIds,
		driverId
	});
}

export function markAsDelivered({ driverId, awb, timeStamp }) {
	return apiHms.post(`/admin/order/mark-as-delivered`, {
		driverId,
		awb,
		timeStamp
	});
}

export function markAsUndelivered({ driverId, awb, reason }) {
	return apiHms.post(`/admin/order/mark-as-undelivered`, {
		driverId,
		awb,
		reason
	});
}

export function markAsCanceled({ awb, reason }) {
	return apiHms.post(`/admin/order/mark-as-cancelled`, {
		awb,
		reason
	});
}

export function markAsRTODelivered({ awb }) {
	return apiHms.post(`/admin/order/mark-as-rto-delivered`, {
		awb
	});
}

export function getCompanies(search) {
	return apiUms.get(`/ums/profile/companies/find?companyName=${search}`);
}
