import React, { useEffect, useState, useMemo, useContext, useRef } from 'react';
import { AuthContext } from 'shared/contexts/Auth';
import { columns } from './columns';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { OrderInfoSlider } from 'shared/components/OrderInfoSlider';
import {
	getCompanyOrders,
	downloadOrdersCSV,
	downloadOrdersPickList,
	getAllClusters,
	downloadMoreOrdersAndMail,
	downloadLablesAndMail
} from 'shared/api/orders';
import { notification, Row, Col, Button } from 'antd';
import { Sidebar } from 'shared/components/AppLayout/Sidebar';
import { sidebarUtil, topFilterUtil } from './utils';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { useHistory } from 'react-router-dom';
import { downloadAsFile } from 'helper';
import errorHandler from 'shared/utils/errorHandler';
import { defaultFilter } from '../../../shared/utils/constant';
import { envs } from '../../../shared/utils/env';
import { RequestEmailModal } from 'shared/components/EmailRequestModal';
import { defaultPageConfig, ORDER_STATUS } from '../../../constants';
import { obtainProfileData } from '../../../shared/api/profile';
import RTOModal from '../../AppSeller/RTOApproval/components/RTOModal';
import { SideDrawer } from '../../../shared/components/SideDrawer/SideDrawer';
import { MissedOrders } from 'app/AppSeller/Orders/MissedOrders';
import { OrderPageOptions } from './constants';
export const orderRoute = '/orders';

const RenderCurrentTabContent = ({ tabIndex, renderTabIndex, children }: any) => {
	if (tabIndex === renderTabIndex) {
		return children;
	}

	return null;
};

export function Orders() {
	const history = useHistory();
	const isMounted = useRef(false);
	const { authState } = useContext(AuthContext);
	const [pageConfig, setPageConfig] = useState({
		...defaultPageConfig,
		onChangePage: (current: any, pageSize: any) => setPageConfig((v) => ({ ...v, current, pageSize }))
	});
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [clusterData, setClusterData] = useState([]);
	const [data, setData] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [counts, setCounts] = useState<any>({});
	const [openOrderInfo, setOpenOrderInfo] = useState<any>(false);
	const [loading, setLoading] = useState(false);
	const [isDownloadButtonLoading, setIsDownloadButtonLoading] = useState(false);
	const [labelEmailModalType, setLabelEmailModalType] = useState('');
	const [isEmailModalVisible, setIsEmailModalVisible] = useState<boolean | undefined>(false);
	const [wmsSidebarTabIndex, setWmsSidebarTabIndex] = useState(() => sessionStorage.getItem('wmsSideBarTabIndex') ?? '1');
	const [missedOrderCount, setMissedOrderCount] = useState(0);
	const [companyProfileData, setCompanyProfileData] = useState();
	const [filter, setFilter] = useState<any>({
		...defaultFilter,
		companyId: authState.selectedCompanyIds
	});
	const [search, setSearch] = useState('');
	const columnsConfig = useMemo(
		() =>
			columns({
				clickOrderId: setOpenOrderInfo,
				sortField: pageConfig.sortField,
				sortOrder: pageConfig.sortOrder,
				showApproveModal,
				showDetailsModal
			}),
		[setOpenOrderInfo, pageConfig.sortField, pageConfig.sortOrder]
	);
	const [openFilterPanel, setOpenFilterPanel] = useState(false);
	const [filterFormValue, setFilterFormValue] = useState({});
	const [status, setStatus] = useState([]);
	const [clusterState, setClusterState] = useState([]);

	const sidebarConfig = useMemo(
		() => sidebarUtil({ count: totalCount, missedOrderCount: missedOrderCount }),
		[totalCount, missedOrderCount]
	);

	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				counts,
				filter,
				setFilter,
				search,
				setSearch,
				pageConfig,
				setPageConfigDefault,
				onDownloadOrders,
				onDownloadOrderPickList,
				wmsSidebarTabIndex,
				selectedRowKeys,
				setPageConfig,
				getAllOrders,
				clusterData,
				setLabelEmailModalType,
				handleFulfillment,
				openFilterPanel,
				setOpenFilterPanel,
				filterFormValue,
				setFilterFormValue,
				status,
				setStatus,
				clusterState,
				setClusterState
			}),
		[counts, filter, pageConfig, wmsSidebarTabIndex, selectedRowKeys, search, openFilterPanel, filterFormValue]
	);

	useEffect(() => {
		const fetchProfileData = async () => {
			const data = await obtainProfileData();
			return data;
		};

		fetchProfileData().then((data) => {
			setCompanyProfileData(data.data.responseBody);
		});
	}, []);

	async function listAllClusters() {
		try {
			const { data } = await getAllClusters();
			if (!isMounted.current) return;
			setClusterData(data.cluster);
		} catch (error) {
			setClusterData([]);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		setLoading(true);
		listAllClusters();
	}, []);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	function getAllOrders(pageConfig: any, filter: any) {
		getCompanyOrders(pageConfig, { ...filter, companyId: authState.selectedCompanyIds })
			.then(({ data }) => {
				if (!isMounted.current) return;
				setData(data.order ? data.order.map((v: any) => ({ ...v, key: v.id })) : []);
				setCounts(data.count || {});
				setTotalCount(data.currentCount || 0);
				setMissedOrderCount(data.missedOrderCount || 0);

				if (data.message) {
					notification.error({
						message: 'Error',
						description: data.message,
						placement: 'topRight'
					});
				}
			})
			.catch(() => {
				if (!isMounted.current) return;
				setData([]);
			})
			.finally(() => {
				isMounted.current && setLoading(false);
			});
	}
	function handleFulfillment() {
		history.push('/fulfillment');
	}

	useEffect(() => {
		setLoading(true);
		if (wmsSidebarTabIndex === '1') {
			// Check if order tab is opened or not
			getAllOrders(pageConfig, filter);
		}
	}, [pageConfig, filter, authState.selectedCompanyIds, wmsSidebarTabIndex]);

	function handleTableChange(pagination: any, filters: any, sorter: any) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'id',
			sortOrder: !sorter.order || sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	function setPageConfigDefault() {
		setPageConfig((v) => ({ ...v, ...defaultPageConfig }));
	}

	async function onDownloadOrders() {
		try {
			setLoading(true);
			if (filter?.status && counts[filter?.status] === 0) {
				throw new Error('There are no orders available for the selected filter');
			}

			const csvFilter = { ...filter, appType: envs.appType };
			const shouldEmailOpen = await downloadOrdersCSV(csvFilter);
			setIsEmailModalVisible(shouldEmailOpen);
		} catch (err) {
			notification.error({
				message: 'Error',
				description: 'Download API Error',
				placement: 'topRight'
			});
		} finally {
			setLoading(false);
		}
	}

	const onOrdersFormSubmit = async (values: any) => {
		try {
			setIsDownloadButtonLoading(true);
			const csvFilter = { ...filter, appType: envs.appType };
			await downloadMoreOrdersAndMail(csvFilter, values.email);
		} catch (e) {
		} finally {
			setIsDownloadButtonLoading(false);
			setIsEmailModalVisible(false);
		}
	};

	const onLabelFormSubmit = async (values: any, type: any) => {
		try {
			setIsDownloadButtonLoading(true);
			await downloadLablesAndMail(selectedRowKeys, values.email, type);
		} catch (e) {
		} finally {
			setIsDownloadButtonLoading(false);
			setLabelEmailModalType('');
		}
	};

	async function onDownloadOrderPickList() {
		try {
			setLoading(true);

			if (!selectedRowKeys?.length) {
				throw new Error('Please select at least one order to generate pick list excel');
			}

			let orderPickListData = await downloadOrdersPickList(selectedRowKeys);
			downloadAsFile({ data: orderPickListData.data, fileName: `PickList`, fileType: 'xlsx' });
		} catch (err) {
			errorHandler(err);
		} finally {
			setLoading(false);
		}
	}

	function showApproveModal(status: any, order: any) {
		if (status === ORDER_STATUS.RTO_DELIVERED || status === ORDER_STATUS.DTO_DELIVERED) {
			return (
				<RTOModal
					orderData={order}
					button={status === ORDER_STATUS.RTO_DELIVERED ? 'Approve RTO' : 'Approve DTO'}
					companyProfileData={companyProfileData}
				/>
			);
		}
	}

	function showDetailsModal(status: any, order: any) {
		if (status === ORDER_STATUS.RTO_PROCESSED || status === ORDER_STATUS.DTO_PROCESSED) {
			return (
				<RTOModal
					orderData={order}
					button={status === ORDER_STATUS.RTO_PROCESSED ? 'RTO Details' : 'DTO Details'}
					companyProfileData={companyProfileData}
				/>
			);
		}
	}

	useEffect(() => {
		setFilter((filter: any) => ({
			...filter,
			companyId: authState.selectedCompanyIds
		}));
	}, [authState.selectedCompanyIds]);

	// For tab system in multiple sidebar items
	useEffect(() => {
		let tabValue;
		tabValue = Object.keys(OrderPageOptions).find((key) => OrderPageOptions[key] == wmsSidebarTabIndex);
		history.push({
			pathname: '/orders',
			search: `?tab=${tabValue}`
		});
		// Set wmsSidebarTabIndex in to session storage
		sessionStorage.setItem('wmsSideBarTabIndex', String(wmsSidebarTabIndex));
	}, [wmsSidebarTabIndex]);

	return (
		<Row className="h-100 p-0">
			<RequestEmailModal
				title="Download Orders"
				visible={isEmailModalVisible}
				onCancel={() => {
					setIsEmailModalVisible(false);
				}}
				footer={[]}
				width={500}
				onFinish={onOrdersFormSubmit}
				instructionMessage="Requested data contains too many rows, please enter your email address to receive download url."
				loading={isDownloadButtonLoading}
			/>

			<RequestEmailModal
				title={`Download ${labelEmailModalType}s`}
				visible={Boolean(labelEmailModalType)}
				onCancel={() => {
					setLabelEmailModalType('');
				}}
				footer={[]}
				width={500}
				onFinish={(values) => onLabelFormSubmit(values, labelEmailModalType)}
				instructionMessage="Requested data contains too many orders, please enter your email address to view/download order data."
				loading={isDownloadButtonLoading}
			/>

			<SideDrawer placement="left">
				<Sidebar
					sideBarMenu={sidebarConfig.sideBarMenu}
					bottomButtons={
						<>
							<Button
								type="primary"
								size="large"
								onClick={() => {
									history.push('/orders/new');
								}}
							>
								Create Order
							</Button>
							{/* <Button icon={<UploadOutlined />} type="primary" size="large" onClick={onDownloadOrders}>
								Upload
							</Button> */}
						</>
					}
					selectedMenu={wmsSidebarTabIndex}
					onMenuItemSelect={setWmsSidebarTabIndex}
				/>
			</SideDrawer>

			<RenderCurrentTabContent tabIndex={wmsSidebarTabIndex} renderTabIndex={'1'}>
				<Col sm={24} lg={20} className="main">
					<TopFilterComponent {...topFilterConfig} />
					<div className="innerDiv">
						<div className="div w-100 h-100">
							<CustomTablePagination
								columns={columnsConfig}
								data={data}
								showSorterTooltip={true}
								{...pageConfig}
								total={totalCount}
								onChange={handleTableChange}
								loading={loading}
								selectedRowKeys={selectedRowKeys}
								setSelectedRowKeys={(keys: any) => setSelectedRowKeys(keys)}
							/>
							{openOrderInfo && (
								<OrderInfoSlider
									orderId={openOrderInfo.orderId}
									companyId={openOrderInfo.companyId}
									onClose={() => setOpenOrderInfo(null)}
								/>
							)}
						</div>
					</div>
				</Col>
			</RenderCurrentTabContent>

			<RenderCurrentTabContent tabIndex={wmsSidebarTabIndex} renderTabIndex={'2'}>
				<MissedOrders
					companyIds={authState.selectedCompanyIds}
					setMissedOrderCount={(count: any) => setMissedOrderCount(count || 0)}
				/>
			</RenderCurrentTabContent>
		</Row>
	);
}
