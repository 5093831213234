import { Alert, Button, Divider, Form, Input, Modal, Tooltip, notification } from 'antd';
import { getWSINMapFormat } from '../../../Catalog/constants';
import errorHandler from '../../../../utils/errorHandler';
import { apiOms } from '../../../../api';
import { useState } from 'react';

interface IUpdateItemsQtyModal {
	order: any;
	isModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setReloadOrder: React.Dispatch<React.SetStateAction<number>>;
}

export const UpdateItemsQtyModal = (props: IUpdateItemsQtyModal) => {
	const { order, isModalVisible, setReloadOrder } = props;

	const [loading, setLoading] = useState(false);

	const handleFormFinish = async (value: any) => {
		try {
			setLoading(true);
			const { data } = await apiOms.patch('/update-sku-qty', {
				itemsWithQty: value,
				splitOrderId: order?.id,
				companyId: order?.companyId
			});
			data?.status &&
				notification.success({
					message: data?.message
				});
			isModalVisible(false);
			setReloadOrder((prevValue) => prevValue + 1);
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	};
	return (
		<>
			<Modal
				title="Fill form to update items Quantity"
				visible={true}
				onCancel={() => isModalVisible(false)}
				footer={null}
				width={600}
			>
				<Form size="small" layout="vertical" onFinish={handleFormFinish}>
					<table style={{ width: '100%', margin: '15px', fontSize: '12px' }}>
						<tr>
							{['Title', 'SKU', 'WSIN', 'Enter new quantity'].map((e) => (
								<th>{e}</th>
							))}
						</tr>

						{order?.items?.map((item: any) => (
							<tr key={item.id} style={{ verticalAlign: 'inherit' }}>
								<td>
									<Tooltip placement="top" title={item.title}>
										<h6 style={{ fontSize: '1.5vmin', textOverflow: 'ellipsis' }}>{item.title}</h6>
									</Tooltip>
								</td>
								<td>
									<h6 style={{ fontSize: '1.5vmin' }}>{item.sku}</h6>
								</td>
								<td>
									<h6 style={{ fontSize: '1.5vmin' }}>{getWSINMapFormat(item?.wsinMapping || {})}</h6>
								</td>
								<td>
									<Form.Item name={[item.id]} style={{ width: '85%' }}>
										<Input
											type="number"
											size="large"
											defaultValue={item?.quantity}
											placeholder="Enter Item Quantity"
											min={1}
										/>
									</Form.Item>
								</td>
								<Divider />
							</tr>
						))}
					</table>
					<Form.Item>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								type="primary"
								size="large"
								block
								htmlType="submit"
								loading={loading}
								style={{ position: 'sticky', top: '0px', width: '20rem' }}
							>
								Update Order
							</Button>
						</div>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};
