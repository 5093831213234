import Validator from 'helper/validator';
import { ERetailerStoreType, retailClusters } from '../../../constants/minq';

export function customFieldValidatorForCatalogue(value, fieldName) {
	const commonValidator = new Validator(value, fieldName);

	const rules = {
		itemName: () => commonValidator.required(),
		productId: () => commonValidator.required(),
		sku: () => commonValidator.required().isValidSku(),
		hsnCode: () => commonValidator.required(),
		category: () => commonValidator.required(),
		price: () => commonValidator.required().isValidNumber().minVal(0),
		length: () => commonValidator.required().isValidNumber().minVal(0),
		breadth: () => commonValidator.required().isValidNumber().minVal(0),
		height: () => commonValidator.required().isValidNumber().minVal(0),
		weight: () => commonValidator.required().isValidNumber().minVal(0),
		sgst: () => commonValidator.required().isValidNumber().minVal(0),
		cgst: () => commonValidator.required().isValidNumber().minVal(0),
		igst: () => commonValidator.required().isValidNumber().minVal(0),
		utgst: () => commonValidator.required().isValidNumber().minVal(0),
		basePrice: () => commonValidator.isValidNumber().minVal(0),
		margin: () => commonValidator.isValidNumber().minVal(0),
		distributorMargin: () => commonValidator.isValidNumber().minVal(0)
	};

	if (!rules[fieldName]) return null;

	const { errors } = rules[fieldName]();
	return errors[0] || null;
}

export function customFieldValidatorForOrder(value, fieldName) {
	const commonValidator = new Validator(value, fieldName);

	const rules = {
		id: () => commonValidator.required(),
		length: () => commonValidator.required().isValidNumber().minVal(1),
		breadth: () => commonValidator.required().isValidNumber().minVal(1),
		width: () => commonValidator.required().isValidNumber().minVal(1),
		height: () => commonValidator.required().isValidNumber().minVal(1),
		weight: () => commonValidator.required().isValidNumber().minVal(1),
		paymentMethod: () => commonValidator.required().valid(['cod', 'prepaid', 'credit']),
		itemName: () => commonValidator.required(),
		itemQuantity: () => commonValidator.required().isValidNumber().minVal(1),
		discount: () => commonValidator.required(),
		sku: () => commonValidator.required(),
		name: () => commonValidator.required().minLength(3),
		phone: () => commonValidator.required().isValidPhoneNumber(),
		email: () => commonValidator.required().isValidEmail(),
		line1: () => commonValidator.required().minLength(3),
		line2: () => commonValidator,
		city: () => commonValidator.required().minLength(3),
		state: () => commonValidator.required().minLength(3),
		pincode: () => commonValidator.required().isValidNumber().minLength(6).maxLength(6),
		awb: () => commonValidator.minLength(4).maxLength(15),
		deliveryPartner: () => commonValidator.minLength(3),
		shippingPrice: () => commonValidator.isValidNumber().minVal(0),
		isb2bOrder: () => commonValidator.valid(['yes', 'no', undefined]).minLength(0),
		isD2ROrder: () => commonValidator.valid(['yes', 'no', undefined]).minLength(0),
		beatId: () => commonValidator,
		dueDate: () => commonValidator
	};

	if (!rules[fieldName]) return null;

	const { errors } = rules[fieldName]();
	return errors[0] || null;
}

export function customFieldValidatorForWSINUpLoad(value, fieldName) {
	const commonValidator = new Validator(value, fieldName);

	const rules = {
		productName: () => commonValidator.required()
	};

	if (!rules[fieldName]) return null;

	const { errors } = rules[fieldName]();
	return errors[0] || null;
}

export function customFieldValidatorForWSINMapping(value, fieldName) {
	const commonValidator = new Validator(value, fieldName);

	const rules = {
		catalogueId: () => commonValidator.required(),
		productTitle: () => commonValidator.required(),
		productType: () => commonValidator.required(),
		wsinMap: () => commonValidator.required()
	};

	if (!rules[fieldName]) return null;

	const { errors } = rules[fieldName]();
	return errors[0] || null;
}

export function customFieldValidatorForShippingChargeAdjustment(value, fieldName) {
	const commonValidator = new Validator(value, fieldName);

	const rules = {
		awb: () => commonValidator.required(),
		shippingCharge: () => commonValidator.required(),
		isDto: () => commonValidator.required()
	};

	if (!rules[fieldName]) return null;

	const { errors } = rules[fieldName]();
	return errors[0] || null;
}

export function customFieldValidatorForInventory(value, fieldName) {
	const commonValidator = new Validator(value, fieldName);

	const rules = {
		wsin: () => commonValidator.required(),
		inboundAccepted: () => commonValidator.required(),
		inboundRejected: () => commonValidator.required(),
		remarks: () => commonValidator.required()
	};

	if (!rules[fieldName]) return null;

	const { errors } = rules[fieldName]();
	return errors[0] || null;
}

export function customFieldValidatorForLightningOrder(value, fieldName) {
	const commonValidator = new Validator(value, fieldName);

	const rules = {
		referenceId: () => commonValidator.required(),
		quantity: () => commonValidator.required(),
		weight: () => commonValidator.required(),
		totalPrice: () => commonValidator.required(),
		paymentMethod: () => commonValidator.required().valid(['cod', 'prepaid']),
		shippingPersonName: () => commonValidator.required(),
		shippingPersonPhone: () => commonValidator.required(),
		shippingLine1: () => commonValidator.required(),
		shippingCity: () => commonValidator.required(),
		shippingState: () => commonValidator.required(),
		shippingPincode: () => commonValidator.required(),
		awb: () => commonValidator.required()
	};

	if (!rules[fieldName]) return null;

	const { errors } = rules[fieldName]();
	return errors[0] || null;
}

export function customFieldValidatorForCODRemittance(value, fieldName) {
	const commonValidator = new Validator(value, fieldName);

	const rules = {
		awb: () => commonValidator.required(),
		codAmount: () => commonValidator.required(),
		status: () => commonValidator.required().valid(['due', 'paid'])
	};

	if (!rules[fieldName]) return null;

	const { errors } = rules[fieldName]();
	return errors[0] || null;
}

export function customFieldValidatorForD2rDiscount(value, fieldName) {
	const commonValidator = new Validator(value, fieldName);

	const rules = {
		discount: () => commonValidator.required(),
		catalogueId: () => commonValidator.required()
	};

	if (!rules[fieldName]) return null;

	const { errors } = rules[fieldName]();
	return errors[0] || null;
}

export function customFieldValidatorForD2rBeats(value, fieldName) {
	const commonValidator = new Validator(value, fieldName);

	const rules = {
		name: () => commonValidator.required(),
		description: () => commonValidator.required(),
		salesmanId: () => commonValidator.required(),
		retailerIds: () => commonValidator.required()
	};

	if (!rules[fieldName]) return null;

	const { errors } = rules[fieldName]();
	return errors[0] || null;
}

export function customFieldValidatorForExternalRetailers(value, fieldName) {
	const commonValidator = new Validator(value, fieldName);

	const rules = {
		name: () => commonValidator.required(),
		address: () => commonValidator.required(),
		pincode: () => commonValidator.required(),
		cluster: () => commonValidator.required().valid(retailClusters),
		phone: () => commonValidator.required().isValidPhoneNumber(),
		ownerName: () => commonValidator.required(),
		type: () => commonValidator.required().valid(Object.values(ERetailerStoreType)),
		class: () => commonValidator.required().valid(['A', 'B', 'C', 'D', 'E']),
		listingPrice: () => commonValidator.required(),
		noOfOutlets: () => commonValidator.required(),
		avgOnboardingTime: () => commonValidator,
		avgPaymentCycle: () => commonValidator,
		primaryProductCategory: () => commonValidator,
		secondaryProductCategory: () => commonValidator,
		tertiaryProductCategory: () => commonValidator,
		avgRepeatFrequency: () => commonValidator,
		avgOrderValue: () => commonValidator,
		avgOrderSize: () => commonValidator,
		lastOrderDate: () => commonValidator,
		lastOrderSize: () => commonValidator,
		lastOrderValue: () => commonValidator,
		isActive: () => commonValidator,
		coveredBy: () => commonValidator
	};

	if (!rules[fieldName]) return null;

	const { errors } = rules[fieldName]();
	return errors[0] || null;
}
