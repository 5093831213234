import { Modal } from 'antd';

export function RequestOwnWHModal({ isModalVisible = true, setIsModalVisible }) {
	const closeModal = () => setIsModalVisible(false);

	return (
		<Modal title="Add own Warehouse" visible={isModalVisible} okText="Yes" onCancel={closeModal}>
			Coming Soon!
		</Modal>
	);
}
