import { Input, Table, Form, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { validationMap } from '../../constants';
import { getFirstErrorField } from '../../utils';

const EditableCell = ({
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	setTableRows,
	errorRowKey,
	errorColumnName,
	previewConfigName,
	...restProps
}) => {
	const { value: initialValue } = record[dataIndex];
	const [value, setValue] = useState(initialValue);
	const errorRef = useRef();

	useEffect(() => {
		if (errorRef.current) {
			errorRef.current.focus();
		}
	}, [errorRowKey, errorColumnName]);

	const handleInputChange = (event) => {
		setValue(event.target.value);
	};

	const handleOnBlur = () => {
		// Return if no changes are made
		if (value === initialValue) return;

		setTableRows((tableRows) => {
			return tableRows.map((row) => {
				if (row.key === record.key) {
					const errorMessage = validationMap[previewConfigName](value, dataIndex);

					row[dataIndex] = { value, errorMessage };
				}

				return row;
			});
		});
	};

	const handleSetRef = () => (errorRowKey === record.key && errorColumnName === dataIndex ? errorRef : null);

	return (
		<td {...restProps}>
			<Form.Item style={{ margin: 0 }}>
				<Input value={value} onChange={handleInputChange} onBlur={handleOnBlur} style={{ border: 'none' }} ref={handleSetRef()} />
				{record[dataIndex]['errorMessage'] && <Typography.Text type="danger">{record[dataIndex]['errorMessage']}</Typography.Text>}
			</Form.Item>
		</td>
	);
};

export const BulkTable = ({ columns, dataSource, setTableRows, previewConfigName }) => {
	const { errorRowKey, errorColumnName } = getFirstErrorField(dataSource);

	const updatedColumns = columns.map((column) => {
		return {
			...column,
			onCell: (record) => ({
				record,
				title: column.title,
				dataIndex: column.dataIndex,
				setTableRows,
				errorRowKey,
				errorColumnName,
				previewConfigName
			})
		};
	});

	return (
		<Form component={false}>
			<Table
				columns={updatedColumns}
				dataSource={dataSource}
				pagination={false}
				scroll={{
					x: 'max-content',
					y: 'calc(85vh - 9.5rem)'
				}}
				size="small"
				sticky
				bordered
				components={{
					body: { cell: EditableCell }
				}}
			/>
		</Form>
	);
};
