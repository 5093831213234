import { Col, Row } from 'antd';
import { useState } from 'react';

import { TOP_FILTER_TABS, REMITTANCE_TABS } from './constants';

const renderCurrentTab = ({ topFilterSelectedTab, remittanceSelectedTab }) => {
	let currentTab = TOP_FILTER_TABS.find(({ key }) => key === topFilterSelectedTab);

	if (currentTab?.key === 'remittance') {
		currentTab = REMITTANCE_TABS.find(({ key }) => key === remittanceSelectedTab);
	}

	if (!currentTab?.component) {
		return <p>Component not found</p>;
	}

	return currentTab.component;
};

export function Invoicing() {
	const [topFilterOptions] = useState({
		topFilterSelectedTab: TOP_FILTER_TABS[0].key,
		remittanceSelectedTab: REMITTANCE_TABS[0].key
	});

	return (
		<>
			<h3 style={{ marginLeft: '1rem', marginTop: '1.5rem' }}>Invoicing</h3>
			<Row className="h-100 p-0">
				<Col className="w-100">{renderCurrentTab(topFilterOptions)}</Col>
			</Row>
		</>
	);
}
