import { Tag } from 'antd';
import { REVERSE_ORDER_STATUS_TYPES } from '../../../app/AppAdmin/Lightning/constants';
import { PAYMENT_METHOD } from '../../../constants';
import { orderStatusColor } from '../../utils/orderStatus';

export const columns = [
	{
		title: 'AWB',
		dataIndex: 'order',
		key: 'order.id',
		render: (order) => <span>{order.awb}</span>
	},
	{
		title: 'Payment Method',
		dataIndex: 'order',
		key: 'order.id',
		render: (order) => (
			<span>{`${order.paymentMethod} ${order.paymentMethod === PAYMENT_METHOD.COD ? `(₹${order.totalPrice})` : ''}`}</span>
		)
	},
	{
		title: 'Status',
		dataIndex: 'journeyOrderStatus',
		key: 'order.id',
		render: (journeyOrderStatus) => (
			<Tag color={orderStatusColor[REVERSE_ORDER_STATUS_TYPES[journeyOrderStatus]]}>
				{REVERSE_ORDER_STATUS_TYPES[journeyOrderStatus]}
			</Tag>
		)
	}
];
