import { Button, Col, notification } from 'antd';
import { useState } from 'react';
import { createWarehouse, getAllOmsWarehouses } from 'shared/api/lightning';
import { FormLayout } from 'shared/components/FormLayout';
import errorHandler from 'shared/utils/errorHandler';
import { AutoCompleteInput } from '../../../../shared/components/AutoCompleteInput.js/AutoCompleteInput';
import styles from '../index.module.scss';
import { addressFields } from '../utils';

export function CreateWarehouse() {
	const [selectedWarehouse, setSelectedWarehouse] = useState(null);

	async function createNewWarehouse(values) {
		try {
			const { data } = await createWarehouse(values);
			if (!data.status) throw new Error(data.message);
			if (data.status) {
				notification.success({
					message: 'Warehouse created',
					description: data.message,
					placement: 'topRight'
				});
			}
		} catch (error) {
			errorHandler(error);
		}
	}

	function handleOnSave(values) {
		createNewWarehouse({ ...values, warehouseId: selectedWarehouse.id });
	}

	const handleWarehouseSelect = (wh) => {
		setSelectedWarehouse(wh);
	};

	return (
		<Col span={8}>
			<FormLayout
				className={styles.form}
				formTitle={'Create Warehouse'}
				isClose={false}
				onSave={handleOnSave}
				formFields={[
					{
						label: 'Warehouse',
						fieldType: 'custom',
						component: (
							<AutoCompleteInput
								getOptions={getAllOmsWarehouses}
								entityName="Warehouses"
								optionKeyPath="id"
								optionLabelPath="name"
								optionValuePath="id"
								responseFieldPath="warehouses"
								placeholder="Search Warehouse"
								handleSelect={handleWarehouseSelect}
							/>
						)
					},
					{
						label: 'Warehouse Name',
						placeholder: '001',
						name: 'warehouseName',
						id: 'warehouseName',
						required: true
					},
					{
						label: 'POC Name',
						placeholder: 'Amit',
						name: 'name',
						id: 'name',
						required: true
					},
					{
						label: 'POC Phone',
						placeholder: '9999977777',
						name: 'phone',
						id: 'phone',
						required: true
					},
					...addressFields
				]}
				customSubmitComponent={
					<Button type="primary" shape="round" size="large" htmlType="submit">
						{'Save'}
					</Button>
				}
			/>
		</Col>
	);
}
