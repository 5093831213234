import { envs } from 'shared/utils/env';
const { appType } = envs;

const setupAxiosInterceptor = (axios) => {
	axios.interceptors.request.use(async (request) => {
		if (appType) {
			// passing appType to get the idea from which panel request has been made
			request.headers['appType'] = appType;
		}
		return request;
	});

	axios.interceptors.response.use(
		(response) => response,
		async (error) => {
			throw error;
		}
	);
};

export const initAxiosInterceptor = (axiosInstance) => {
	axiosInstance.forEach((element) => {
		setupAxiosInterceptor(element);
	});
};
