import React, { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AppLayout } from 'shared/components/AppLayout';
import { AuthContext } from 'shared/contexts/Auth';
import { Loading } from 'shared/components/Loading';
import { topBar } from './utils';
import { Orders, orderRoute } from './Orders';
import { bulkLightningOrderPreviewRoute } from '../../shared/components/UploadBulkPreview/constants';
import { BulkPreview } from '../../shared/components/UploadBulkPreview/Preview';
export const AppRoutes = ['/', '/lightning', '/app', orderRoute, bulkLightningOrderPreviewRoute];

export function App() {
	const currentRoute = get(useLocation(), 'pathname');

	const [loading, setLoading] = useState(false);
	const { authActions, authState } = useContext(AuthContext);

	useEffect(() => {
		setLoading(true);
		authActions.obtainProfileData().finally(() => setLoading(false));
	}, []);
	return (
		<>
			<Loading loading={loading} />
			{authState.profileLoaded && (
				<AppLayout currentRoute={currentRoute} topBar={topBar}>
					<Switch>
						<Route exact path={['/app', '/']} render={() => <Redirect to={orderRoute} />} />
						<Route exact path={orderRoute} component={Orders} />
						<Route exact path={bulkLightningOrderPreviewRoute} component={BulkPreview} />
						<Redirect from="*" to="/" />
					</Switch>
				</AppLayout>
			)}
		</>
	);
}
