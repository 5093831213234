import { AddressCard } from './Components/AddressCard';
import styles from './index.module.scss';

const pickupAddresses = [];

export function PickupAddress() {
	return (
		<div className={styles.pickupAddressPage}>
			<h5 className={styles.title}>Pickup Address</h5>

			<div className={styles.pickupAddressesList}>
				{pickupAddresses.map((value, index) => (
					<AddressCard key={index} />
				))}
			</div>
		</div>
	);
}
