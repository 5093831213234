import { Alert, Form, Input, Modal, notification, Select } from 'antd';
import React from 'react';
import { apiUms } from 'shared/api';
import errorHandler from 'shared/utils/errorHandler';
import { WEBHOOK_EVENT_TYPE } from '../constants';

type WebhookModalProps = {
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	isModalVisible: boolean;
	companyId: string;
	reloadPage: React.Dispatch<React.SetStateAction<number>>;
};
const WebhookModal = ({ setIsModalVisible, isModalVisible, companyId, reloadPage }: WebhookModalProps) => {
	const [form] = Form.useForm();
	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const createWebhooks = async (formData: any) => {
		try {
			const webhook = {
				webhookUrl: formData.webhookUrl,
				authHeaders: { ...(formData.key && formData.value && { [formData.key]: formData.value }) },
				eventType: formData.eventType
			};
			const {
				data: { status, message }
			} = await apiUms.post(
				`/ums/webhooks`,
				{ ...webhook },
				{
					headers: { 'x-company-id': companyId }
				}
			);
			if (!status) {
				errorHandler(new Error(message));
			}
			if (status) {
				notification.success({
					message: 'Success'
				});
				setIsModalVisible(false);
			}
		} catch (error) {
			errorHandler(error);
			setIsModalVisible(true);
		} finally {
			reloadPage((e) => e + 1);
		}
	};

	return (
		<Modal
			title="Create New Webhook"
			visible={isModalVisible}
			onCancel={handleCancel}
			onOk={() => form.validateFields().then((values) => createWebhooks(values))}
			okText="Create"
			cancelText="Cancel"
		>
			<Alert
				message="We will be hitting a POST request to the given callback"
				type="info"
				showIcon
				style={{ marginBlockEnd: '1rem' }}
				closable={true}
			/>
			<Form layout={'vertical'} form={form} requiredMark={true} onFinish={createWebhooks}>
				<Form.Item
					label="Webhook Url"
					name="webhookUrl"
					required
					rules={[
						{
							required: true,
							message: 'The Webhook Url is required.'
						}
					]}
				>
					<Input placeholder="Enter Webhook Url" />
				</Form.Item>
				<Form.Item label="Auth Headers" name="code" required>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
						<Form.Item label="Key" name="key">
							<Input placeholder="Enter key for header" />
						</Form.Item>
						-
						<Form.Item label="Value" name="value">
							<Input placeholder="Enter value for header" />
						</Form.Item>
					</div>
				</Form.Item>
				<Form.Item label="Event Type" name="eventType" initialValue={WEBHOOK_EVENT_TYPE.OMS_STATUS_UPDATE}>
					<Select defaultValue={WEBHOOK_EVENT_TYPE.OMS_STATUS_UPDATE}>
						<Select.Option value={WEBHOOK_EVENT_TYPE.OMS_STATUS_UPDATE}>{WEBHOOK_EVENT_TYPE.OMS_STATUS_UPDATE}</Select.Option>
						<Select.Option value={WEBHOOK_EVENT_TYPE.LIGHTNING_STATUS_UPDATE}>
							{WEBHOOK_EVENT_TYPE.LIGHTNING_STATUS_UPDATE}
						</Select.Option>
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default WebhookModal;
