import { Button, Card, Col, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { CUSTOMER_REQUEST_TYPE } from './constants';

export const CustomerRequestDetails = ({ customerRequest, order }) => {
	const requestType = customerRequest.requestType;

	const history = useHistory();
	return (
		<div style={{ margin: '1rem' }}>
			<div style={{ maxWidth: '50rem', margin: 'auto' }}>
				{requestType === CUSTOMER_REQUEST_TYPE.UPDATE_DETAILS && (
					<Card title="Request Details">
						<Row gutter={12}>
							<Col span={8}>Request Type</Col>
							<Col span={16}>Update deails</Col>
							<Col span={8}>Phone Number</Col>
							<Col span={16}>{customerRequest.alternatePhone}</Col>
							<Col span={8}>Preferred date</Col>
							<Col span={16}>{new Date(customerRequest.deferredDeliveryDate).toLocaleDateString()}</Col>
							<Col span={8}>Address</Col>
							<Col span={16}>{customerRequest.alternateAddress}</Col>
							<Col span={8}>Approval Status</Col>
							<Col span={16}>{customerRequest.approvalStatus}</Col>
						</Row>
					</Card>
				)}

				{requestType === CUSTOMER_REQUEST_TYPE.CANCEL_ORDER && (
					<Card title="Request Details">
						<Row gutter={12}>
							<Col span={8}>Request Type</Col>
							<Col span={16}>Cancel Order</Col>
							<Col span={8}>Issue Type</Col>
							<Col span={16}>{String(customerRequest.issueType).split('_').join(' ')}</Col>
							<Col span={8}>Issue Description</Col>
							<Col span={16}>{customerRequest?.issueDescription}</Col>
							<Col span={8}>Approval Status</Col>
							<Col span={16}>{customerRequest.approvalStatus}</Col>
						</Row>
					</Card>
				)}
			</div>

			<div style={{ marginTop: '2rem' }}>
				<Typography.Paragraph style={{ textAlign: 'center' }} strong={true} type="success">
					Your request has been submitted successfully.
				</Typography.Paragraph>
				<div style={{ margin: 'auto', width: 'fit-content' }}>
					<Button
						type="primary"
						onClick={() => {
							history.push(`/track/${order.deliveryAwb}`);
						}}
					>
						Track your order
					</Button>
				</div>
			</div>
		</div>
	);
};
