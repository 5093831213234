import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { getSalesChannels, syncOrderForShopType } from 'shared/api/settings';
import { STORE_TYPES } from '../../../../../constants';
import { InvoiceValidator } from 'shared/components/InvoiceValidator';
import { IntegrationsCard } from './Components/IntegrationsCard';
import styles from './index.module.scss';

const allowedFields = {
	[STORE_TYPES.SHOPIFY]: ['shop', 'key', 'secret', 'webhook_client_id'],
	[STORE_TYPES.WORDPRESS]: ['shop', 'key', 'secret'],
	[STORE_TYPES.UNICOMMERCE]: ['shop', 'key', 'secret', 'uc_client_id', 'webhook_client_id', 'shipping_provider_code'],
	[STORE_TYPES.EASYECOM]: ['key', 'secret', 'carrier_id'],
	[STORE_TYPES.SHIPWAY]: ['key', 'secret'],
	[STORE_TYPES.AMAZON]: ['key']
};

const defaultSalesChannel = {
	shop: '',
	key: '',
	secret: '',
	type: ''
};

const getSalesChannel = (salesChannels, salesChannelType) => {
	const salesChannel = salesChannels.find(({ type }) => type?.toLowerCase() === salesChannelType.toLowerCase());

	return salesChannel || { ...defaultSalesChannel, type: salesChannelType };
};

export function SalesChannels() {
	const [salesChannels, setSalesChannels] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isSyncLoading, setIsSyncLoading] = useState(false);
	const [syncShopType, setSyncShopType] = useState('');

	const fetchSalesChannels = async () => {
		try {
			setIsLoading(true);
			const {
				data: { salesChannels }
			} = await getSalesChannels();
			setSalesChannels(salesChannels);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchSalesChannels();
	}, []);

	const syncOrderData = async (shopType) => {
		try {
			setIsSyncLoading(true);
			setSyncShopType(shopType);
			await syncOrderForShopType(shopType);
		} catch (error) {
			console.log(error);
		} finally {
			setIsSyncLoading(false);
			setSyncShopType('');
		}
	};

	return (
		<div className={styles.integrationsPage}>
			<InvoiceValidator />
			<h4>Sales Channels</h4>

			{isLoading ? (
				<Spin />
			) : (
				<div className={styles.integrationCardsContainer}>
					{Object.entries(allowedFields).map(([channel, visibleFields], index) => {
						const salesChannel = getSalesChannel(salesChannels, channel);

						return (
							<IntegrationsCard
								key={index}
								syncOrderData={syncOrderData}
								visibleFields={visibleFields}
								salesChannel={salesChannel}
								isSyncLoading={isSyncLoading}
								syncShopType={syncShopType}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
}
