import { Button, Col, Form, Input, Modal, notification } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultPageConfig } from '../../../../constants';
import { apiHms } from '../../../../shared/api';
import { TopFilterComponent } from '../../../../shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from '../../../../shared/components/CustomTablePagination';
import errorHandler from '../../../../shared/utils/errorHandler';
import { ISalesmanFilter, d2rSalesmanColumns, defaultFilter, salesmanFilters } from './utils';
import { cleanObject } from 'helper';
import { IPageConfiguration } from 'app/AppAdmin/commonTypes';
import { SalesmanInfoSlider } from '../../../../shared/components/SalesmanInfoSlider/SalesmanInfoSlider';

export const Salesman = () => {
	const [salesmanData, setSalesmanData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [isCreateSalesmanModalVisible, setIsCreateSalesmanModalVisible] = useState(false);
	const [filter, setFilter] = useState<ISalesmanFilter>(defaultFilter);
	const [salesmanCount, setSalesmanCount] = useState(0);
	const setPageConfigDefault = () => {
		setPageConfig((v) => ({ ...v, ...defaultPageConfig }));
	};
	const [isSalesmanDrawerVisible, setIsSalesmanDrawerVisible] = useState<boolean>(false);
	const [salesmanId, setSalesmanId] = useState<string | number>('');
	const [reloadSalesmanList, setReloadSalesmanList] = useState<number>(0);
	const topFilterConfig = useMemo(
		() =>
			salesmanFilters({
				setIsCreateSalesmanModalVisible,
				setFilter,
				filter,
				setPageConfigDefault
			}),
		[filter]
	);
	const columnsConfig = useMemo(() => d2rSalesmanColumns(setIsSalesmanDrawerVisible, setSalesmanId), []);

	const getSalesmanData = useCallback(async () => {
		const { data } = await apiHms.post('/admin/salesman/list', {
			where: cleanObject({ ...filter }, ['', null, undefined]),
			pagination: {
				records: pageConfig.pageSize,
				pageNumber: pageConfig.current
			},
			sortBy: {
				order: pageConfig.sortField,
				by: pageConfig.sortOrder
			}
		});
		if (data?.status) {
			setSalesmanData(data?.salesman);
			setSalesmanCount(data?.count);
		}
	}, [filter, reloadSalesmanList, pageConfig]);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			await getSalesmanData();
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}, [getSalesmanData]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const onSubmitCreateSalesman = async (values: any) => {
		try {
			const { data } = await apiHms.post('/admin/salesman', { ...values });
			setIsCreateSalesmanModalVisible(false);
			fetchData();

			if (data.status) {
				notification.success({
					message: 'Salesman Created Successfully',
					description: data?.message,
					placement: 'topRight'
				});
			}
		} catch (err: any) {
			const errData = err?.response?.data;
			const errMsg = errData?.message || errData?.responseMessage || errData?.message || 'error creating salesman';

			notification.error({
				message: errMsg,
				placement: 'topRight'
			});
		}
	};

	const handleTableChange = (pagination: IPageConfiguration, filters: any, sorter: any) => {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'createdAt',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	};

	return (
		<>
			<Col sm={24} lg={24} span={24} className="main">
				<TopFilterComponent {...topFilterConfig} />
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={columnsConfig}
							total={salesmanCount}
							data={salesmanData}
							loading={loading}
							shouldRowSelection={false}
							selectedItem={undefined}
							selectedRowKeys={undefined}
							setSelectedRowKeys={undefined}
							setSelectedItem={undefined}
							onChangePage={(current: any, pageSize: any) => setPageConfig((v) => ({ ...v, current, pageSize }))}
							onChange={handleTableChange}
							{...pageConfig}
						/>
					</div>
					{isSalesmanDrawerVisible && (
						<SalesmanInfoSlider
							isSalesmanDrawerVisible={isSalesmanDrawerVisible}
							salesmanData={salesmanData.filter((R: any) => R.id === salesmanId)}
							setIsSalesmanDrawerVisible={setIsSalesmanDrawerVisible}
							setReloadSalesmanList={setReloadSalesmanList}
							salesmanId={salesmanId}
						/>
					)}
				</div>
			</Col>
			<Modal
				title={'Create Salesman'}
				visible={isCreateSalesmanModalVisible}
				onCancel={() => {
					setIsCreateSalesmanModalVisible(false);
				}}
				footer={null}
				centered
			>
				<Form size="small" layout="vertical" onFinish={onSubmitCreateSalesman}>
					<Form.Item
						name={'name'}
						label={'Name'}
						style={{ width: '65%' }}
						rules={[{ required: true, message: 'Please enter name' }]}
					>
						<Input size="middle" placeholder={`Enter Name`} />
					</Form.Item>

					<Form.Item
						name={'phone'}
						label={'Phone'}
						style={{ width: '65%' }}
						rules={[{ required: true, message: 'Please enter phone' }]}
					>
						<TextArea size="middle" placeholder={`Enter Phone`} />
					</Form.Item>

					<Form.Item>
						<Button
							type="primary"
							size="middle"
							block
							htmlType="submit"
							loading={loading}
							style={{ top: '20px', width: '10rem' }}
						>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};
