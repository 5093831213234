import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Sidebar } from 'shared/components/AppLayout/Sidebar';
import { SideDrawer } from 'shared/components/SideDrawer/SideDrawer';
import { Preferences } from '../../../shared/components/Preferences';
import { CustomizedNavbar } from './CustomizeTrackingPage/Navbar';
import styles from './index.module.scss';
import { Integrations } from './Integrations';
import { Invoicing } from './Invoicing';
import { PickupAddress } from './PickupAddress';
import { Profile } from './Profile';
import ShippingPackages from './ShippingPackages/ShippingPackages';
import { Support } from './Support';
import { TaskCenter } from './TaskCenter';
import { sidebarUtil } from './utils';
import { Warehouses } from './Warehouses';
import { Api } from './Api/Api.tsx';
import { Billing } from '../Billing/wallet';
import { Address } from './StoreAddress';

export const settingsRoute = '/administration';

const SETTINGS_SUB_PAGE_MAPPER = {
	1: <Profile />,
	2: <Integrations />,
	3: <Invoicing />,
	4: <PickupAddress />,
	5: <Warehouses />,
	6: <Address />,
	7: <CustomizedNavbar />,
	8: <Preferences />,
	9: <ShippingPackages />,
	10: <Billing />,
	11: <Api />,
	12: <TaskCenter />,
	13: <Support />
};

const SETTINGS_OPTIONS = {
	profile: 1,
	integrations: 2,
	invoicing: 3,
	warehouses: 5,
	storeAddress: 6,
	customizednavbar: 7,
	preferences: 8,
	shippingpackages: 9,
	wallet: 10,
	Api: 11,
	taskCenter: 12,
	support: 13
};

export function SettingsPage(props = {}) {
	const { search } = useLocation();
	const history = useHistory();
	const [isTrackingLayout, setIsTrackingLayout] = useState(null);

	let tab = search.split('=')[1];
	if (!tab) {
		tab = 'profile';
	}
	const [sidebarMenuItem, setSidebarMenuItem] = useState(SETTINGS_OPTIONS[tab]);

	useEffect(() => {
		let val;
		val = Object.keys(SETTINGS_OPTIONS).find((key) => SETTINGS_OPTIONS[key] == sidebarMenuItem);
		history.push({
			pathname: '/administration',
			search: `?tab=${val}`
		});
		if (+sidebarMenuItem === 7) {
			setIsTrackingLayout(1);
		} else setIsTrackingLayout(null);
	}, [sidebarMenuItem]);

	return (
		<Row className="h-100 p-0">
			<SideDrawer placement="left" isTrackingLayout={isTrackingLayout}>
				{
					<Sidebar
						setIsTrackingLayout={setIsTrackingLayout}
						isTrackingLayout={isTrackingLayout}
						sideBarMenu={sidebarUtil.sideBar}
						bottomButtons={null}
						selectedMenu={sidebarMenuItem}
						onMenuItemSelect={setSidebarMenuItem}
					/>
				}
			</SideDrawer>
			<Col sm={24} lg={isTrackingLayout === 1 ? 23 : 20} className={isTrackingLayout && styles.classForTransition}>
				<div className={styles.settingsPageContainer}>{SETTINGS_SUB_PAGE_MAPPER[sidebarMenuItem]}</div>
			</Col>
		</Row>
	);
}
