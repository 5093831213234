import { useContext } from 'react';
import { AuthContext } from 'shared/contexts/Auth';

const useInvoiceValidator = () => {
	const {
		authState: { profile }
	} = useContext(AuthContext);
	const { hasActiveWarehouse, isValidInvoicing } = profile;

	return {
		canIntegrateStore: hasActiveWarehouse && isValidInvoicing,
		hasActiveWarehouse,
		isValidInvoicing
	};
};

export default useInvoiceValidator;
