import { LinkOutlined, UploadOutlined } from '@ant-design/icons';
import { uuid4 } from '@sentry/utils';
import { Button, Card, Col, Input, notification, Row } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { moveArrayElement } from '../../../../helper';
import { apiUms } from '../../../../shared/api';
import { Loading } from '../../../../shared/components/Loading';
import drag_svg from '../../../../shared/svgs/drag_svg';
import errorHandler from '../../../../shared/utils/errorHandler';
import styles from './index.module.scss';
import { ColorPicker } from '../../../../shared/components/ColorPicker/ColorPicker';
import { TrackDetails } from './utils/TrackingLayout';
import ReactDOM from 'react-dom';
import { colorName } from '../../../../constants';
import { backgroundColor, buttonColor, commonTrackingLayout, headerLinkColor, primaryColor, secondaryColor } from './utils';

const MAX_DIMENSIONS = {
	maxHeight: 200,
	maxWidth: 400
};
const allowedImageFileTypes = /(\/jpg|\/jpeg|\/png|\/webp)$/i;

export const CustomizedNavbar = () => {
	const [trackingLink, setTrackingLink] = useState([]);
	const [editableLink, setEditableLink] = useState();
	const [isAddLink, setIsAddLink] = useState(false);
	const [editableLinkId, setEditableLinkId] = useState('');
	const [loading, setLoading] = useState(false);
	const [brandsTrackingLayout, setBrandsTrackingLayout] = useState(commonTrackingLayout);
	const [trackingLinkText, setTrackingLinkText] = useState({
		linkName: '',
		linkURL: ''
	});

	const handleChange = ({ name, value }) => {
		setBrandsTrackingLayout({
			...brandsTrackingLayout,
			[name]: value
		});
	};
	const onDragEnd = ({ source, destination }) => {
		if (!destination) return;

		const updatedArray = [...trackingLink];
		ReactDOM.unstable_batchedUpdates(() => {
			moveArrayElement(updatedArray, source.index, destination.index);
			setTrackingLink(updatedArray);
		});
	};

	const convertToBase64 = (file, img) => {
		let reader = new FileReader();
		let result;
		reader.onloadend = function () {
			result = reader.result;
			handleChange({ name: 'hl', value: { data: reader.result, name: 'Preview', url: img.src } });
		};
		reader.readAsDataURL(file);
		return result;
	};

	const onFileChange = ({ fileList }) => {
		try {
			const file = fileList.pop().originFileObj;
			const img = new Image();
			if (!allowedImageFileTypes.exec(file.type)) {
				throw new Error('Only images are allowed');
			}
			img.src = URL.createObjectURL(file);

			img.onload = () => {
				const { maxWidth, maxHeight } = MAX_DIMENSIONS;
				const isValid = img.width <= maxWidth && img.height <= maxHeight;

				if (!isValid) {
					return notification.error({
						message: 'Failed',
						description: `Image dimensions must be ${maxWidth} x ${maxHeight}`,
						placement: 'topRight'
					});
				}
				convertToBase64(file, img);
			};
		} catch (error) {
			errorHandler(error);
		}
	};
	const handleGetLayout = async () => {
		try {
			setLoading(true);
			const { data } = await apiUms.get('/ums/profile/get-tracking-layout');
			ReactDOM.unstable_batchedUpdates(() => {
				setBrandsTrackingLayout(data.responseBody.trackingLayout);
				setTrackingLink(data.responseBody.trackingLayout.links || []);
			});
		} catch (err) {
			errorHandler(err);
		} finally {
			setLoading(false);
		}
	};

	const handleOnPublish = async () => {
		try {
			setLoading(true);
			const {
				data: { message }
			} = await apiUms.patch('/ums/profile/tracking-layout', { trackingLayout: brandsTrackingLayout });
			notification.success({
				message: 'Tracking layout updated',
				description: message,
				placement: 'topRight'
			});
		} catch (err) {
			errorHandler(err);
		} finally {
			setTimeout(() => {
				setLoading(false);
			}, 2000);
		}
	};

	const handleLinkChange = (e) => {
		const { value, name } = e.target;
		setTrackingLinkText({
			...trackingLinkText,
			[name]: value,
			key: uuid4()
		});
	};

	const handleEditableLinkChange = (e) => {
		const { value, name } = e.target;
		setEditableLink({
			...editableLink,
			[name]: value
		});
	};

	const handleURLtextAdd = () => {
		try {
			if (trackingLink.length === 5) {
				throw new Error('Link should not be more than five');
			}
			if (!trackingLinkText.linkURL) {
				throw new Error('Kindly enter URL');
			}
			if (!trackingLinkText.linkName) {
				throw new Error('Kindly enter link Name');
			}
			ReactDOM.unstable_batchedUpdates(() => {
				setIsAddLink(false);
				setTrackingLink([
					...trackingLink,
					{
						linkName: trackingLinkText.linkName,
						linkURL: trackingLinkText.linkURL,
						key: uuid4(6)
					}
				]);
			});
		} catch (err) {
			errorHandler(err);
		}
	};
	const handleTextAddIntoLayout = () => {
		setBrandsTrackingLayout({
			...brandsTrackingLayout,
			links: trackingLink
		});
	};
	const handleRemoveLink = (id) => {
		const updatedLinks = trackingLink.filter((e) => {
			return e.key !== id;
		});
		setTrackingLink(updatedLinks);
	};

	const handleEditLinks = (id) => {
		const selectedLink = trackingLink.filter((e) => {
			return e.key === id;
		});
		ReactDOM.unstable_batchedUpdates(() => {
			setEditableLinkId(id);
			setEditableLink(selectedLink[0]);
		});
	};
	const handleAddUpdatedURL = () => {
		const updatedLinks = trackingLink.map((e) => {
			if (e.key === editableLink.key) {
				return { ...e, ...editableLink };
			}
			return e;
		});
		ReactDOM.unstable_batchedUpdates(() => {
			setEditableLinkId('');
			setTrackingLink(updatedLinks);
		});
	};

	const handleEnterClick = (event, fun) => {
		if (event.key === 'Enter') {
			fun();
		}
	};

	const handleResetLayout = async () => {
		setBrandsTrackingLayout(commonTrackingLayout);
		try {
			setLoading(true);
			const {
				data: { message }
			} = await apiUms.patch('/ums/profile/tracking-layout', { trackingLayout: commonTrackingLayout });
			notification.success({
				message: 'Tracking layout reset successfully',
				description: message,
				placement: 'topRight'
			});
		} catch (err) {
			errorHandler(err);
		} finally {
			setTimeout(() => {
				setLoading(false);
			}, 2000);
		}
	};
	useEffect(() => {
		handleGetLayout();
	}, []);

	useEffect(() => {
		handleTextAddIntoLayout();
	}, [trackingLink]);
	return (
		<>
			<Loading loading={loading}></Loading>
			<Card bodyStyle={{ padding: 0 }}>
				<Row>
					<Col span={18} style={{ width: 40, height: '95vh', overflow: 'scroll', padding: '1 1rem 0 0' }}>
						<TrackDetails
							setLoading={loading}
							headerFont={brandsTrackingLayout.hf}
							primaryColor={brandsTrackingLayout.pc}
							background={brandsTrackingLayout.bg}
							secondaryColor={brandsTrackingLayout.sc}
							headerLogo={brandsTrackingLayout.hl}
							trackingLink={trackingLink}
							buttonColor={brandsTrackingLayout.bc}
						/>
					</Col>
					<Col span={6} style={{ height: '92vh', paddingTop: '1rem' }} className={styles.headerChangerColumns}>
						<div>
							<div className={styles.publishButton}>
								<strong style={{ fontSize: 18 }}>Live Preview</strong>
								<Button
									style={{ background: '#46DD6B', border: 'none' }}
									onClick={handleOnPublish}
									type="primary"
									size="sm"
								>
									Publish
								</Button>
							</div>
							<div className={styles.overflowDiv}>
								<div>
									<Dragger
										name="avatar"
										listType="picture-card"
										className="avatar-uploader"
										accept="image/*"
										multiple={false}
										onChange={({ fileList }) => {
											onFileChange({ fileList });
										}}
										style={{ width: '100%', height: '100px' }}
									>
										<UploadOutlined />
										<p>
											<strong>Upload Logo</strong>
										</p>
										<p style={{ fontSize: 'small' }}>Max 400X200 px</p>
									</Dragger>
								</div>
								<strong>Select Primary Color</strong>
								<ColorPicker
									colorPickerColors={primaryColor}
									colorName={colorName.primary}
									color={brandsTrackingLayout.pc}
									handleChange={handleChange}
								/>
								<strong>Select Secondary Color</strong>
								<ColorPicker
									colorPickerColors={secondaryColor}
									colorName={colorName.secondary}
									color={brandsTrackingLayout.sc}
									handleChange={handleChange}
								/>
								<strong>Select Background Color</strong>
								<ColorPicker
									colorPickerColors={backgroundColor}
									colorName={colorName.background}
									color={brandsTrackingLayout.bg}
									handleChange={handleChange}
								/>
								<strong>Select Button Color</strong>
								<ColorPicker
									colorPickerColors={buttonColor}
									colorName={colorName.button}
									color={brandsTrackingLayout.bc}
									handleChange={handleChange}
								/>
								<strong style={{ fontSize: 22 }}>Add Links</strong>
								<Card bodyStyle={{ padding: '0.5rem' }}>
									<DragDropContext onDragEnd={onDragEnd}>
										<Droppable droppableId="droppable">
											{(provided) => (
												<div {...provided.droppableProps} ref={provided.innerRef}>
													{trackingLink?.length > 0 &&
														trackingLink.map((e, index) => {
															if (e.key === editableLinkId) {
																return (
																	<Draggable key={e.key} draggableId={e.key} index={index}>
																		{(provider) => (
																			<div
																				ref={provider.innerRef}
																				{...provider.draggableProps}
																				{...provider.dragHandleProps}
																			>
																				<Card
																					bodyStyle={{ padding: 0 }}
																					style={{ marginBottom: '0.2em' }}
																				>
																					<div className={styles.linksDiv}>
																						<div>
																							<span>{drag_svg}</span>
																							<p>{e.linkName}</p>
																						</div>
																						<div>
																							<Button
																								size="small"
																								onClick={handleAddUpdatedURL}
																							>
																								Save
																							</Button>
																							<Button
																								size="small"
																								disabled={isAddLink}
																								onClick={() => setEditableLinkId('')}
																							>
																								Cancel
																							</Button>
																						</div>
																					</div>
																				</Card>
																				<Card bodyStyle={{ padding: '0' }}>
																					<div className={styles.inputCardDiv}>
																						<Input.Group compact style={{ display: 'flex' }}>
																							<Input
																								type="text"
																								defaultValue={editableLink.linkName}
																								name="linkName"
																								onChange={handleEditableLinkChange}
																								placeholder="Edit Link Name"
																								onKeyUp={(x) =>
																									handleEnterClick(x, handleAddUpdatedURL)
																								}
																							/>
																						</Input.Group>
																						<Input.Group compact>
																							<Input
																								type="text"
																								onKeyUp={(x) =>
																									handleEnterClick(x, handleAddUpdatedURL)
																								}
																								placeholder="Edit Link URL"
																								defaultValue={editableLink.linkURL}
																								name="linkURL"
																								onChange={handleEditableLinkChange}
																							/>
																						</Input.Group>
																					</div>
																				</Card>
																			</div>
																		)}
																	</Draggable>
																);
															}
															return (
																<Draggable key={e.key} draggableId={e.key} index={index}>
																	{(provider) => (
																		<div
																			ref={provider.innerRef}
																			{...provider.draggableProps}
																			{...provider.dragHandleProps}
																		>
																			<Card
																				bodyStyle={{ padding: 0 }}
																				style={{ marginBottom: '0.2em' }}
																			>
																				<div className={styles.linksDiv}>
																					<div>
																						<span>{drag_svg}</span>
																						<p>{e.linkName}</p>
																					</div>
																					<div>
																						<Button
																							size="small"
																							disabled={isAddLink}
																							onClick={() => handleEditLinks(e.key)}
																						>
																							Edit
																						</Button>
																						<Button
																							size="small"
																							disabled={isAddLink}
																							onClick={() => handleRemoveLink(e.key)}
																						>
																							Delete
																						</Button>
																					</div>
																				</div>
																			</Card>
																		</div>
																	)}
																</Draggable>
															);
														})}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>
									<div>
										{!isAddLink && (
											<Button
												disabled={trackingLink?.length === 5}
												icon={<LinkOutlined />}
												onClick={() => setIsAddLink((e) => !e)}
												className={styles.addLinkButton}
											>
												Add Link
											</Button>
										)}
										{isAddLink && (
											<Card
												className={styles.addLinkCard}
												bodyStyle={{
													padding: '0 0.5rem',
													display: 'flex',
													flexDirection: 'column',
													rowGap: '0.5rem'
												}}
											>
												<Input.Group style={{ display: 'flex' }} compact>
													<Input
														name="linkName"
														onKeyUp={(e) => handleEnterClick(e, handleURLtextAdd)}
														onChange={handleLinkChange}
														placeholder="Enter Name"
														type="text"
													/>
												</Input.Group>
												<Input.Group style={{ display: 'flex' }} compact>
													<Input
														name="linkURL"
														onKeyUp={(e) => handleEnterClick(e, handleURLtextAdd)}
														onChange={handleLinkChange}
														placeholder="Enter URL"
														type="text"
													/>
													<Button onClick={handleURLtextAdd} type="primary">
														Save
													</Button>
												</Input.Group>
											</Card>
										)}
									</div>
								</Card>
								<strong>Select Header Link Color</strong>
								<ColorPicker
									colorPickerColors={headerLinkColor}
									colorName={colorName.headerFont}
									color={brandsTrackingLayout.hf}
									handleChange={handleChange}
								/>
								<Button style={{ marginTop: '1rem' }} onClick={handleResetLayout}>
									Reset Layout
								</Button>
							</div>
						</div>
					</Col>
				</Row>
			</Card>
		</>
	);
};
