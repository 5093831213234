import { IPageConfiguration } from 'app/AppAdmin/commonTypes';
import { apiBms } from '.';
import { IFilter } from '../../app/AppAdmin/Billing/CODRemittance/types';
import { IFilter as IPaymentFilter } from '../../app/AppAdmin/Billing/Payments/types';
import { ICreateTransactionObject, IWalletFilter } from '../../shared/components/Wallet/types';
import errorHandler from '../utils/errorHandler';

export function getDeliveredOrders({ pageConfiguration, filters }: { pageConfiguration: IPageConfiguration; filters: IFilter }) {
	try {
		const { start, end, searchOptionType, searchValue, remittanceStatus, companyID } = filters;
		const { current, pageSize, sortField, sortOrder } = pageConfiguration;
		const searchParams = new URLSearchParams();
		if (searchValue && searchOptionType) {
			searchParams.append(searchOptionType, searchValue.trim());
		}

		// apply filters
		const singleFieldMap = {
			order: sortField,
			by: sortOrder,
			records: pageSize,
			status: remittanceStatus,
			companyID: companyID,
			pageNumber: current
		};

		Object.entries(singleFieldMap).forEach(([fieldName, fieldValue]) => {
			if (fieldValue) {
				searchParams.append(fieldName, String(fieldValue));
			}
		});

		// attach other filters
		if (start && end) {
			searchParams.append('start', String(start));
			searchParams.append('end', String(end));
		}

		return apiBms.get(`/cod-remittance?${searchParams.toString()}`);
	} catch (error) {
		errorHandler(error);
	}
}

export function getBmsWalletTransaction({ pageConfiguration, filters }: { pageConfiguration: IPageConfiguration; filters: IWalletFilter }) {
	try {
		const { searchOptionType, searchValue, companyID } = filters;
		const { current, pageSize, sortField, sortOrder } = pageConfiguration;
		return apiBms.post(`brands/wallet/transaction/find`, {
			where: {
				searchOptionType: searchOptionType,
				searchValue: searchValue?.trim()
			},
			pagination: {
				records: pageSize,
				pageNumber: current
			},
			sortBy: {
				order: sortField,
				by: sortOrder
			},
			companyId: companyID
		});
	} catch (error) {
		errorHandler(error);
	}
}

export function createBmsWalletTransaction(createTransactionObject: ICreateTransactionObject) {
	try {
		return apiBms.post(`/wallet/transaction`, createTransactionObject);
	} catch (error) {
		errorHandler(error);
	}
}

export function getPayments({ pageConfiguration, filters }: { pageConfiguration: IPageConfiguration; filters: IPaymentFilter }) {
	try {
		const { start, end, searchOptionType, searchValue, paymentStatus } = filters;
		const { current, pageSize, sortField, sortOrder } = pageConfiguration;
		const searchParams = new URLSearchParams();
		if (searchValue && searchOptionType) {
			searchParams.append(searchOptionType, searchValue.trim());
		}

		// apply filters
		const singleFieldMap = {
			order: sortField,
			by: sortOrder,
			records: pageSize,
			status: paymentStatus,
			pageNumber: current
		};

		Object.entries(singleFieldMap).forEach(([fieldName, fieldValue]) => {
			if (fieldValue) {
				searchParams.append(fieldName, String(fieldValue));
			}
		});

		// attach other filters
		if (start && end) {
			searchParams.append('start', String(start));
			searchParams.append('end', String(end));
		}

		return apiBms.get(`/phonepe/wh-payments?${searchParams.toString()}`);
	} catch (error) {
		errorHandler(error);
	}
}

export function initiatePayment({ amount, mobile, message }: { amount: number; mobile: string; message: string }) {
	try {
		return apiBms.post(`/phonepe/initiate`, {
			amount,
			mobile,
			message
		});
	} catch (error) {
		errorHandler(error);
	}
}
