import { Button, Popconfirm, Row } from 'antd';
import { isSortField, tableSorter } from 'shared/utils/table';
import { PodCoordinateModal } from '../../components/GoogleMap/PodCoordinateModal';
import { formatCoordinate } from '../extra';

export const columns = ({ sortField, sortOrder, userType, handleAccountAction }) => [
	{
		title: 'Date',
		dataIndex: 'createdAt',
		render: (text) => <div className="text-center">{new Date(text).toLocaleDateString()}</div>,
		width: 150,
		sortOrder: isSortField(sortField, sortOrder, 'createdAt')
	},
	{
		title: `${userType} ID`,
		dataIndex: 'entityId',
		width: 150,
		render: (entityId) => <div className="text-center">{entityId}</div>
	},
	{
		title: `${userType} Name`,
		dataIndex: 'name',
		width: 200,
		render: (name) => <div className="text-center">{name}</div>
	},
	{
		title: 'Email',
		dataIndex: 'email',
		width: 200,
		render: (email) => <div className="text-center">{email}</div>
	},
	{
		title: 'POC',
		dataIndex: 'poc',
		width: 150,
		render: (poc) => <div className="text-center">{poc}</div>
	},
	{
		title: 'POC Number',
		dataIndex: 'pocPhone',
		width: 150,
		render: (pocPhone) => <div className="text-center">{pocPhone}</div>
	},
	{
		title: 'Address',
		dataIndex: 'address',
		width: 250,
		render: (address) => (
			<div className="text-center">{address?.line1 + ', ' + address?.line2 + ', ' + address?.city + ', ' + address?.state}</div>
		)
	},
	{
		title: 'Gst',
		dataIndex: 'gst',
		width: 150,
		render: (gst) => <div className="text-center">{gst}</div>
	},
	{
		title: 'Pan',
		dataIndex: 'pan',
		width: 150,
		render: (pan) => <div className="text-center">{pan}</div>
	},
	{
		title: 'Action',
		width: 150,
		fixed: 'right',
		render: (data) => (
			<Popconfirm onConfirm={() => handleAccountAction(data?.entityId, data?.inActive)} title="Do you want to confirm?">
				<Button type={data?.inActive ? 'primary' : 'danger'}>{data?.inActive ? 'Activate' : 'Deactivate'}</Button>
			</Popconfirm>
		)
	}
];

export const whcolumns = ({ sortField, sortOrder }) => [
	{
		title: 'Date',
		dataIndex: 'createdAt',
		render: (text) => <div className="text-center">{new Date(text).toLocaleDateString()}</div>,
		width: 150,
		sorter: {
			compare: (a, b) => tableSorter(a.createdAt, b.createdAt)
		},
		sortOrder: isSortField(sortField, sortOrder, 'createdAt')
	},
	{
		title: `WH ID`,
		dataIndex: 'id',
		width: 150,
		render: (entityId) => <div className="text-center">{entityId}</div>
	},

	{
		title: `WH Name`,
		dataIndex: 'name',
		width: 200,
		render: (name) => <div className="text-center">{name}</div>
	},
	{
		title: 'Phone',
		dataIndex: 'phone',
		width: 200,
		render: (Phone) => <div className="text-center">{Phone}</div>
	},
	{
		title: 'Email',
		dataIndex: 'email',
		width: 200,
		render: (email) => <div className="text-center">{email || ''}</div>
	},
	{
		title: 'Addressline 1',
		dataIndex: 'addressLine1',
		width: 150,
		render: (addressLine1) => <div className="text-center">{addressLine1}</div>
	},
	{
		title: 'Addressline 2',
		dataIndex: 'addressLine2',
		width: 200,
		render: (addressLine2) => <div className="text-center">{addressLine2}</div>
	},
	{
		title: 'Landmark',
		dataIndex: 'landmark',
		width: 200,
		render: (landmark) => <div className="text-center">{landmark}</div>
	},
	{
		title: 'City',
		dataIndex: 'city',
		render: (city) => <div className="text-center">{city}</div>,
		width: 150,
		sorter: {
			compare: (a, b) => tableSorter(a.city, b.city)
		},
		sortOrder: isSortField(sortField, sortOrder, 'city')
	},
	{
		title: 'State',
		dataIndex: 'state',
		render: (state) => <div className="text-center">{state}</div>,
		width: 150,
		sorter: {
			compare: (a, b) => tableSorter(a.state, b.state)
		},
		sortOrder: isSortField(sortField, sortOrder, 'state')
	},
	{
		title: 'Pincode',
		dataIndex: 'pincode',
		render: (pincode) => <div className="text-center">{pincode}</div>,
		width: 150,
		sorter: {
			compare: (a, b) => tableSorter(a.pincode, b.pincode)
		},
		sortOrder: isSortField(sortField, sortOrder, 'pincode')
	},
	{
		title: 'WH Type',
		dataIndex: 'IsSellerWarehouse',
		width: 200,
		render: (IsSellerWarehouse) => <div className="text-center">{IsSellerWarehouse ? 'Seller' : 'Wherehouse Owned'}</div>
	},
	{
		title: 'Coordinates',
		dataIndex: 'coordinate',
		width: 270,
		render: (coordinate) => {
			const lat = coordinate?.coordinates?.[0] || 0;
			const lng = coordinate?.coordinates?.[1] || 0;
			return (
				<div className="text-center">
					<PodCoordinateModal buttonText={`${lat}, ${lng}`} buttonType={'link'} coordinate={formatCoordinate(coordinate)} />
				</div>
			);
		}
	}
];

export const userColumns = ({ sortField, sortOrder, userType, handleAccountAction, setShowResetPassword }) => [
	{
		title: 'Date',
		dataIndex: 'createdAt',
		render: (text) => <div className="text-center">{new Date(text).toLocaleDateString()}</div>,
		width: 150,
		sortOrder: isSortField(sortField, sortOrder, 'createdAt')
	},
	{
		title: `${userType} ID`,
		dataIndex: 'entityId',
		width: 150,
		render: (entityId) => <div className="text-center">{entityId}</div>
	},
	{
		title: `${userType} Role`,
		dataIndex: 'userRole',
		width: 200,
		render: (userRole) => <div className="text-center">{userRole}</div>
	},
	{
		title: `${userType} Name`,
		dataIndex: 'name',
		width: 200,
		render: (name) => <div className="text-center">{name}</div>
	},
	{
		title: 'Email',
		dataIndex: 'email',
		width: 200,
		render: (email) => <div className="text-center">{email}</div>
	},
	{
		title: 'Phone',
		dataIndex: 'pocPhone',
		width: 200,
		render: (pocPhone) => <div className="text-center">{pocPhone}</div>
	},
	{
		title: 'Action',
		width: 150,
		fixed: 'right',
		render: (data) => (
			<Row>
				<div>
					<Popconfirm onConfirm={() => handleAccountAction(data?.entityId, data?.inActive)} title="Do you want to confirm?">
						<Button type={data?.inActive ? 'primary' : 'danger'}>{data?.inActive ? 'Activate' : 'Deactivate'}</Button>
					</Popconfirm>
				</div>
				<div style={{ marginTop: 10 }}>
					<Popconfirm onConfirm={() => setShowResetPassword(data?.entityId)} title="Do you want to reset?">
						<Button type={'primary'}>Reset Password</Button>
					</Popconfirm>
				</div>
			</Row>
		)
	}
];
