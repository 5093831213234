import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Input, Popconfirm } from 'antd';
import { useRef, useState } from 'react';
import debounce from '../../../utils/debounce';
import errorHandler from '../../utils/errorHandler';
import styles from './index.module.scss';

// creates a object with all input field as its key and their default values
const getEmptyInputRow = (inputFields) => {
	const obj = {};
	inputFields.forEach((field) => (obj[field.inputKey] = field.defaultValue));
	return obj;
};

/**
 *
 * @typedef InputField
 * @type {Object}
 */

/**
 *
 * @param {string} companyId - company id
 * @param {Function} handleSubmit - called with array of object, each object have all fields passed in inputFields
 * @param {InputField[]} inputFields - array input field object
 * @param {string} inputFields[].inputKey - name of field
 * @param {string|number} inputFields[].defaultValue - default value
 * @param {string} inputFields[].inputType - text/number
 * @param {boolean} inputFields[].autoComlete - true/false
 * @param {string} inputFields[].getOptions - function to fetch option for auto complete input
 * @param {string} inputFields[].placeholder - placeholder for input
 * @returns component which can have set of input fields also we can add new row of same set of input fields
 */
const DynamicInputs = ({ companyId, handleSubmit, inputFields }) => {
	const [fieldOptions, setFieldOptions] = useState([]);
	const debouncedHandleSearch = useRef(debounce(getAllOptions, 500));
	const [inputRows, setInputRows] = useState(Array(1).fill(getEmptyInputRow(inputFields)));

	async function getAllOptions(fieldName, searchText) {
		try {
			const targetFiled = inputFields.find((field) => field.inputKey === fieldName);
			if (!targetFiled) {
				return;
			}
			const getOptions = targetFiled.getOptions;
			const newOptions = await getOptions(searchText, fieldName, companyId);

			setFieldOptions(newOptions);
		} catch (error) {
			errorHandler(error);
		}
	}

	const handleAdd = () => {
		const defaultValueRow = getEmptyInputRow(inputFields);
		setInputRows((prev) => [...prev, defaultValueRow]);
	};

	const handleRemove = (index) => {
		if (inputRows.length !== 1) {
			const newInputs = inputRows;
			newInputs.splice(index, 1);
			setInputRows([...newInputs]);
		}
	};

	const handleInputFieldChange = (rowIndex, fieldName, fieldValue) => {
		setInputRows((prev) => prev.map((row, index) => (index === rowIndex ? { ...row, [fieldName]: fieldValue } : row)));
	};

	return (
		<>
			{inputRows.map((_row, rowIndex) => (
				<div key={rowIndex} className={styles.dynamicInputs}>
					{inputFields.map((field, index) => (
						<div key={index} className={styles.inputField}>
							{field.autoComlete ? (
								<AutoComplete
									key={`${field.inputKey}${index}`}
									value={inputRows[rowIndex][field.inputKey]}
									options={fieldOptions}
									style={{
										width: '100%'
									}}
									placeholder={field.placeholder || String(field.inputKey).toUpperCase()}
									onChange={(val) => handleInputFieldChange(rowIndex, field.inputKey, val)}
									onSearch={(searchText) => debouncedHandleSearch.current(field.inputKey, searchText)}
									onBlur={() => setFieldOptions([])}
								/>
							) : (
								<Input
									{...field}
									type={field.inputType}
									onChange={(e) => handleInputFieldChange(rowIndex, field.inputKey, e.target.value)}
									placeholder={field.placeholder || String(field.inputKey).toUpperCase()}
									className={styles.inputField}
									defaultValue={field.defaultValue}
									onBlur={() => setFieldOptions([])}
								/>
							)}
						</div>
					))}
					<Button icon={<PlusOutlined />} onClick={handleAdd} />
					<Button icon={<MinusOutlined />} onClick={() => handleRemove(rowIndex)} />
				</div>
			))}
			<Popconfirm title="Are you sure?" onConfirm={() => handleSubmit(inputRows)} onCancel={() => null} okText="Yes" cancelText="No">
				<Button size="small" type="primary dashed">
					Submit
				</Button>
			</Popconfirm>
		</>
	);
};

export default DynamicInputs;
