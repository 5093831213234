import { useEffect, useState, useMemo, useRef } from 'react';
import { whcolumns } from 'shared/utils/admin/companyWhColumns';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { Row, Col } from 'antd';
import { defaultPageConfig } from '../../../constants';
import { ErrorBoundary } from '@sentry/react';
import { getAllWherehouses } from 'shared/api/companies';
import { Fallback } from 'shared/components/ErrorBoundryFallback';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { topFilterUtil } from 'utils/admin/companyWhUtils';
import errorHandler from 'shared/utils/errorHandler';

export const WherehouseRoute = '/admin/wherehouses';

export function Wherehouses() {
	const isMounted = useRef(false);
	const [whPageConfig, setWhPageConfig] = useState(defaultPageConfig);
	const [whData, setWhData] = useState([]);
	const [filter, setFilter] = useState({ searchOptionType: 'name' });
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const searchOptions = useMemo(
		() => [
			{ value: 'name', label: 'Wherehouse Name' },
			{ value: 'phone', label: 'Phone Number' },
			{ value: 'id', label: 'Wherehouse ID' },
			{ value: 'pincode', label: 'Pincode' }
		],
		[]
	);

	const columnsConfig = useMemo(
		() => whcolumns({ sortField: whPageConfig.sortField, sortOrder: whPageConfig.sortOrder }),
		[whPageConfig.sortField, whPageConfig.sortOrder]
	);

	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				filter,
				setFilter,
				setPageConfigDefault,
				searchOptions
			}),
		[filter, searchOptions]
	);

	function setPageConfigDefault() {
		setWhPageConfig((v) => ({ ...v, ...defaultPageConfig }));
	}

	function handleTableChange(_pagination, _filters, sorter) {
		setWhPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'id',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	useEffect(function trackIsComponentMounted() {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(
		function listAllWarehouses() {
			setLoading(true);

			getAllWherehouses(whPageConfig, filter)
				.then(({ data }) => {
					if (!isMounted.current) return;
					setWhData(data.warehouses);
					setCount(data.count);
					if (data.message) {
						errorHandler(data.message);
					}
				})
				.catch(() => {
					if (!isMounted.current) return;
					setWhData([]);
				})
				.finally(() => {
					isMounted.current && setLoading(false);
				});
		},
		[whPageConfig, filter]
	);

	return (
		<Row className="h-100 p-0">
			<ErrorBoundary fallback={<Fallback fallBackMessage="Sorry, Failed to load data" />}>
				<Col span={24} className="main">
					<TopFilterComponent {...topFilterConfig} />

					<div className="innerDiv">
						<div className="div w-100 h-100">
							<CustomTablePagination
								{...whPageConfig}
								columns={columnsConfig}
								data={whData}
								showSorterTooltip={true}
								onChangePage={(current, pageSize) => setWhPageConfig((v) => ({ ...v, current, pageSize }))}
								total={count}
								onChange={handleTableChange}
								loading={loading}
								shouldRowSelection={false}
							/>
						</div>
					</div>
				</Col>
			</ErrorBoundary>
		</Row>
	);
}
