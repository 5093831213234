import { Table } from 'antd';
import { columns } from './columns';

export function Warehouses({ warehouses }) {
	return (
		<>
			<Table dataSource={warehouses} columns={columns} />
		</>
	);
}
