import { verifyOtpRoute } from 'app/AppSeller/VerifyOtp';
import React, { useContext } from 'react';

import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from 'shared/contexts/Auth';

export function PrivateAppRoute(props) {
	const { children, ...rest } = props;
	const {
		authState: { isLogined, profile, profileLoaded }
	} = useContext(AuthContext);

	return (
		<Route
			{...rest}
			render={({ location }) => {
				switch (true) {
					case isLogined && profileLoaded && !profile?.accountVerified && !profile?.isEmailVerified:
						return <Redirect to={{ pathname: verifyOtpRoute }} />;
					case isLogined:
						return children;
					default:
						return <Redirect to={{ pathname: '/signin', state: { from: location } }} />;
				}
			}}
		/>
	);
}

export function PrivateAuthRoute(props) {
	const { children, ...rest } = props;
	const {
		authState: { isLogined }
	} = useContext(AuthContext);
	return (
		<Route
			{...rest}
			render={({ location }) => {
				if (!isLogined) {
					//write condition
					return children;
				}
				return <Redirect to={{ pathname: '/app', state: { from: location } }} />;
			}}
		/>
	);
}
