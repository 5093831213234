export const searchOptions = [
	{ label: 'Name', value: 'name' },
	{ label: 'Code', value: 'code' }
];

export const defaultFilter = {
	searchOptionType: searchOptions[0].value,
	searchValue: '',
	active: 1
};
