export default (
	<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.6117 0H1.80068C0.80571 0 0 0.789982 0 1.76553V16.2345C0 17.21 0.80571 18 1.80068 18H16.6117C17.6066 18 18.4124 17.21 18.4124 16.2345V1.76553C18.4124 0.789982 17.6066 0 16.6117 0ZM16.9253 1.76553V16.2345C16.9253 16.4041 16.7847 16.542 16.6117 16.542H1.80068C1.62764 16.542 1.48705 16.4041 1.48705 16.2345V1.76553C1.48705 1.59587 1.62764 1.45803 1.80068 1.45803H16.6117C16.7847 1.45803 16.9253 1.59587 16.9253 1.76553Z"
			fill="#231F20"
		/>
		<path
			d="M13.1942 4.98358C12.9941 5.00479 12.8211 5.10022 12.6967 5.25398L8.23557 10.7786L6.01852 8.19654C5.88874 8.04809 5.71029 7.95795 5.51562 7.93675C5.32096 7.92084 5.12629 7.97917 4.97488 8.10641C4.82347 8.23366 4.73154 8.40862 4.71532 8.59949C4.6991 8.79566 4.75858 8.98122 4.88836 9.12968L7.68942 12.3957C7.83001 12.56 8.0409 12.6555 8.26801 12.6555C8.48972 12.6502 8.70061 12.5494 8.8412 12.3798L13.8647 6.15531C13.9891 6.00155 14.0432 5.81068 14.0215 5.61981C13.9999 5.42894 13.9026 5.25398 13.7458 5.13204C13.5835 5.01539 13.3943 4.96237 13.1942 4.98358Z"
			fill="#231F20"
		/>
	</svg>
);
