import { Select } from 'antd';

const { Option } = Select;

export const topBar = [
	{
		key: '2',
		route: '/orders',
		label: 'Orders'
	},
	{
		key: '3',
		route: '/catalogs',
		label: 'Catalog'
	},
	{
		key: '4',
		route: '/inventory',
		label: 'Inventory'
	},
	{
		key: '5',
		route: '/settings',
		label: 'Settings'
	}
];
