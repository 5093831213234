import { Modal, Form, Select, Input, InputNumber, Row, Col, notification } from 'antd';
import { indianStates } from 'constants/indianStates';
import { useContext, useState } from 'react';
import { apiWms } from 'shared/api';
import { AuthContext } from 'shared/contexts/Auth';
import errorHandler from 'shared/utils/errorHandler';

export function RequestNewMicroWHModal({ isModalVisible = true, setIsModalVisible }) {
	const [isLoading, setIsLoading] = useState(false);
	const closeModal = () => setIsModalVisible(false);
	const [form] = Form.useForm();
	const {
		authState: { profile }
	} = useContext(AuthContext);

	const { id, email, phone, name } = profile;

	const raiseWarehouseRequest = async (formValues) => {
		try {
			setIsLoading(true);

			const body = {
				...formValues,
				companyId: id,
				email,
				phone,
				name
			};

			const {
				data: { message }
			} = await apiWms.post('/warehouse/request', body);

			notification.success({
				description: message,
				position: 'topRight'
			});
		} catch (error) {
			console.log(error);
			errorHandler(error);
		} finally {
			setIsLoading(false);
		}
	};

	const onFinishForm = async () => {
		const formValues = await form.validateFields();
		raiseWarehouseRequest(formValues);
	};

	return (
		<Modal
			title="Request a new Micro-WH"
			visible={isModalVisible}
			onCancel={closeModal}
			confirmLoading={isLoading}
			okText="Submit Request"
			onOk={onFinishForm}
		>
			<Form form={form} layout="vertical">
				<Form.Item name="state" label="State" rules={[{ required: true, message: 'Please input State!' }]}>
					<Select placeholder="Delhi" showSearch>
						{indianStates.map(({ label, value }) => (
							<Select.Option value={value}> {label}</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item name="city" label="City" rules={[{ required: true, message: 'Please input City!' }]}>
					<Input placeholder="Delhi" />
				</Form.Item>

				<Row gutter={56}>
					<Col>
						<Form.Item
							name="ordersPerMonth"
							label="Expected no. of orders/Month"
							rules={[{ required: true, message: 'Please input Orders per month!' }]}
						>
							<InputNumber placeholder="400" />
						</Form.Item>
					</Col>

					<Col>
						<Form.Item
							name="estimatedUnits"
							label="Estimated units to store"
							rules={[{ required: true, message: 'Please input Estimated units to store!' }]}
						>
							<InputNumber placeholder="600" />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
