import { AutoComplete } from 'antd';
import { useState } from 'react';
import errorHandler from '../../utils/errorHandler';
import debounce from '../../../utils/debounce';

const resolveObjectPath = (object, path, defaultValue) => {
	return path.split('.').reduce((cum, curr) => (cum ? cum[curr] : defaultValue), object);
};

/**
 *
 * @typedef {Object} Option
 * @property {string} id
 * @property {string} name
 */

/**
 * This Component can be used as auto complete input field
 * @param {string} placeholder
 * @param {string} entityName - name of entity eg. brand, warehouse, category
 * @param {string} responseFieldPath - path of key in response body which containes list of entity items
 * @param {string} optionKeyPath - path of unique key in entity object for react
 * @param {string} optionValuePath - path of value in entity object
 * @param {string} optionLabelPath - path of label in entity object
 * @param {number} debounceTime - debounce time default value is 500ms
 * @param {Function} getOptions - fetcher function for entity
 * @param {Function} handleSelect - This function is called with selected option which can be either null or an object type
 *
 * @returns
 */
export function AutoCompleteInput(props) {
	const {
		placeholder,
		getOptions,
		entityName,
		responseFieldPath,
		optionKeyPath,
		optionValuePath,
		optionLabelPath,
		debounceTime = 500,
		children,
		handleChange,
		handleSelect,
		selectedFilterItems
	} = props;
	const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState(selectedFilterItems);
	async function getAllOptions(searchValue) {
		try {
			const { data } = await getOptions(searchValue);
			if (data.status) {
				if (Array.isArray(resolveObjectPath(data, responseFieldPath, null))) {
					setOptions(
						resolveObjectPath(data, responseFieldPath, []).map((option) => ({
							key: resolveObjectPath(option, optionKeyPath, null),
							value: resolveObjectPath(option, optionValuePath, null),
							label: resolveObjectPath(option, optionLabelPath, null),
							data: option
						}))
					);
				} else {
					throw new Error(`Couldn't get ${entityName}`);
				}
			} else throw new Error(`Couldn't get ${entityName}`);
		} catch (error) {
			errorHandler(error);
		}
	}

	const handleOptionChange = (option) => {
		if (!option) {
			setSelectedOption(null);
			handleSelect(null);
		} else {
			const { id, name } = JSON.parse(option);
			const selectedOp = options.find((op) => op.value === id && op.label === name);
			setSelectedOption(selectedOp?.data);
			handleSelect(selectedOp?.data);
		}
	};

	const debouncedSearchChange = debounce((input) => {
		if (input.trim()) {
			getAllOptions(input);
		} else {
			setOptions([]);
		}
	}, debounceTime);

	const handleKeyDown = (event) => {
		if (event.key === 'f') {
			event.stopPropagation();
		}
	};

	return (
		<>
			{children}
			<AutoComplete
				placeholder={placeholder}
				onSearch={debouncedSearchChange}
				onClear={handleOptionChange}
				onSelect={handleOptionChange}
				style={{ width: '100%' }}
				allowClear
				value={selectedOption?.name}
				notFoundContent={`No ${entityName} found`}
				autoFocus={true}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
			>
				{options.map(({ key, value, label }) => {
					return (
						<AutoComplete.Option key={key} value={JSON.stringify({ id: value, name: label })}>
							{label}
						</AutoComplete.Option>
					);
				})}
			</AutoComplete>
		</>
	);
}
