import { Button, Select } from 'antd';
import { envs } from '../../../../shared/utils/env';
import { companyType } from '../D2r';

const { cmsBaseUrl } = envs;
interface IDiscountFilter {
	company: companyType;
	setIsUploadExcelModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	storeAddress: any[];
	setStoreId: React.Dispatch<React.SetStateAction<null | string>>;
	storeId: string;
	setCatalogues: React.Dispatch<React.SetStateAction<never[]>>;
}

export const discountFilters = (props: IDiscountFilter) => {
	return {
		row1: [],
		row1children: [
			<div style={{ width: '15rem' }}>
				<label htmlFor="Select_Retail">Select Retailer:</label>
				<Select
					id="Select_Retail"
					allowClear
					onClear={() => props.setCatalogues([])}
					style={{ width: '400px' }}
					placeholder="Select Store Address"
					showSearch
					disabled={!props.company.id}
					optionFilterProp="children"
					defaultValue={props.storeId}
					onSelect={(shopId: string) => props.setStoreId(shopId)}
				>
					{props.storeAddress?.map(({ id, firstName, lastName, pincode, addressLine1, isActive }) => {
						return (
							isActive && <Select.Option value={id}>{`${firstName} ${lastName}, ${pincode}, ${addressLine1}`}</Select.Option>
						);
					})}
				</Select>
			</div>
		],
		row2: [
			<div style={{ display: 'flex', gap: '1rem' }}>
				<Button
					disabled={!props.storeId || !props.company.id}
					type="primary"
					onClick={() => handleDownloadCatalogues(props.company.id, props.storeId)}
				>
					Download Catalogues
				</Button>
				<Button
					disabled={!props.storeId || !props.company.id}
					type="primary"
					onClick={() => props.setIsUploadExcelModalVisible(true)}
				>
					Upload Catalogue
				</Button>
			</div>
		]
	};
};

export const d2rDiscountPricingColumn = () => [
	{
		title: 'Serial No',
		render: (data: any) => <span>{data?.catalogue.productId}</span>
	},
	{
		title: 'Item Name',
		render: (data: any) => <span>{data?.catalogue.productTitle}</span>
	},
	{
		title: 'SKU Id',
		render: (data: any) => <span>{data?.catalogue.sku}</span>
	},
	{
		title: 'Category',
		render: (data: any) => <span>{data?.catalogue.category}</span>
	},

	{
		title: 'Discount (%)',
		dataIndex: 'discount'
	},
	{
		title: 'Total Price',
		render: (data: any) => <span>{data?.catalogue.price}</span>
	},
	{
		title: 'Final Price',
		render: (data: any) => <span>{Number(data?.catalogue.price) - Number(data?.discount)}</span>
	}
];

export function handleDownloadCatalogues(companyId: null | string, storeId: string) {
	const url = new URL(`${cmsBaseUrl}/csv/catalogue/pricing/${companyId}/${storeId}`);
	window.open(url);
}
