import { useEffect, useState } from 'react';
import { getCompanyInventory } from '../../shared/api/inventory';
import errorHandler from '../../shared/utils/errorHandler';

export default function useInventory({ pageConfig, filter, warehouseID, companyID, isWMS = false, reloadInventoryList }) {
	const [inventory, setInventory] = useState([]);
	const [inventoryCount, setInventoryCount] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function getInventory() {
			try {
				setLoading(true);
				const {
					data: { status, inventory: fetchedInventory, totalCount: totalItemsCount, message }
				} = await getCompanyInventory(pageConfig, {
					...filter,
					warehouseId: warehouseID,
					...(companyID && { companyId: companyID })
				});

				if (status) {
					setInventory(fetchedInventory);
					setInventoryCount(totalItemsCount);
				} else {
					throw new Error(message || 'Could not get inventory');
				}
			} catch (error) {
				errorHandler(error);
			} finally {
				setLoading(false);
			}
		}
		getInventory();
	}, [pageConfig, filter, warehouseID, companyID, isWMS, reloadInventoryList]);

	return {
		inventory,
		inventoryCount,
		loading
	};
}
