import { adminRoute } from './Admin';
import { billingRoute } from './Billing/Billing';
import { brandsRoute } from './Brands';
import { d2rRoute } from './D2R/D2r';
import { lightningRoute } from './Lightning';

export const topBar = [
	{
		key: '1',
		route: adminRoute,
		label: 'Home'
	},
	{
		key: '2',
		route: lightningRoute,
		label: 'Lightning'
	},
	{
		key: '3',
		route: brandsRoute,
		label: 'Brands'
	},
	{
		key: '4',
		route: d2rRoute,
		label: 'D2R'
	},
	{
		key: '5',
		route: billingRoute,
		label: 'Billing'
	}
];
