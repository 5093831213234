import { Col, Row } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getDeliveredOrders } from '../../../../shared/api/bms';
import { TopFilterComponent } from '../../../../shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from '../../../../shared/components/CustomTablePagination';
import errorHandler from '../../../../shared/utils/errorHandler';
import useColumns from './columns';
import { defaultFilter, defaultPageConfig } from './constants';
import { ICODRemittanceDeliveredOrder, IFilter } from './types';
import { topFilterUtil } from './utils';
import { IPageConfiguration } from '../../commonTypes';

export default function CODRemittance() {
	const [data, setData] = useState<ICODRemittanceDeliveredOrder[]>([]);
	const [currentCount, setCurrentCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<IFilter>(defaultFilter);
	const [companies, setCompanies] = useState([]);
	const [pageConfig, setPageConfig] = useState<IPageConfiguration>(defaultPageConfig);
	const columns = useColumns();

	const setPageConfigDefault = useCallback(() => {
		setPageConfig(defaultPageConfig);
	}, []);

	const topFilterConfig = useMemo(
		() => topFilterUtil({ filter, setFilter, setPageConfigDefault, companies, setCompanies }),
		[filter, companies, setFilter, setPageConfigDefault, setCompanies]
	);

	useEffect(() => {
		async function getAllDeliveredOrders({ pageConfiguration, filters }: any) {
			try {
				setLoading(true);
				const response = await getDeliveredOrders({ pageConfiguration, filters });
				if (response?.data.status) {
					setData(response.data.data);
					setCurrentCount(response.data.currentCount);
				} else {
					throw new Error(response?.data.message || 'Could not get delivered orders');
				}
			} catch (error: unknown) {
				errorHandler(error);
			} finally {
				setLoading(false);
			}
		}
		getAllDeliveredOrders({ pageConfiguration: pageConfig, filters: filter });
	}, [filter, pageConfig]);

	return (
		<>
			<Row className="h-100 p-0">
				<Col span={24} className="main">
					<TopFilterComponent {...topFilterConfig} />
					<div className="innerDiv">
						<div className="div w-100 h-100">
							<CustomTablePagination
								columns={columns}
								data={data}
								onChangePage={(current: any, pageSize: any) => setPageConfig((v) => ({ ...v, current, pageSize }))}
								current={Number(pageConfig.current)}
								total={currentCount}
								loading={loading}
								shouldRowSelection={false}
								selectedItem={undefined}
								catalog={undefined}
								selectedRowKeys={undefined}
								setSelectedRowKeys={undefined}
								setSelectedItem={undefined}
								pageSize={Number(pageConfig.pageSize)}
								sortField={pageConfig.sortField}
								sortOrder={pageConfig.sortField}
							/>
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
}
