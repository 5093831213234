import dayjs from 'dayjs';

export const commonTopFilterRow = (startDate: any, endDate: any) => {
	console.log(`startDate - ${startDate} and endDate - ${endDate}`);
	return {
		label: 'FILTER BY DATE',
		type: 'range',
		allowClear: false,
		value: [dayjs(startDate), dayjs(endDate)],
		defaultValue: [dayjs(startDate), dayjs(endDate)],
		ranges: {
			Today: [dayjs().startOf('date'), dayjs()],
			'Last 1 Week': [dayjs().subtract(1, 'w'), dayjs()],
			'Last 1 Month': [dayjs().subtract(1, 'M'), dayjs()],
			'Last 3 Months': [dayjs().subtract(3, 'M'), dayjs()],
			'Last 1 Year': [dayjs().subtract(1, 'y'), dayjs()]
		}
	};
};
