import { fetchApi } from '../../../../helper/fetchApi';
import useQuery from '../../../../helper/useQuery';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';

export function PermissionRedirectComponent() {
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();
	const query = useQuery();
	const code = query.get('code');
	const host = query.get('host');
	const state = query.get('state');

	const postShopifyData = async () => {
		try {
			setIsLoading(true);

			const data = {
				authorizationCode: code,
				host,
				state
			};

			await fetchApi({
				url: '/ums/shopify-integration/permission',
				method: 'post',
				data
			});

			notification.success({
				message: 'Success',
				description: 'Shopify integrated successfully.',
				placement: 'topRight'
			});
			history.push('/');
		} catch (error) {
			console.log(error);
			notification.error({
				message: 'Shopify integration failed',
				description: error.message,
				placement: 'topRight'
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		postShopifyData();
	}, []);

	return <div>{isLoading ? <h4>Loading...</h4> : null}</div>;
}
