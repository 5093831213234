import { Button, Modal, Space } from 'antd';
import styles from './index.module.scss';
import { DatePicker } from 'shared/components/DatePickers';
import dayjs from 'dayjs';
import { commonTopFilterRow } from '../../../utils/filters';

export function FilterDTOModal({ title, visible, onCancel, footer, width, onFinish, instructionMessage, loading, setDTODate }) {
	const topFilterRow = commonTopFilterRow(dayjs(dayjs().subtract(30, 'day')), dayjs(dayjs()));
	return (
		<Modal title={title} visible={visible} onCancel={onCancel} footer={footer} width={width}>
			<Space direction="vertical" className={'ml-0'} key={'1'}>
				<label style={{ marginBottom: '30px' }}>{instructionMessage}</label>

				<label className={styles.filterByDateTitle}>Filter By Date</label>
				<DatePicker.RangePicker
					{...{
						...topFilterRow,
						label: 'FILTER BY DATE(DTO_PLACED)',
						onChange: (dates, dateStrings) => setDTODate(...dateStrings)
					}}
				/>
			</Space>
			<div onClick={onFinish} style={{ marginTop: '30px' }}>
				<Button loading={loading} type="primary">
					Download
				</Button>
			</div>
		</Modal>
	);
}
