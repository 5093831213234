import { Timeline, Typography } from 'antd';
import { REVERSE_ORDER_STATUS_TYPES } from 'app/AppAdmin/Lightning/constants';
import { formatDate, formatTime } from 'shared/utils/date';
import { orderStatus, orderStatusColor } from 'shared/utils/orderStatus';

export function HMSTrail({ trailDetails }) {
	return (
		<Timeline.Item color={orderStatusColor[REVERSE_ORDER_STATUS_TYPES[trailDetails.status]]}>
			<p style={{ alignItems: 'center', marginBottom: 0, fontWeight: 'normal' }}>
				{orderStatus[REVERSE_ORDER_STATUS_TYPES[trailDetails.status]]}
				<Typography.Paragraph style={{ fontWeight: 300, fontSize: '0.8rem' }}>{trailDetails.description}</Typography.Paragraph>
			</p>
			<p style={{ fontSize: '0.7rem', fontWeight: 'lighter' }}>
				{formatDate(trailDetails.updatedAt)} | {formatTime(trailDetails.updatedAt)}
			</p>
		</Timeline.Item>
	);
}
