import { Col, Row, Form, Input } from 'antd';

export const FulfilllmentDetails = ({ customStyle, existOrderData }) => {
	return (
		<div className={customStyle}>
			<h4>Fulfillment Details</h4>

			<Row gutter={36}>
				<Col span={8}>
					<Form.Item name="awb" label="AWB">
						<Input placeholder="873690182" />
					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item name="awbPartner" label="Delivery Provider">
						<Input placeholder="Blue Dart" />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={36}>
				<Col span={8}>
					<Form.Item
						name="shippingLabelUrl"
						label="Shipping Label URL"
						rules={[{ type: 'url', message: 'Please input a valid URL!' }]}
					>
						<Input placeholder="" />
					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item name="invoiceUrl" label="Invoice URL" rules={[{ type: 'url', message: 'Please input a valid URL!' }]}>
						<Input placeholder="" />
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};
