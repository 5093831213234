import { Col, notification } from 'antd';
import { createWarehouseUser } from 'shared/api/lightning';
import errorHandler from 'shared/utils/errorHandler';
import { createUserForm } from '../utils';

export function CreateWarehouseUser({ warehouses }) {
	async function createNewWarehouseUser(values) {
		try {
			const { data } = await createWarehouseUser(values);
			if (!data.status) throw new Error(data.message);
			if (data.status) {
				notification.success({
					message: 'Warehouse user created',
					description: data.message,
					placement: 'topRight'
				});
			}
		} catch (error) {
			errorHandler(error);
		}
	}

	function handleOnSave(values) {
		createNewWarehouseUser(values);
	}

	return (
		<Col span={8}>
			{createUserForm({ formTitle: 'Create Warehouse User', onSave: handleOnSave, isWarehouseUser: true, warehouses: warehouses })}
		</Col>
	);
}
