import { Button, Form, Input, Row, Select, Slider, notification } from 'antd';
import styles from './index.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { apiOms } from '../../../../shared/api';
import { Option } from 'antd/lib/mentions';
import { envs } from '../../../../shared/utils/env';

export default function Recomendation() {
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const { d2rBaseUrl } = envs;
	const getCategories = useCallback(async () => {
		try {
			const { data } = await apiOms.get('/d2r/categories');
			setCategories(data?.data);
		} catch (error: any) {
			notification.error({
				message: 'Failed to fetch categories',
				description: typeof error.message === 'string' ? error.message : 'Something went wrong',
				placement: 'topRight'
			});
		}
	}, []);

	const handleFinish = async (values: any) => {
		try {
			setLoading(true);
			const { category, type, packSize, online, price, weight, listing, aov, maturity, volume, skus, clusters } = values;

			const url = new URL(
				`${d2rBaseUrl}/recommend-retailers?category=${category}&type=${type}&packSize=${packSize}&online=${online}&price=${price}&weight=${weight}&listing=${listing}&aov=${aov}&maturity=${maturity}&bv=${volume}&skus=${skus}&clusters=${clusters}`
			);
			window.open(url);
		} catch (error: any) {
			notification.error({
				message: 'Transaction Creation',
				description: typeof error.message === 'string' ? error.message : 'Something went wrong',
				placement: 'topRight'
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getCategories();
	}, []);

	return (
		<div className={styles.page}>
			<div style={{ width: '45%' }}>
				<Row>
					<h3>Retailers Recomendation Engine</h3>
				</Row>
				<br />
				<br />
				<Row>
					<h5>Enter Product Detailes</h5>
				</Row>
				<br />
				<Row>
					<Form onFinish={handleFinish} layout="vertical" className={styles.form}>
						<Form.Item label="Product Name" name={'name'} rules={[{ required: true, message: 'Required' }]}>
							<Input />
						</Form.Item>

						<Form.Item label="Product Category" name={'category'} rules={[{ required: true, message: 'Required' }]}>
							<Select
								onSelect={() => {}}
								placeholder={'Select Product Category'}
								style={{ width: '30vw' }}
								allowClear
								showSearch
							>
								{categories.map((category) => (
									<Option key={category} value={category}>
										{category}
									</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label="Product Type" name={'type'} rules={[{ required: true, message: 'Required' }]}>
							<Select onSelect={() => {}} placeholder={'Select Product type'} style={{ width: '30vw' }} allowClear showSearch>
								{['Premium', 'Sub-premium', 'Mass', 'Niche'].map((type) => (
									<Option key={type} value={type}>
										{type}
									</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label="Pack Size" name={'packSize'} rules={[{ required: true, message: 'Required' }]}>
							<Select onSelect={() => {}} placeholder={'Select Pack Size'} style={{ width: '30vw' }} allowClear showSearch>
								{['Sachet', 'Small', 'Medium', 'Large', 'Family Pack'].map((packSize) => (
									<Option key={packSize} value={packSize}>
										{packSize}
									</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label="Online Presence" name={'online'} rules={[{ required: true, message: 'Required' }]}>
							<Select
								onSelect={() => {}}
								placeholder={'Select Online Presence'}
								style={{ width: '30vw' }}
								allowClear
								showSearch
							>
								{['Ecom', 'Ecom & Quick comm', 'No', 'Very few'].map((online) => (
									<Option key={online} value={online}>
										{online}
									</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label="Industry" name={'industry'} rules={[{ required: true, message: 'Required' }]}>
							<Select onSelect={() => {}} placeholder={'Select Industry'} style={{ width: '30vw' }} allowClear showSearch>
								{['FMCG', 'Pharma', 'Cosmetics', 'Electronics', 'Other'].map((online) => (
									<Option key={online} value={online}>
										{online}
									</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label="Brand Age" name={'maturity'} rules={[{ required: true, message: 'Required' }]}>
							<Select onSelect={() => {}} placeholder={'Select'} style={{ width: '30vw' }} allowClear showSearch>
								{['New', 'Regional', 'National', 'Challenge'].map((online) => (
									<Option key={online} value={online}>
										{online}
									</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label="Price (₹)" name={'price'} rules={[{ required: true, message: 'Required' }]}>
							<Input type="number" />
						</Form.Item>

						<Form.Item label="Weight (grams)" name={'weight'} rules={[{ required: true, message: 'Required' }]}>
							<Input type="number" />
						</Form.Item>

						<Form.Item label="Target Listing Store" name={'listing'} rules={[{ required: true, message: 'Required' }]}>
							<Select onSelect={() => {}} placeholder={'Select'} style={{ width: '30vw' }} allowClear showSearch>
								{['Yes', 'No'].map((online, index) => (
									<Option key={online} value={online}>
										{online}
									</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label="Average Order Value (₹)" name={'aov'} rules={[{ required: true, message: 'Required' }]}>
							<Input type="number" />
						</Form.Item>

						<Form.Item label="Business Size (Cr)" name={'volume'} rules={[{ required: true, message: 'Required' }]}>
							<Input type="number" />
						</Form.Item>

						<Form.Item
							label="Catalogue Size (Total SKUs of Brand)"
							name={'skus'}
							rules={[{ required: true, message: 'Required' }]}
						>
							<Slider></Slider>
						</Form.Item>
						<Form.Item label="Target Clusters" name={'clusters'} rules={[{ required: true, message: 'Required' }]}>
							<Select
								mode={'tags'}
								placeholder={'Select Multiple Clusters'}
								style={{ width: '30vw' }}
								allowClear
								showSearch={false}
							>
								{[
									'Gurgaon',
									'East Delhi',
									'West Delhi',
									'North Delhi',
									'South Delhi',
									'Noida',
									'Greater Noida',
									'Ghaziabad'
								].map((cluster) => (
									<Option key={cluster} value={cluster}>
										{cluster}
									</Option>
								))}
							</Select>
						</Form.Item>

						<Button htmlType="submit" type="primary" loading={loading}>
							Generate Analytics
						</Button>
					</Form>
				</Row>
			</div>
			<div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
				<img
					src="https://wherehouse-prod-content.s3.ap-south-1.amazonaws.com/assets/illustrations/3556940.jpg"
					alt=""
					style={{ height: '500px', width: '400px' }}
				/>
			</div>
		</div>
	);
}
