import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, notification, Row, Select, Table } from 'antd';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { apiWms } from '../../../shared/api';
import errorHandler from '../../../shared/utils/errorHandler';
import { FulfillmentColumns } from './Column';
import { expandedRowRender } from './ExpendableRow';
import styles from './index.module.scss';
import reactDom from 'react-dom';
import { checkFulfillStatus, instructionsToPackOrders, statusWiseClassName } from './utils';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../shared/contexts/Auth';

export const fulfullmentRoute = '/fulfillment';

export const FulfillmentWms = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [expandRowKey, setExpandRowKey] = useState<string>('');
	const [inputText, setInputText] = useState('');
	const inputRef = useRef<HTMLInputElement>(null);
	const { authState } = useContext(AuthContext);
	const [columnData, setColumnData] = useState<any[]>([]);
	const [filter, setFilter] = useState({
		selectType: '0'
	});
	const history = useHistory();
	const search = useLocation().search;
	const awbToScan = new URLSearchParams(search).get('t');

	const handleEnterInput = async (event: any) => {
		if (event.key !== 'Enter') {
			return;
		}
		if (expandRowKey) {
			const existingOrder = columnData.find((e: any) => e.key === expandRowKey);
			const quantityCheck = existingOrder?.items_to_scan?.find((e: any) => e.wsin === inputText);

			if (!Boolean(quantityCheck)) {
				notification.error({
					message: 'Wsin Error',
					description: 'Please Scan a Valid Wsin',
					placement: 'bottom'
				});
				setInputText('');
				return;
			}

			if (quantityCheck && quantityCheck?.scanned_qty >= quantityCheck?.qty) {
				notification.error({
					message: 'Quantity Error',
					description: 'You can not scan a wsin more than required quantity!',
					placement: 'bottom'
				});
				setInputText('');
				return;
			}
			setColumnData(
				columnData.map((e) => {
					if (e.key === expandRowKey) {
						return {
							...e,
							items_to_scan: e?.items_to_scan.map((O: any) => {
								if (O.wsin === inputText) {
									return {
										...O,
										scanned_qty: ++O.scanned_qty
									};
								}
								return O;
							})
						};
					}
					return { ...e };
				})
			);

			setInputText('');
			return;
		}
		history.push(`/fulfillment?t=${inputText}`);
	};

	const handleGetOrder = useCallback(async () => {
		if (!awbToScan) {
			return;
		}
		try {
			setLoading(true);
			const { data } = await apiWms.post(`/order/packaging-metadata/find/${awbToScan}`, {
				selectedCompanyIds: authState.selectedCompanyIds
			});
			if (data?.status) {
				setColumnData([]);
				setColumnData([data?.order]);
				setExpandRowKey(data?.order?.key);
				notification.info({
					message: 'Awb Scanned',
					description: 'Please scan order items',
					placement: 'bottom'
				});
			}
		} catch (error: any) {
			errorHandler(error);
			history.push(`/fulfillment`);
		} finally {
			reactDom.unstable_batchedUpdates(() => {
				setInputText('');
				setLoading(false);
			});
		}
	}, [awbToScan, authState.selectedCompanyIds, history]);

	const getOrders = useCallback(async () => {
		try {
			setLoading(true);
			const { data } = await apiWms.post('/order/packaging-metadata/get', {
				filter,
				selectedCompanyIds: authState.selectedCompanyIds
			});
			if (data?.status) {
				setColumnData([]);
				setColumnData(data?.orders);
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}, [filter, authState?.selectedCompanyIds]);

	const handleFulfillment = useCallback(async () => {
		try {
			setLoading(true);
			const orderData = columnData.find((e) => e.key === expandRowKey);
			const { data } = await apiWms.post(`/order/packaging-metadata/${expandRowKey}`, { orderData: orderData?.items_to_scan });
			notification.success({
				message: 'Order Packed',
				description: data?.message,
				placement: 'bottom'
			});
		} catch (error) {
			errorHandler(error);
		} finally {
			setExpandRowKey('');
			getOrders();
			setLoading(false);
			history.push('/fulfillment');
		}
	}, [expandRowKey, columnData, getOrders, history]);

	const handleChangeSelect = (data: any) => {
		setFilter((prevData) => ({
			...prevData,
			selectType: data
		}));
	};

	useEffect(() => {
		!awbToScan && getOrders();
		return () => {
			setColumnData([]);
		};
	}, [getOrders, awbToScan]);

	useEffect(() => {
		const isAwbFulfilled = columnData.find((e: any) => e?.key === expandRowKey);
		if (isAwbFulfilled && checkFulfillStatus(isAwbFulfilled?.items_to_scan).status === 'Packed') {
			handleFulfillment();
		}
	}, [columnData, expandRowKey, handleFulfillment]);

	useEffect(() => {
		handleGetOrder();
	}, [handleGetOrder]);

	return (
		<>
			<Row className={`h-100 p-0 ${styles.mainContainer}`}>
				<Col span={4} className={styles.sidebar}>
					<Button
						className={styles.backButton}
						icon={<ArrowLeftOutlined />}
						onClick={() => {
							awbToScan && getOrders();
							setExpandRowKey('');
							history.push(awbToScan ? '/fulfillment' : '/orders');
						}}
					>
						Go back
					</Button>
					<Row className={styles.sideDrawerBottomRow}>
						<div>
							<img
								width={200}
								alt=""
								src="https://wherehouse-prod-content.s3.ap-south-1.amazonaws.com/assets/illustrations/info_icon_packing_page.webp"
							/>
						</div>
						<strong>Instruction to pack orders:</strong>
						{instructionsToPackOrders.map((e, i) => (
							<p style={{ marginBottom: '0.4rem' }}>
								<strong>{i + 1 + '.'}</strong> {e}
							</p>
						))}
						<p>
							<strong>Note:</strong> orders scanned and packed successfully will be highlighted in green
						</p>
					</Row>
				</Col>

				<Col span={20} className={styles.outerContainer}>
					<Row className={`${styles.topHeading}`}>
						<div className={styles.topHeadingDiv}>
							{/**
							 * // TODO: Make this input box commom
							 */}
							<input
								placeholder={`Click here to scan ${expandRowKey ? 'Wsin' : 'Awb'}`}
								value={inputText}
								style={{ width: '17rem' }}
								className={`${styles.wsinInput} ${styles.inputBlink}`}
								onKeyUp={handleEnterInput}
								onChange={(e: any) => setInputText(e.target.value)}
								autoFocus
								ref={inputRef}
							/>
							<label style={{ marginLeft: '1rem', marginRight: '0.4rem' }}>Filter By:</label>

							<Select
								style={{ width: 120 }}
								defaultValue="All"
								onChange={handleChangeSelect}
								options={[
									{
										value: '0',
										label: 'All'
									},
									{
										value: '1',
										label: 'Packed'
									},
									{
										value: '2',
										label: 'Not Packed'
									}
								]}
							/>
						</div>
					</Row>
					<Row className={styles.tableRow}>
						<Table
							style={{ width: '100%', height: '100%' }}
							loading={loading}
							columns={FulfillmentColumns()}
							className={styles.RCM_two_level_table1}
							expandable={{ expandedRowRender, expandedRowKeys: [expandRowKey], expandIcon: () => null }}
							dataSource={columnData}
							pagination={false}
							rowClassName={(data) =>
								data?.key === expandRowKey
									? styles.disabledRow
									: styles[statusWiseClassName[data.status as keyof typeof statusWiseClassName]]
							}
						/>
					</Row>
				</Col>
			</Row>
		</>
	);
};
