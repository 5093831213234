export const Gradient = (props: any) => {
	const { data } = props;
	const boxStyle = {
		width: 180,
		margin: 'auto',
		marginTop: '1rem'
	};
	const gradientStyle = {
		backgroundImage: `linear-gradient(to right, ${data.fromColor} , ${data.toColor})`,
		height: 20
	};
	return (
		<div>
			<div style={boxStyle} className="display-flex">
				<span className="fill"></span>
			</div>
			<div style={{ ...boxStyle, ...gradientStyle }} className="mt8"></div>
		</div>
	);
};
