import { Button, Col, DatePicker, Form, Input, Modal, notification, Radio, Row, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { updateCustomerRequestByToken } from '../../shared/api/nms';
import errorHandler from '../../shared/utils/errorHandler';
import { addressFields } from './columns';
import { ConfirmationModalContent } from './ConfirmationModalContent';
import { CUSTOMER_REQUEST_TYPE, ISSUE_TYPE } from './constants';

const CustomerRequestForm = ({ token, getOrderDetails, customerRequest, order }) => {
	const [requestType, setRequestType] = useState(CUSTOMER_REQUEST_TYPE.UPDATE_DETAILS);
	const [showConfirmation, setShowConfirmation] = useState(false);

	const [newCustomerRequest, setNewCustomerRequest] = useState(null);

	const [confirmLoading, setConfirmLoading] = useState(false);

	const handleSubmit = async (values) => {
		setNewCustomerRequest(values);
		setShowConfirmation(true);
	};

	const updateCustomerRequest = async () => {
		try {
			const {
				data: { status, message, responseMessage }
			} = await updateCustomerRequestByToken(token, { requestType, ...newCustomerRequest });
			if (!status) {
				throw new Error(message || responseMessage);
			}
			getOrderDetails();
			notification.success({
				message: 'Form Submitted successfully',
				placement: 'topRight'
			});
		} catch (error) {
			errorHandler(error);
		} finally {
			setConfirmLoading(false);
			setShowConfirmation(false);
		}
	};

	const checkIfDateDisabled = (date) => {
		const targetDateInMs = new Date(String(date)).getTime();
		const currentTimeInMs = new Date().getTime();
		const formCreatedTimeInMs = new Date(customerRequest.createdAt).getTime();
		const lastAllowedDateInMs = formCreatedTimeInMs + 7 * 24 * 60 * 60 * 1000;
		const isOlderDate = targetDateInMs <= currentTimeInMs;
		const isAfterAllowedperiod = lastAllowedDateInMs < targetDateInMs;
		return isOlderDate || isAfterAllowedperiod;
	};

	const handleOk = () => {
		setConfirmLoading(true);
		updateCustomerRequest();
	};

	const handleCancel = () => {
		console.log('Clicked cancel button');
		setShowConfirmation(false);
	};

	return (
		<div>
			<Typography.Paragraph strong={true}>
				Your order could not be delivered today. Please fill this form to help us deliver your order on time.
			</Typography.Paragraph>

			<Radio.Group value={requestType} onChange={(e) => setRequestType(e.target.value)} style={{ marginBottom: '1rem' }}>
				<Radio value={CUSTOMER_REQUEST_TYPE.UPDATE_DETAILS}>Update details</Radio>
				<Radio value={CUSTOMER_REQUEST_TYPE.CANCEL_ORDER}>Cancel order</Radio>
			</Radio.Group>

			{requestType === CUSTOMER_REQUEST_TYPE.UPDATE_DETAILS && order && (
				<Form
					labelCol={{ span: 24 }}
					wrapperCol={{ span: 24 }}
					labelAlign="left"
					style={{ width: '100%' }}
					onFinish={handleSubmit}
					initialValues={{ address: order.shippingAddress }}
				>
					<Row gutter={48}>
						<Col xs={24} sm={12} md={8}>
							<Form.Item label="Alternate Phone Number" name="alternatePhone">
								<Input type="number" />
							</Form.Item>
						</Col>
						<Col xs={24} sm={12} md={8}>
							<Form.Item label="Preferred Delivery Date" name="deferredDeliveryDate">
								<DatePicker
									style={{ width: '100%' }}
									disabledDate={checkIfDateDisabled}
									getPopupContainer={(trigger) => trigger.parentNode}
								/>
							</Form.Item>
						</Col>
						{addressFields.map((addressField) => (
							<Col xs={24} sm={12} md={8} key={addressField.id}>
								<Form.Item label={addressField.label} name={addressField.name} rules={addressField.rules}>
									<Input placeholder={addressField.placeholder} disabled={addressField.disabled} />
								</Form.Item>
							</Col>
						))}
					</Row>

					<Button type="primary" htmlType="submit">
						Update Details
					</Button>
				</Form>
			)}

			{requestType === CUSTOMER_REQUEST_TYPE.CANCEL_ORDER && (
				<Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} labelAlign="left" style={{ width: '100%' }} onFinish={handleSubmit}>
					<Form.Item label="Reason of cancellation" name="issueType" rules={[{ required: true, message: 'Required!!' }]}>
						<Radio.Group>
							<Space direction="vertical">
								<Radio value={ISSUE_TYPE.ORDER_PLACED_BY_MISTAKE}>Order Placed By Mistake</Radio>
								<Radio value={ISSUE_TYPE.AVAILABLE_FOR_LOWER_PRICE}>Available for lower Price</Radio>
								<Radio value={ISSUE_TYPE.CHANGED_MY_MIND}>I changed my mind</Radio>
								<Radio value={ISSUE_TYPE.NO_LONGER_NEEDED}>No longer needed</Radio>
								<Radio value={ISSUE_TYPE.OTHER}>Other</Radio>
							</Space>
						</Radio.Group>
					</Form.Item>

					<Form.Item label="Description" name="issueDescription" rules={[{ required: true, message: 'Required!!' }]}>
						<Input />
					</Form.Item>

					<Button type="primary" danger={true} htmlType="submit">
						Cancel Order
					</Button>
				</Form>
			)}

			<Modal
				title={
					requestType === CUSTOMER_REQUEST_TYPE.UPDATE_DETAILS
						? 'Please confirm your contact details!'
						: 'Are you sure you want to cancel this order?'
				}
				visible={showConfirmation}
				okText={requestType === CUSTOMER_REQUEST_TYPE.UPDATE_DETAILS ? 'Confirm' : 'Yes'}
				cancelText={requestType === CUSTOMER_REQUEST_TYPE.UPDATE_DETAILS ? 'Cancel' : 'No'}
				okType={requestType === CUSTOMER_REQUEST_TYPE.UPDATE_DETAILS ? 'primary' : 'danger'}
				onOk={handleOk}
				confirmLoading={confirmLoading}
				onCancel={handleCancel}
			>
				<ConfirmationModalContent requestType={requestType} newCustomerRequest={newCustomerRequest} />
			</Modal>
		</div>
	);
};

export default CustomerRequestForm;
