import { Button, Form, Input, InputNumber, Modal } from 'antd';

export function PaymentModal({
	title,
	visible,
	onCancel,
	footer,
	width,
	onFinish,
	instructionMessage,
	loading
}: {
	title: string;
	visible: boolean;
	onCancel: Function;
	footer: any;
	width: number;
	onFinish: Function;
	instructionMessage: string;
	loading: boolean;
}) {
	const [form] = Form.useForm();

	return (
		<Modal title={title} visible={visible} onCancel={() => onCancel()} footer={footer} width={width}>
			<Form form={form} size="small" layout="vertical" onFinish={(values: any) => onFinish(values)}>
				<label style={{ marginBottom: '30px' }}>{instructionMessage}</label>

				<Form.Item name={'mobile'} style={{ width: '100%' }} rules={[{ required: true, message: 'Please enter phone number' }]}>
					<Input size="large" placeholder={`Enter Phone Number`} />
				</Form.Item>

				<Form.Item name={'amount'} style={{ width: '100%' }} rules={[{ required: true, message: 'Please enter amount' }]}>
					<InputNumber size="large" placeholder={`Amount`} />
				</Form.Item>

				<Form.Item name={'message'} style={{ width: '100%' }} rules={[{ required: true, message: 'Please enter message' }]}>
					<Input size="large" placeholder={`Enter Message`} />
				</Form.Item>

				<Form.Item>
					<Button type="primary" size="large" block htmlType="submit" loading={loading} style={{ top: '20px' }}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
}
