export const columns = [
	{
		title: 'Serial No',
		dataIndex: 'productId'
	},
	{
		title: 'Item Name',
		dataIndex: 'productTitle'
	},
	{
		title: 'SKU Id',
		dataIndex: 'skuId'
	},
	{
		title: 'Category',
		dataIndex: 'category'
	},
	{
		title: 'Type',
		dataIndex: 'productType'
	},
	{
		title: 'Price',
		dataIndex: 'price'
	},
	{
		title: 'LxBxH (cm)',
		dataIndex: 'dimensions'
	},
	{
		title: 'Weight (gm)',
		dataIndex: 'weight'
	},
	{
		title: 'Stock',
		dataIndex: 'inventoryQuantity'
	}
];
