import { Col, notification } from 'antd';
import { createClusterOwner } from 'shared/api/lightning';
import errorHandler from 'shared/utils/errorHandler';
import { createUserForm } from '../utils';

export function CreateClusterOwner({ clusters }) {
	async function createNewClusterOwner(values) {
		try {
			const { data } = await createClusterOwner(values);
			if (!data.status) throw new Error(data.message);
			if (data.status) {
				notification.success({
					message: 'Cluster Owner created',
					description: data.message,
					placement: 'topRight'
				});
			}
		} catch (error) {
			errorHandler(error);
		}
	}

	function handleOnSave(values) {
		createNewClusterOwner(values);
	}

	return <Col span={8}>{createUserForm({ formTitle: 'Create Cluster owner', onSave: handleOnSave, clusters: clusters })}</Col>;
}
