import { DownloadOutlined } from '@ant-design/icons';
import { Badge, Button, DatePicker, notification, Popover, Select, Space, Tooltip, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { ORDER_STATUS, BULK_ACTIONS } from '../../../../constants';
import { searchOptions } from '../../../../shared/utils/constant';
import styles from './index.module.scss';
import { downloadAsFile } from 'helper';
import { apiOms } from 'shared/api';
import filterSvg from 'shared/svgs/filter_svg';
import { MAX_DOWNLOAD_LABEL_LIMIT } from 'app/AppSeller/Orders/constants';
import errorHandler from 'shared/utils/errorHandler';
import { UserRoleType } from 'app/AppAdmin/AdminIUser/constants';
import { MultipleFilterComponent } from '../../../../shared/components/MultipleFilterComponent/MultipleFilterComponent';
import { commonTopFilterRow } from '../../../../utils/filters';

export function topFilterUtil({
	filter,
	setFilter,
	setPageConfigDefault,
	onDownloadOrders,
	setLabelEmailModalType,
	selectedRowKeys,
	onDownloadOrderPickList,
	profile,
	openFilterPanel,
	setOpenFilterPanel,
	filterFormValue,
	setFilterFormValue,
	status,
	setStatus
}) {
	const { Text } = Typography;
	const setDate = (startDate, endDate) => {
		setPageConfigDefault();
		setFilter((v) => ({ ...v, startDate, endDate }));
	};
	const { MARK_READY_TO_SHIP, ...BULK_ADMIN_ACTION } = BULK_ACTIONS;
	const setDTODate = (startDate, endDate) => {
		setPageConfigDefault();
		setFilter((v) => ({ ...v, startDTODate: startDate, endDTODate: endDate }));
	};

	function shouldShowDTOFilter(status) {
		const dtoStatus = [
			ORDER_STATUS.DTO_PLACED,
			ORDER_STATUS.DTO_IN_TRANSIT,
			ORDER_STATUS.DTO_OUT_FOR_DELIVERY,
			ORDER_STATUS.DTO_DELIVERED,
			ORDER_STATUS.DTO_UNDELIVERED,
			ORDER_STATUS.DTO_PROCESSED,
			ORDER_STATUS.DTO_CANCELLED
		];

		return dtoStatus.some((s) => String(status).split(',').includes(s));
	}

	const handleBulkActionChange = (bulkAction) => {
		switch (bulkAction) {
			case 'Print Label':
				bulkDownload('label');
				break;
			case 'Print Invoice':
				bulkDownload('invoice');
				break;
			case 'Download Picklist':
				onDownloadOrderPickList();
				break;
			case 'Download Shipping Manifest':
				onDownloadShippingManifest();
				break;
			case 'Bulk Cancel Placed Orders':
				onBulkCancelPlacedOrders();
				break;
			default:
				break;
		}
	};

	const onDownloadShippingManifest = async () => {
		let URL, downloadUrl;
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');
			const { data } = await apiOms.get(`/download/shipping-manifest?orderIds=${selectedRowKeys.join(',')}`, {
				responseType: 'blob'
			});

			// create an object URL from the Blob
			URL = window.URL || window.webkitURL;
			downloadUrl = URL.createObjectURL(data);
			window.open(downloadUrl, '_blank');
		} catch (error) {
			errorHandler(error);
			if (downloadUrl) {
				URL.revokeObjectURL(downloadUrl);
			}
		}
	};

	const onBulkCancelPlacedOrders = async () => {
		let URL, downloadUrl;
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');
			if (selectedRowKeys.length > Number(50)) throw new Error('Limit to bulk cancel placed orders is 50');

			const {
				data: { status, message }
			} = await apiOms.get(`/bulk/cancel-placed-orders/${filter?.companyId}?orderIds=${selectedRowKeys.join(',')}`);
			if (!status) {
				throw new Error(message);
			}

			notification.success({
				message: 'Success',
				description: message,
				placement: 'topRight'
			});
		} catch (error) {
			errorHandler(error);
			URL?.revokeObjectURL(downloadUrl);
		}
	};

	const bulkDownload = async (type) => {
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');

			if ((type === 'label' || type === 'invoice') && selectedRowKeys.length > MAX_DOWNLOAD_LABEL_LIMIT) {
				type === 'label' ? setLabelEmailModalType('Label') : setLabelEmailModalType('Invoice');
			} else {
				const res = await apiOms.post(`/download/${type}`, { orderId: selectedRowKeys }, { responseType: 'blob' });
				downloadAsFile({ data: res.data, fileName: `${type}s`, fileType: 'pdf' });
			}
		} catch (error) {
			if (error?.response?.data?.type === 'application/json') {
				error.message = `Unable to find ${type}`;
			}

			errorHandler(error);
		}
	};

	const handleCloseTag = (closedTag) => {
		let updatedFilter = {};
		const newFormObj = { ...filterFormValue };
		if (closedTag === 'status') {
			setStatus([]);
			updatedFilter = { ...updatedFilter, filterFormValue: newFormObj, status: [] };
		} else {
			delete newFormObj[closedTag];
			updatedFilter = { ...updatedFilter, filterFormValue: newFormObj, status: status };
			setFilterFormValue(newFormObj);
		}

		setFilter((prevFilter) => {
			const newFilter = {
				...prevFilter,
				...updatedFilter
			};

			const filteredFilter = Object.keys(newFilter).reduce((filterObject, key) => {
				if (!(Array.isArray(newFilter[key]) && newFilter[key].length === 0)) {
					filterObject[key] = newFilter[key];
				}
				return filterObject;
			}, {});

			return filteredFilter;
		});
	};

	const individualTagElementRender = (formField) => {
		const tagElementLabel = searchOptions.find((element) => element.value === formField);
		const tagElem = (
			<Tag
				key={formField}
				closable
				color="blue"
				onClose={() => {
					handleCloseTag(formField);
				}}
				className={styles.borderLessTag}
				size="medium"
				style={{ display: 'flex', alignItems: 'center' }}
			>
				{tagElementLabel.label}
			</Tag>
		);
		return tagElem;
	};
	const formValueKeys = filter?.filterFormValue && Object.keys(filter?.filterFormValue);
	const showMoreFilters = filter?.filterFormValue && formValueKeys.length > 2;
	const renderTagRange = (start, end) => {
		return (
			formValueKeys &&
			formValueKeys.slice(start, end).map((formField) => {
				return individualTagElementRender(formField);
			})
		);
	};

	const topFilterRow = commonTopFilterRow(filter.startDate, filter.endDate);

	return {
		row1Filter: [
			<div className={styles.searchContainer}>
				<Badge offset={[-2, 4]} count={filter?.filterFormValue && Object.keys(filter?.filterFormValue).length} size="small">
					<Button className={styles.filterButton} onClick={() => setOpenFilterPanel(true)} size="medium">
						<span className={styles.filterImage}>{filterSvg}</span>
						<span style={{ marginLeft: '.3rem', fontSize: '0.85rem' }}>Filters</span>
					</Button>
				</Badge>
				<MultipleFilterComponent
					openFilterPanel={openFilterPanel}
					setOpenFilterPanel={setOpenFilterPanel}
					setFilter={setFilter}
					filterFormValue={filterFormValue}
					setFilterFormValue={setFilterFormValue}
					searchOptions={searchOptions}
					filter={filter}
					isAdmin={true}
					status={status}
					setStatus={setStatus}
				/>
			</div>
		],
		row1: [
			{
				...topFilterRow,
				onChange: (dates, dateStrings) => setDate(...dateStrings),
				showLabel: false
			}
		],
		row1children: [
			<div className={styles.orderStatusFilter}>
				<Select
					disabled={!selectedRowKeys || selectedRowKeys.length === 0}
					placeholder="Bulk Order Actions"
					style={{ width: '100%' }}
					allowClear
					onChange={handleBulkActionChange}
				>
					{Object.values(BULK_ADMIN_ACTION).map((bulkAction) => {
						return <Select.Option key={bulkAction}>{bulkAction}</Select.Option>;
					})}
				</Select>
			</div>,
			<div className={styles.searchContainer}>
				{(filter?.filterFormValue || filter?.status) && (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{filter?.status && filter.status.length > 0 && (
							<Space wrap>
								<Tag
									closable
									color="blue"
									onClose={() => {
										handleCloseTag('status');
									}}
									key="status"
									className={styles.borderLessTag}
									size="medium"
									style={{ display: 'flex', alignItems: 'center', marginRight: 2 }}
								>
									Status applied
								</Tag>
							</Space>
						)}
						{filter?.filterFormValue && !showMoreFilters ? (
							<Space wrap>{renderTagRange(0, 2)}</Space>
						) : (
							<Space wrap>
								{renderTagRange(0, 2)}
								{showMoreFilters && (
									<Text underline style={{ color: 'slategrey' }} onClick={() => setOpenFilterPanel(true)}>
										+{filter?.filterFormValue && Object.keys(filter?.filterFormValue).length - 2} more filters
									</Text>
								)}
							</Space>
						)}
					</div>
				)}
			</div>,
			selectedRowKeys.length !== 0 && (
				<Badge size="default" count={`${selectedRowKeys.length} selected`} style={{ backgroundColor: '#6c757d' }} />
			)
		],
		row2: [
			<Tooltip placement="left" title={'Download Brands CSV'}>
				{profile.user_role === UserRoleType.FINANCE && String(filter.status) && shouldShowDTOFilter(filter.status) ? (
					<Popover
						style={{ maxWidth: 100 }}
						content={
							<Space direction="vertical" className={'ml-0'} key={'1'}>
								<span style={{ marginBottom: '30px', maxWidth: '350px' }}>
									{
										'Since selected orders have some DTO items, DTO date range is required. Please select from the input box below'
									}
								</span>

								<span className={styles.filterByDateTitle}>Filter By Date</span>
								<DatePicker.RangePicker
									{...{
										...topFilterRow,
										label: 'FILTER BY DATE(DTO_PLACED)',
										onChange: (dates, dateStrings) => setDTODate(...dateStrings)
									}}
								/>

								<Button type="primary" onClick={onDownloadOrders} style={{ marginTop: '30px' }}>
									Download
								</Button>
							</Space>
						}
						title="DTO Filter"
						trigger="click"
					>
						<Button type="primary" className={styles.downloadButton} shape={'round'} icon={<DownloadOutlined />} size="large" />
					</Popover>
				) : (
					<Button
						type="primary"
						className={styles.downloadButton}
						shape={'round'}
						icon={<DownloadOutlined />}
						onClick={onDownloadOrders}
						size="large"
					>
						Download
					</Button>
				)}
			</Tooltip>
		]
	};
}
