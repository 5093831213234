import { apiD2r } from '.';
import { IPageConfiguration } from '../components/Wallet/types';
import { envs } from '../utils/env';
import errorHandler from '../utils/errorHandler';

const { d2rBaseUrl } = envs;
export function getInvoices(pageConfiguration: IPageConfiguration, filters: any, csvFlag = 0) {
	try {
		const { start, end, searchOptionType, searchValue, invoiceStatus, company } = filters;
		const { current, pageSize, sortField, sortOrder } = pageConfiguration;

		const searchParams = new URLSearchParams();
		if (searchValue && searchOptionType) {
			searchParams.append('searchOptionType', searchOptionType);
			searchParams.append('searchValue', searchValue.trim());
		}

		// apply filters
		const singleFieldMap = {
			order: sortField,
			sortBy: sortOrder,
			records: pageSize,
			page: current,
			company: company?.id ?? null,
			invoiceStatus,
			csvFlag
		};

		Object.entries(singleFieldMap).forEach(([fieldName, fieldValue]) => {
			// no need to apply invoice_status restriction in case of filter not selected
			if (fieldName === 'invoiceStatus' && typeof fieldValue === 'undefined') {
				return;
			}
			searchParams.append(fieldName, String(fieldValue));
		});

		// attach other filters
		if (start && end) {
			searchParams.append('start', String(start));
			searchParams.append('end', String(end));
		}

		const apiUrl = `/invoices?${searchParams.toString()}`;

		if (csvFlag) {
			const url = new URL(`${d2rBaseUrl}${apiUrl}`);
			window.open(url);
		}

		return apiD2r.get(apiUrl);
	} catch (error) {
		errorHandler(error);
	}
}
