import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

export const trackingColumn = () => [
	{
		title: 'Created At',
		dataIndex: 'createdAt',
		render: (time: string) => <div>{dayjs(time).format('DD/MM/YY')}</div>
	},
	{
		title: 'Pickup Pincode',
		dataIndex: 'pickupPincode'
	},
	{
		title: 'Drop Pincode',
		dataIndex: 'dropPincode'
	},
	{
		title: 'Tracking AWB',
		dataIndex: 'trackingNumber'
	},
	{
		title: 'More',
		render: (data: any) => <Link to={`/track/wh-track${data?.id}`}>View Details</Link>
	}
];
