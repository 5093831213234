import { Button } from 'antd';
import dayjs from 'dayjs';
import { jsxElementKeys } from 'shared/utils/constant';

import { companyType } from '../D2r';

interface IBeatsFilter {
	company: companyType;
	setIsUploadExcelModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setIsCreateBeatModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const beatsFilters = (props: IBeatsFilter) => {
	return {
		row1: [],
		row1children: [],
		row2: [
			<div style={{ display: 'flex', gap: '1rem' }} key={jsxElementKeys.BEAT_BUTTON_GROUP}>
				<Button disabled={!props?.company?.id} type="primary" onClick={() => props.setIsCreateBeatModalVisible(true)}>
					CreateBeat
				</Button>
				<Button disabled={!props?.company?.id} type="primary" onClick={() => {}}>
					Download Beats
				</Button>
				<Button disabled={!props?.company?.id} type="primary" onClick={() => props.setIsUploadExcelModalVisible(true)}>
					Upload Beats
				</Button>
			</div>
		]
	};
};

export const d2rBeatsColumns = (setShowBeatsInfoSlider: any) => [
	{
		title: 'Name',
		render: (data: any) => (
			<Button
				type="link"
				onClick={() => {
					setShowBeatsInfoSlider(data?.id);
				}}
			>
				{data?.name}
			</Button>
		)
	},
	{
		title: 'Created At',
		render: (data: any) => <div className="text-center">{dayjs(new Date(data.createdAt)).format('MMM D, YYYY h:mm A')}</div>
	},
	{
		title: 'Last Updated',
		render: (data: any) => <div className="text-center">{dayjs(new Date(data.updatedAt)).format('MMM D, YYYY h:mm A')}</div>
	},
	{
		title: 'Description/Area',
		render: (data: any) => <span>{data?.description}</span>
	},
	{
		title: 'Salesman',
		render: (data: any) => (
			<span>
				{data?.salesman?.firstName} {data?.salesman?.lastName}({data?.salesman?.phone})
			</span>
		)
	}
];
