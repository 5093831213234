import { useEffect, useState } from 'react';
import { getCompanyWarehouses } from 'shared/api/warehouse';
import errorHandler from 'shared/utils/errorHandler';

export default function useWarehouse({ companyID = undefined }) {
	const [loading, setLoading] = useState(false);
	const [warehouses, setWarehouses] = useState(undefined);
	const [activeWarehouses, setActiveWarehouses] = useState(undefined);

	useEffect(() => {
		async function getWarehouse() {
			try {
				setLoading(true);
				const {
					data: { warehouses: fetchedWarehouses }
				} = await getCompanyWarehouses(companyID);
				setWarehouses(fetchedWarehouses);
				setActiveWarehouses(fetchedWarehouses.filter(({ active }) => active));
			} catch (error) {
				setWarehouses(null);
				setActiveWarehouses(null);
				errorHandler(error);
			} finally {
				setLoading(false);
			}
		}
		getWarehouse();
	}, [companyID]);

	return {
		loading,
		warehouses,
		activeWarehouses
	};
}
