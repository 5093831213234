import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, notification, Upload } from 'antd';
import 'cropperjs/dist/cropper.css';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'shared/contexts/Auth';
import { antDesignDecimalValueValidator } from '../../../../../helper';
import { apiUms } from '../../../../../shared/api/index';
import errorHandler from '../../../../../shared/utils/errorHandler';
import styles from './index.module.scss';
import { UploadFileModal } from './UploadFileModal';

const IMAGE_NAMES = {
	logo: 'logo',
	signature: 'signature'
};

export function InvoicingForm() {
	const [files, setFiles] = useState({
		logo: [],
		signature: []
	});
	const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
	const [warehouses, setWarehouses] = useState([]);
	const [isFormEditable, setIsFormEditable] = useState(false);
	const [form] = Form.useForm();
	const { authActions } = useContext(AuthContext);
	const [islogoModalOpen, setIsLogoModalOpen] = useState(false);
	const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
	const fetchFormData = async () => {
		try {
			const {
				data: { userProfileDataObject, warehouses }
			} = await apiUms.get('/ums/invoices');

			form.setFieldsValue(userProfileDataObject);

			setWarehouses(warehouses);

			const files = {};
			const { logo, signature } = userProfileDataObject;

			if (logo) files.logo = [{ url: logo, name: 'View' }];
			if (signature) files.signature = [{ url: signature, name: 'View' }];

			setFiles({ ...files });
		} catch (error) {
			errorHandler(error);
			console.log(error);
		}
	};

	useEffect(() => {
		fetchFormData();
	}, []);

	const postInvoiceFormDetails = async (formValues) => {
		try {
			return await apiUms.post('/ums/invoices', { ...formValues });
		} catch (error) {
			errorHandler(error);
		}
	};

	const submitForm = async (formValues) => {
		try {
			setIsSaveButtonLoading(true);
			setIsFormEditable(false);

			if (!formValues.utgstPercentage) {
				formValues.utgstPercentage = formValues.sgstPercentage;
			}

			formValues.logo = files?.logo ? files.logo[0] : null;
			formValues.signature = files?.signature ? files.signature[0] : null;

			if (!formValues.warehouseInvoiceSeries) {
				formValues.warehouseInvoiceSeries = [];
			}

			if (!formValues.logo) throw new Error('Please upload logo');
			if (!formValues.signature) throw new Error('Please upload signature');

			const { data } = await postInvoiceFormDetails(formValues);

			// change data.status value to boolean in auth
			if (data.status === 'Success') {
				notification.success({
					message: 'Invoice updated successfully!',
					placement: 'topRight'
				});
			}

			authActions.updateProfileData({ isValidInvoicing: true });
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsSaveButtonLoading(false);
		}
	};

	const showDisableNotification = async (e) => {
		if (!isFormEditable) {
			await notification.warn({ message: 'Please click on the edit button first!' });
		}
	};

	const checkIsInvoiceSeriesUnique = ({ getFieldValue }) => ({
		validator: async (_, value) => {
			const warehouseInvoiceSeries = getFieldValue('warehouseInvoiceSeries'); // returns object { warehouseId: invoice }
			let count = 0;

			// checking whitespace
			if (/\s/g.test(value)) throw new Error('Invoice Series must not have white space!');

			for (const invoiceSeries of Object.values(warehouseInvoiceSeries)) {
				if (invoiceSeries === value) count++;

				if (count > 1) throw new Error('Invoice Series must be unique!');
			}

			return;
		}
	});

	const handleUploadFileModal = (name) => {
		if (isFormEditable) {
			if (IMAGE_NAMES.logo === name) {
				setIsLogoModalOpen((prevModal) => !prevModal);
			}
			if (IMAGE_NAMES.signature === name) {
				setIsSignatureModalOpen((prevModal) => !prevModal);
			}
		}
		showDisableNotification();
	};

	return (
		<div className={styles.invoicingForm}>
			<Form form={form} layout="vertical" className={styles.form} onFinish={submitForm} disabled={true}>
				<div className={styles.addressContainer} id={styles.addressContainer}>
					<Form.Item
						name="registeredName"
						label="Registered Company Name"
						rules={[{ required: true, message: 'Please input your registered company name' }]}
					>
						<Input disabled={!isFormEditable} placeholder="ABC Company Pvt Ltd." className={styles.input} />
					</Form.Item>
					<Form.Item
						name="brandName"
						label="Brand Name (for sending email)"
						rules={[{ required: true, message: 'Please input your Brand Name.' }]}
					>
						<Input disabled={!isFormEditable} placeholder=" Your Brand Name goes here" className={styles.input} />
					</Form.Item>
				</div>
				<p>Registered Address (used for Billing)</p>
				<div className={styles.addressContainer} id={styles.addressContainer}>
					<Form.Item
						name={['invoicingAddress', 'line1']}
						label="Address Line 1"
						rules={[{ required: true, message: 'Please input your Address line 1' }]}
					>
						<Input disabled={!isFormEditable} placeholder="68/22, A2B" className={styles.input} />
					</Form.Item>

					<Form.Item
						name={['invoicingAddress', 'line2']}
						label="Address Line 2"
						rules={[{ required: true, message: 'Please input your Address line 2' }]}
					>
						<Input disabled={!isFormEditable} placeholder="44 Xyz." className={styles.input} />
					</Form.Item>

					<Form.Item
						name={['invoicingAddress', 'city']}
						label="City"
						rules={[{ required: true, message: 'Please input your City' }]}
					>
						<Input disabled={!isFormEditable} placeholder="Delhi" className={styles.input} />
					</Form.Item>

					<Form.Item
						name={['invoicingAddress', 'state']}
						label="State"
						rules={[{ required: true, message: 'Please input your State' }]}
					>
						<Input disabled={!isFormEditable} placeholder="Delhi" className={styles.input} />
					</Form.Item>

					<Form.Item
						name={['invoicingAddress', 'pincode']}
						label="Pincode"
						rules={[{ required: true, message: 'Please input your Pincode' }]}
					>
						<Input disabled={!isFormEditable} placeholder="100011" className={styles.input} />
					</Form.Item>
				</div>
				<div className={styles.uploadContainer} id={styles.addressContainer}>
					<Form.Item name="logo" label="Logo*">
						<Upload disabled fileList={files.logo}>
							<Button onClick={() => handleUploadFileModal(IMAGE_NAMES.logo)} icon={<UploadOutlined />}>
								Upload Logo
							</Button>
						</Upload>
						<UploadFileModal
							isModalVisible={islogoModalOpen}
							setIsModalVisible={setIsLogoModalOpen}
							setFiles={setFiles}
							modalName={IMAGE_NAMES.logo}
						/>
					</Form.Item>

					<Form.Item name="signature" label="Signature*">
						<UploadFileModal
							isModalVisible={isSignatureModalOpen}
							setIsModalVisible={setIsSignatureModalOpen}
							setFiles={setFiles}
							modalName={IMAGE_NAMES.signature}
						/>
						<Upload disabled fileList={files.signature}>
							<Button onClick={() => handleUploadFileModal(IMAGE_NAMES.signature)} icon={<UploadOutlined />}>
								Upload Signature
							</Button>
						</Upload>
					</Form.Item>
				</div>
				<div className={styles.gridContainer} id={styles.addressContainer}>
					<Form.Item
						name="sgstPercentage"
						label="SGST (%)"
						rules={[antDesignDecimalValueValidator, { required: true, message: 'Please input your SGST' }]}
					>
						<Input disabled={!isFormEditable} className={styles.input} type="number" />
					</Form.Item>

					<Form.Item
						name="cgstPercentage"
						label="CGST (%)"
						rules={[antDesignDecimalValueValidator, { required: true, message: 'Please input your CGST' }]}
					>
						<Input disabled={!isFormEditable} className={styles.input} type="number" />
					</Form.Item>
				</div>
				<div className={styles.gridContainer} id={styles.addressContainer}>
					<Form.Item
						name="igstPercentage"
						label="IGST (%)"
						rules={[antDesignDecimalValueValidator, { required: true, message: 'Please input your IGST' }]}
					>
						<Input disabled={!isFormEditable} className={styles.input} type="number" />
					</Form.Item>

					<Form.Item name="utgstPercentage" label="UT-GST (%)" rules={[antDesignDecimalValueValidator]}>
						<Input disabled={!isFormEditable} className={styles.input} type="number" />
					</Form.Item>
				</div>
				{warehouses.length ? <h4>Invoice series:</h4> : null} <br />
				{warehouses?.map((warehouse) => {
					return (
						<div style={{ width: '100%' }} key={warehouse.id}>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<h6 className={styles.warehouseName}>{warehouse.profile?.name}</h6>
								<Col span={24} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
									<Form.Item
										style={{ width: '100%', marginRight: '1rem' }}
										name={['warehouseInvoiceSeries', warehouse.invoicePrefix]}
										initialValue={warehouse.invoicePrefix}
										rules={[checkIsInvoiceSeriesUnique, { required: true, message: 'Please input invoice series' }]}
										label="B2C"
									>
										<Input disabled={warehouse.active || !isFormEditable} className={styles.input} />
									</Form.Item>
									<Form.Item
										style={{ width: '100%', marginRight: '1rem' }}
										name={['warehouseInvoiceSeries', warehouse.b2bInvoicePrefix]}
										initialValue={warehouse.b2bInvoicePrefix}
										rules={[checkIsInvoiceSeriesUnique, { required: true, message: 'Please input invoice series' }]}
										label="B2B"
									>
										<Input disabled={warehouse.active || !isFormEditable} className={styles.input} />
									</Form.Item>
									<Form.Item
										style={{ width: '100%', marginRight: '1rem' }}
										name={['invoiceIndexes', warehouse?.warehouseId, 'b2cIndex']}
										initialValue={warehouse?.indexes?.b2cIndex}
										label="Current B2C Index"
									>
										<Input type="number" disabled={!isFormEditable} className={styles.input} />
									</Form.Item>
									<Form.Item
										style={{ width: '100%' }}
										name={['invoiceIndexes', warehouse?.warehouseId, 'b2bIndex']}
										initialValue={warehouse?.indexes?.b2bIndex}
										label="Current B2B Index"
									>
										<Input type="number" disabled={!isFormEditable} className={styles.input} />
									</Form.Item>
								</Col>
							</div>
						</div>
					);
				})}
				<Form.Item>
					<Button loading={isSaveButtonLoading} type="primary" htmlType="submit" disabled={!isFormEditable}>
						Save
					</Button>
				</Form.Item>
			</Form>

			{!isFormEditable && (
				<Button
					type="primary"
					onClick={() => {
						setIsFormEditable(true);
					}}
				>
					Edit
				</Button>
			)}
		</div>
	);
}
