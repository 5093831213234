import { useCallback } from 'react';
import { getPricingDetails } from '../../../../shared/api/billing';
import errorHandler from '../../../../shared/utils/errorHandler';

const { useState, useEffect } = require('react');

export const usePricingDetails = (companyId: string | undefined) => {
	const [pricing, setPricing] = useState(null);

	const fetchPricingDetails = useCallback(async () => {
		try {
			if (!companyId) {
				throw new Error('Please select a brand');
			}
			const { data } = await getPricingDetails(companyId);
			if (!data.status) {
				throw new Error(data?.message);
			}
			setPricing(data.pricings);
		} catch (error) {
			errorHandler(error);
		}
	}, [companyId]);

	useEffect(() => {
		fetchPricingDetails();
	}, [fetchPricingDetails]);

	return {
		pricing,
		revalidate: fetchPricingDetails
	};
};
