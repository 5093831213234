import { Button, Col, Form, Input, notification, Row } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';
import { addGupshupCredentials, getCompanyGupshupCreds } from '../../../../shared/api/nms';
import errorHandler from '../../../../shared/utils/errorHandler';

export const GupshupCredentialsForm = ({ companyId }) => {
	const [loading, setLoading] = useState(false);
	const [isGupshupCredsAvailable, setIsGupshupCredsAvailable] = useState(false);

	const [form] = Form.useForm();

	const fetchData = async () => {
		try {
			const { data } = await getCompanyGupshupCreds(companyId);
			if (!data.status) {
				throw new Error(data.message);
			}
			const { isCredsAvailable, creds } = data;
			if (isCredsAvailable) {
				setIsGupshupCredsAvailable(true);
				form.setFields([
					{ name: 'userId', value: creds.gupshupUserId },
					{ name: 'password', value: creds.gupshupPassword },
					{ name: 'whatsAppNumber', value: creds.whatsAppNumber }
				]);
			}
		} catch (error) {
			errorHandler(error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleFinish = async (values) => {
		setLoading(true);
		try {
			const { data } = await addGupshupCredentials({ companyId, ...values });

			if (!data.status) {
				throw new Error(data?.message);
			}
			notification.success({
				message: 'Success',
				description: data?.message,
				placement: 'topRight'
			});
		} catch (error) {
			errorHandler(error);
		}
		setLoading(false);
	};
	return (
		<Form form={form} onFinish={handleFinish} layout="vertical">
			<Row gutter={24} align="middle">
				<Col span={24} md={6}>
					<Form.Item label="Gupshup Credentials" name={'userId'} rules={[{ required: true, message: 'Required' }]}>
						<Input autoComplete={false} />
					</Form.Item>
				</Col>
				<Col span={24} md={6}>
					<Form.Item label="Gupshup Password" name={'password'} rules={[{ required: true, message: 'Required' }]}>
						<Input type="password" />
					</Form.Item>
				</Col>
				<Col span={24} md={6}>
					<Form.Item
						label="WhatsApp Number"
						name={'whatsAppNumber'}
						rules={[
							{ required: true, message: 'Required' },
							{ len: 10, message: 'invalid number' }
						]}
					>
						<Input type="number" />
					</Form.Item>
				</Col>

				<Col span={24} md={6}>
					<Button htmlType="submit" type="primary" loading={loading}>
						{isGupshupCredsAvailable ? 'Update' : 'Save'}
					</Button>
				</Col>
			</Row>
		</Form>
	);
};
