import flagSvgIcon from 'shared/svgs/flag_svg';
import allSvgIcon from 'shared/svgs/all_svg';
import returnSvgIcon from 'shared/svgs/return_svg';
import ndrSvgIcon from 'shared/svgs/ndr_svg';
import activeSvgIcon from 'shared/svgs/active_svg';
import apiSvg from 'shared/svgs/api_svg';
import downloadSvg from 'shared/svgs/download_svg';
import supportSvg from 'shared/svgs/support_svg';
import preferenceSvg from 'shared/svgs/preference_svg';
import walletSvg from 'shared/svgs/wallet_svg';
import retailerSvg from 'shared/svgs/retailer_svg';
import trackingPageSvg from 'shared/svgs/tracking_page_svg.js';
import userSvg from 'shared/svgs/user_svg.js';
import integrateSvg from 'shared/svgs/link_svg.js';
import invoicingSvg from 'shared/svgs/invoicing_svg.js';
import locationSvg from 'shared/svgs/location_sm_svg.js';
import shippingSvg from 'shared/svgs/shipping_package_svg.js';
import orderSvg from 'shared/svgs/order_svg';
import unserviceableSvg from 'shared/svgs/unserviceable_svg';
import missedSvg from 'shared/svgs/missed_svg';
import ndrCustomersSvg from 'shared/svgs/ndr_customer_svg';
import { insightsIconSvg } from '../../../../app/AppSeller/Insights/svgs/svgs';

export const ICON_MAPPER = {
	allSvgIcon: allSvgIcon,
	activeSvgIcon: activeSvgIcon,
	flagSvgIcon: flagSvgIcon,
	returnSvgIcon: returnSvgIcon,
	ndrSvgIcon: ndrSvgIcon,
	insightSvgIcon: insightsIconSvg,
	api: apiSvg,
	download: downloadSvg,
	support: supportSvg,
	preference: preferenceSvg,
	wallet: walletSvg,
	retailer: retailerSvg,
	tracking: trackingPageSvg,
	user: userSvg,
	integrate: integrateSvg,
	invoicing: invoicingSvg,
	location: locationSvg,
	shipping: shippingSvg,
	order: orderSvg,
	unserviceable: unserviceableSvg,
	missed: missedSvg,
	ndr2: ndrCustomersSvg
};
