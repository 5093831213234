import { useEffect, useState, useMemo, useRef } from 'react';
import { userColumns } from 'shared/utils/admin/companyWhColumns';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { Row, Col, notification, Modal, Form, Input, Button } from 'antd';
import { defaultPageConfig } from '../../../constants';
import { ErrorBoundary } from '@sentry/react';
import { getAdminUsers } from 'shared/api/companies';
import { Fallback } from 'shared/components/ErrorBoundryFallback';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { topFilterUtil } from 'utils/admin/companyWhUtils';
import errorHandler from 'shared/utils/errorHandler';
import { apiUms } from '../../../shared/api';
import { searchOptions } from './constants';
import { useHistory } from 'react-router-dom';
import { createSubAdminRoute } from './CreateAdminUser';

export function AdminUser() {
	const [form] = Form.useForm();
	const isMounted = useRef(false);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [showResetPassword, setShowResetPassword] = useState(false);
	const [selectedEntityId, setSelectedEntityId] = useState('');
	const [data, setData] = useState([]);
	const [filter, setFilter] = useState({ searchOptionType: 'name' });
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const columnsConfig = useMemo(
		() =>
			userColumns({
				sortField: pageConfig.sortField,
				sortOrder: pageConfig.sortOrder,
				userType: 'Admin',
				handleAccountAction,
				setShowResetPassword: (entityId) => {
					if (entityId) {
						setSelectedEntityId(entityId);
						setShowResetPassword(true);
					}
				}
			}),
		[pageConfig.sortField, pageConfig.sortOrder]
	);

	const addSubAdmin = () => {
		history.push(createSubAdminRoute);
		window.location.reload();
	};

	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				filter,
				setFilter,
				setPageConfigDefault,
				searchOptions,
				addSubAdmin
			}),
		[filter, pageConfig]
	);

	function setPageConfigDefault() {
		setPageConfig((v) => ({ ...v, ...defaultPageConfig }));
	}

	async function handleAccountAction(entityId, statusToUpdate) {
		try {
			const { data } = await apiUms.patch('/ums/register/admin/status', {
				entityId,
				status: Boolean(!statusToUpdate)
			});
			notification.success({
				message: 'Account Status',
				description: data?.message,
				placement: 'topRight'
			});
		} catch (error) {
			errorHandler(error);
		} finally {
			getAllAdminUsers();
		}
	}

	async function handleResetPassword(values) {
		try {
			const { data } = await apiUms.patch('/ums/register/admin/password', {
				entityId: selectedEntityId,
				password: values.password
			});
			notification.success({
				message: 'Account Password',
				description: data?.message,
				placement: 'topRight'
			});
		} catch (error) {
			errorHandler(error);
		} finally {
			setShowResetPassword(false);
			setSelectedEntityId('');
			getAllAdminUsers();
		}
	}

	function getAllAdminUsers(pageConfig, filter) {
		getAdminUsers(pageConfig || 1, filter)
			.then(({ data }) => {
				if (!isMounted?.current) return;
				setData(data.adminUserDetails);
				setCount(data.count);
				if (data.message) {
					errorHandler(data.message);
				}
			})
			.catch(() => {
				if (!isMounted?.current) return;
				setData([]);
			})
			.finally(() => {
				isMounted?.current && setLoading(false);
			});
	}

	function handleTableChange(pagination, filters, sorter) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'createdAt',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		setLoading(true);
		getAllAdminUsers(pageConfig, filter);
	}, [pageConfig, filter]);

	return (
		<Row className="h-100 p-0">
			{showResetPassword && (
				<Modal title={'Reset Password'} visible={showResetPassword} onCancel={() => setShowResetPassword(false)} width={400}>
					<Form form={form} size="small" layout="vertical" onFinish={(values) => handleResetPassword(values)}>
						<Form.Item
							name={'password'}
							style={{ width: '65%' }}
							rules={[{ required: true, message: 'Please enter password' }]}
						>
							<Input size="large" placeholder={`Enter Password`} />
						</Form.Item>

						<Form.Item>
							<Button type="primary" size="large" block htmlType="submit" loading={loading} style={{ top: '20px' }}>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Modal>
			)}

			<ErrorBoundary fallback={<Fallback fallBackMessage="Sorry, Failed to load data" />}>
				<Col span={24} className="main">
					<TopFilterComponent {...topFilterConfig} />

					<div className="innerDiv">
						<div className="div w-100 h-100">
							<CustomTablePagination
								columns={columnsConfig}
								data={data}
								showSorterTooltip={true}
								{...pageConfig}
								onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
								total={count}
								onChange={handleTableChange}
								loading={loading}
								shouldRowSelection={false}
							/>
						</div>
					</div>
				</Col>
			</ErrorBoundary>
		</Row>
	);
}
