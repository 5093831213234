import { createContext, useContext, useEffect, useState } from 'react';
import { ISocketConstant } from 'shared/components/OrderInfoSlider/components/ChatUi/constant';
import socketIOClient from 'socket.io-client';
import { envs } from '../utils/env';
import { AuthContext } from './Auth';
export const SocketContext = createContext<any>(null);

export const SocketContextProvider = ({ children }: any) => {
	const [socket, setSocket] = useState<any>(null);
	const { authState } = useContext(AuthContext);
	useEffect(() => {
		/**
		 * create socket after user is logged in
		 */
		if (authState.profileLoaded && authState?.profile?.id) {
			// authState?.profileLoaded &&
			const socketConnection = socketIOClient(envs.socketUrl as string, {
				transports: ['websocket'],
				query: {
					companyId: authState?.profile?.id
				}
			});
			setSocket(socketConnection);

			socketConnection.on(ISocketConstant.NEW_SOCKET_ID, newSocketIDConnection);
			return () => {
				socketConnection.off(ISocketConstant.TASK_STATUS_CHANGE, newSocketIDConnection);
				socketConnection.off(ISocketConstant.TASK_CENTER_PROGRESS_UPDATE, newSocketIDConnection);
			};
		}
	}, [authState?.profile?.id, authState.profileLoaded]); //authState.authLoaded,

	const newSocketIDConnection = (data: any) => {
		localStorage.setItem('socketId', data.socketId);
	};

	return authState?.isLogined && authState?.profileLoaded && authState?.authLoaded ? (
		<SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
	) : (
		<>{children}</>
	);
};
