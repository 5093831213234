import { useHistory } from 'react-router-dom';
import React, { Fragment, useContext, useState } from 'react';
import { Button, Form, Input, notification, Select } from 'antd';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { EVENT_TYPE, SHOP_TYPE } from '../../../../constants';
import { createCatalog, updateCatalog } from 'shared/api/catalog';
import Spinner from 'shared/components/spinner/Spinner';
import EditableTable from './EditableTable';
import styles from './index.module.scss';
import { AuthContext } from 'shared/contexts/Auth';
import { antDesignDecimalValueValidator, isNumberAndNotZero } from '../../../../helper';
import errorHandler from 'shared/utils/errorHandler';
import { HsnFormItem } from 'shared/components/HsnFormItem';
import { decimalRegexValidator } from 'utils/regex';

const gstFields = [
	{ label: 'SGST (%)', name: 'sgst', required: true },
	{ label: 'CGST (%)', name: 'cgst', required: true },
	{ label: 'IGST (%)', name: 'igst', required: true },
	{ label: 'UTGST (%)', name: 'utgst', required: false }
];

export function AddNewItemForm(props) {
	const { initialValues, isUpdate, refreshData } = props;
	const history = useHistory();
	const [type, setType] = useState('child');
	const [showParentProduct, setShowParentProduct] = useState(true);
	const [loading, setLoading] = useState(false);
	const {
		authState: { profile }
	} = useContext(AuthContext);

	const onCancelHandler = () => history.push('/catalogs');

	const onSubmitHandler = (values) => {
		setLoading(true);

		if (initialValues) {
			delete initialValues.key;
			delete initialValues.mappedDimensions;

			const { id: catalogId } = initialValues;

			return updateCatalog(catalogId, {
				// eventType: 'update',
				shopType: SHOP_TYPE.MANUAL,
				companyId: profile?.id,
				productId: values.productId,
				productTitle: values.productTitle,
				variantId: '', // added variant_id
				variantTitle: values.productTitle,
				category: values.category,
				weight: values.weight,
				weightUnit: 'g',
				active: values.active,
				isActive: values.active,
				price: values.price,
				basePrice: values.basePrice,
				margin: values.margin,
				// priceCurrency: 'INR',
				inventoryQuantity: values.inventoryQuantity,
				barCode: '',
				hsnCode: values.hsnCode,
				handle: values.productTitle,
				imageUrls: [],
				productType: values.category,
				dimensions: {
					length: values.length,
					breadth: values.breadth,
					height: values.height
				}
			})
				.then((res) => {
					setLoading(false);
					refreshData();
					history.push('/catalogs');
					notification.success({
						message: 'Catalog Updated successfully!',
						placement: 'topRight'
					});
				})
				.catch((err) => {
					errorHandler(err);
					setLoading(false);
				});
		}

		/**
		 * In Future We have to add variantId for one product, as of now we are not managing different
		 * variant ids for one product so we are passing variantId as a blank and we have made the
		 * condition on backend if variantId comes blank the we will add uuid by default for variantId
		 *
		 * As Of now we are adding uuid for every productId we are adding
		 *
		 * In Future we will allow customers to add variantId and for that we will have to make one unique key
		 * which will be the combination of productId and variantId and Duplication will checked on that combination
		 *
		 */
		let reqData = {
			eventType: EVENT_TYPE.CREATE,
			shopType: SHOP_TYPE.MANUAL,
			companyId: profile?.id,
			productId: values.productId,
			productTitle: values.productTitle,
			variantId: '',
			variantTitle: values.productTitle,
			category: values.category,
			weight: values.weight,
			weightUnit: 'g',
			active: values.active,
			isActive: values.active,
			sku: values.sku,
			skuId: values.sku,
			price: values.price,
			basePrice: parseFloat(values.basePrice),
			distributorMargin: parseFloat(values.distributorMargin),
			margin: parseFloat(values.margin),
			priceCurrency: 'INR',
			inventoryQuantity: values.inventoryQuantity,
			barCode: '',
			hsnCode: values.hsnCode,
			handle: values.productTitle,
			imageUrls: [],
			productType: values.category,
			dimensions: {
				length: values.length,
				breadth: values.breadth,
				height: values.height
			},
			gstPercentage: { ...values.gstPercentage, utgst: values.gstPercentage.utgst || values.gstPercentage.sgst } // if ut-gst is null, use sgst
		};

		console.log('reqData', reqData);

		return createCatalog(reqData)
			.then((res) => {
				setLoading(false);
				refreshData();
				history.push('/catalogs');
				notification.success({
					message: 'Catalog added successfully!',
					placement: 'topRight'
				});
			})
			.catch((err) => {
				errorHandler(err);
				setLoading(false);
			});
	};

	return (
		<div className={styles.addNewItemForm}>
			<Form
				layout="vertical"
				className={styles.form}
				requiredMark={false}
				initialValues={
					initialValues
						? {
								length: initialValues?.dimensions?.length,
								breadth: initialValues?.dimensions?.breadth,
								height: initialValues?.dimensions?.height,
								...initialValues
						  }
						: {
								productId: '',
								productTitle: '',
								skuId: '',
								category: '',
								productType: 'child',
								price: '',
								dimensions: '',
								weight: '',
								active: 1,
								inventoryQuantity: ''
						  }
				}
				onFinish={onSubmitHandler}
				onValuesChange={({ type }) => {
					if (type) {
						setType(type);
					}
				}}
			>
				<div className={styles.productDetailsContainer}>
					<Form.Item
						name="productTitle"
						label="Item Name"
						rules={[{ required: true, message: 'Item Name is required!' }]}
						className={styles.formItem}
					>
						<Input size="large" placeholder="Apple" className={styles.input} disabled={loading} />
					</Form.Item>

					<Form.Item
						name="productId"
						label="Product ID"
						rules={[{ required: true, message: 'Product ID is required!' }]}
						className={styles.formItem}
					>
						<Input size="large" placeholder="265215464646" className={styles.input} disabled={loading} />
					</Form.Item>

					<Form.Item
						name="sku"
						label="SKU ID"
						rules={[
							{ required: !isUpdate, message: 'SKU ID is required!' },
							{ pattern: /^((?![ ]).)*$/, message: 'SKU ID must not contain space' },
							{ pattern: /^((?![()]).)*$/, message: 'SKU ID must not contain brackets' }
						]}
						className={styles.formItem}
					>
						<Input
							size="large"
							placeholder={isUpdate ? '' : '2187089'}
							className={styles.input}
							disabled={isUpdate || loading}
						/>
					</Form.Item>

					<HsnFormItem loading={loading} />

					<Form.Item
						name="category"
						label="Category"
						rules={[{ required: true, message: 'Category is required!' }]}
						className={styles.formItem}
					>
						<Input size="large" placeholder="Food" className={styles.input} disabled={loading} />
					</Form.Item>

					{/* <Form.Item
						name="productType"
						label="Type"
						rules={[{ required: true, message: 'Type is required!' }]}
						className={styles.formItem}
					>
						<Select
							className={styles.select}
							// onChange={handleChange}
							disabled={loading}
						>
							<Option value="child">Child</Option>
							<Option value="parent">Parent</Option>
						</Select>
					</Form.Item> */}

					{type === 'parent' ? (
						<Fragment>
							<div className={styles.parentDetailsContainer}>
								<span className={styles.expandAddChild} onClick={() => setShowParentProduct(!showParentProduct)}>
									Add Child Products {showParentProduct ? <CaretDownOutlined /> : <CaretRightOutlined />}
								</span>
							</div>
							<div>{showParentProduct ? <EditableTable /> : null}</div>
						</Fragment>
					) : null}

					<Form.Item
						name="active"
						label="Status"
						rules={[{ required: true, message: 'Status is required!' }]}
						className={styles.formItem}
					>
						<Select className={styles.select} disabled={loading} size={'large'}>
							<Select.Option value={1}>Active</Select.Option>
							<Select.Option value={0}>InActive</Select.Option>
						</Select>
					</Form.Item>

					<Form.Item
						name="price"
						label="Price (inclusive of GST)"
						rules={[
							{ required: true, message: 'Price is required!' },
							{ pattern: decimalRegexValidator, message: 'Price must be a number' }
						]}
						className={styles.formItem}
					>
						<Input size="large" placeholder="12" className={styles.input} disabled={loading} />
					</Form.Item>

					<Form.Item
						name="basePrice"
						label="Base Price"
						rules={[
							{ required: false, message: 'Price is required!' },
							{ pattern: decimalRegexValidator, message: 'Price must be a number' }
						]}
						className={styles.formItem}
					>
						<Input size="large" placeholder="Enter base price" className={styles.input} disabled={loading} />
					</Form.Item>

					<Form.Item
						name="margin"
						label="Margin %"
						rules={[
							{ required: false, message: 'Margin % is required!' },
							{ pattern: decimalRegexValidator, message: 'Price must be a number' }
						]}
						className={styles.formItem}
					>
						<Input size="large" placeholder="Enter base price" className={styles.input} disabled={loading} />
					</Form.Item>

					<Form.Item
						name="distributorMargin"
						label="Distributor Margin %"
						rules={[
							{ required: false, message: 'Distributor Margin % is required!' },
							{ pattern: decimalRegexValidator, message: 'Price must be a number' }
						]}
						className={styles.formItem}
					>
						<Input size="large" placeholder="Enter base price" className={styles.input} disabled={loading} />
					</Form.Item>

					<Form.Item
						name="length"
						label="Length(cm)"
						rules={[
							{ required: true, message: 'Length is required!' },
							{ pattern: decimalRegexValidator, message: 'Length must be a number' }
						]}
						className={styles.formItem}
					>
						<Input size="large" placeholder="Length in cm" className={styles.input} disabled={loading} />
					</Form.Item>

					<Form.Item
						name="breadth"
						label="Breadth(cm)"
						rules={[
							{ required: true, message: 'Breadth is required!' },
							{ pattern: decimalRegexValidator, message: 'Breadth must be a number' }
						]}
						className={styles.formItem}
					>
						<Input size="large" placeholder="Breadth in cm" className={styles.input} disabled={loading} />
					</Form.Item>

					<Form.Item
						name="height"
						label="Height(cm)"
						rules={[
							{ required: true, message: 'Height is required!' },
							{ pattern: decimalRegexValidator, message: 'Height must be a number' }
						]}
						className={styles.formItem}
					>
						<Input size="large" placeholder="Height in cm" className={styles.input} disabled={loading} />
					</Form.Item>

					<Form.Item
						name="weight"
						label="Weight (gm)"
						rules={[{ required: true, message: 'Weight is required!' }, isNumberAndNotZero]}
						className={styles.formItem}
					>
						<Input size="large" placeholder="100" className={styles.input} disabled={loading} />
					</Form.Item>

					{/* <Form.Item
						name="inventoryQuantity"
						label="Available Stock"
						rules={[{ required: true, message: 'Stock is required!' }]}
						className={styles.formItem}
					>
						<Input size="large" placeholder="22" className={styles.input} />
					</Form.Item> */}

					{gstFields.map(({ label, name, required }) => {
						return (
							<Form.Item
								name={['gstPercentage', name]}
								label={label}
								rules={[{ required, message: `Please input ${label}` }, antDesignDecimalValueValidator]}
								className={styles.formItem}
							>
								<Input size="large" className={styles.input} placeholder="18" />
							</Form.Item>
						);
					})}
				</div>

				<Form.Item className={styles.formItem}>
					<Button size="large" onClick={onCancelHandler} className="mr-5">
						Cancel
					</Button>

					<Button type="primary" htmlType="submit" disabled={loading} size="large">
						Save
					</Button>
				</Form.Item>
			</Form>
			<div className={styles.spinner}>{loading ? <Spinner /> : null}</div>
		</div>
	);
}
