import { Col, Row } from 'antd';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { getCompanyCatalog, syncCatalogue, downloadCataloguesCSV } from 'shared/api/catalog';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { CatalogueInfoSlider } from 'shared/components/CatalogueInfoSlider';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { AddNewItemForm } from './AddNewItemForm';
import { catalogColumns } from 'shared/columns/Catalog/catalogColumns';
import { useSidebarMenu, topBarConfig } from './utils';
import errorHandler from 'shared/utils/errorHandler';
import { defaultFilter } from './constants';
import { defaultPageConfig } from '../../../constants';
import * as moment from 'moment';
import { adminCatalogColumns } from 'app/AppAdmin/Brands/Products/columns';
import { SellerCatalog } from './SellerCatalog';
import amplitude from '../../../shared/utils/Amplitude';
import { EVENT_NAMES } from 'utils/analytics';
import { useHistory, useLocation } from 'react-router-dom';

export function Catalog({ location, isAdminView = false, companyId, setAdminCatalogueSelectedKeys, adminCatalogueSelectedKeys }) {
	const hash = window.location.hash;
	const searchQuery = new URLSearchParams(location?.search);
	const queryString = searchQuery.get('q');
	const [search, setSearch] = useState('');
	const [catalogList, setCatalogList] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [searchCatalogList, setSearchCatalogList] = useState([]);
	const [selectedItem, setSelectedItem] = useState();
	const sideBarMenu = useSidebarMenu(isAdminView, companyId);
	const [loading, setLoading] = useState(false);
	const [openCatalogueInfo, setOpenCatalogueInfo] = useState(false);
	const [reloadCatalogueList, setReloadCatalogueList] = useState(0);
	const columnsConfig = useMemo(() => catalogColumns({ clickCatalogueId: setOpenCatalogueInfo }), [setOpenCatalogueInfo]);
	const adminColumnsConfig = useMemo(() => adminCatalogColumns({ clickCatalogueId: setOpenCatalogueInfo }), [setOpenCatalogueInfo]);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [syncDisabledFlag, setSyncDisabledFlag] = useState(false);
	const [filter, setFilter] = useState(defaultFilter);

	const columns = isAdminView ? adminColumnsConfig : columnsConfig;
	const history = useHistory();
	const CatalogOptions = useRef({});

	const historyLocation = useLocation();

	let queryValue = historyLocation['search'].split('=')[1];

	if (!queryValue && !isAdminView) {
		queryValue = 'manual';
		history.push({
			pathname: '/catalogs',
			search: `?tab=${queryValue}`
		});
	}

	if (sideBarMenu.length > 0 && !isAdminView) {
		let catalogObject = {};
		for (let i = 0; i < sideBarMenu.length; i++) {
			let temp_key = sideBarMenu[i]['key'];
			temp_key = temp_key + '';
			catalogObject[temp_key] = i + 1;
		}
		CatalogOptions.current = { ...catalogObject };
	}

	const [shopType, setShopType] = useState(queryValue);

	useEffect(function syncCatalog() {
		if (!isAdminView) {
			const syncFlagData = JSON.parse(localStorage.getItem('syncFlagData'));
			if (syncFlagData) {
				const timeStamp = syncFlagData.timestamp;
				const seconds = moment(new Date()).diff(moment(new Date(timeStamp)), 'seconds');
				if (seconds < 60) {
					setSyncDisabledFlag(true);
				} else {
					setSyncDisabledFlag(false);
					localStorage.removeItem('syncFlagData');
				}
			}
		}
	}, []);

	async function getCatalog() {
		try {
			setLoading(true);
			const {
				data: { catalogue, totalCount: count }
			} = await getCompanyCatalog({ ...pageConfig, shopType: shopType, isAdminView, companyId, search, filter });
			setTotalCount(count);
			setCatalogList(
				[...catalogue].map((item) => ({
					...item,
					mappedDimensions: `${
						Object.keys(item.dimensions).length
							? `${item?.dimensions?.length}x${item?.dimensions?.breadth}x${item?.dimensions?.height}`
							: '0 x 0 x 0'
					} `,
					key: item.catalogId
				}))
			);
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		if (isAdminView) {
			getCatalog();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageConfig, search, filter, companyId, shopType]);

	useEffect(() => {
		if (queryString) {
			setSearchCatalogList(
				[...catalogList].filter((item) => item.skuId.includes(`${queryString}`) || item.productTitle.includes(`${queryString}`))
			);
		} else {
			setSearchCatalogList([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryString]);

	useEffect(() => {
		if (!isAdminView) {
			setSearchCatalogList(catalogList.filter((item) => item.shopType === shopType));
			setPageConfig((previousPageConfig) => ({ ...previousPageConfig, ...defaultPageConfig }));
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}
	}, [shopType]);

	useEffect(() => {
		if (shopType && !isAdminView) {
			getCatalog();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageConfig, search, reloadCatalogueList, filter, companyId]);

	/**
	 * Sync Catalogue with shopify latest product list
	 * Sync Button will be disabled for one minute after syncing with products start
	 */
	const onSyncCatalogue = async () => {
		amplitude.getInstance().logEvent(EVENT_NAMES.SYNC_CATALOG_BUTTON);
		try {
			setSyncDisabledFlag(true);
			localStorage.setItem(
				'syncFlagData',
				JSON.stringify({
					enabled: true,
					timestamp: moment(new Date(), 'DD/MM/YYYY HH:mm:ss')
				})
			);

			// Disabled for one minute and then refetch catalogue list
			setTimeout(() => {
				setSyncDisabledFlag(false);
				setReloadCatalogueList((prevValue) => prevValue + 1);
			}, 60000);

			// Called Sync Api
			await syncCatalogue(shopType);
		} catch (err) {
			errorHandler(err);
		}
	};

	async function onDownloadCatalogues(selectedShopType) {
		try {
			setLoading(true);
			await downloadCataloguesCSV(selectedShopType);
		} catch (err) {
			errorHandler(err);
		} finally {
			setLoading(false);
		}
	}

	const showTopFilterComponent = () => hash !== '#update' && hash !== '#add';

	function BottomComponent(hash) {
		if (hash === '#update') {
			return (
				<AddNewItemForm
					refreshData={() => setReloadCatalogueList((prevValue) => prevValue + 1)}
					initialValues={selectedItem && selectedItem[0]}
					shopType={shopType}
					isUpdate
				/>
			);
		} else if (hash === '#add') {
			return <AddNewItemForm refreshData={() => setReloadCatalogueList((prevValue) => prevValue + 1)} />;
		} else {
			return (
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={columns}
							data={searchCatalogList[0] ? searchCatalogList : catalogList}
							onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
							total={searchCatalogList[0] ? searchCatalogList.length : totalCount}
							isCatalog={true}
							catalog={true}
							selectedItem={selectedItem}
							setSelectedItem={isAdminView ? setAdminCatalogueSelectedKeys : setSelectedItem}
							loading={loading}
							emptyTableMessage={'Please integrate sales channel or create manual catalog!'}
							{...pageConfig}
						/>
					</div>
					{openCatalogueInfo && (
						<CatalogueInfoSlider
							reloadList={() => setReloadCatalogueList((prevValue) => prevValue + 1)}
							catalogueId={openCatalogueInfo}
							onClose={() => setOpenCatalogueInfo(null)}
							shopType={shopType}
							isAdminView={isAdminView}
							companyId={companyId}
						/>
					)}
				</div>
			);
		}
	}

	useEffect(() => {
		if (!isAdminView) {
			let newQueryValue;
			newQueryValue = Object.keys(CatalogOptions.current).find((key) => CatalogOptions.current[key] == shopType);

			for (let catalogoption in CatalogOptions.current) {
				if (catalogoption === shopType) {
					newQueryValue = catalogoption;
				}
			}

			if (newQueryValue) {
				history.push({
					pathname: '/catalogs',
					search: `?tab=${newQueryValue}`
				});
			}
		}
	}, [shopType]);

	return (
		<Row className="h-100 p-0">
			{!isAdminView && <SellerCatalog sideBarMenu={sideBarMenu} shopType={shopType} setShopType={setShopType} />}

			<Col sm={24} lg={isAdminView ? 24 : 20} className="main">
				{showTopFilterComponent() && (
					<TopFilterComponent
						{...topBarConfig({
							shopType,
							setShopType,
							setSearch,
							onSyncCatalogue,
							syncDisabledFlag,
							onDownloadCatalogues,
							setPageConfig,
							filter,
							setFilter,
							isAdminView,
							adminCatalogueSelectedKeys,
							sideBarMenu
						})}
					/>
				)}

				{BottomComponent(hash)}
			</Col>
		</Row>
	);
}
