import { apiOms, apiWms } from './index';
import { cleanObject } from 'helper';
import { notification } from 'antd';
import { envs } from 'shared/utils/env';
import { MAX_DOWNLOAD_ORDER_LIMIT } from '../../app/AppSeller/Orders/constants';
import errorHandler from '../utils/errorHandler';
import dayjs from 'dayjs';
const { omsBaseUrl } = envs;

function getCompanyOrders({ current = 1, pageSize = 10, sortField = 'createdAt', sortOrder = 'DESC' }, filter, abortControllerRef = {}) {
	if (filter?.status?.length === 0) {
		delete filter.status;
	}
	if (abortControllerRef.current) {
		abortControllerRef.current.abort();
	}
	abortControllerRef.current = new AbortController();
	return apiOms.post(
		'/orders/find',
		{
			where: cleanObject({ ...filter }, ['', null, undefined]),
			pagination: {
				records: pageSize,
				pageNumber: current
			},
			sortBy: {
				order: sortField,
				by: sortOrder
			}
		},
		{
			signal: abortControllerRef.current.signal
		}
	);
}

function getCompanyMissedOrders({ current = 1, pageSize = 10, sortField = 'createdAt', sortOrder = 'DESC' }, filter, companyIds) {
	return apiOms.post('/orders/missed-orders', {
		pagination: {
			records: pageSize,
			pageNumber: current
		},
		sortBy: {
			order: sortField,
			by: sortOrder
		},
		where: cleanObject({ ...filter }, ['', null, undefined]),
		companyIds
	});
}

function syncMissedOrderForExternalStore(orderId, shopType, missedOrderId, companyId) {
	return apiOms.post('/order/sync-missed-order', {
		orderId,
		shopType,
		missedOrderId,
		companyId
	});
}

function getAllClusters() {
	return apiOms.get('/clusters/find');
}

async function downloadFillteredCSV(orderIDs) {
	try {
		const { data } = await apiOms.post(
			'/orders/csv',
			{
				orderID: orderIDs
			},
			{ responseType: 'blob' }
		);
		const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
		let a = document.createElement('a');
		a.href = URL.createObjectURL(blob);
		a.download = `order${dayjs().format()}`;
		a.click();
	} catch (err) {
		errorHandler(err);
	}
}

async function downloadOrdersCSV(params, orderIds) {
	if (orderIds && orderIds.length > 0) {
		downloadFillteredCSV(orderIds);
		return;
	}
	try {
		for (const [key, value] of Object.entries(params)) {
			if (!value) delete params[key];
			if (key === 'filterFormValue') {
				params[key] = JSON.stringify(value);
			}
		}
		const url = new URL(`${omsBaseUrl}/orders/csv`);
		url.search = new URLSearchParams(params);

		const countUrl = new URL(`${omsBaseUrl}/orders/filter/count`);
		countUrl.search = new URLSearchParams(params);

		// if admin download, trigger email flow
		if (window.location.host?.includes('admin')) {
			return true;
		}

		/**
		 * Get Order Count
		 */
		const {
			data: { ordersCount }
		} = await apiOms.get(countUrl);

		if (ordersCount && ordersCount > MAX_DOWNLOAD_ORDER_LIMIT) {
			// Open a modal to ask user to enter email
			return true;
		}
		window.open(url);
		return false;
	} catch (err) {
		notification.error({
			message: 'Download CSV Error',
			description: 'Please try again after some time.',
			placement: 'topRight'
		});
	}
}

async function downloadMoreOrdersAndMail(params, email, socketId) {
	for (const [key, value] of Object.entries(params)) {
		if (!value) delete params[key];
		if (key === 'filterFormValue') {
			params[key] = JSON.stringify(value);
		}
	}
	const mailUrl = new URL(`${omsBaseUrl}/orders/download/email`);
	mailUrl.search = new URLSearchParams(params);

	const { data } = await apiOms.post(mailUrl, {
		email,
		socketId
	});

	notification.success({
		message: 'Download Orders',
		description: data?.message,
		placement: 'topRight'
	});

	return true;
}

async function downloadLablesAndMail(ids, email, type, socketId) {
	const { data } = apiOms.post('/download/bulk-label', {
		email: email,
		orderIds: ids,
		urlType: type === 'Label' ? 'shippingUrl' : 'invoiceUrl',
		socketId: socketId
	});

	notification.success({
		message: `Download ${type}s`,
		description: data?.message,
		placement: 'topRight'
	});

	return true;
}

function fetchOrderInfo(id, companyId) {
	if (companyId) {
		// For wms panel we need to pass companyId from panel
		return apiOms.get(`/order/${id}/${companyId}`);
	}
	return apiOms.get(`/order/${id}`);
}

function fetchOrderDriverInfo(orderId) {
	return apiOms.post(`/hyperlocal_data`, {
		orderId
	});
}

function setReadyToShip(id, couriorPartnerId) {
	return apiOms.post(`/readytoship/${id}`, {
		couriorPartnerId
	});
}

function setReadyToShipBulk(ids) {
	return apiWms.post('/order/processOrders', {
		orderIds: ids
	});
}

function downloadOrdersPickList(ids) {
	return apiOms.post(
		'/picklist',
		{
			orderIds: ids
		},
		{
			responseType: 'blob'
		}
	);
}

function updateOrderCustomerAddress(orderId, data) {
	return apiOms.put(`/order/customer-address/${orderId}`, data);
}

function updateOrderDetails(orderId, data) {
	return apiOms.put(`/order/details/${orderId}`, data);
}

function checkOrderServiceablity(orderId) {
	return apiOms.get(`/check-serviceability?orderId=${orderId}`);
}

function getPincodeDetails(pincode) {
	return apiOms.get(`/pincodeDetails/${pincode}`);
}

function cloneOrder(orderId) {
	return apiOms.post('/order/clone', {
		orderId: orderId
	});
}

function regenerateInvoiceAndLabel(orderId) {
	return apiOms.get(`/regenerate-invoice-and-label/${orderId}`);
}

function revertOrderStatusToPlaced(orderId) {
	return apiOms.get(`/revert-order-status-to-placed/${orderId}`);
}

export {
	getCompanyOrders,
	downloadOrdersCSV,
	fetchOrderInfo,
	setReadyToShip,
	updateOrderCustomerAddress,
	downloadOrdersPickList,
	checkOrderServiceablity,
	getAllClusters,
	fetchOrderDriverInfo,
	getCompanyMissedOrders,
	syncMissedOrderForExternalStore,
	getPincodeDetails,
	downloadMoreOrdersAndMail,
	downloadLablesAndMail,
	cloneOrder,
	regenerateInvoiceAndLabel,
	revertOrderStatusToPlaced,
	updateOrderDetails
};
