import { Button, Form, Input, Modal } from 'antd';

interface IRequestEmailModal {
	title: string;
	visible: boolean | undefined;
	onCancel: () => void;
	footer: [];
	width: number | string;
	onFinish: (values: any) => Promise<void>;
	instructionMessage: string;
	loading?: any;
}

export function RequestEmailModal({
	title,
	visible,
	onCancel,
	footer,
	width,
	onFinish,
	instructionMessage,
	loading
}: Readonly<IRequestEmailModal>) {
	const [form] = Form.useForm();

	return (
		<Modal title={title} visible={visible} onCancel={onCancel} footer={footer} width={width}>
			<Form form={form} size="small" layout="vertical" onFinish={onFinish}>
				<label style={{ marginBottom: '30px' }}>{instructionMessage}</label>

				<Form.Item name={'email'} style={{ width: '65%' }} rules={[{ required: true, message: 'Please enter email' }]}>
					<Input size="large" placeholder={`Enter Email`} />
				</Form.Item>

				<Form.Item>
					<Button type="primary" size="large" block htmlType="submit" loading={loading} style={{ top: '20px' }}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
}
