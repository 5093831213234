export const locationSvg = (
	<svg
		stroke="currentColor"
		fill="currentColor"
		stroke-width="0"
		version="1.1"
		viewBox="0 0 16 16"
		height="1em"
		width="1em"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M8 0c-2.761 0-5 2.239-5 5 0 5 5 11 5 11s5-6 5-11c0-2.761-2.239-5-5-5zM8 8c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"></path>
	</svg>
);

export const locationNewSvg = (routeSequenceNumber) => {
	let color = '#4285F4';

	if (routeSequenceNumber % 2 === 0) {
		color = '#3fff00';
	}

	if (routeSequenceNumber % 3 === 0) {
		color = '#00ffea';
	}

	if (routeSequenceNumber % 5 === 0) {
		color = '#faad14';
	}

	return (
		<div style={{ width: 20, height: 20 }}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
				<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512z" fill={color} />
			</svg>
		</div>
	);
};
