import { Button, notification } from 'antd';
import { useState, useContext } from 'react';
import { updateProfileDetails } from 'shared/api/profile';
import { AuthContext } from 'shared/contexts/Auth';
import { ProfileForm } from '../../../../shared/components/ProfileForm';
import styles from './index.module.scss';

export function Profile() {
	const [isFormDisabled, setIsFormDisabled] = useState(true);
	const {
		authState: { profile }
	} = useContext(AuthContext);

	const handleEditButtonClick = () => setIsFormDisabled(false);

	const onFinish = async (updateProfileValues) => {
		try {
			await updateProfileDetails(updateProfileValues);

			notification.success({
				message: 'Success',
				description: 'Profile updated successfully.',
				placement: 'topRight'
			});

			setIsFormDisabled(true);
		} catch (error) {
			console.log(error);
		}
	};

	//   useEffect(() => {
	//     getProfileData()
	// }, []);

	return (
		<div className={styles.profilePage}>
			<div className={styles.header}>
				<h4 className={styles.title}>Profile Details</h4>
				{isFormDisabled && (
					<Button type="primary" onClick={handleEditButtonClick}>
						Edit
					</Button>
				)}
			</div>

			<ProfileForm onFinish={onFinish} isFormDisabled={isFormDisabled} initialValues={profile} isWms={true} />
		</div>
	);
}
