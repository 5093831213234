export const statusWiseBadge = {
	Packed: '#87d068',
	Pending: '#faad14'
};

export const checkFulfillStatus = (items: any[], status?: string) => {
	let successCount = 0;
	for (const item of items) {
		if (item?.scanned_qty === item?.qty) {
			successCount++;
		}
		if (item?.scanned_qty > 0 && item?.scanned_qty !== item?.qty) {
			return {
				O: '#108ee9',
				status: 'Partially Packed'
			};
		}
	}

	if (items.length && successCount === items.length) {
		return { status: 'Packed', O: 'success' };
	}

	return { status: status, O: statusWiseBadge[status as keyof typeof statusWiseBadge] };
};

export const statusWiseClassName = {
	Pending: 'orangeRow',
	Packed: 'greenR'
};

export const instructionsToPackOrders = [
	'Scan awb in the form of barcode available in shipping labels',
	'Screen will show items to be packed within the order, start scanning the items',
	'once all the items of that particular order are scanned, screen will show a success message',
	'move to the next order and repeat the steps'
];
