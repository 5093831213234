import { useState, useMemo, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import { Menu, Button } from 'antd';
import { PaperClipOutlined, ApiOutlined } from '@ant-design/icons';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { columns } from './column';
import { defaultPageConfig, defaultFilter } from './constants';
import { topFilterUtil } from './util';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { apiUms } from 'shared/api';
import errorHandler from 'shared/utils/errorHandler';
import { AuthContext } from 'shared/contexts/Auth';
import { DEFAULT_FILTER, DEFAULT_PAGE_CONFIG, IWEBHOOK } from './interfaces';
import WebhookModal from './components/WebhookModal';

const apiMenuItems = [
	{ label: 'Configure Webhooks', key: 'webhooks', icon: <PaperClipOutlined /> },
	{
		label: (
			<a href="https://documenter.getpostman.com/view/26032653/2s93CRLXYX" target="_blank" rel="noopener noreferrer">
				Wherehouse Lightning Docs
			</a>
		),
		key: 'docs',
		icon: <ApiOutlined />,
		default: true
	}
];

export function Api(): JSX.Element {
	const [webhooks, setWebhooks] = useState<[IWEBHOOK]>();
	const [tab, setTab] = useState<string>('webhooks');
	const [pageConfig, setPageConfig] = useState<DEFAULT_PAGE_CONFIG>(defaultPageConfig);
	const [filter, setFilter] = useState<DEFAULT_FILTER>({ ...defaultFilter });
	const [loading, setLoading] = useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [currentCount, setCurrentCount] = useState<number>(0);
	const { authState } = useContext(AuthContext);
	const [selectedRowKeys, setSelectedRowKeys] = useState<[]>([]);
	const [reloadPage, setReloadPage] = useState<number>(0);

	const topFilterConfig = useMemo(
		function () {
			return topFilterUtil({
				filter: { ...filter },
				setFilter,
				setPageConfig,
				selectedRowKeys,
				companyId: authState.profile.id,
				getAllWebhooks,
				setIsModalVisible
			});
		},
		[filter, pageConfig, selectedRowKeys]
	);
	async function getAllWebhooks() {
		try {
			setLoading(true);
			const {
				data: { data }
			} = await apiUms.post(
				`/ums/webhooks/get`,
				{
					filter,
					pageConfig
				},
				{
					headers: { 'x-company-id': authState.profile.id }
				}
			);
			setWebhooks(data);
			setCurrentCount(data.length);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			errorHandler(error);
		}
	}

	useEffect(() => {
		getAllWebhooks();
	}, [filter, pageConfig, reloadPage]);

	return (
		<div className={styles.api}>
			<WebhookModal
				setIsModalVisible={setIsModalVisible}
				isModalVisible={isModalVisible}
				companyId={authState.profile.id}
				reloadPage={setReloadPage}
			/>
			<Menu mode="horizontal" defaultSelectedKeys={['webhooks']}>
				{apiMenuItems.map(({ label, key, icon }) => {
					return (
						<Menu.Item key={key} icon={icon}>
							<Button type="text" size="small" onClick={() => setTab(key)}>
								{label}
							</Button>
						</Menu.Item>
					);
				})}
			</Menu>
			{tab === 'webhooks' && (
				<div className={styles.webhook_table}>
					<TopFilterComponent {...topFilterConfig} />
					{/* @ts-ignore */}
					<CustomTablePagination
						columns={columns}
						data={webhooks}
						showSorterTooltop={true}
						{...pageConfig}
						onChangePage={(current: any, pageSize: any) => setPageConfig((v) => ({ ...v, current, pageSize }))}
						total={currentCount}
						loading={loading}
						selectedRowKeys={selectedRowKeys}
						setSelectedRowKeys={setSelectedRowKeys}
					/>
				</div>
			)}
		</div>
	);
}
