import ReactApexChart from 'react-apexcharts';
import styles from './index.module.scss';
interface IInsightsMostSellingProduct {
	data: any;
}
export const MostSellingProductChart = ({ data }: IInsightsMostSellingProduct) => {
	return (
		<>
			{data && (
				<div className={styles.mostSellingChart}>
					<div className={styles.topHeading}>
						<p>Most Selling Products</p>
					</div>
					<div className={styles.chartParentDiv}>
						{/* @ts-ignore */}
						<ReactApexChart options={data.options} series={data.series} type="bar" height={355} />
					</div>
					<p className={styles.last30Days}>Last 30 Days</p>
				</div>
			)}
		</>
	);
};
