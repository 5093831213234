import React from 'react';
import { Row, Tag } from 'antd';

export const columns = [
	{
		title: 'Date',
		dataIndex: 'createdAt',
		render: (text: string) => <div className="text-center">{new Date(text).toLocaleDateString()}</div>,
		width: 100
	},
	{
		title: 'Webhook Callback',
		dataIndex: 'webhookUrl',
		width: 200
	},
	{
		title: 'Event Type',
		dataIndex: 'eventType'
	},
	{
		title: 'Active',
		dataIndex: 'isActive',
		render: (v: any, webhook: any): JSX.Element => (
			<Row justify="center">{webhook.isActive ? <Tag color="geekblue">True</Tag> : <Tag color="red">False</Tag>}</Row>
		)
	}
];
