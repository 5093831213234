import { Button, Col, DatePicker, Input, Popconfirm, Typography } from 'antd';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { AutoCompleteInput } from '../../../../shared/components/AutoCompleteInput.js/AutoCompleteInput';
import { getCompanies } from '../../../../shared/api/lightning';
import NoCompany from '../../../../app/AppAdmin/NoCompany/NoCompany';
import errorHandler from '../../../../shared/utils/errorHandler';
import { usePricingDetails } from '../Pricing/usePricingDetails';
import { storageChargesTypes } from '../Pricing/constants';
import { storageChargesInputMap } from './constant';
import { apiBms } from '../../../../shared/api';

export default function Actions() {
	const [company, setCompany] = useState(() => {
		const companyDetails = localStorage.getItem(`company-details`);
		return companyDetails ? JSON.parse(companyDetails) : { id: undefined, name: undefined };
	});
	const [dateRange, setDateRange] = useState(['', '']);
	const [quantity, setQuantity] = useState('');
	const [buttonLoading, setButtonLoading] = useState(false);
	const { pricing } = usePricingDetails(company.id);

	const onSubmit = async () => {
		try {
			setButtonLoading(true);
			if (!dateRange[0]) throw new Error('Date Range is mandatory');
			const response = await apiBms.post(`/charges/deduct-storage-charges`, {
				startDate: new Date(dateRange[0]),
				endDate: new Date(dateRange[1]),
				companyId: company.id,
				quantity
			});

			if (!response?.data.status) {
				throw new Error(response?.data.message || 'Could not generate storage charges');
			}
		} catch (error: unknown) {
			errorHandler(error);
		} finally {
			setButtonLoading(false);
		}
	};

	useEffect(() => {
		localStorage.setItem(`company-details`, JSON.stringify(company));
	}, [company]);

	return (
		<>
			<Col className={styles.topHeader}>
				<Col span={6}>
					<AutoCompleteInput
						getOptions={getCompanies}
						entityName="companies"
						selectedFilterItems={company && { entityId: company.id, name: company.name }}
						responseFieldPath="companies"
						optionKeyPath="entityId"
						optionLabelPath="name"
						optionValuePath="entityId"
						placeholder="Search company"
						handleSelect={(op: any) => setCompany({ id: op?.entityId, name: op?.name })}
					/>
				</Col>
			</Col>

			{!company.name ? (
				<NoCompany></NoCompany>
			) : (
				<Col className={styles.contentPage}>
					<Col className={styles.chargesDiv}>
						<Typography.Title level={3}>Generate Storage Charges</Typography.Title>
						<br />
						<Typography.Title level={5}>
							Storage Charge Type -
							{storageChargesTypes.filter((charge) => charge.value == pricing?.storageChargeType)[0]?.label}
						</Typography.Title>
						<Typography.Title level={5}>Select Date Cycle</Typography.Title>
						<DatePicker.RangePicker onChange={(dates: any, dateStrings: [string, string]) => setDateRange(dateStrings)} />
						<br /> <br />
						{storageChargesInputMap[pricing?.storageChargeType as keyof typeof storageChargesInputMap] && (
							<>
								<Typography.Title level={5}>
									{storageChargesInputMap[pricing?.storageChargeType as keyof typeof storageChargesInputMap]}
								</Typography.Title>
								<Input
									name="amount"
									type="number"
									onChange={(e) => {
										setQuantity(e.target.value);
									}}
									style={{ width: '6rem', marginRight: '0.5rem' }}
								/>
								<br /> <br />
							</>
						)}
						<Popconfirm title="Are you sure?" onConfirm={() => onSubmit()} onCancel={() => null} okText="Yes" cancelText="No">
							<Button loading={buttonLoading} type="primary">
								Deduct Storage Charges
							</Button>
						</Popconfirm>
					</Col>
				</Col>
			)}
		</>
	);
}
