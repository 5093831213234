import shopify from 'shared/images/shopify.png';
import wordpress from 'shared/images/wordpress.png';
import manualChannel from 'shared/images/manual_order_logo.svg';
import easyecom from 'shared/images/easyecom.jpeg';
import unicommerce from 'shared/images/unicommerce.png';
import shipway from 'shared/images/shipway.png';

export const images = {
	shopify,
	wordpress,
	manual: manualChannel,
	'manual FBW': manualChannel,
	easyecom,
	unicommerce,
	shipway
};
