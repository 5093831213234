import { antDesignValidator } from '../../../helper';

export const customerDetailsForm = (
	onPincodeChange: (
		{
			target: { value }
		}: {
			target: {
				value: any;
			};
		},
		form: any
	) => Promise<void>
) => [
	{
		name: 'firstName',
		id: 'firstName',
		label: 'First Name',
		required: true,
		placeholder: 'Enter First Name'
	},
	{
		name: 'lastName',
		id: 'lastName',
		label: 'Last Name',
		required: true,
		placeholder: 'Enter Last Name'
	},
	{
		name: 'email',
		id: 'email',
		label: 'Email',
		required: true,
		placeholder: 'Enter Email'
	},
	{
		name: 'phone',
		id: 'phone',
		label: 'Phone',
		required: true,
		placeholder: 'Enter Phone',
		rules: [antDesignValidator.phoneNumber]
	},
	{
		name: 'addressLine1',
		id: 'addressLine1',
		label: 'Address Line 1',
		required: true,
		placeholder: 'Enter Address Line 1'
	},
	{
		name: 'addressLine2',
		id: 'addressLine2',
		label: 'Address Line 2',
		required: true,
		placeholder: 'Enter Address Line 2'
	},
	{
		name: 'pincode',
		id: 'pincode',
		label: 'Pincode',
		required: true,
		placeholder: 'Enter Pincode',
		rules: [antDesignValidator.pincode],
		onInputChange: (value: any, form: any) => {
			onPincodeChange(value, form);
		}
	},
	{
		name: 'city',
		id: 'city',
		label: 'City',
		required: true,
		placeholder: 'Enter City'
	},
	{
		name: 'state',
		id: 'state',
		label: 'State',
		required: true,
		placeholder: 'Enter State'
	}
];

export const addIndexInTrail = (trails: any, statusType = 'orderStatus') => {
	let index = 0;
	for (let i = 0; i < trails.length; i++) {
		if (trails[i]?.[statusType] === trails[i + 1]?.[statusType]) {
			trails[i].index = index;
		} else {
			trails[i].index = index;
			index++;
		}
	}
	return trails;
};

export const ICapturePaymentFormInputFields = [
	{
		key: 'amount',
		placeholder: 'Enter Amount',
		type: 'number',
		rules: [
			{
				required: true,
				message: 'Please Enter Amount'
			},
			antDesignValidator.positiveNumber
		]
	},
	{
		key: 'remarks',
		type: 'text',
		placeholder: 'Enter Remarks',
		rules: [
			{
				required: true
			}
		]
	}
];
