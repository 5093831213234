import { antDesignValidator } from '../../../../helper';

export const getInventoreyStatsMapper = (type: string, data: any) => {
	return type === '1'
		? [
				{
					name: 'Total Count',
					value: Number(data?.inboundAccepted) + Number(data?.inboundRejected) || 0,
					color: '#fcba03'
				},
				{
					name: 'Inbound Accepted',
					value: data?.inboundAccepted || 0,
					color: '#7743DB'
				},
				{
					name: 'Inbound Rejected',
					value: data?.inboundRejected || 0,
					color: '#EB6440'
				}
		  ]
		: [
				{
					name: 'Quantity',
					value: data?.quantity || 0,
					color: '#00ABB3'
				}
		  ];
};

export const InventoryInboundFormInputFields = [
	{
		label: 'Inbound Date',
		name: 'date',
		placeholder: 'DD/MM/YYYY',
		type: 'datetime-local',
		rules: [antDesignValidator.maxDateToday]
	},
	{
		label: 'Accepted',
		name: 'inboundAccepted',
		placeholder: 'Enter Inbound accepted',
		type: 'number',
		rules: [
			{
				required: true,
				message: '👆 Inbound accepted is empty'
			},
			antDesignValidator.positiveNumber
		]
	},
	{
		label: 'Rejected',
		name: 'inboundRejected',
		placeholder: 'Enter Inbound rejected',
		type: 'number',
		rules: [
			{
				required: true,
				message: '👆 Inbound rejected is empty'
			},
			antDesignValidator.positiveNumber
		]
	},
	{
		label: 'Remark',
		name: 'remark',
		placeholder: 'Enter Remark',
		type: 'text',
		rules: [
			{
				required: true,
				message: '👆 Remark rejected is empty'
			}
		]
	}
];

export const IInventoryAdjustmestFormInputFields = [
	{
		label: 'Date',
		name: 'date',
		placeholder: 'DD/MM/YYYY',
		type: 'datetime-local',
		rules: [antDesignValidator.maxDateToday]
	},
	{
		label: 'Quantity',
		name: 'quantity',
		placeholder: 'Enter Quantity',
		type: 'number',
		rules: [
			{
				required: true,
				message: '👆 Quantity is empty'
			},
			antDesignValidator.positiveNumber
		]
	},
	{
		label: 'Reason',
		name: 'remark',
		type: 'text',
		placeholder: 'Enter Reason',
		rules: [
			{
				required: true,
				message: '👆 Reason is empty'
			}
		]
	}
];

export const STOCK_TYPES = {
	CURRENT_STOCK: 'Current stock',
	SAFE_STOCK: 'Safe stock'
};

export const SELECT_AND_FILTER_INVENTORY = [
	{
		name: 'Blocked stock Increased',
		value: 'Blocked stock Increased'
	},
	{
		name: 'Blocked stock Decreased',
		value: 'Blocked stock Decreased'
	},
	{
		name: 'Current stock Increased',
		value: 'Current stock Increased'
	},
	{
		name: 'Current stock Decreased',
		value: 'Current stock Decreased'
	},
	{
		name: 'Inventory Added',
		value: 'Inventory Added'
	}
];
