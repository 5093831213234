import { Button, Form, Input, notification } from 'antd';
import { useState } from 'react';
import { addInventory } from '../../../api/inventory';
import { Loading } from '../../Loading';
import { InventoryInboundFormInputFields } from '../components/utils';
import dayjs from 'dayjs';

export const IInventoryCustomFromData = {
	date: String(dayjs()),
	inboundAccepted: 0,
	inboundRejected: 0,
	remark: ''
};

interface IInventoryInboundFrom {
	inventoryId?: string;
	reloadInventory: () => void;
	setFormData: React.Dispatch<React.SetStateAction<typeof IInventoryCustomFromData>>;
	formData: typeof IInventoryCustomFromData;
	setReloadInventoryList: React.Dispatch<React.SetStateAction<number>>;
}

export const InventoryInboundForm = (props: IInventoryInboundFrom) => {
	const { inventoryId, reloadInventory, setFormData, formData, setReloadInventoryList } = props;

	const [loading, setLoading] = useState<boolean>(false);
	const handleFormItemChange = (name: string, value: string) => {
		setFormData({
			...formData,
			[name]: value
		});
	};

	const handleOnClickAddButton = async () => {
		try {
			setLoading(true);
			const { data } = await addInventory({
				date: formData.date,
				inboundAccepted: formData.inboundAccepted,
				inboundRejected: formData.inboundRejected,
				remark: formData.remark,
				inventoryId: inventoryId
			});
			if (data.status) {
				notification.success({
					message: 'Inventory added',
					description: data.message,
					placement: 'topRight'
				});
			} else {
				throw new Error(data.message || 'Could not add inventory');
			}
		} catch (error: any) {
		} finally {
			reloadInventory();
			setReloadInventoryList((e) => e + 1);
			setFormData(IInventoryCustomFromData);
			setLoading(false);
		}
	};

	if (loading) {
		return <Loading loading={loading} />;
	}

	return (
		<Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="horizontal" style={{ marginTop: '2rem' }}>
			{InventoryInboundFormInputFields.map((e) => (
				<Form.Item name={e.name} label={e.label} key={e.label} rules={e.rules}>
					<Input
						type={e.type}
						value={formData[e.name as keyof typeof IInventoryCustomFromData]}
						onChange={(evnet: any) => handleFormItemChange(e.name, evnet.target.value)}
						placeholder={e.placeholder}
					/>
				</Form.Item>
			))}
			<Form.Item label="Actions">
				<Button
					disabled={!Boolean(formData.inboundAccepted && formData.inboundRejected && formData.remark)}
					loading={loading}
					onClick={handleOnClickAddButton}
					type="primary"
				>
					Add
				</Button>
			</Form.Item>
		</Form>
	);
};
