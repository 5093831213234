import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Sidebar } from '../../../shared/components/AppLayout/Sidebar';
import { SideDrawer } from '../../../shared/components/SideDrawer/SideDrawer';
import { UploadBulkModal } from '../../../shared/components/UploadBulkModal';
import {
	bulkCODRemittancePreviewRoute,
	bulkShippingAdjustmentPreviewRoute,
	MAX_ROWS_EXCEED_ERROR_MSG
} from '../../../shared/components/UploadBulkPreview/constants';
import { AdminNdrCustomerRequestTable } from '../Ndr';
import Actions from './Actions';
import CODRemittance from './CODRemittance';
import Pricings from './Pricing';
import { BillingPageOptions, sidebarUtil } from './utils';
import BmsWallet from './Wallet';
import Payments from './Payments';

export const billingRoute = '/admin/billing';

const RenderCurrentTabContent = ({ tabIndex, renderTabIndex, children }: { tabIndex: string; renderTabIndex: string; children: any }) => {
	if (tabIndex === renderTabIndex) {
		return children;
	}

	return null;
};

const renderPage = (sidebarMenuItem: any, tabIndex: string, component: ReactNode) => (
	<RenderCurrentTabContent tabIndex={sidebarMenuItem} renderTabIndex={tabIndex}>
		<Col sm={20} lg={20} className={`scrollable`}>
			{component}
		</Col>
	</RenderCurrentTabContent>
);

export function Billing() {
	const [sidebarMenuItem, setSidebarMenuItem] = useState<string>(() => sessionStorage.getItem('billingSideBarTabIndex') ?? '1');
	const [isUploadBulkWSINModalVisible, setIsUploadBulkWSINModalVisible] = useState<boolean>(false);
	const [isUploadBulkShippingChargeAdjustmentModalVisible, setIsUploadBulkShippingChargeAdjustmentModalVisible] =
		useState<boolean>(false);
	const history = useHistory();

	// For tab system in multiple sidebar items
	useEffect(() => {
		const tabIndex = Number(sidebarMenuItem) || 1;
		const tabValue = BillingPageOptions[tabIndex - 1];

		history.push({
			pathname: '/admin/billing',
			search: `?tab=${tabValue}`
		});

		// Set billingSideBarTabIndex in to session storage
		sessionStorage.setItem('billingSideBarTabIndex', String(sidebarMenuItem));
	}, [sidebarMenuItem, history]);
	return (
		<Row className="h-100 p-0">
			<UploadBulkModal
				instructionMessage={
					<ul className="instructions">
						<li>Download the excel template by clicking "Download Sample" button below</li>
						<li>Edit file with AWB, COD amount and remittance status</li>
						<br />
						Note: {MAX_ROWS_EXCEED_ERROR_MSG}
					</ul>
				}
				templatePath={process.env.REACT_APP_EXCEL_BILLING_TEMPLATE}
				previewPath={bulkCODRemittancePreviewRoute}
				modalTitle="Bulk COD Remittance Upload"
				isModalVisible={isUploadBulkWSINModalVisible}
				setIsModalVisible={setIsUploadBulkWSINModalVisible}
				companyId={undefined}
			/>
			<UploadBulkModal
				instructionMessage={
					<ul className="instructions">
						<li>Download the excel template by clicking "Download Sample" button below</li>
						<li>Edit file with AWB and total shipping charge</li>
						<br />
						Note: {MAX_ROWS_EXCEED_ERROR_MSG}
					</ul>
				}
				templatePath={process.env.REACT_APP_EXCEL_SHIPPING_CHARGE_ADJUSTMENT_TEMPLATE}
				previewPath={bulkShippingAdjustmentPreviewRoute}
				modalTitle="Shipping Charge Adjustment Upload"
				isModalVisible={isUploadBulkShippingChargeAdjustmentModalVisible}
				setIsModalVisible={setIsUploadBulkShippingChargeAdjustmentModalVisible}
				companyId={undefined}
			/>
			<SideDrawer placement="left">
				<Sidebar
					selectedMenu={sidebarMenuItem}
					sideBarMenu={sidebarUtil.sideBar}
					onMenuItemSelect={setSidebarMenuItem}
					bottomButtons={
						<>
							{sidebarMenuItem === '4' ? (
								<Button
									icon={<UploadOutlined />}
									type="primary"
									size="large"
									onClick={() => setIsUploadBulkShippingChargeAdjustmentModalVisible(true)}
								>
									Upload Bulk Shipping Charge
								</Button>
							) : null}
							{sidebarMenuItem === '1' ? (
								<Button
									icon={<UploadOutlined />}
									type="primary"
									size="large"
									onClick={() => setIsUploadBulkWSINModalVisible(true)}
								>
									Upload COD status
								</Button>
							) : null}
						</>
					}
				/>
			</SideDrawer>
			{[
				<CODRemittance key={1} />,
				<BmsWallet key={2} />,
				<Pricings key={3} />,
				<Actions key={4} />,
				<AdminNdrCustomerRequestTable key={5} />,
				<Payments key={6} />
			].map((page, index) => renderPage(sidebarMenuItem, String(index + 1), page))}
		</Row>
	);
}
