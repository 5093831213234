import { DownloadOutlined, RocketOutlined } from '@ant-design/icons';
import { Button, Col, Modal, notification, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { apiWms } from 'shared/api';
import { downloadCataloguesCSV, getCatalogForAutoComplete } from 'shared/api/catalog';
import { getCompanies } from 'shared/api/lightning';
import { Sidebar } from 'shared/components/AppLayout/Sidebar';
import DynamicInputs from 'shared/components/DynamicInputs/DynamicInputs';
import { UploadBulkModal } from 'shared/components/UploadBulkModal';
import {
	bulkWSINMappingPreviewRoute,
	bulkWSINUploadPreviewRoute,
	MAX_ROWS_EXCEED_ERROR_MSG
} from 'shared/components/UploadBulkPreview/constants';
import errorHandler from 'shared/utils/errorHandler';
import { AutoCompleteInput } from '../../../shared/components/AutoCompleteInput.js/AutoCompleteInput';
import { SideDrawer } from '../../../shared/components/SideDrawer/SideDrawer';
import { getBrandsSubPageMapper, sidebarUtil } from './utils';

export const brandsRoute = '/admin/brands';

export function Brands() {
	const [sidebarMenuItem, setSidebarMenuItem] = useState(1);
	const [adminCatalogueSelectedKeys, setAdminCatalogueSelectedKeys] = useState([]);
	const [brand, setBrand] = useState(() => JSON.parse(localStorage.getItem('ADMIN_CATALOGUE_CLICKED')) || {});
	const [isUploadBulkWSINModalVisible, setIsUploadBulkWSINModalVisible] = useState(false);
	const [isUploadWSINModalVisible, setIsUploadWSINModalVisible] = useState(false);
	const [isMappedBulkWSINModalVisible, setIsMappedBulkWSINModalVisible] = useState(false);
	const BRANDS_SUB_PAGE_MAPPER = useMemo(
		() => getBrandsSubPageMapper({ brand, setAdminCatalogueSelectedKeys, adminCatalogueSelectedKeys }),
		[brand]
	);

	const onDownloadCatalogCSV = () => {
		if (adminCatalogueSelectedKeys.length === 0) {
			return errorHandler(new Error('Please select rows!!!'));
		}
		downloadCataloguesCSV(
			'',
			null,
			brand?.id,
			adminCatalogueSelectedKeys.map((item) => item.id)
		);
	};

	useEffect(() => {
		localStorage.setItem('ADMIN_CATALOGUE_CLICKED', JSON.stringify(brand));
	}, [brand]);

	const handleCloseModal = () => setIsUploadWSINModalVisible(false);
	const handleGenerateWSIN = async (inputs) => {
		const {
			data: { status, message }
		} = await apiWms.post('/wsin/generate', {
			data: inputs,
			companyId: brand?.id
		});
		if (!status) return errorHandler(new Error(message));
		notification.success({
			message: 'WSIN Generated',
			description: message,
			placement: 'topRight'
		});
		setIsUploadWSINModalVisible(false);
	};

	return (
		<Row className="h-100 p-0">
			{sidebarMenuItem === 2 ? (
				<UploadBulkModal
					instructionMessage={
						<ul className="instructions">
							<li>Download the excel template by clicking "Download Sample" button below</li>
							<li>Edit file with WSIN mapping data and upload </li>
							<br />
							Note: {MAX_ROWS_EXCEED_ERROR_MSG}
						</ul>
					}
					templatePath={process.env.REACT_APP_EXCEL_WSIN_MAP_TEMPLATE}
					previewPath={bulkWSINMappingPreviewRoute}
					modalTitle="Bulk WSIN Map"
					isModalVisible={isMappedBulkWSINModalVisible}
					setIsModalVisible={setIsMappedBulkWSINModalVisible}
					companyId={brand?.id}
				/>
			) : (
				<>
					<UploadBulkModal
						instructionMessage={
							<ul className="instructions">
								<li>Download the excel template by clicking "Download Sample" button below</li>
								<li>Edit file with WSIN data and upload </li>
								<br />
								Note: {MAX_ROWS_EXCEED_ERROR_MSG}
							</ul>
						}
						templatePath={process.env.REACT_APP_EXCEL_WSIN_TEMPLATE}
						previewPath={bulkWSINUploadPreviewRoute}
						modalTitle="Bulk WSIN Upload"
						isModalVisible={isUploadBulkWSINModalVisible}
						setIsModalVisible={setIsUploadBulkWSINModalVisible}
						companyId={brand?.id}
					/>

					<Modal
						title={'Generate WSIN'}
						visible={isUploadWSINModalVisible}
						onCancel={handleCloseModal}
						footer={null}
						centered
						width={'35vw'}
					>
						<DynamicInputs
							inputFields={[
								{
									inputKey: 'productTitle',
									inputType: 'text',
									autoComlete: true,
									getOptions: getCatalogForAutoComplete,
									placeholder: 'PRODUCT TITLE'
								},
								{
									inputKey: 'wsin',
									inputType: 'text',
									autoComlete: false,
									placeholder: 'WSIN'
								}
							]}
							handleSubmit={handleGenerateWSIN}
							companyId={brand?.id}
						/>
					</Modal>
				</>
			)}

			<SideDrawer placement="left">
				<Sidebar
					selectedMenu={sidebarMenuItem}
					sideBarMenu={sidebarUtil.sideBar}
					onMenuItemSelect={setSidebarMenuItem}
					topComponent={
						<AutoCompleteInput
							getOptions={getCompanies}
							entityName="companies"
							selectedFilterItems={{ entityId: brand.id, name: brand.name }}
							responseFieldPath="companies"
							optionKeyPath="entityId"
							optionLabelPath="name"
							optionValuePath="entityId"
							placeholder="Search company"
							handleSelect={(op) => setBrand({ id: op?.entityId, name: op?.name })}
						/>
					}
					bottomButtons={
						<>
							{sidebarMenuItem === 2 ? (
								<>
									<Button
										icon={<DownloadOutlined />}
										type="primary"
										size="large"
										disabled={brand.id ? false : true}
										onClick={onDownloadCatalogCSV}
									>
										Download Catalogue
									</Button>
									<Button
										icon={<RocketOutlined />}
										type="primary"
										size="large"
										disabled={brand.id ? false : true}
										onClick={() => setIsMappedBulkWSINModalVisible(true)}
									>
										Bulk Map WSIN
									</Button>
								</>
							) : null}

							{sidebarMenuItem === 3 ? (
								<>
									<Button
										icon={<RocketOutlined />}
										type="primary"
										size="large"
										disabled={brand.id ? false : true}
										onClick={() => setIsUploadBulkWSINModalVisible(true)}
									>
										Bulk Generate WSIN
									</Button>
									<Button
										icon={<RocketOutlined />}
										type="primary"
										size="large"
										disabled={brand.id ? false : true}
										onClick={() => setIsUploadWSINModalVisible(true)}
									>
										Generate WSIN
									</Button>
								</>
							) : null}
						</>
					}
				/>
			</SideDrawer>

			<Col sm={24} lg={20} className={`scrollable`}>
				{BRANDS_SUB_PAGE_MAPPER[sidebarMenuItem]}
			</Col>
		</Row>
	);
}
