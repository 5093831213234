export default (
	<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.3053 4.61782L14.8188 1.01672C14.5685 0.400678 13.9606 0 13.2813 0H4.02013C3.36629 0 2.77374 0.375636 2.50811 0.95662L0.853065 4.54269C0.337139 4.74303 0 5.22385 0 5.76977V16.1874C0 16.9186 0.607873 17.5147 1.35367 17.5147H15.6463C16.3921 17.5147 17 16.9186 17 16.1874V5.76977C16.9949 5.28896 16.7344 4.85322 16.3053 4.61782ZM2.44171 4.44252L3.78516 1.52258C3.82602 1.43243 3.91797 1.37733 4.01502 1.37733H7.95853V4.44252H2.44171ZM9.36839 4.44252V1.37733H13.2813C13.3834 1.37733 13.4805 1.43743 13.5162 1.5326L14.7166 4.44252H9.36839ZM15.5901 5.81985V16.1373H1.40475V5.81985H15.5901Z"
			fill="#231F20"
		/>
		<path
			d="M10.3593 14.4595H13.4855C13.8738 14.4595 14.1905 14.149 14.1905 13.7683C14.1905 13.3877 13.8738 13.0771 13.4855 13.0771H10.3593C9.97113 13.0771 9.65442 13.3877 9.65442 13.7683C9.65442 14.149 9.97113 14.4595 10.3593 14.4595Z"
			fill="#231F20"
		/>
	</svg>
);
