import { orderStatusColor } from '../../utils/orderStatus';

const { ORDER_STATUS_TYPES } = require('../../../app/AppAdmin/Lightning/constants');

export function getJourneyStatsMapper(journey) {
	const deliveredOrders = Array.isArray(journey?.journeyOrders)
		? journey?.journeyOrders?.filter((order) => order.journeyOrderStatus === ORDER_STATUS_TYPES.DELIVERED).length
		: 0;
	const undeliveredOrders = Array.isArray(journey?.journeyOrders)
		? journey?.journeyOrders?.filter((order) => order?.journeyOrderStatus === ORDER_STATUS_TYPES.UNDELIVERED).length
		: 0;
	const unattemptedOrders = Array.isArray(journey?.journeyOrders)
		? journey?.journeyOrders?.filter((order) => order?.journeyOrderStatus === ORDER_STATUS_TYPES.UNATTEMPTED).length
		: 0;
	return [
		{
			title: `Total orders`,
			value: journey?.journeyOrders?.length,
			valueStyle: { fontWeight: 'bold' }
		},
		{
			title: `Delivered`,
			value: deliveredOrders,
			valueStyle: { color: orderStatusColor.DELIVERED, fontWeight: 'bold' }
		},
		{
			title: `Undelivered`,
			value: undeliveredOrders,
			valueStyle: { color: orderStatusColor.UNDELIVERED, fontWeight: 'bold' }
		},
		{
			title: `Unattempted`,
			value: unattemptedOrders,
			valueStyle: { color: `rgb(250, 204, 21)`, fontWeight: 'bold' }
		},
		{
			title: `Amount Collected`,
			value: journey.codCollected,
			prefix: '₹'
		},
		{
			title: `Amount Expected`,
			value: journey.cod,
			prefix: '₹'
		}
	];
}
