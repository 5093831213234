import { codRemittanceStatusColor } from './constants';
import { ICODRemittanceDeliveredOrderStatus } from './types';

export default function useColumns() {
	return [
		{
			title: 'Delivered At',
			dataIndex: 'createdAt',
			render: (text: string) => <div className="text-center">{new Date(text).toLocaleDateString()}</div>,
			width: 150,
			sorter: true
		},
		{
			title: 'AWB',
			dataIndex: 'awb',
			render: (awb: string) => <div className="text-center">{awb}</div>
		},
		{
			title: 'COD Amount',
			dataIndex: 'codAmount',
			render: (codAmount: number) => <div className="text-center">₹{Number(codAmount).toFixed(2)}</div>
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: (status: string) => (
				<div
					className="text-center"
					style={{ color: `${codRemittanceStatusColor[status as ICODRemittanceDeliveredOrderStatus]}`, fontWeight: 'bold' }}
				>
					{status.toUpperCase()}
				</div>
			)
		},
		{
			title: 'Due Since',
			dataIndex: 'paymentDueTimeStamp',
			render: (paymentDueTimeStamp: string | null) => (
				<div className="text-center">{paymentDueTimeStamp ? new Date(paymentDueTimeStamp).toLocaleDateString() : '-'}</div>
			)
		},
		{
			title: 'Paid At',
			dataIndex: 'paymentPaidTimeStamp',
			render: (paymentPaidTimeStamp: string | null) => (
				<div className="text-center">{paymentPaidTimeStamp ? new Date(paymentPaidTimeStamp).toLocaleDateString() : '-'}</div>
			)
		}
	];
}
