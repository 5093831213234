import { Col, Row } from 'antd';
import { useColumns } from 'app/AppSeller/Orders/columns';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import reactDom from 'react-dom';
import { downloadAsFile } from 'helper';
import {
	downloadLablesAndMail,
	downloadMoreOrdersAndMail,
	downloadOrdersCSV,
	downloadOrdersPickList,
	getCompanyOrders
} from 'shared/api/orders';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { OrderInfoSlider } from 'shared/components/OrderInfoSlider';
import useWarehouse from 'shared/hooks/useWarehouse';
import { envs } from 'shared/utils/env';
import errorHandler from 'shared/utils/errorHandler';
import { EVENT_NAMES } from 'utils/analytics';
import { defaultFilter } from '../../../../shared/utils/constant';
import { defaultPageConfig } from '../../../../constants';
import { topFilterUtil } from './utils';
import amplitude from '../../../../shared/utils/Amplitude';
import { RequestEmailModal } from 'shared/components/EmailRequestModal';
import { AuthContext } from 'shared/contexts/Auth';
import { FilterDTOModal } from 'shared/components/DTOFilterDateModal';

export function Orders({ brand }: any) {
	const [orders, setOrders] = useState([]);
	const {
		authState: { profile }
	} = useContext(AuthContext);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [filter, setFilter] = useState({ ...defaultFilter, companyId: brand.id });
	const [loading, setLoading] = useState(false);
	const [currentCount, setCurrentCount] = useState(0);
	const [openOrderInfo, setOpenOrderInfo] = useState<any>();
	const [reloadList, setReloadList] = useState(0);
	const abortControllerRef = useRef(null);
	const [isEmailModalVisible, setIsEmailModalVisible] = useState<boolean | undefined>(false);
	const [isFilterDTODateVisible, setIsFilterDTODateVisible] = useState(false);
	const [isDownloadButtonLoading, setIsDownloadButtonLoading] = useState(false);
	const [labelEmailModalType, setLabelEmailModalType] = useState('');
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [openFilterPanel, setOpenFilterPanel] = useState(false);
	const [filterFormValue, setFilterFormValue] = useState({});
	const [status, setStatus] = useState([]);
	const columns = useColumns({
		clickOrderId: setOpenOrderInfo,
		sortField: pageConfig.sortField,
		sortOrder: pageConfig.sortOrder,
		isAdmin: true
	});

	const { warehouses }: any = useWarehouse({ companyID: brand.id });

	const topFilterConfig = useMemo(
		function () {
			return topFilterUtil({
				filter: { ...filter, companyId: brand.id },
				setFilter,
				setPageConfigDefault,
				onDownloadOrders,
				setLabelEmailModalType,
				selectedRowKeys,
				onDownloadOrderPickList,
				profile,
				openFilterPanel,
				setOpenFilterPanel,
				filterFormValue,
				setFilterFormValue,
				status,
				setStatus
			});
		},
		[filter, setFilter, brand.id, selectedRowKeys, openFilterPanel, filterFormValue, status]
	);

	function setPageConfigDefault() {
		setPageConfig((previousConfig) => ({ ...previousConfig, ...defaultPageConfig }));
	}

	function handleTableChange(pagination: any, filters: any, sorter: any) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'id',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	async function getAllOrders({ pageConfiguration, filters, abortControllerRef }: any) {
		try {
			setLoading(true);
			const { data } = await getCompanyOrders(pageConfiguration, filters, abortControllerRef);
			if (data.status) {
				reactDom.unstable_batchedUpdates(() => {
					setOrders(data.order);
					setCurrentCount(data.currentCount || 0);
					setLoading(false);
				});
			} else {
				throw new Error(data.message);
			}
		} catch (error: any) {
			if (error.message === 'canceled') return;
			setLoading(false);
			errorHandler(error);
		}
	}

	useEffect(
		function getOrders() {
			getAllOrders({ pageConfiguration: pageConfig, filters: { ...filter, companyId: brand.id }, abortControllerRef });
		},
		[brand.id, filter, pageConfig, reloadList]
	);

	async function onDownloadOrders() {
		amplitude.getInstance().logEvent(EVENT_NAMES.DOWNLOAD_ORDER_EXCEL);
		try {
			const csvFilter = { ...filter, companyId: brand.id, appType: envs.appType };
			const shouldEmailOpen = await downloadOrdersCSV(csvFilter);
			setIsEmailModalVisible(shouldEmailOpen);
		} catch (err) {
			errorHandler(err);
		}
	}

	const onOrdersFormSubmit = async (values: any) => {
		try {
			setIsDownloadButtonLoading(true);
			const csvFilter = { ...filter, companyId: brand.id, appType: envs.appType };
			await downloadMoreOrdersAndMail(csvFilter, values.email);
		} catch (e) {
		} finally {
			setIsDownloadButtonLoading(false);
			setIsEmailModalVisible(false);
		}
	};

	const onLabelFormSubmit = async (values: any, type: any) => {
		try {
			setIsDownloadButtonLoading(true);
			downloadLablesAndMail(selectedRowKeys, values.email, type, brand.id);
		} catch (e) {
		} finally {
			setIsDownloadButtonLoading(false);
			setLabelEmailModalType('');
		}
	};

	async function onDownloadOrderPickList() {
		try {
			if (!selectedRowKeys?.length) {
				throw new Error('Please select at least one order to generate pick list excel');
			}

			let orderPickListData = await downloadOrdersPickList(selectedRowKeys);
			downloadAsFile({ data: orderPickListData.data, fileName: `PickList`, fileType: 'xlsx' });
		} catch (err) {
			errorHandler(err);
		}
	}

	return (
		<Row className="h-100 p-0">
			<FilterDTOModal
				title="DTO Filter"
				visible={isFilterDTODateVisible}
				onCancel={() => {
					setIsFilterDTODateVisible(false);
				}}
				setDTODate={(startDate: string | any, endDate: string | any) => {
					setPageConfigDefault();
					setFilter((v) => ({ ...v, startDTODate: startDate, endDTODate: endDate }));
				}}
				footer={[]}
				width={500}
				onFinish={onDownloadOrders}
				instructionMessage="Since selected orders have some DTO items, DTO date range is required. Please select from the input box below."
				loading={isDownloadButtonLoading}
			/>
			<RequestEmailModal
				title="Download Orders"
				visible={isEmailModalVisible}
				onCancel={() => {
					setIsEmailModalVisible(false);
				}}
				footer={[]}
				width={500}
				onFinish={onOrdersFormSubmit}
				instructionMessage="Requested data contains too many rows, please enter your email address to receive download url."
				loading={isDownloadButtonLoading}
			/>
			<RequestEmailModal
				title={`Download ${labelEmailModalType}s`}
				visible={labelEmailModalType.length > 0}
				onCancel={() => {
					setLabelEmailModalType('');
				}}
				footer={[]}
				width={500}
				onFinish={(values) => onLabelFormSubmit(values, labelEmailModalType)}
				instructionMessage="Requested data contains too many orders, please enter your email address to receive url."
				loading={isDownloadButtonLoading}
			/>
			<Col span={24} className="main">
				<TopFilterComponent {...topFilterConfig} />
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={columns}
							data={orders}
							showSorterTooltop={true}
							{...pageConfig}
							onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
							total={currentCount}
							onChange={handleTableChange}
							loading={loading}
							setSelectedRowKeys={setSelectedRowKeys}
						/>
						{openOrderInfo && (
							<OrderInfoSlider
								orderId={openOrderInfo}
								onClose={() => setOpenOrderInfo(null)}
								companyId={brand.id}
								showCancelButton={true}
								reloadList={() => setReloadList((number) => number + 1)}
								warehouses={warehouses.filter((warehouse: any) => warehouse.active)}
								showReassignWarehouse={true}
								showUpdateWeight={true}
								isAdminPanel={true}
							/>
						)}
					</div>
				</div>
			</Col>
		</Row>
	);
}
