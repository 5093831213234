import { Button, Row, Tooltip } from 'antd';
import { journeyStatusColor, REVERSE_JOURNEY_STATUS_MAPPING } from '../constants';

export function useColumns({ clickTripId }) {
	return [
		{
			title: 'Date',
			render: (journey) => (
				<Row justify="center">
					<Button type="link" onClick={() => clickTripId(journey.id)}>
						{new Date(journey.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', hour12: true })}
					</Button>
				</Row>
			),
			width: 'auto',
			sorter: true
		},
		{
			title: 'Driver Name',
			dataIndex: 'driver',
			render: (driver) => <div className="text-center">{driver.name}</div>
		},
		{
			title: 'Driver Phone',
			dataIndex: 'driver',
			render: (driver) => <div className="text-center">{driver.phone}</div>
		},
		{
			title: 'COD Amount',
			render: (journey) => (
				<div className="text-center">
					<Tooltip
						placement="top"
						title={
							<>
								<span>{`Amount Collected: ${journey.codCollected}`}</span>
								<br />
								<span>{`Expected: ${journey.cod}`}</span>
							</>
						}
					>
						{journey.codCollected}/{journey.cod}
					</Tooltip>
				</div>
			)
		},
		{
			title: 'Distance',
			dataIndex: 'distance',
			render: (distance) => <div className="text-center">{`${parseFloat(distance).toFixed(2)} Km`}</div>
		},
		{
			title: 'Trip Status',
			dataIndex: 'status',
			render: (status) => (
				<div
					className="text-center"
					style={{ color: `${journeyStatusColor[REVERSE_JOURNEY_STATUS_MAPPING[status]]}`, fontWeight: 'bold' }}
				>
					{REVERSE_JOURNEY_STATUS_MAPPING[status]?.replace('_', ' ')}
				</div>
			)
		}
	];
}
