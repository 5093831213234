import { useEffect, useState } from 'react';
import { Col, Input, Row, Select, Switch, Typography } from 'antd';

import errorHandler from 'shared/utils/errorHandler';
import NoCompany from '../../../app/AppAdmin/NoCompany/NoCompany';
import { Loading } from '../Loading';
import {
	DEFAULT_WL_MAX_WEIGHT,
	EPanelPreferences,
	goSwiftsCouriorPartners,
	preferenceRowFields,
	shiperfectoCouriorPartners
} from './constants';
import styles from './index.module.scss';
import { useSellerProfile } from './useSellerProfile';
import { WarehouseModal } from './components/WarehouseModal';
import { AdminPreference } from './AdminPreference';
import { apiUms, apiWms } from '../../api';
import { FulfillmentProviders } from '../../../constants';

// add preference for rto processed shopify location here -- for brands panel

export function Preferences({ isAdmin = false, companyId = undefined }) {
	const { profile, revalidate, updatePreference } = useSellerProfile({ isAdmin, companyId });
	const [loading, setLoading] = useState(false);
	const [maxWeightLimit, setMaxWeightLimit] = useState('');
	const [preferenceFields, setPreferenceFields] = useState<Record<string, any>>({});
	const [isRtoToOtherWh, setIsRtoToOtherWh] = useState(false);
	const [warehouse, setWarehouse] = useState([]);
	const [deliveryPartners, setDelivedyPartners] = useState<string[]>([]);

	const createPreferenceFieldObject = (profileData: any) => {
		const prefernceFieldObject: Record<string, boolean | string> = {};
		preferenceRowFields.forEach((field) => {
			if (field.key === EPanelPreferences.PRINT_ITEM_LABEL) {
				prefernceFieldObject[field.key] = !profileData[field.key] ? true : Boolean(profileData.printItemLabel === '1');
				return;
			}

			if (field.key === EPanelPreferences.MAX_WEIGHT_WL) {
				setMaxWeightLimit(profileData[field.key] || DEFAULT_WL_MAX_WEIGHT);
			}

			if (field.key === EPanelPreferences.GOSWIFT_COURIER) {
				prefernceFieldObject[field.key] = profileData[field.key];
				return;
			}
			if (field.key === EPanelPreferences.SHIPERFECTO_COURIER) {
				prefernceFieldObject[field.key] = profileData[field.key];
				return;
			}

			prefernceFieldObject[field.key] = Boolean(profileData[field.key] === '1');
		});
		return prefernceFieldObject;
	};

	const filterDeliveryPartners = (data: Record<string, any>[]) => {
		const activePartnerNames: string[] = data
			.filter((e) => {
				return e.status === true;
			})
			?.map((e) => e.name);
		return activePartnerNames;
	};

	const fetchDeliveryPartners = async () => {
		try {
			const {
				data: { responseBody }
			} = await apiUms.get('/ums/profile/deliveryPartners');
			const deliveryPartners: Record<string, any>[] = responseBody?.deliveryPartners;
			if (deliveryPartners.length > 0) {
				const filteredData = filterDeliveryPartners(deliveryPartners);
				setDelivedyPartners(filteredData);
			}
		} catch (error) {
			errorHandler(error);
		}
	};

	const onChangePreference = async (updatedValue: any, fieldName: string, rtoOtherWh = false) => {
		if (updatedValue && !rtoOtherWh && fieldName === EPanelPreferences.RTO_OTHER_WAREHOUSE) {
			setIsRtoToOtherWh(true);
			return;
		}
		if (rtoOtherWh) {
			setIsRtoToOtherWh(false);
		}
		setLoading(true);
		try {
			const updatedPreferenceFields: Record<string, any> = { ...preferenceFields };
			updatedPreferenceFields[fieldName] = updatedValue;

			setPreferenceFields(updatedPreferenceFields);

			await updatePreference(preferenceFields, fieldName, updatedValue);
			await revalidate();
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (profile) {
			const updatedFields = createPreferenceFieldObject(profile);
			setPreferenceFields(updatedFields);
		}
	}, [profile, loading]);

	const getWarehouses = async () => {
		try {
			const {
				data: { warehouses }
			} = await apiWms.get('/company/warehouses');
			if (warehouse) {
				let updatedWarehouse = warehouses.filter((e: any) => e.active === true);
				setWarehouse(updatedWarehouse);
			}
		} catch (err) {
			errorHandler(err);
		}
	};

	useEffect(() => {
		fetchDeliveryPartners();
		getWarehouses();
	}, []);

	const isSwitchDisabledForPreferenceField = (field: string) => {
		if (loading) {
			return true;
		}
		return preferenceFields[field] === '1';
	};
	if (isAdmin && !companyId) {
		return <NoCompany />;
	}

	return (
		<div className={styles.profilePage}>
			{isRtoToOtherWh && <WarehouseModal onOk={onChangePreference} warehouse={warehouse} setIsModalVisible={setIsRtoToOtherWh} />}
			<Typography.Title level={3}>Preferences</Typography.Title>
			<Loading loading={loading} />
			<Row className={styles.row1}>
				<Col span={24}>
					<Row className={styles.rowData}>
						{preferenceRowFields.map((item) => {
							if (item.key === EPanelPreferences.RTO_OTHER_WAREHOUSE) {
								return (
									<Col span={24} key={item.key}>
										<Row className={styles.preferenceRow}>
											<Col span={12}>
												<span className="description">{item.description}</span>
											</Col>
											<Col span={12}>
												<Switch
													checkedChildren="ON"
													disabled={!deliveryPartners.includes(FulfillmentProviders.CLICKPOST)}
													unCheckedChildren="OFF"
													checked={preferenceFields[item.key]}
													onChange={(updatedStatus) => onChangePreference(updatedStatus, item.key)}
												/>
											</Col>
										</Row>
									</Col>
								);
							}

							if (item.key === EPanelPreferences.MAX_WEIGHT_WL) {
								return (
									<Col span={24} key={item.key}>
										<Row className={styles.preferenceRow}>
											<Col span={12}>
												<span className="description">{item.description}</span>
											</Col>
											<Col style={{ marginTop: '5px' }} span={2}>
												<Input
													disabled={!isAdmin}
													value={maxWeightLimit}
													type="number"
													onChange={(e) => setMaxWeightLimit(e.target.value)}
													onBlur={() => onChangePreference(maxWeightLimit, item.key)}
													placeholder={'Maximum Weight Limit in WL'}
												/>
											</Col>
										</Row>
									</Col>
								);
							}

							if (item?.key === EPanelPreferences.GOSWIFT_COURIER) {
								return (
									<>
										<Col span={24} key={item.key}>
											<Row className={styles.preferenceRow}>
												<Col span={12}>
													<span className="description">{item.description}</span>
												</Col>
												<Col style={{ marginTop: '5px' }} span={4}>
													<Select
														style={{ width: '100%' }}
														placeholder="Select Fulfillment Provider"
														options={goSwiftsCouriorPartners}
														value={preferenceFields[item.key]}
														onChange={(e) => onChangePreference(e, item.key)}
													/>
												</Col>
											</Row>
										</Col>
									</>
								);
							}
							if (item?.key === EPanelPreferences.SHIPERFECTO_COURIER) {
								return (
									<>
										<Col span={24} key={item.key}>
											<Row className={styles.preferenceRow}>
												<Col span={12}>
													<span className="description">{item.description}</span>
												</Col>
												<Col style={{ marginTop: '5px' }} span={4}>
													<Select
														style={{ width: '100%' }}
														placeholder="Select Fulfillment Provider"
														options={shiperfectoCouriorPartners}
														value={preferenceFields[item.key]}
														onChange={(e) => onChangePreference(e, item.key)}
													/>
												</Col>
											</Row>
										</Col>
									</>
								);
							}

							return (
								<Col span={24} key={item.key}>
									<Row className={styles.preferenceRow}>
										<Col span={12}>
											<span className="description">{item.description}</span>
										</Col>
										<Col span={12}>
											<Switch
												checkedChildren="ON"
												disabled={isSwitchDisabledForPreferenceField(item.key)}
												unCheckedChildren="OFF"
												checked={preferenceFields[item.key]}
												onChange={(updatedStatus) => onChangePreference(updatedStatus, item.key)}
											/>
										</Col>
									</Row>
								</Col>
							);
						})}
						{/**
						 * TODO: refactoring WhatsappPreference 👇🏻
						 */}
						<AdminPreference
							profile={profile}
							isAdmin={isAdmin}
							revalidate={revalidate}
							companyId={companyId}
							setLoading={setLoading}
						/>
					</Row>
				</Col>
			</Row>
		</div>
	);
}
