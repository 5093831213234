import { Col, Row } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getBmsWalletTransaction } from '../../../shared/api/bms';
import { TopFilterComponent } from '../../../shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from '../../../shared/components/CustomTablePagination';
import errorHandler from '../../../shared/utils/errorHandler';
import useColumns from '../../../shared/components/Wallet/columns';
import { defaultFilter, defaultPageConfig } from '../../../shared/components/Wallet/constants';
import { IWalletFilter, IPageConfiguration } from '../../../shared/components/Wallet/types';
import { topFilterUtil } from './utils';

export function Billing() {
	const [data, setData] = useState<any[]>([]);
	const [companyBalance, setCompanyBalance] = useState<number>(0);
	const [currentCount, setCurrentCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<IWalletFilter>(defaultFilter);
	const [pageConfig, setPageConfig] = useState<IPageConfiguration>(defaultPageConfig);
	const [rechargeAmount, setRechargeAmount] = useState(0);
	const [rechargeFormVisibile, setRechargeFormVisibile] = useState(false);
	const [rechargeLoading, setRechargeLoading] = useState<boolean>(false);
	const columns = useColumns();

	const setPageConfigDefault = useCallback(() => {
		setPageConfig(defaultPageConfig);
	}, []);
	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				filter,
				setFilter,
				setPageConfig,
				companyBalance,
				rechargeAmount,
				setRechargeAmount,
				rechargeFormVisibile,
				setRechargeFormVisibile,
				rechargeLoading,
				setRechargeLoading
			}),
		[filter, setFilter, setPageConfigDefault, companyBalance, rechargeAmount, rechargeFormVisibile, rechargeLoading]
	);

	const getAllTransactions = async ({ pageConfiguration, filters }: any) => {
		try {
			setLoading(true);
			const response = await getBmsWalletTransaction({ pageConfiguration, filters });
			if (response?.data.status) {
				setData(response.data.data);
				setCurrentCount(response.data.count);
				setCompanyBalance(response.data.balance);
			} else {
				throw new Error(response?.data.message || 'Could not get transactions');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	};
	const loadScript = (src: any) => {
		return new Promise((resolve) => {
			const script = document.createElement('script');
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	};

	useEffect(() => {
		loadScript('https://checkout.razorpay.com/v1/checkout.js');
	});

	useEffect(() => {
		getAllTransactions({ pageConfiguration: pageConfig, filters: filter });
	}, [filter, pageConfig]);

	return (
		<div style={{ height: window.innerHeight - 50 }}>
			<Row className="h-100 p-0">
				<Col span={24} className="main">
					<TopFilterComponent {...topFilterConfig} />
					<div className="innerDiv">
						<div className="div w-100 h-100">
							<CustomTablePagination
								columns={columns}
								data={data}
								onChangePage={(current: any, pageSize: any) => setPageConfig((v: any) => ({ ...v, current, pageSize }))}
								current={Number(pageConfig.current)}
								total={currentCount}
								loading={loading}
								shouldRowSelection={false}
								selectedItem={undefined}
								catalog={undefined}
								selectedRowKeys={undefined}
								setSelectedRowKeys={undefined}
								setSelectedItem={undefined}
								pageSize={Number(pageConfig.pageSize)}
								sortField={pageConfig.sortField}
								sortOrder={pageConfig.sortField}
							/>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
}
