import { useEffect, useState, useMemo, useRef } from 'react';
import { columns } from 'shared/utils/admin/companyWhColumns';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { Row, Col, notification } from 'antd';
import { defaultPageConfig } from '../../../constants';
import { ErrorBoundary } from '@sentry/react';
import { getCompanies } from 'shared/api/companies';
import { Fallback } from 'shared/components/ErrorBoundryFallback';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { topFilterUtil } from 'utils/admin/companyWhUtils';
import errorHandler from 'shared/utils/errorHandler';
import { apiUms } from '../../../shared/api';

export const companyRoute = '/admin/companies';

export function Companies() {
	const isMounted = useRef(false);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [data, setData] = useState([]);
	const [filter, setFilter] = useState({ searchOptionType: 'name' });
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const searchOptions = [
		{ value: 'name', label: 'Company Name' },
		{ value: 'email', label: 'Company Email' },
		{ value: 'entityId', label: 'Company ID' }
	];

	const columnsConfig = useMemo(
		() => columns({ sortField: pageConfig.sortField, sortOrder: pageConfig.sortOrder, userType: 'Company', handleAccountAction }),
		[pageConfig.sortField, pageConfig.sortOrder]
	);
	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				filter,
				setFilter,
				setPageConfigDefault,
				searchOptions
			}),
		[filter, pageConfig]
	);
	function setPageConfigDefault() {
		setPageConfig((v) => ({ ...v, ...defaultPageConfig }));
	}

	async function handleAccountAction(entityId, statusToUpdate) {
		try {
			const { data } = await apiUms.patch('/ums/profile/toggle-account-status', {
				entityId,
				status: Boolean(!statusToUpdate)
			});
			notification.success({
				message: 'Account Status',
				description: data?.message,
				placement: 'topRight'
			});
		} catch (error) {
			errorHandler(error);
		} finally {
			getAllCompanies();
		}
	}

	function getAllCompanies(pageConfig, filter) {
		getCompanies(pageConfig || 1, filter)
			.then(({ data }) => {
				if (!isMounted?.current) return;
				setData(data.companyDetails);
				setCount(data.count);
				if (data.message) {
					errorHandler(data.message);
				}
			})
			.catch(() => {
				if (!isMounted?.current) return;
				setData([]);
			})
			.finally(() => {
				isMounted?.current && setLoading(false);
			});
	}

	function handleTableChange(pagination, filters, sorter) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'createdAt',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		setLoading(true);
		getAllCompanies(pageConfig, filter);
	}, [pageConfig, filter]);

	return (
		<Row className="h-100 p-0">
			<ErrorBoundary fallback={<Fallback fallBackMessage="Sorry, Failed to load data" />}>
				<Col span={24} className="main">
					<TopFilterComponent {...topFilterConfig} />

					<div className="innerDiv">
						<div className="div w-100 h-100">
							<CustomTablePagination
								columns={columnsConfig}
								data={data}
								showSorterTooltip={true}
								{...pageConfig}
								onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
								total={count}
								onChange={handleTableChange}
								loading={loading}
								shouldRowSelection={false}
							/>
						</div>
					</div>
				</Col>
			</ErrorBoundary>
		</Row>
	);
}
