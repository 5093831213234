import { DownloadOutlined } from '@ant-design/icons';
import { Badge, Button, notification, Select, Space, Tag, Typography } from 'antd';
import { MAX_DOWNLOAD_LABEL_LIMIT } from 'app/AppSeller/Orders/constants';
import { jsxElementKeys, searchOptions } from '../../../shared/utils/constant';
import dayjs from 'dayjs';
import { downloadAsFile } from 'helper';
import { apiOms } from 'shared/api';
import errorHandler from 'shared/utils/errorHandler';
import { EVENT_NAMES } from 'utils/analytics';
import { ORDER_STATUS, BULK_ACTIONS_WMS } from '../../../constants';
import amplitude from '../../../shared/utils/Amplitude';
import styles from './index.module.scss';
import filterSvg from 'shared/svgs/filter_svg';
import { MultipleFilterComponent } from '../../../shared/components/MultipleFilterComponent/MultipleFilterComponent';
import { commonTopFilterRow } from '../../../utils/filters';
const { Text } = Typography;
const calculateAllCount = (counts: any) => {
	let allCount = 0;
	const ALL_COUNT_FIELDS = [
		ORDER_STATUS.PLACED,
		ORDER_STATUS.READY_TO_SHIP,
		ORDER_STATUS.IN_TRANSIT,
		ORDER_STATUS.OUT_FOR_DELIVERY,
		ORDER_STATUS.DELIVERED,
		ORDER_STATUS.CANCELLED
	];

	ALL_COUNT_FIELDS.forEach((field) => (allCount += +counts[field] || 0));
	return allCount;
};

export const sidebarUtil = ({ count, missedOrderCount }: any) => ({
	sideBarMenu: [
		{
			key: '1',
			label: `All  (${count})`,
			icon: 'allSvgIcon'
		},
		{
			key: '2',
			label: `Missed Orders (${missedOrderCount})`,
			icon: 'returnSvgIcon',
			isLink: true
		}
	]
});

const showOrderStatusFilters = (sidebarMenuItem: string) => sidebarMenuItem !== '5';

const showClusterStatusFilters = (sidebarMenuItem: string) => sidebarMenuItem !== '5';

export interface ITopFilterUtil {
	onDownloadOrders?: any;
	onDownloadOrderPickList?: any;
	filter?: any;
	setFilter?: any;
	counts?: any;
	pageConfig?: any;
	setPageConfigDefault?: any;
	sidebarMenuItem?: any;
	selectedRowKeys?: any;
	getAllOrders?: any;
	clusterData?: any;
	setLabelEmailModalType?: any;
	handleFulfillment?: () => void;
	openFilterPanel: boolean | undefined;
	setOpenFilterPanel?: any;
	filterFormValue?: any;
	setFilterFormValue?: any;
	status?: any;
	setStatus?: any;
	clusterState?: any;
	setClusterState?: any;
	search?: any;
	setSearch?: any;
	wmsSidebarTabIndex?: any;
	setPageConfig?: any;
}

export const topFilterUtil = ({
	onDownloadOrders,
	onDownloadOrderPickList,
	filter,
	setFilter,
	counts,
	pageConfig,
	setPageConfigDefault,
	sidebarMenuItem,
	selectedRowKeys,
	getAllOrders,
	clusterData,
	setLabelEmailModalType,
	handleFulfillment = () => {},
	openFilterPanel,
	setOpenFilterPanel,
	filterFormValue,
	setFilterFormValue,
	status,
	setStatus,
	clusterState,
	setClusterState
}: ITopFilterUtil) => {
	const setDate = (startDate: any, endDate: any) => {
		setPageConfigDefault();
		setFilter((v: any) => ({ ...v, startDate, endDate }));
	};

	const bulkDownload = async (type: any) => {
		amplitude.getInstance().logEvent(`clicked on ${type}`);
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');

			if ((type === 'label' || type === 'invoice') && selectedRowKeys.length > MAX_DOWNLOAD_LABEL_LIMIT) {
				type === 'label' ? setLabelEmailModalType('Label') : setLabelEmailModalType('Invoice');
			} else {
				const res = await apiOms.post(`/download/${type}`, { orderId: selectedRowKeys }, { responseType: 'blob' });
				downloadAsFile({ data: res.data, fileName: `${type}s`, fileType: 'pdf' });
			}
		} catch (error: any) {
			if (error?.response?.data?.type === 'application/json') {
				error.message = `Unable to find ${type}`;
			}

			errorHandler(error);
		}
	};

	const bulkReadyToShip = async (ids: any) => {
		amplitude.getInstance().logEvent(EVENT_NAMES.MARK_READY_SHIP);
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');

			const {
				data: { status, message }
			} = await apiOms.post(`/bulkreadytoship/`, {
				orderids: selectedRowKeys.join(',')
			});

			if (!status) {
				notification.error({
					message: 'Failed',
					description: message,
					placement: 'topRight'
				});
				return;
			}

			getAllOrders(pageConfig, filter);
			notification.success({
				message: 'Success',
				description: message,
				placement: 'topRight'
			});
		} catch (error: any) {
			if (error?.response?.data?.type === 'application/json') {
				error.message = `Something went wrong!`;
			}

			errorHandler(error);
		}
	};

	const onDownloadShippingManifest = async (val?: any) => {
		amplitude.getInstance().logEvent(`Clicked on ${val}`);
		let URL: any = window.URL || window.webkitURL;
		let downloadUrl: any = {};
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');
			const { data } = await apiOms.get(`/download/shipping-manifest?orderIds=${selectedRowKeys.join(',')}`, {
				responseType: 'blob'
			});
			// create an object URL from the Blob
			downloadUrl = URL.createObjectURL(data);
			window.open(downloadUrl, '_blank');
		} catch (error: any) {
			errorHandler(error);
			URL.revokeObjectURL(downloadUrl);
		}
	};

	const handleBulkActionChange = (bulkAction: string) => {
		console.log(`value selected: ${bulkAction}`);
		switch (bulkAction) {
			case 'Print Label':
				bulkDownload('label');
				break;
			case 'Print Invoice':
				bulkDownload('invoice');
				break;
			case 'Mark Ready To Ship':
				bulkReadyToShip(selectedRowKeys);
				break;
			case 'Download Picklist':
				onDownloadOrderPickList();
				break;
			case 'Download Shipping Manifest':
				onDownloadShippingManifest();
				break;
			case 'Start Fulfillment':
				handleFulfillment();
				break;
			default:
				break;
		}
	};

	const handleCloseTag: any = (closedTag: string) => {
		let updatedFilter = {};
		const newFormObj = { ...filterFormValue };
		if (closedTag === 'status') {
			setStatus([]);
			updatedFilter = { ...updatedFilter, filterFormValue, status: [], cluster: clusterState };
		} else if (closedTag === 'cluster') {
			setClusterState([]);
			updatedFilter = { ...updatedFilter, filterFormValue, status: status, cluster: [] };
		} else {
			delete newFormObj[closedTag];
			updatedFilter = { ...updatedFilter, filterFormValue: newFormObj, status: status, cluster: clusterState };
			setFilterFormValue(newFormObj);
		}

		setFilter((prevFilter: any) => {
			const newFilter = {
				...prevFilter,
				...updatedFilter
			};

			const filteredFilter = Object.keys(newFilter).reduce((filterObject: any, key: any) => {
				if (!(Array.isArray(newFilter[key]) && newFilter[key].length === 0)) {
					filterObject[key] = newFilter[key];
				}
				return filterObject;
			}, {});

			return filteredFilter;
		});
	};
	const individualTagElementRender = (formField: string) => {
		const tagElementLabel: any = searchOptions.find((element: any) => element.value === formField);
		const tagElem = (
			<Tag
				key={formField}
				closable
				color="blue"
				onClose={() => {
					handleCloseTag(formField);
				}}
				className={styles.borderLessTag}
				style={{ display: 'flex', alignItems: 'center' }}
			>
				{tagElementLabel.label}
			</Tag>
		);
		return tagElem;
	};
	const formValueKeys = filter?.filterFormValue && Object.keys(filter?.filterFormValue);
	const showMoreFilters = filter?.filterFormValue && formValueKeys.length > 3;
	const renderTagRange = (start: number, end: number) => {
		return formValueKeys?.slice(start, end).map((formField: string) => {
			return individualTagElementRender(formField);
		});
	};
	const topFilterRow = commonTopFilterRow(filter.startDate, filter.endDate);
	return {
		row1Filter: [
			<div className={styles.searchContainer} key={jsxElementKeys.MULTIPLE_FILTER}>
				<Badge offset={[-2, 4]} count={filter?.filterFormValue && Object.keys(filter?.filterFormValue).length} size="small">
					<Button className={styles.filterButton} onClick={() => setOpenFilterPanel(true)} size="middle">
						<span className={styles.filterImage}>{filterSvg}</span>
						<span style={{ marginLeft: '.3rem', fontSize: '0.85rem' }}>Filters</span>
					</Button>
				</Badge>

				<MultipleFilterComponent
					openFilterPanel={openFilterPanel}
					setOpenFilterPanel={setOpenFilterPanel}
					setFilter={setFilter}
					filterFormValue={filterFormValue}
					setFilterFormValue={setFilterFormValue}
					searchOptions={searchOptions}
					showOrderStatusFilters={showOrderStatusFilters}
					filter={filter}
					sidebarMenuItem={sidebarMenuItem}
					counts={counts}
					isWms={true}
					showClusterStatusFilters={showClusterStatusFilters}
					clusterData={clusterData}
					status={status}
					setStatus={setStatus}
					clusterState={clusterState}
					setClusterState={setClusterState}
				/>
			</div>
		],
		row1: [
			{
				...topFilterRow,
				onChange: (dates: any, dateStrings: any) => setDate(dateStrings[0], dateStrings[1]), //setDate(...dateStrings)
				showLabel: false
			}
		],
		row1children: [
			<div className={styles.orderStatusFilter} key={jsxElementKeys.BULK_ACTION}>
				<Select
					disabled={!selectedRowKeys || selectedRowKeys.length === 0}
					placeholder="Bulk Order Actions"
					style={{ width: '100%' }}
					allowClear
					onChange={handleBulkActionChange}
				>
					{Object.values(BULK_ACTIONS_WMS).map((bulkAction) => {
						return <Select.Option key={bulkAction}>{bulkAction}</Select.Option>;
					})}
				</Select>
			</div>,
			<div className={styles.searchContainer} key={jsxElementKeys.TAG_FILTER}>
				{filter?.filterFormValue && !showMoreFilters ? (
					<Space wrap>{renderTagRange(0, 3)}</Space>
				) : (
					<Space wrap>
						{renderTagRange(0, 3)}
						{showMoreFilters && (
							<Text underline style={{ color: 'slategrey' }} onClick={() => setOpenFilterPanel(true)}>
								+{filter?.filterFormValue && Object.keys(filter?.filterFormValue).length - 3} more filters
							</Text>
						)}
					</Space>
				)}
				{(filter?.status || filter?.cluster) && (
					<Space wrap style={{ marginTop: 3, display: 'flex' }}>
						{filter?.status && filter.status.length > 0 && (
							<Tag
								closable
								color="blue"
								onClose={() => {
									handleCloseTag('status');
								}}
								key="status"
								className={styles.borderLessTag}
								style={{ display: 'flex', alignItems: 'center' }}
							>
								Status filter applied
							</Tag>
						)}
						{filter?.cluster && filter.cluster.length > 0 && (
							<Tag
								closable
								color="blue"
								onClose={() => {
									handleCloseTag('cluster');
								}}
								className={styles.borderLessTag}
								style={{ display: 'flex', alignItems: 'center' }}
							>
								Cluster filter applied
							</Tag>
						)}
					</Space>
				)}
			</div>,
			selectedRowKeys.length !== 0 && (
				<Badge size="default" count={`${selectedRowKeys.length} selected`} style={{ backgroundColor: '#6c757d' }} />
			)
		],
		row2: [
			<Button type="primary" icon={<DownloadOutlined />} size="large" onClick={onDownloadOrders} key={jsxElementKeys.DOWNLOAD_ORDERS}>
				Download
			</Button>
		]
	};
};
