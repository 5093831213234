import dayjs from 'dayjs';
import { DebouncedCompactSearch } from '../../../../shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import { searchOptions } from '../../../../shared/components/TaskCenter/constants';
import { ITaskCenterFilter, ITopFilterUtil } from '../../../../shared/components/TaskCenter/types';
import { commonTopFilterRow } from '../../../../utils/filters';

export function topFilterUtil({ filter, setFilter, setPageConfigDefault }: ITopFilterUtil): any {
	const setDate = (startDate: string, endDate: string) => {
		setPageConfigDefault();
		setFilter((prevFilter: ITaskCenterFilter) => ({ ...prevFilter, start: startDate, end: endDate }));
	};
	const topFilterRow = commonTopFilterRow(filter.start, filter.end);
	return {
		row1: [
			{
				...topFilterRow,
				onChange: (dates: any, dateStrings: [string, string]) => setDate(...dateStrings)
			}
		],
		row1children: [
			<div>
				<DebouncedCompactSearch
					defaultSearchOptionType={filter.searchOptionType}
					searchOptions={searchOptions}
					setFilter={setFilter}
					setDefault={setPageConfigDefault}
					selectStyle={{ minWidth: '100px' }}
				>
					<span>Search By:</span>
				</DebouncedCompactSearch>
			</div>
		],
		row2: []
	};
}
