import useQuery from '../../../helper/useQuery';
import { Fragment } from 'react';
import { InstallationRedirectComponent } from './InstallationRedirectComponent';
import { PermissionRedirectComponent } from './PermissionRedirectComponent';

export const shopifyRedirectRoute = '/shopify-redirect';

const shopifyRedirectTypes = {
	installation: <InstallationRedirectComponent />,
	permission: <PermissionRedirectComponent />
};

export function ShopifyRedirect() {
	let query = useQuery();
	let redirectType = query.get('redirect-type');

	return <Fragment>{shopifyRedirectTypes[redirectType]}</Fragment>;
}
