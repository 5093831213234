import { Button, Col, notification } from 'antd';
import { createCluster } from 'shared/api/lightning';
import { FormLayout } from 'shared/components/FormLayout';
import errorHandler from 'shared/utils/errorHandler';
import { CLUSTER_MAPPING, REVERSE_CLUSTER_MAPPING } from '../constants';
import styles from '../index.module.scss';
import { addressFields } from '../utils';

export function CreateCluster({ setClusters }) {
	async function createNewCluster(values) {
		try {
			values.clusterName = REVERSE_CLUSTER_MAPPING[values.clusterName];
			const { data } = await createCluster(values);
			if (!data.status) throw new Error(data.message);
			if (data.status) {
				setClusters((clusters) =>
					clusters.concat({
						key: data.data.id,
						value: data.data.id,
						label: CLUSTER_MAPPING[data.data.clusterName],
						...data.data
					})
				);
				notification.success({
					message: 'Cluster created',
					description: data.message,
					placement: 'topRight'
				});
			}
		} catch (error) {
			errorHandler(error);
		}
	}

	function handleOnSave(values) {
		createNewCluster(values);
	}

	return (
		<Col span={8}>
			<FormLayout
				className={styles.form}
				formTitle={'Create Cluster'}
				isClose={false}
				onSave={handleOnSave}
				formFields={[
					{
						label: 'Cluster Name',
						placeholder: 'C3',
						name: 'clusterName',
						id: 'clusterName',
						required: true,
						rules: [{ pattern: /^C(1|2|3|4|5|6)$/, message: 'Cluster name not allowed' }]
					},
					{
						label: 'POC Name',
						placeholder: 'Yash',
						name: 'name',
						id: 'name',
						required: true
					},
					{
						label: 'POC Phone',
						placeholder: '9999977777',
						name: 'phone',
						id: 'phone',
						required: true
					},
					...addressFields
				]}
				customSubmitComponent={
					<Button type="primary" shape="round" size="large" htmlType="submit">
						{'Save'}
					</Button>
				}
			/>
		</Col>
	);
}
