import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { getWarehouseUser } from 'shared/api/lightning';
import { getUser, userColumns } from '../utils';

export function WarehouseUser() {
	const [warehouseUser, setWarehouseUser] = useState([]);

	useEffect(() => {
		getUser({
			setUser: setWarehouseUser,
			getUser: getWarehouseUser,
			userType: 'warehouse users'
		});
	}, []);

	return (
		<>
			<Table dataSource={warehouseUser} columns={userColumns({ isWarehouseUser: true })} />
		</>
	);
}
