import { Button, Tooltip } from 'antd';
import { useState } from 'react';
import ModalForm from './ModalForm';

const RTOModal = ({ orderData, button, companyProfileData }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);

	return (
		<div>
			<Tooltip title="If the item is not damaged or missing" placement="left">
				<Button type="primary" size="small" onClick={() => setIsModalVisible(true)} style={{ marginTop: '8px' }}>
					{button}
				</Button>
			</Tooltip>
			{isModalVisible ? (
				<ModalForm
					orderData={orderData}
					companyProfileData={companyProfileData}
					isModalVisible={isModalVisible}
					setIsModalVisible={setIsModalVisible}
					button={button}
					returnInfoFlag={button === 'RTO Details' || button === 'DTO Details' ? true : false}
				/>
			) : null}
		</div>
	);
};

export default RTOModal;
