import { Row } from 'antd';
import { Insights } from '../Insights/Insights';
import { QueryClient, QueryClientProvider } from 'react-query';

export const overviewRoute = '/insights';
const queryClient = new QueryClient();

const Overview = () => {
	return (
		<Row className="h-100 p-0">
			<QueryClientProvider client={queryClient}>
				<Insights />
			</QueryClientProvider>
		</Row>
	);
};

export default Overview;
