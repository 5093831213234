import styles from './index.module.scss';

interface IMessageBox {
	mobileNumber: number | string;
	time: string;
	message: string;
	boxPosition: 'left' | 'right';
	backGroundColor: '#e1ffc7' | '#fff';
	borderRadius: '0px 14px 7px 7px' | '14px 0px 7px 7px';
}

export function MessageBox(props: IMessageBox) {
	const { mobileNumber, time, message, boxPosition, backGroundColor: background, borderRadius } = props;
	return (
		<>
			<div style={{ display: 'flex', justifyContent: boxPosition }}>
				<div
					className={styles.messageParentDiv}
					style={{
						borderRadius,
						background
					}}
				>
					<div className={styles.messagesHeading}>
						<p>+91{mobileNumber}</p>
						<p>{time}</p>
					</div>
					<p>{message?.replaceAll('*', '')}</p>
				</div>
			</div>
		</>
	);
}
