import { Button, Col, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Sidebar } from '../../../shared/components/AppLayout/Sidebar';
import { SideDrawer } from '../../../shared/components/SideDrawer/SideDrawer';
import { D2RsubSectionsMap, sidebarUtilD2r } from './constant';
import { getD2RSubPageMapper } from './utils';
import { DownloadOutlined } from '@ant-design/icons';
import { envs } from '../../../shared/utils/env';
import { getCompanies } from '../../../shared/api/lightning';
import { AutoCompleteInput } from '../../../shared/components/AutoCompleteInput.js/AutoCompleteInput';

const { omsBaseUrl } = envs;
export const d2rRoute = '/admin/d2r';
export type companyType = { id: string | null; name: string | null };
const defaultCompany = '{ "id": null, "name":null }';

export const D2r = () => {
	const [sidebarMenuItem, setSidebarMenuItem] = useState(D2RsubSectionsMap['RETAIL_DISCOUNT']); // Default Tab To show
	const storedCompanyData = () => localStorage.getItem('ADMIN_CATALOGUE_CLICKED');
	const [company, setCompany] = useState<companyType>(JSON.parse(storedCompanyData() ?? defaultCompany));

	const handleDownloadSalesmens = () => {
		const url = new URL(`${omsBaseUrl}/csv/d2r/salesmen`);
		window.open(url);
	};

	const D2R_SUB_PAGE_MAPPER = useMemo(() => getD2RSubPageMapper(company), [company]);
	const showCompanySearchWhereIndexes = [
		D2RsubSectionsMap['RETAIL_DISCOUNT'],
		D2RsubSectionsMap['BEATS'],
		D2RsubSectionsMap['RETAILERS'],
		D2RsubSectionsMap['INVOICES']
	];

	useEffect(() => {
		localStorage.setItem('ADMIN_CATALOGUE_CLICKED', JSON.stringify(company));
	}, [company]);

	return (
		<Row className="h-100 p-0">
			<SideDrawer placement="left">
				<Sidebar
					selectedMenu={sidebarMenuItem}
					sideBarMenu={sidebarUtilD2r.sideBar}
					onMenuItemSelect={setSidebarMenuItem}
					topComponent={
						showCompanySearchWhereIndexes.includes(sidebarMenuItem) ? (
							<AutoCompleteInput
								getOptions={getCompanies}
								entityName="companies"
								selectedFilterItems={{ entityId: company?.id, name: company?.name }}
								responseFieldPath="companies"
								optionKeyPath="entityId"
								optionLabelPath="name"
								optionValuePath="entityId"
								placeholder="Search company"
								handleSelect={(option: any) => setCompany({ id: option?.entityId, name: option?.name })}
							/>
						) : null
					}
					bottomButtons={
						sidebarMenuItem === D2RsubSectionsMap['RETAIL_DISCOUNT'] ? (
							<Button icon={<DownloadOutlined />} type="primary" size="large" onClick={() => handleDownloadSalesmens()}>
								Download Salesmen Data
							</Button>
						) : null
					}
				/>
			</SideDrawer>
			<Col sm={24} lg={20} className={`scrollable`}>
				{D2R_SUB_PAGE_MAPPER[sidebarMenuItem]}
			</Col>
		</Row>
	);
};
