import { CLUSTER_MAPPING } from '../constants';

export const columns = [
	{
		title: 'Cluster',
		dataIndex: 'clusterName',
		key: 'clusterName',
		render: (clusterName) => CLUSTER_MAPPING[clusterName]
	},
	{
		title: 'POC',
		dataIndex: 'clusterAddress',
		key: 'id',
		render: (clusterAddress) => clusterAddress?.name
	},
	{
		title: 'POC Number',
		dataIndex: 'clusterAddress',
		key: 'id',
		render: (clusterAddress) => clusterAddress?.phone
	},
	{
		title: 'Address',
		dataIndex: 'clusterAddress',
		key: 'id',
		render: (clusterAddress) =>
			`${clusterAddress?.addressLine1}, ${clusterAddress?.addressLine2 ? clusterAddress?.addressLine2 : ''}, ${
				clusterAddress?.city
			}, ${clusterAddress?.state} ${clusterAddress?.pincode}`
	}
];
