import { notification } from 'antd';

/**
 *
 * @param {*} message (string) Message to display
 * @param {*} description (string) Description to display
 * @param {*} type (string) Type of notification
 * @param {*} placement (string) Placement of notification
 * @returns
 */
export function displayNotification(message, description, type = 'error', placement = 'topRight') {
	if (type === 'success') {
		notification.success({
			message: message,
			description: description,
			placement: placement
		});

		return;
	}

	notification.error({
		message: message,
		description: description,
		placement: placement
	});
}
