import { Button, Col, Form, Input, Modal, notification, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultPageConfig } from '../../../../constants';
import { apiOms } from '../../../../shared/api';
import { TopFilterComponent } from '../../../../shared/components/AppLayout/TopFilterComponent';
import { BeatsInfoSlider } from '../../../../shared/components/BeatsInfoSlider';
import { CustomTablePagination } from '../../../../shared/components/CustomTablePagination';
import { Loading } from '../../../../shared/components/Loading';
import { UploadBulkModal } from '../../../../shared/components/UploadBulkModal';
import { bulkD2rBeatsPreviewRoute, MAX_ROWS_EXCEED_ERROR_MSG } from '../../../../shared/components/UploadBulkPreview/constants';
import errorHandler from '../../../../shared/utils/errorHandler';
import { d2rBeatsColumns, beatsFilters } from './utils';
import { companyType } from '../D2r';

export const Beats = ({ company }: { company: companyType }) => {
	const [isUploadExcelModalVisible, setIsUploadExcelModalVisible] = useState(false);
	const [beats, setBeats] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [isCreateBeatModalVisible, setIsCreateBeatModalVisible] = useState(false);
	const [salesman, setSalesman] = useState([]);
	const [showBeatsInfoSlider, setShowBeatsInfoSlider] = useState('');

	const getSalesman = async () => {
		try {
			const { data } = await apiOms('/salesman');
			if (!data?.status) {
				throw new Error(data?.message);
			}
			setSalesman(data?.data);
		} catch (error) {
			errorHandler(error);
		}
	};

	useEffect(() => {
		!isCreateBeatModalVisible && getSalesman();
	}, [isCreateBeatModalVisible]);

	const topFilterConfig = useMemo(
		() =>
			beatsFilters({
				company,
				setIsUploadExcelModalVisible,
				setIsCreateBeatModalVisible
			}),
		[company]
	);
	const columnsConfig = useMemo(() => d2rBeatsColumns(setShowBeatsInfoSlider), []);

	const getBeats = useCallback(async () => {
		if (!company.id) {
			return;
		}
		const { data } = await apiOms.get(`/d2r/beats/?companyId=${company.id}&size=${pageConfig.pageSize}&page=${pageConfig.current}`);
		if (data?.status) {
			setBeats(data?.beats);
		}
	}, [company.id]);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			await getBeats();
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}, [getBeats]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	if (loading) {
		return <Loading loading={true} />;
	}

	const onSubmitCreateBeat = async (values: any) => {
		try {
			const { data } = await apiOms.post('/d2r/beats', { ...values, companyId: company.id });
			setIsCreateBeatModalVisible(false);
			fetchData();

			if (data.status) {
				notification.success({
					message: 'Beat Creted Successfully',
					description: data?.message,
					placement: 'topRight'
				});
			}
		} catch (err: any) {
			notification.error({
				message: 'Error in creating Beat',
				placement: 'topRight'
			});
		}
	};

	return (
		<>
			<Col sm={24} lg={24} span={24} className="main">
				<TopFilterComponent {...topFilterConfig} />
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={columnsConfig}
							data={beats}
							loading={loading}
							shouldRowSelection={false}
							selectedItem={undefined}
							selectedRowKeys={undefined}
							setSelectedRowKeys={undefined}
							setSelectedItem={undefined}
							onChangePage={(current: any, pageSize: any) => setPageConfig((v) => ({ ...v, current, pageSize }))}
						/>
					</div>
				</div>
			</Col>
			<UploadBulkModal
				instructionMessage={
					<ul className="instructions">
						<li>Download the excel template by clicking "Download Sample" button below</li>
						<li>Edit file with Name, Description/Area, Salesman Id and Comma Seperated Retailer Ids </li>
						<br />
						Note: {MAX_ROWS_EXCEED_ERROR_MSG}
					</ul>
				}
				templatePath={process.env.REACT_APP_EXCEL_BEAT_TEMPLATE}
				previewPath={bulkD2rBeatsPreviewRoute}
				modalTitle="Bulk D2R Beats Upload"
				isModalVisible={isUploadExcelModalVisible}
				setIsModalVisible={setIsUploadExcelModalVisible}
				companyId={company.id}
				shouldShowDownloadSample={true}
			/>
			<Modal
				title={'Create Beat'}
				visible={isCreateBeatModalVisible}
				onCancel={() => {
					setIsCreateBeatModalVisible(false);
				}}
				footer={null}
				// confirmLoading={isLoading}
				centered
				// className={styles.uploadBulkModal}
			>
				<Form size="small" layout="vertical" onFinish={(values) => onSubmitCreateBeat(values)}>
					<Form.Item
						name={'name'}
						label={'Name'}
						style={{ width: '65%' }}
						rules={[{ required: true, message: 'Please enter name' }]}
					>
						<Input size="middle" placeholder={`Enter Name`} />
					</Form.Item>

					<Form.Item
						name={'description'}
						label={'Description / Area'}
						style={{ width: '65%' }}
						rules={[{ required: true, message: 'Please enter description' }]}
					>
						<TextArea size="middle" placeholder={`Enter Description`} />
					</Form.Item>

					<Form.Item name={'salesmanId'} label="Salesman Name" rules={[{ required: true, message: 'Please select Salesman' }]}>
						<Select
							size="middle"
							style={{ width: '400px' }}
							placeholder="Select Salesman"
							showSearch
							optionFilterProp="children"
						>
							{salesman.map(({ id, firstName, lastName, phone }: any) => {
								return <Select.Option key={id} value={id}>{`${firstName} ${lastName}, (${phone})`}</Select.Option>;
							})}
						</Select>
					</Form.Item>

					<Form.Item>
						<Button
							type="primary"
							size="middle"
							block
							htmlType="submit"
							loading={loading}
							style={{ top: '20px', width: '10rem' }}
						>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Modal>
			{showBeatsInfoSlider && (
				<BeatsInfoSlider beatId={showBeatsInfoSlider} companyId={company.id} onClose={() => setShowBeatsInfoSlider('')} />
			)}
		</>
	);
};
