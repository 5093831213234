import { apiUms, apiOms, apiWms } from './index';

async function getWarehouseCompanyData() {
	try {
		const {
			data: { company }
		} = await apiWms.get(`/warehouse/company`);

		return company;
	} catch (err) {
		throw err;
	}
}

async function login(data, password, xsrfToken) {
	try {
		let authResp;

		/**
		 * in case of lightning we are using username to login
		 * a few old ligthning account might have email,phone as login id
		 */
		if (data.userLoginType === 'lightning') {
			authResp = await apiUms.post('/oauth2/authenticate', {
				username: data.username || data.email,
				phone: data.phone,
				password,
				xsrfToken,
				userLoginType: data.userLoginType
			});
		} else {
			authResp = await apiUms.post('/oauth2/authenticate', {
				email: data.email,
				phone: data.phone,
				password,
				xsrfToken,
				userLoginType: data.userLoginType
			});
		}

		const {
			data: {
				responseBody: { oauthCode }
			}
		} = authResp;

		await apiOms.get(`/cookie?oauthCode=${oauthCode}&userType=${data.userLoginType}`);

		return authResp;
	} catch (err) {
		throw err;
	}
}

function generateOmsCookie(oauthCode) {
	try {
		return apiOms.get(`/cookie?oauthCode=${oauthCode}`);
	} catch (err) {
		throw err;
	}
}

async function authenticateUser(state, client_id, redirect_url) {
	let authenticateData = await apiUms.get('/oauth2/authenticate', {
		params: {
			response_type: 'code',
			state,
			client_id,
			redirect_url
		}
	});

	// check if only ums cookie is expired then we have to manually remove oms cookie as well
	// This function is applied for all page except login page and signup page
	const { data } = authenticateData;
	if (!['/signin', '/signup'].includes(window.location.pathname) && data.responseCode === '000007') {
		try {
			await apiOms.delete('/cookie');
		} catch (e) {
			console.log('!!!!!!Error!!!!!!', e);
		}
	}

	return authenticateData;
}

async function logout(isClearCookie = true) {
	try {
		// Don't run both promises parallelly i.e. Promise.all()
		let logoutResponse = await apiUms.delete('/oauth2/logout');

		if (isClearCookie) {
			await apiOms.delete('/cookie');
			window.location.reload();
		}

		return logoutResponse;
	} catch (err) {
		throw err;
	}
}

function signUp(values, role) {
	return apiUms.post(`/ums/register/${role}`, values);
}

async function logoutUms() {
	try {
		return apiUms.delete('/oauth2/logout');
	} catch (err) {
		console.log('!!!!!!!!LogoutUms Error!!!!!!!!!!', err);
		throw err;
	}
}

export { login, authenticateUser, logout, signUp, generateOmsCookie, getWarehouseCompanyData, logoutUms };
