import { Form, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { ManualOrderAddressForm } from '../ShippingAddress';
import { IManualOrderFormName } from '../../utils';
import errorHandler from '../../../../../../shared/utils/errorHandler';
import { apiOms } from '../../../../../../shared/api';

interface ISalesmanDetails {
	onSubmitSalesmanForm: () => void;
	setShouldShowSalesmanForm: React.Dispatch<React.SetStateAction<boolean>>;
	shouldShowSalesmanForm: boolean;
	existOrderData: any;
}

export const SalesmanDetails = (props: ISalesmanDetails) => {
	const { onSubmitSalesmanForm, setShouldShowSalesmanForm, shouldShowSalesmanForm, existOrderData } = props;
	const [salesman, setSalesman] = useState([]);

	const getSalesman = async () => {
		try {
			const { data } = await apiOms('/salesman');
			if (!data?.status) {
				throw new Error(data?.message);
			}
			setSalesman(data?.data);
		} catch (error) {
			errorHandler(error);
		}
	};

	useEffect(() => {
		!shouldShowSalesmanForm && getSalesman();
	}, [shouldShowSalesmanForm]);

	return (
		<>
			<Row>
				<div>
					<div style={{ display: 'flex' }}>
						<Form.Item
							name={'d2rSalesmanId'}
							label="Salesman Name"
							rules={[{ required: true, message: 'Please select Salesman' }]}
							initialValue={existOrderData?.d2rSalesmanId}
						>
							<Select style={{ width: '400px' }} placeholder="Select Salesman" showSearch optionFilterProp="children">
								{salesman.map(({ id, firstName, lastName, phone }: any) => {
									return <Select.Option key={id} value={id}>{`${firstName} ${lastName}, (${phone})`}</Select.Option>;
								})}
							</Select>
						</Form.Item>
						{/* <Button
							style={{ marginLeft: '2rem', marginTop: '1.8rem' }}
							type="primary"
							onClick={() => setShouldShowSalesmanForm(true)}
						>
							Add Salesman
						</Button> */}
					</div>
				</div>
			</Row>

			{shouldShowSalesmanForm && (
				<ManualOrderAddressForm
					formName={IManualOrderFormName.SALESMAN_DETAILS}
					customStyle={''}
					editAddress={() => {}}
					formHeading={IManualOrderFormName.SALESMAN_DETAILS}
					onCreateNewStoreAddress={() => onSubmitSalesmanForm()}
					onCancelNewStoreAddress={() => setShouldShowSalesmanForm(false)}
					isStoreAddressForm={false}
					shouldShowFullForm={false}
					existOrderData={null}
				/>
			)}
		</>
	);
};
