import { Button, Form, Input, notification, Select } from 'antd';
import { createTemplate } from '../../../../shared/api/nms';
import errorHandler from '../../../../shared/utils/errorHandler';
import { availableNotificationTemplates } from './constants';

const { Option } = Select;

export const GupshupTemplateForm = ({ companyId, onAdd }) => {
	const handleFinish = async (values) => {
		try {
			const messageBody = String(values.messageBody).trim();
			const templateName = values.templateName;
			const { data } = await createTemplate({ companyId, messageBody, templateName });

			if (!data.status) {
				throw new Error(data.message);
			}
			notification.success({
				message: 'Success',
				description: data.message
			});
			onAdd();
		} catch (error) {
			errorHandler(error);
		}
	};
	return (
		<Form onFinish={handleFinish} layout="vertical">
			<Form.Item label="Template Name" name={'templateName'} rules={[{ required: true, message: 'Required' }]}>
				<Select>
					{availableNotificationTemplates.map((tmpl) => (
						<Option value={tmpl.value}>{tmpl.label}</Option>
					))}
				</Select>
			</Form.Item>

			<Form.Item label="Template" name={'messageBody'} rules={[{ required: true, message: 'Required' }]}>
				<Input.TextArea />
			</Form.Item>

			<Button htmlType="submit" type="primary">
				Save
			</Button>
		</Form>
	);
};
