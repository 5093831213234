import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';
import { Sidebar } from '../AppLayout/Sidebar';
import { SideDrawer } from '../SideDrawer/SideDrawer';
import { UploadBulkModal } from '../UploadBulkModal';
import { bulkCataloguePreviewRoute, MAX_ROWS_EXCEED_ERROR_MSG } from '../UploadBulkPreview/constants';
import amplitude from '../../../shared/utils/Amplitude';
import { EVENT_NAMES } from 'utils/analytics';

export function SellerCatalog({ sideBarMenu, shopType, setShopType }) {
	const [isUploadBulkCatalogueModalVisible, setIsUploadBulkCatalogueModalVisible] = useState(false);

	return (
		<>
			<UploadBulkModal
				instructionMessage={
					<ul className="instructions">
						<li>Download the excel template by clicking "Download Sample" button below</li>
						<li>Edit file with catalogue data and upload </li>
						<br />
						Note: {MAX_ROWS_EXCEED_ERROR_MSG}
					</ul>
				}
				templatePath={process.env.REACT_APP_EXCEL_CATALOGUE_TEMPLATE}
				previewPath={bulkCataloguePreviewRoute}
				modalTitle="Bulk Catalogue Upload"
				isModalVisible={isUploadBulkCatalogueModalVisible}
				setIsModalVisible={setIsUploadBulkCatalogueModalVisible}
			/>
			<SideDrawer placement="left">
				<Sidebar
					sideBarMenu={sideBarMenu}
					bottomButtons={
						<>
							<Button
								icon={<UploadOutlined />}
								type="primary"
								size="large"
								onClick={() => {
									amplitude.getInstance().logEvent(EVENT_NAMES.UPLOAD_BULK_CATALOG);
									setIsUploadBulkCatalogueModalVisible(true);
								}}
							>
								Upload Bulk Catalogs
							</Button>
						</>
					}
					selectedMenu={shopType}
					onMenuItemSelect={setShopType}
				/>
			</SideDrawer>
		</>
	);
}
