import { Button, Select } from 'antd';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { paymentStatus } from '../../../../constants';
import { DebouncedCompactSearch } from '../../../../shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import { searchOptions } from './constants';
import { IFilter } from './types';
import { ITopFilterUtil } from '../../commonTypes';
import { commonTopFilterRow } from '../../../../utils/filters';

export function topFilterUtil({ filter, setFilter, setPageConfigDefault, setModalVisible }: ITopFilterUtil<IFilter>): any {
	const setDate = (startDate: string, endDate: string) => {
		setPageConfigDefault();
		setFilter((prevFilter: IFilter) => ({ ...prevFilter, start: startDate, end: endDate }));
	};

	const handlePaymentStatusChange = (paymentStatus: string) => {
		setPageConfigDefault();
		setFilter((prevFilter: IFilter) => ({ ...prevFilter, paymentStatus }));
	};
	const topFilterRow = commonTopFilterRow(filter.start, filter.end);
	return {
		row1: [
			{
				...topFilterRow,
				onChange: (dateStrings: [string, string]) => setDate(...dateStrings)
			}
		],
		row1children: [
			<div key={1}>
				<DebouncedCompactSearch
					defaultSearchOptionType={filter.searchOptionType}
					searchOptions={searchOptions}
					setFilter={setFilter}
					setDefault={setPageConfigDefault}
					selectStyle={{ minWidth: '100px' }}
				>
					<span>Search By:</span>
				</DebouncedCompactSearch>
			</div>,
			<div key={2} className={styles.paymentStatusFilter}>
				<span>Payment Status:</span>
				<Select
					mode="multiple"
					maxTagCount="responsive"
					placeholder="Select Payment Status"
					style={{ width: '100%' }}
					value={filter.paymentStatus}
					allowClear={true}
					onChange={handlePaymentStatusChange}
				>
					{Object.keys(paymentStatus).map((status) => {
						return <Select.Option key={status}>{status}</Select.Option>;
					})}
				</Select>
			</div>
		],
		row2: [
			<div key={3}>
				<Button
					type="primary"
					onClick={() => {
						if (setModalVisible) {
							setModalVisible(true);
						}
					}}
				>
					Initiate Payment
				</Button>
			</div>
		]
	};
}
