import { InvoicingForm } from './InvoicingForm';
import { BankDetails } from './Remittance';

export const TOP_FILTER_TABS = [
	{ label: 'Invoicing', key: 'invoicing', component: <InvoicingForm /> }
	// { label: 'Remittance', key: 'remittance' }
];

export const REMITTANCE_TABS = [
	{ label: 'KYC Details', key: 'kycDetails' },
	{ label: 'Bank Details', key: 'bankDetails', component: <BankDetails /> }
];
