import { AdminUser } from '../AdminIUser';
import { Companies } from '../Companies';
import { Wherehouses } from '../Wherehouses';
import styles from './index.module.scss';
import { FormLayout } from 'shared/components/FormLayout';
import { Button } from 'antd';
import { CreateSubAdmin } from '../AdminIUser/CreateAdminUser';
import { userRoleEnum } from '../AdminIUser/constants';

const sideBarArray = [
	{
		label: 'Companies',
		parent: 'Companies',
		key: 1,
		icon: 'allSvgIcon'
	},
	{
		label: 'Warehouses',
		parent: 'Warehouses',
		key: 2,
		icon: 'allSvgIcon'
	},
	{
		label: 'Sub Admin',
		parent: 'Users',
		key: 3,
		icon: 'allSvgIcon'
	}
];

export const sidebarUtil = (userType) => {
	if (userType === 'sub_admin') {
		return sideBarArray.filter((item) => item.parent !== 'Users');
	}

	return sideBarArray;
};

export const ADMIN_MENU_OPTIONS = {
	Companies: 1,
	Warehouses: 2,
	Users: 3,
	CreateUser: 4
};

export function getAdminSubMenuWrapper() {
	return {
		1: <Companies />,
		2: <Wherehouses />,
		3: <AdminUser />,
		4: <CreateSubAdmin />
	};
}

export function createSubAdminForm({ formTitle, onSave }) {
	return (
		<FormLayout
			className={styles.form}
			formTitle={formTitle}
			isClose={false}
			onSave={onSave}
			formFields={[
				{
					label: 'Name',
					placeholder: 'Enter Name',
					name: 'name',
					id: 'name',
					required: true
				},
				{
					label: 'Phone',
					placeholder: 'Enter Phone',
					name: 'phone',
					id: 'phone',
					required: true
				},
				{
					label: 'Email',
					placeholder: 'Enter Email',
					name: 'email',
					id: 'email',
					required: true
				},
				{
					label: 'Password',
					placeholder: 'Enter Password',
					name: 'password',
					id: 'password',
					required: true
				},
				{
					name: 'user_role',
					id: 'user_role',
					label: 'Role',
					required: true,
					placeholder: 'Select Role',
					fieldType: 'select',
					options: userRoleEnum
				}
			]}
			customSubmitComponent={
				<Button type="primary" shape="round" size="large" htmlType="submit">
					{'Save'}
				</Button>
			}
		/>
	);
}
