import { BeatsD2R } from './Beats/Index';
import { companyType } from './D2r';
import { DiscountD2r } from './Discount/Index';
import { ExternalRetailers } from './ExternalRetailers';
import Invoices from './Invoices';
import Recomendation from './RecomendationEngine';
import { Retailers } from './Retailers';
import { SalesmanD2R } from './Salesmans';

export function getD2RSubPageMapper(company: companyType): { [key: string]: JSX.Element } {
	return {
		'1': <DiscountD2r company={company} />,
		'2': <BeatsD2R company={company} />,
		'3': <Retailers company={company} />,
		'4': <ExternalRetailers />,
		'5': <Recomendation />,
		'6': <SalesmanD2R />,
		'7': <Invoices company={company} />
	};
}
