// Delivery Partner Body should be all lower case
export const deliveryPartnersWithBody = [
	'goswift',
	'pickrr',
	'shiprocket',
	'shipway',
	'shyplitelite',
	'shyplitesurface',
	'shypliteair',
	'clickpost',
	'delhiverysurface',
	'delhiveryexpress',
	'blowhorn',
	'bluedart',
	'xpressbees',
	'ithinklogistics',
	'blitz',
	'shiperfecto'
];

export const deliveryPartnerFields = {
	goswift: [
		{ label: 'Client ID', name: 'clientId' },
		{ label: 'Username', name: 'username' },
		{ label: 'Password', name: 'password' }
	],
	pickrr: [{ label: 'Auth Token', name: 'password' }],
	delhiverysurface: [
		{ label: 'Auth Token', name: 'password' },
		{ label: 'Client', name: 'clientId' }
	],
	delhiveryexpress: [
		{ label: 'Auth Token', name: 'password' },
		{ label: 'Client', name: 'clientId' }
	],
	shipRocket: [
		{ label: 'Email', name: 'email' },
		{ label: 'Password', name: 'password' }
	],
	shipway: [
		{ label: 'Email', name: 'email' },
		{ label: 'Licence key', name: 'licenceKey' }
	],
	shypliteLite: [
		{ label: 'Seller ID', name: 'clientId' },
		{ label: 'App ID', name: 'appId' },
		{ label: 'Public Key', name: 'username' },
		{ label: 'App Secret', name: 'password' }
	],
	shypliteSurface: [
		{ label: 'Seller ID', name: 'clientId' },
		{ label: 'App ID', name: 'appId' },
		{ label: 'Public Key', name: 'username' },
		{ label: 'App Secret', name: 'password' }
	],
	shypliteAir: [
		{ label: 'Seller ID', name: 'clientId' },
		{ label: 'App ID', name: 'appId' },
		{ label: 'Public Key', name: 'username' },
		{ label: 'App Secret', name: 'password' }
	],
	clickPost: [
		{ label: 'UserName', name: 'username' },
		{ label: 'Seller ID', name: 'clientId' }
	],
	blueDart: [
		{ label: 'Login Id', name: 'loginId' },
		{ label: 'Origin Area', name: 'originArea' },
		{ label: 'Shipping License key', name: 'shippingLicenseKey' },
		{ label: 'Customer Code', name: 'customerCode' },
		{ label: 'Tracking License key', name: 'trackingLicKey' },
		{ label: 'Tracking Login Id', name: 'trackingLoginId' }
	],
	blowHorn: [
		{ label: 'Email', name: 'email' },
		{ label: 'Password', name: 'password' }
	],
	xpressbees: [
		{ label: 'Username', name: 'username' },
		{ label: 'Password', name: 'password' },
		{ label: 'Secret key', name: 'secretkey' },
		{ label: 'XB Key', name: 'XBkey' },
		{ label: 'Reverse XB Key', name: 'reverseXBkey' },
		{ label: 'Business Name', name: 'businessName' }
	],
	iThinkLogistics: [
		{ label: 'Access Token', name: 'accessToken' },
		{ label: 'Secret Key', name: 'secretkey' }
	],
	blitz: [
		{ label: 'Username', name: 'username' },
		{ label: 'Password', name: 'password' }
	],
	shiperfecto: [
		{ label: 'Client ID', name: 'clientId' },
		{ label: 'Username', name: 'username' },
		{ label: 'Password', name: 'password' }
	]
};
