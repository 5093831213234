import dayjs from 'dayjs';

export const searchOptions = [{ value: 'awb', label: 'AWB' }];

export const defaultFilter = {
	startDate: dayjs().subtract(30, 'day'),
	endDate: dayjs(),
	searchOptionType: searchOptions[0].value
};

export const undeliverReasons = [
	'Wrong Or Incomplete Address',
	'No Response from Customer',
	'Route Obstacles',
	'Refused by customer',
	'Bad Weather conditions',
	'Other'
];
