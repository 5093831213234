import reactDom from 'react-dom';
import { Col, Input, Select } from 'antd';
import styles from './index.module.scss';
import debounce from 'utils/debounce';
import { searchOptions } from './constants';
import { CLUSTER_MAPPING } from '../constants';

export const defaultFilter = {
	searchOptionType: searchOptions[0].value
};

export const topFilterUtil = ({ filter, setFilter, clusters, setPageConfigDefault }) => {
	const handleSearchOptionChange = (updatedSearchOptionType) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, searchOptionType: updatedSearchOptionType, searchValue: '' }));
	};
	const handleClusterChange = (clusterId) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, clusterId }));
	};

	const debouncedSearchChange = debounce(({ target: { value } }) => {
		reactDom.unstable_batchedUpdates(() => {
			setPageConfigDefault();
			setFilter((prevFilter) => ({ ...prevFilter, searchValue: value }));
		});
	}, 700);

	return {
		row1: [],
		row1children: [
			<Col>
				<div className={styles.searchContainer}>
					<lable>Search By:</lable>
					<Input.Group compact>
						<Select defaultValue={filter.searchOptionType} onChange={handleSearchOptionChange} style={{ minWidth: '150px' }}>
							{searchOptions.map(({ value, label }) => (
								<Select.Option key={value} value={value}>
									{label}
								</Select.Option>
							))}
						</Select>
						<Input style={{ width: '200px' }} allowClear onChange={debouncedSearchChange} placeholder="Search" />
					</Input.Group>
				</div>
			</Col>,
			<div className={styles.clusterFilter}>
				<span>Cluster:</span>
				<Select
					mode="multiple"
					placeholder="Select Cluster"
					style={{ width: '100%' }}
					allowClear
					value={filter.clusterId}
					maxTagCount="responsive"
					optionFilterProp={'children'}
					filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
					onChange={handleClusterChange}
				>
					{clusters.map((cluster) => {
						return (
							<Select.Option key={cluster.id} value={cluster.id}>
								{CLUSTER_MAPPING[cluster.clusterName]}
							</Select.Option>
						);
					})}
				</Select>
			</div>
		],
		row2: []
	};
};
