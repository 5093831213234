import { locationNewSvg } from '../../svgs/location_svg';
import styles from './index.module.scss';

interface MarkerProps {
	text: string;
	lat: number;
	lng: number;
	position: {
		lat: number;
		lng: number;
	};
	routeSequenceNumber?: number;
}

const MarkerComponent = (props: MarkerProps): React.ReactElement<MarkerProps> => {
	const { text, routeSequenceNumber, ...restProps } = props;

	return (
		<div className={styles.marker} {...restProps}>
			{routeSequenceNumber && <div className={styles.topHeading}>{routeSequenceNumber}</div>}
			{locationNewSvg(props.routeSequenceNumber)}
			<div className={styles.text}>{text || 'No Description'}</div>
		</div>
	);
};

export default MarkerComponent;
