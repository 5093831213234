export const gupshupTemplateColumns = [
	{
		title: 'Template name',
		dataIndex: 'name'
	},
	{
		title: 'Template Body',
		dataIndex: 'body'
	}
];
