import React, { useEffect, useState } from 'react';
import { Col, Row, Timeline } from 'antd';
import { fetchDriverInfo } from '../../api/lightning';
import styles from './index.module.scss';

import { Loading } from 'shared/components/Loading';

import { ReactComponent as CloseIcon } from '../../svgs/closeIcon.svg';
import { InfoSlider } from '../InfoSlider/InfoSlider';
import errorHandler from 'shared/utils/errorHandler';

import { DRIVER_STATUS_MAPPING } from '../../../app/AppAdmin/Lightning/constants';
import { HMSTrail } from '../OrderInfoSlider/components/OrderTrail/HMSOrderTrail';

export function DriverInfoSlider({ driverId, onClose }) {
	const [loading, setLoading] = useState(false);
	const [driver, setDriver] = useState(null);

	async function fetchDriverDetails() {
		try {
			setLoading(true);
			const { data } = await fetchDriverInfo(driverId);
			setDriver({ data: data.driver, trail: data.trail, totalOrders: data.totalOrders });
		} catch (error) {
			errorHandler(error);
			setDriver(null);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchDriverDetails();
	}, [driverId]);

	return (
		<InfoSlider onClose={onClose}>
			<div className={styles.main} style={{ width: '28rem' }}>
				{loading && <Loading loading={loading} />}
				<CloseIcon title="Close" className="closeBtn" onClick={onClose} />

				<Col className={styles.orderInfoContainer}>
					<Row className={styles.paddedRow}>
						<Col span={24}>
							<Row className={styles.row1}>
								<Col span={16}>
									<span className="title">{` ${driver?.data?.name}`}</span>
								</Col>
								<Col span={8}>
									{driver?.data?.isActive ? (
										<span className="title" style={{ color: 'green' }}>
											{' '}
											Active
										</span>
									) : (
										<span className="title">Not Active</span>
									)}
								</Col>
							</Row>
							<Row style={{ margin: '1rem 0' }}>
								<Col span={16}>
									<span className="title">
										{' '}
										<b>Phone: </b> {driver?.data?.phone}
									</span>
								</Col>
							</Row>
							<Row style={{ margin: '0.5rem 0' }}>
								<Col span={16}>
									<b>Status: </b> {DRIVER_STATUS_MAPPING[driver?.data?.status]}
								</Col>
							</Row>
						</Col>
						<Col>
							<Row style={{ margin: '1rem 0' }}>
								<b>Total Orders Delivered : </b> {driver?.totalOrders}
							</Row>

							<Row style={{ margin: '1rem 0' }}>
								<b>Recent Activity:</b>
							</Row>
							<Row className="row2">
								{driver?.trail?.map((trail) => (
									<Timeline>
										<HMSTrail trailDetails={trail} />
									</Timeline>
								))}
							</Row>
						</Col>
					</Row>
				</Col>
			</div>
		</InfoSlider>
	);
}
