import { Orders } from '../../AppAdmin/Lightning/Orders';

const sideBarArray = ['All'];

export const sidebarUtil = {
	sideBar: sideBarArray.map((item, index) => ({ key: index + 1, label: item, icon: 'allSvgIcon' }))
};

export function getLightningSubPageMapper() {
	return {
		1: <Orders lightningPanel={true} />
	};
}
