import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Timeline, Tooltip, Upload, message } from 'antd';
import styles from './index.module.scss';

import { ReactComponent as CloseIcon } from '../../svgs/closeIcon.svg';
import { InfoSlider } from '../InfoSlider/InfoSlider';
import errorHandler from 'shared/utils/errorHandler';

import { ICapturePaymentFormInputFields } from '../OrderInfoSlider/utils';
import { apiD2r } from '../../api';
import { Loading } from '../Loading';
import { CheckCircleFilled, UploadOutlined } from '@ant-design/icons';
import { PaymentLogs } from './PaymentLogs';
import { sortByDate } from '../../utils/date';

export function D2RInvoiceSlider(props: any) {
	const { orderId, onClose } = props;
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState({});
	const [itemUploadStatus, setItemUploadStatus] = useState(false) as any;
	const [logs, setLogs] = useState([]) as any;
	const [invoice, setInvoice] = useState([]) as any;

	//to override the internal antd http calls
	const dummyReq = (options: any) => {
		const { onSuccess } = options;
		onSuccess();
	};

	const getPaymentLogs = async () => {
		try {
			setLoading(true);
			const response = await apiD2r.get(`/collections/${orderId}`);
			if (response?.data?.status) {
				setLogs(response?.data?.logs);
				setInvoice(response?.data?.invoice);
			} else {
				throw new Error(response?.data.message || 'Could not get payments');
			}
		} catch (error: unknown) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	};

	const onFileChange = ({ fileList }: any) => {
		try {
			const proof = fileList[0]?.originFileObj;

			//becauase for one upload onFileChange is called many times, so to avoid it
			if (!proof) return;

			const img = new Image();
			img.src = URL.createObjectURL(proof);
			setFile({});

			img.onload = () => {
				let reader = new FileReader();
				let result;

				//after async reading of the file sets the result to the result variable inside reader
				reader.onloadend = () => {
					result = reader.result;

					setFile({ data: reader.result, name: proof.name, url: img.src });
				};

				//this function reads the file in a async way and gives back a base64 data in the result
				reader.readAsDataURL(proof);
				return result;
				// convertToBase64(file, productName, img, setFile);
			};
		} catch (error) {
			errorHandler(error);
		}
	};

	useEffect(() => {
		getPaymentLogs();
	}, [orderId]);

	async function handleCapturePayment(formData: any) {
		try {
			console.log(formData);
			setLoading(true);
			if (formData.amount === 0) {
				throw new Error('Please Select Valid Amount');
			}
			const response = await apiD2r.patch('/capture-payment', {
				id: orderId,
				proof: file,
				...formData
			});

			if (response.data.status) {
				message.success('Payment Captured');
				onClose();
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}

	return (
		<InfoSlider onClose={() => {}}>
			<div className={styles.main} style={{ width: '28rem' }}>
				{loading && <Loading loading={loading} />}
				<CloseIcon title="Close" className="closeBtn" onClick={onClose} />

				<Col className={styles.retailerContainer}>
					<Row className={styles.paddedRow}>
						<Col span={24}>
							<Row className={styles.row1}>
								<span className="title">Capture Payment for {invoice?.invoiceId}</span>
							</Row>
							<h6>
								Balance <strong>₹{Number(invoice?.finalPrice - invoice?.amountPaid).toFixed(2)} </strong>
							</h6>
							<Form
								labelCol={{ span: 4 }}
								wrapperCol={{ span: 15 }}
								layout="horizontal"
								style={{ marginTop: '1rem' }}
								onFinish={handleCapturePayment}
							>
								{ICapturePaymentFormInputFields.map((e) => (
									<Form.Item rules={e.rules} key={e.key} name={e.key}>
										<Input type={e.type} placeholder={e.placeholder} />
									</Form.Item>
								))}
								<Form.Item key={0} name={'mode'} rules={[{ required: true, message: 'Please select payment Mode' }]}>
									<Select placeholder={'Select Payment Mode'}>
										{['UPI', 'CASH', 'CHEQUE', 'BANK TRANSFER', 'CN-ADJUSTED'].map((mode) => (
											<Select.Option value={mode}>{mode}</Select.Option>
										))}
									</Select>
								</Form.Item>
								<span>Upload Payment Proof</span>
								<Tooltip title="Image size should be less than 600 KB" placement="right">
									<Upload
										customRequest={dummyReq}
										accept="image/*"
										multiple={false}
										onChange={({ fileList }) => {
											onFileChange({ fileList });
											setItemUploadStatus(true);
										}}
										style={{ marginBlock: '15px' }}
										showUploadList={false}
									>
										<Button
											type="text"
											size="middle"
											icon={
												itemUploadStatus ? <CheckCircleFilled style={{ color: '#228B22' }} /> : <UploadOutlined />
											}
										></Button>
									</Upload>
								</Tooltip>
								<br /> <br />
								<Button type="primary" htmlType="submit" disabled={invoice?.finalPrice - invoice?.amountPaid < 1}>
									Submit
								</Button>
							</Form>
							<br /> <br />
							{logs?.length ? (
								<>
									<Row className={styles.row1}>
										<span className="title">Payment Logs</span>
									</Row>
									{sortByDate({
										data: logs,
										fieldName: 'createdAt',
										sortFilter: 'DESC'
									}).map((log: any, index: number) => (
										<Timeline>
											<PaymentLogs paymentLog={log} key={index}></PaymentLogs>
										</Timeline>
									))}
								</>
							) : (
								<></>
							)}
						</Col>
					</Row>
				</Col>
			</div>
		</InfoSlider>
	);
}
