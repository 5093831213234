import { Profile } from './Profile';
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Sidebar } from 'shared/components/AppLayout/Sidebar';
import { sidebarUtil } from './utils';
import styles from './index.module.scss';
import { SideDrawer } from '../../../shared/components/SideDrawer/SideDrawer';

export const settingsRoute = '/settings';

const SETTINGS_SUB_PAGE_MAPPER = {
	1: <Profile />
};

export function SettingsPage(props = {}) {
	const [sidebarMenuItem, setSidebarMenuItem] = useState(1);

	return (
		<Row className="h-100 p-0">
			<SideDrawer placement="left">
				<Sidebar
					sideBarMenu={sidebarUtil.sideBar}
					bottomButtons={null}
					selectedMenu={sidebarMenuItem}
					onMenuItemSelect={setSidebarMenuItem}
				/>
			</SideDrawer>
			<Col sm={24} lg={20}>
				<div className={styles.settingsPageContainer}>{SETTINGS_SUB_PAGE_MAPPER[sidebarMenuItem]}</div>
			</Col>
		</Row>
	);
}
