import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { InventoryActionType } from './ScanAndAddInventoryModal';

export const scanAndAddInventorycolumn = (
	handleScannedWsinQuantity: (id: string, shouldIncQty: boolean) => void,
	handleRemoveScannedWsin: (id: string) => void
) => [
	{
		title: 'WSIN',
		dataIndex: 'wsin',
		key: 'id'
	},
	{
		title: 'Quantity',
		dataIndex: 'quantity',
		key: 'id'
	},
	{
		title: 'Action Type',
		key: 'actionType',
		dataIndex: 'actionType',
		render: (data: string) => <Tag color={data === InventoryActionType.INBOUND ? 'green' : 'red'}>{data}</Tag>
	},
	{
		title: 'Actions/Error',
		key: 'address',
		render: (data: any) => {
			return data?.reason ? (
				<p>{data.reason}</p>
			) : (
				<>
					<Button onClick={() => handleScannedWsinQuantity(data.id, true)} icon={<PlusOutlined />} />
					<Button
						disabled={data.quantity === 1}
						onClick={() => handleScannedWsinQuantity(data.id, false)}
						icon={<MinusOutlined />}
					/>
				</>
			);
		}
	},
	{
		title: '#',
		key: 'address',
		render: (data: any) => {
			return (
				<>
					{/* @ts-ignore */}
					<Button type="danger" onClick={() => handleRemoveScannedWsin(data.id)}>
						Remove
					</Button>
				</>
			);
		}
	}
];
