import { DashOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Popover, Tooltip } from 'antd';
import { useState } from 'react';

export function TrailDetails({ trailDetails, showTrailCondition = true }) {
	const [showDescription, setShowDescription] = useState(false);

	if (trailDetails && showTrailCondition) {
		return (
			<Col style={{ marginTop: '-0.4rem' }}>
				<Popover
					placement="left"
					content={trailDetails.description}
					title="Reason"
					trigger="click"
					visible={showDescription}
					onVisibleChange={() => setShowDescription((prevState) => !prevState)}
				>
					<Tooltip title="Click to view detail" color={'green'} placement="top">
						<Button
							type="text"
							icon={
								showDescription ? (
									<DashOutlined style={{ fontSize: '0.9rem' }} />
								) : (
									<InfoCircleOutlined style={{ fontSize: '0.9rem' }} />
								)
							}
							onClick={(e) => e.stopPropagation()}
						/>
					</Tooltip>
				</Popover>
			</Col>
		);
	}
	return null;
}
