import { QuestionCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { useState } from 'react';

interface IInfoPopOver {
	description: string;
}

export const InfoPopOver = (props: IInfoPopOver) => {
	const { description } = props;

	const [showRemarks, setShowRemarks] = useState<boolean>(false);

	return (
		<Popover
			className="mb-2"
			placement="left"
			content={description}
			title=""
			trigger="click"
			visible={showRemarks}
			onVisibleChange={() => setShowRemarks((showRemarks) => !showRemarks)}
			overlayStyle={{
				width: '20vw'
			}}
		>
			<Button style={{ color: '#faad14' }} type="text" icon={showRemarks ? <QuestionCircleFilled /> : <QuestionCircleOutlined />} />
		</Popover>
	);
};
