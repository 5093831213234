import { Button, Col, Select } from 'antd';
import dayjs from 'dayjs';
import { DebouncedCompactSearch } from 'shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import export_svg from 'shared/svgs/export_svg';
import { JOURNEY_STATUS } from '../constants';
import { searchOptions } from './constants';
import styles from './index.module.scss';
import { commonTopFilterRow } from '../../../../utils/filters';

export function topFilterUtil({ filter, setFilter, setPageConfigDefault, onDownloadTrips }) {
	const setDate = (startDate, endDate) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, startDate, endDate }));
	};

	const handleTripStatusChange = (status) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, status }));
	};
	const topFilterRow = commonTopFilterRow(filter.startDate, filter.endDate);
	return {
		row1: [
			{
				...topFilterRow,
				onChange: (_dates, dateStrings) => setDate(...dateStrings)
			}
		],
		row1children: [
			<Col>
				<div className={styles.searchContainer}>
					<DebouncedCompactSearch
						defaultSearchOptionType={filter.searchOptionType}
						searchOptions={searchOptions}
						setFilter={setFilter}
						setDefault={setPageConfigDefault}
						selectStyle={{ minWidth: '150px' }}
					>
						<span>Search By:</span>
					</DebouncedCompactSearch>
				</div>
			</Col>,
			<div className={styles.orderStatusFilter}>
				<span>Status:</span>
				<Select
					mode="multiple"
					placeholder="Select Order Status"
					maxTagCount="responsive"
					style={{ width: '100%' }}
					allowClear
					value={filter.status}
					onChange={handleTripStatusChange}
				>
					{Object.keys(JOURNEY_STATUS).map((tripStatus) => {
						return <Select.Option key={tripStatus}>{tripStatus}</Select.Option>;
					})}
				</Select>
			</div>
		],
		row2: [
			<Col>
				<Button style={{ marginTop: '1.5rem' }} onClick={() => onDownloadTrips()}>
					<span>{export_svg}</span>
					<span style={{ marginLeft: '.4rem', fontSize: '0.85rem' }}>Export</span>
				</Button>
			</Col>
		]
	};
}
