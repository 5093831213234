import { Switch } from 'antd';
import { useState } from 'react';
import styles from 'app/AppSeller/SettingsPage/Warehouses/components/WarehouseCard/index.module.scss';
import { apiWms } from '../../../api';
import errorHandler from '../../../utils/errorHandler';

interface IWhAddressDetails {
	addressLine1: string;
	addressLine2: string;
	city: string;
	state: string;
	pincode: number | string;
	phone: number | string;
}

interface IWHaddress {
	id?: string;
	index?: number | string;
	warehouseType?: string;
	invoicePrefix?: string;
	active?: boolean;
	rtoOtherWh?: boolean;
	profile?: IWhAddressDetails | any;
}

interface PropsInt {
	warehouse: IWHaddress;
}

export function WarehouseCard({ warehouse }: PropsInt) {
	const {
		id,
		index,
		warehouseType,
		invoicePrefix,
		rtoOtherWh,
		profile: { addressLine1, addressLine2, city, state, pincode, phone }
	} = warehouse;

	const [isWarehouseActive, setIsWarehouseActive] = useState(rtoOtherWh);
	const [isLoading, setIsLoading] = useState(false);

	const onToggleWarehouseRTO = async (updatedActiveStatus: boolean) => {
		try {
			setIsLoading(true);
			await apiWms.patch(`warehouse/${id}/rto`, { rtoOtherWh: updatedActiveStatus });
			setIsWarehouseActive(updatedActiveStatus);
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className={styles.warehouseCard}>
			<div className={styles.warehouseCardInnerContainer}>
				<div className={styles.left}>
					<div>
						<div className={styles.warehouseId}>{index}</div>
					</div>
					<div>
						<h5 className={styles.warehouseType}>{warehouseType} Warehouse</h5>

						<address className={styles.address}>
							{addressLine1}, {addressLine2}, <br />
							{city}, {state}
							<br />
							Pincode : {pincode}
							<br />
							Contact: {phone}
						</address>

						<p className={styles.invoicePrefix}>Invoice Prefix: {invoicePrefix}</p>
					</div>
				</div>

				<div className={styles.right}>
					<Switch checked={isWarehouseActive} onChange={onToggleWarehouseRTO} loading={isLoading} />
				</div>
			</div>
		</div>
	);
}
