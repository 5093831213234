import { Col, Popover, Button, Timeline } from 'antd';
import { useState } from 'react';
import dayjs from 'dayjs';
import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

export const PaymentLogs = ({ paymentLog }: any) => {
	const [showRemarks, setShowRemarks] = useState(false);
	let descriptionComponent;
	const description = `${String(paymentLog.remarks)} 
	${paymentLog?.mode && `, Mode -  ${paymentLog?.mode}, `}${
		paymentLog.createdAt && `Time -  ${dayjs(paymentLog?.createdAt).format('MMM DD, YYYY hh:mm A')}`
	}`;

	if (paymentLog) {
		descriptionComponent = (
			<Col className={styles.iconWrap}>
				<Popover
					placement="left"
					content={description}
					title="Remarks"
					trigger="click"
					visible={showRemarks}
					onVisibleChange={() => setShowRemarks((showRemarks) => !showRemarks)}
					overlayStyle={{
						width: '20vw'
					}}
				>
					<Button type="text" icon={showRemarks ? <InfoCircleFilled /> : <InfoCircleOutlined />} />
				</Popover>
			</Col>
		);
	}

	return (
		<Timeline.Item>
			<span style={{ display: 'flex' }}>
				{` Payment of amount ₹${paymentLog.amount} captured, `}
				{paymentLog.remarks}
				{descriptionComponent}

				{paymentLog?.proofUrl && (
					<Button
						size="small"
						onClick={() => {
							window.open(`${paymentLog?.proofUrl}`, '_blank');
						}}
					>
						Proof
					</Button>
				)}
			</span>
		</Timeline.Item>
	);
};
