import { Modal, Form, Select, Input, Row, Col, notification, Checkbox } from 'antd';
import { indianStates } from 'constants/indianStates';
import { antDesignValidator } from 'helper';
import { useContext, useState } from 'react';
import { apiOms } from 'shared/api';
import { getPincodeDetails } from 'shared/api/orders';
import { AuthContext } from 'shared/contexts/Auth';
import errorHandler from 'shared/utils/errorHandler';
import styles from './index.module.scss';

export function RequestNewAddressModal({ isModalVisible = true, setIsModalVisible, fetchStoreAddress }) {
	const [isLoading, setIsLoading] = useState(false);
	const closeModal = () => setIsModalVisible(false);
	const [form] = Form.useForm();
	const {
		authState: { profile }
	} = useContext(AuthContext);

	const { id } = profile;

	const raiseStoreAddressRequest = async (formValues) => {
		try {
			const storeName = formValues?.storeName;
			setIsLoading(true);
			const body = {
				companyId: id,
				GSTIN: formValues.GSTIN,
				storeName,
				email: formValues.email,
				address1: formValues.address1,
				phone: formValues.phone,
				city: formValues.city,
				pincode: formValues.zip || formValues.pincode,
				province: formValues.state,
				country: 'India',
				address2: formValues.address2,
				company: '',
				latitude: 0,
				longitude: 0,
				countryCode: 'IN',
				provinceCode: '',
				isDistributor: formValues.isDistributor
			};

			const {
				data: { message }
			} = await apiOms.post('/d2r/store-address', body);

			notification.success({
				description: message,
				position: 'topRight'
			});
		} catch (error) {
			console.log(error);
			errorHandler(error);
		} finally {
			setIsLoading(false);
			closeModal();
			fetchStoreAddress();
		}
	};

	const onFinishForm = async () => {
		const formValues = await form.validateFields();
		raiseStoreAddressRequest(formValues);
	};

	/**
	 * getWarehousePinDetails on change of pincode value
	 * @param {*} pincode
	 */
	const getWarehousePinDetails = async (pincode) => {
		try {
			// Pincode length should be 6
			if (pincode.length !== 6) return;

			// Pincode length greater than 6 then pincode and is invalid and city and state will ne in its initial state
			if (pincode.length > 6) {
				editStoreAddress({
					state: undefined,
					city: ''
				});
				return;
			}

			setIsLoading(true);
			// Get pincode details
			let {
				data: { warehousePinDetails }
			} = await getPincodeDetails(pincode);

			if (warehousePinDetails[0]) {
				editStoreAddress({
					state: String(warehousePinDetails[0]['state'] || warehousePinDetails[0]['State']).replace(/"/g, ''),
					city: String(warehousePinDetails[0]['district'] || warehousePinDetails[0]['District']).replace(/"/g, '')
				});

				return;
			}

			editStoreAddress({
				state: undefined,
				city: ''
			});
		} catch (e) {
		} finally {
			setIsLoading(false);
		}
	};

	const editStoreAddress = (updatedAddress) => {
		form.setFieldsValue({
			...form.getFieldsValue(),
			...updatedAddress
		});
	};

	return (
		<Modal
			title="Request New Store Address"
			visible={isModalVisible}
			onCancel={closeModal}
			confirmLoading={isLoading}
			okText="Submit Request"
			onOk={onFinishForm}
			width={900}
		>
			<Form form={form} layout="vertical">
				<h5 className={styles.subHeading}>Contact Person</h5>

				<Row gutter={24}>
					<Col span={8}>
						<Form.Item label="Store Name" name={'storeName'} rules={[{ required: true, message: 'Please input Store name' }]}>
							<Input placeholder="John" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label="Phone (10 digits)"
							name={'phone'}
							rules={[{ required: true, message: 'Please input Phone Number' }, antDesignValidator.phoneNumber]}
						>
							<Input placeholder="9900097000" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label="Email"
							name={'email'}
							rules={[
								{ required: true, message: 'Please input Email' },
								{
									type: 'email',
									message: 'Please input valid E-mail!'
								}
							]}
						>
							<Input placeholder="john.wick@mail.com" />
						</Form.Item>
					</Col>
				</Row>

				<h5 className={styles.subHeading}>Address</h5>

				<Row gutter={24}>
					<Col span={8}>
						<Form.Item
							label="Address Line 1"
							name={'address1'}
							rules={[{ required: true, message: 'Please input Address Line 1' }]}
						>
							<Input placeholder="12/33 Abc Street" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label="Address Line 2"
							name={'address2'}
							rules={[{ required: true, message: 'Please input Address Line 2' }]}
						>
							<Input placeholder="Karol Bagh" />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={8}>
						<Form.Item
							label="Pincode"
							name={'zip'}
							rules={[{ required: true, message: 'Please input Pincode' }, antDesignValidator.pincode]}
						>
							<Input placeholder="600011" onChange={(event) => getWarehousePinDetails(event.target.value)} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label="City" name={'city'} rules={[{ required: true, message: 'Please input City' }]}>
							<Input placeholder="Delhi" disabled={isLoading} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label="State" name={'state'} rules={[{ required: true, message: 'Please input State' }]}>
							<Select disabled={isLoading} showSearch placeholder="Delhi">
								{indianStates.map(({ label, value }) => {
									return (
										<Select.Option value={value} key={value}>
											{label}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col>
						<Form.Item label="GSTIN" name={'GSTIN'}>
							<Input placeholder="Optional" maxLength={15} minLength={15} />
						</Form.Item>
					</Col>
					<Col>
						<Form.Item initialValue={false} valuePropName="checked" name={'isDistributor'}>
							<Checkbox className={styles.Checkbox} style={{ marginTop: '35px' }} defaultChecked={false}>
								Is Distributor
							</Checkbox>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
