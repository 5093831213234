import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select } from 'antd';
import { useState } from 'react';
import { MAX_ROWS_EXCEED_ERROR_MSG } from 'shared/components/UploadBulkPreview/constants';
import styles from './index.module.scss';
import { BeatsModal } from './utils';

export function Beats() {
	const [isUploadBulkOrderModalVisible, setIsUploadBulkOrderModalVisible] = useState(false);

	const VEHICLE = {
		Tata_Ace: 1,
		Tata_407: 1,
		Mahindra_Jeeto: 1,
		Maruti_Suzuki_Super_Carry: 1,
		Mahindra_Bolero_Pikup4x4: 1,
		Bike: 1
	};

	return (
		<>
			<Col className={styles.topHeader}>
				<Col span={6}>
					<strong>BEATS PLANNER</strong>
				</Col>
			</Col>
			<Col className={styles.contentPage}>
				<Row className={styles.beatConfig}>
					<Col>
						<strong>Vehicle:</strong> <br />
						<Select maxTagCount="responsive" placeholder="Select Vehicle" style={{ width: '15rem' }} allowClear>
							{Object.keys(VEHICLE).map((v) => {
								return <Select.Option key={v}>{v}</Select.Option>;
							})}
						</Select>
					</Col>
					<Col>
						<strong>Beat Size:</strong>
						<Input placeholder="Beat Size"></Input>
					</Col>
					<Col style={{ marginTop: '1rem' }}>
						{isUploadBulkOrderModalVisible && (
							<BeatsModal
								instructionMessage={
									<ul className={'instructions'}>
										<li>Download the excel template by clicking "Download Sample" button below</li>
										<li>Edit file with order data and upload </li>
										<br />
										Note: {MAX_ROWS_EXCEED_ERROR_MSG}
									</ul>
								}
								templatePath="https://wh-staging-s3.s3.ap-south-1.amazonaws.com/excel/BEAT-762.xlsx"
								modalTitle="Upload order and Download Beats"
								isModalVisible={isUploadBulkOrderModalVisible}
								setIsModalVisible={setIsUploadBulkOrderModalVisible}
							/>
						)}
						<Button
							icon={<UploadOutlined />}
							type="primary"
							size="large"
							onClick={() => {
								setIsUploadBulkOrderModalVisible(true);
							}}
						>
							Upload Orders and Download Beat
						</Button>
					</Col>
				</Row>

				<Row className={styles.beatConfig}></Row>
			</Col>
		</>
	);
}
