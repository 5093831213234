import dayjs from 'dayjs';

export const formatDate = (date, format = 'DD MMMM YYYY') => dayjs(date).format(format);
export const formatTime = (date, format = 'h:mm A') => dayjs(date).format(format);

// returns new copy of sorted Array
export const sortByDate = ({ data, fieldName, sortFilter = 'ASC' }) => {
	const clonedData = JSON.parse(JSON.stringify(data));

	clonedData.sort((element1, element2) => {
		const date1 = element1[fieldName];
		const date2 = element2[fieldName];
		let sortElements;

		if (sortFilter === 'ASC') {
			sortElements = dayjs(date1).isBefore(dayjs(date2)) ? -1 : 1;
		} else {
			sortElements = dayjs(date1).isAfter(dayjs(date2)) ? -1 : 1;
		}
		return sortElements;
	});

	return clonedData;
};
