import { apiWms } from './index';

function getCompanyWarehouses(companyId) {
	if (companyId) {
		return apiWms.get('/company/warehouses', { params: { companyId } });
	}
	return apiWms.get('/company/warehouses');
}

function attachedWarehouseToCompany(data) {
	return apiWms.post('/company/attach-warehouse', data);
}

export { getCompanyWarehouses, attachedWarehouseToCompany };
