import { Tag, Progress } from 'antd';
import dayjs from 'dayjs';
import { tagColor, taskStatus } from './constants';

export default function useColumns(progressMap: any) {
	return [
		{
			title: 'Name',
			dataIndex: 'name',
			render: (name: string) => <div className="text-center">{name}</div>
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: (status: taskStatus, record: any) => (
				<div className="text-center">
					{progressMap && progressMap[record.taskId] ? (
						progressMap[record.taskId] === 100 ? (
							<Tag color={tagColor[taskStatus.COMPLETED]}>{taskStatus.COMPLETED}</Tag>
						) : (
							<Progress type="circle" percent={Math.round(progressMap[record?.taskId])} width={40} />
						)
					) : (
						<Tag color={tagColor[status]}>{status}</Tag>
					)}
				</div>
			)
		},
		{
			title: 'Started At',
			dataIndex: 'startedAt',
			render: (startedAt: string) => <div className="text-center">{dayjs(startedAt).format('MMM D, YYYY h:mm A')}</div>,
			width: 150,
			sorter: true
		},
		{
			title: 'Ended At',
			dataIndex: 'endedAt',
			render: (endedAt: string) => <div className="text-center">{endedAt ? dayjs(endedAt).format('MMM D, YYYY h:mm A') : '-'}</div>,
			width: 150,
			sorter: true
		},
		{
			title: 'Failure Reason',
			dataIndex: 'description',
			render: (description: string) => <div className="text-center">{description || '-'}</div>
		},
		{
			title: 'Download Link',
			dataIndex: 'downloadLink',
			render: (downloadLink: string) => (
				<div className="text-center">
					{downloadLink ? (
						<a
							target="_blank"
							rel="noreferrer"
							href={`https://wherehouse-prod-content.s3.ap-south-1.amazonaws.com/${downloadLink}`}
						>
							Download Link
						</a>
					) : (
						<span>-</span>
					)}
				</div>
			)
		}
	];
}
