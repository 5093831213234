import { Button, Form, Select } from 'antd';
import { IManualOrderFormName } from '../../utils';
import { ManualOrderAddressForm } from '../ShippingAddress';
import styles from '../../index.module.scss';

interface IStoreAddress {
	isNewStoreAddress: boolean;
	setIsNewStoreAddress: any;
	addNewStoreAddress: any;
	editStoreAddress: any;
	storeAddressData: any;
	editStoreDetailsSelection: any;
	showStoreAddress: boolean;
	existOrderData: any;
}

export const StoreAddress = (props: IStoreAddress) => {
	const {
		isNewStoreAddress,
		setIsNewStoreAddress,
		addNewStoreAddress,
		editStoreDetailsSelection,
		storeAddressData,
		editStoreAddress,
		showStoreAddress,
		existOrderData
	} = props;

	return (
		<>
			{!isNewStoreAddress && showStoreAddress && (
				<div>
					<h4 className={styles.heading}>{IManualOrderFormName.STORE_ADDRESS_DETAILS}</h4>

					<div style={{ display: 'flex' }}>
						<Form.Item
							name={IManualOrderFormName.STORE_ADDRESS}
							label="Store Address"
							rules={[{ required: true, message: 'Please select store address' }]}
							initialValue={existOrderData?.shippingD2RAddressId}
						>
							<Select
								style={{ width: '400px' }}
								placeholder="Select Store Address"
								showSearch
								optionFilterProp="children"
								onSelect={editStoreDetailsSelection}
							>
								{storeAddressData.map(({ id, firstName, lastName, pincode, addressLine1, isActive }: any) => {
									return (
										isActive && (
											<Select.Option
												value={id}
												key={id}
											>{`${firstName} ${lastName}, ${pincode}, ${addressLine1}`}</Select.Option>
										)
									);
								})}
							</Select>
						</Form.Item>
						<Button
							style={{ marginLeft: '2rem', marginTop: '1.8rem' }}
							type="primary"
							onClick={() => setIsNewStoreAddress(true)}
						>
							New Store Address
						</Button>
					</div>
				</div>
			)}
			{isNewStoreAddress && (
				<ManualOrderAddressForm
					formName={IManualOrderFormName.STORE_ADDRESS}
					customStyle={''}
					editAddress={editStoreAddress}
					formHeading={IManualOrderFormName.STORE_ADDRESS_DETAILS}
					onCreateNewStoreAddress={() => addNewStoreAddress()}
					onCancelNewStoreAddress={() => setIsNewStoreAddress(false)}
					isStoreAddressForm={true}
					existOrderData={null}
				/>
			)}
		</>
	);
};
